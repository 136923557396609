import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { BaseModule } from 'components';

@Component({
	selector: 'signup-settings-accordion',
	template: `
		<mat-expansion-panel
			id="panel"
			hideToggle
			[expanded]="expanded"
			[class]="expanded ? 'open' : ''"
			(opened)="toggle.emit()"
		>
			<mat-expansion-panel-header [class]="className" (click)="onClick()">
				<mat-panel-title class="title">{{ title }}</mat-panel-title>
				<mat-icon *ngIf="!expanded">keyboard_arrow_down</mat-icon>
				<mat-icon *ngIf="expanded">keyboard_arrow_up</mat-icon>
			</mat-expansion-panel-header>
			<column>
				<row class="divider"></row>
				<ng-content></ng-content>
			</column>
		</mat-expansion-panel>
	`,

	styles: [
		`
			@use 'typography2';
			@use 'common';

			:host {
				@include common.row;
			}
			.title {
				@include typography2.header-h3;
			}

			#panel {
				&.open {
					overflow: unset;
					transition: 0.3s;
				}

				box-shadow: none;
				@include common.column;
				@include common.paper;
				flex: 1;
			}

			.divider {
				border-bottom: 1px solid #d9d9d9;
			}

			:host::ng-deep {
				mat-expansion-panel-header.mat-expanded {
					height: 48px;
				}
			}
		`
	],
	imports: [BaseModule, MatExpansionModule, MatIconModule, CommonModule],
	standalone: true
})
export class SignupSettingsAccordionComponent {
	@Input() title!: string;
	@Input() expanded?: boolean;

	@Output() toggle = new EventEmitter<void>();

	onClick() {
		this.expanded = !this.expanded;
	}

	get className() {
		if (this.expanded) {
			return 'expanded-header';
		} else {
			return;
		}
	}
}
