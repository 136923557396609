import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import {
	catchError,
	exhaustMap,
	map,
	switchMap,
	withLatestFrom
} from 'rxjs/operators';
import { InvoiceDetails } from '../../_models/invoice-details.interface';
import { User } from '../../_models/user-models/user';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { ErrorsLoggingService } from '../../_services/errors-logging/errors-logging.service';
import { AdAccountApiService } from '../../_services/facebook-accounts/ad-account-api.service';
import { UserServiceSubscription } from '../../_services/user/user-subscription.service';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { GetPermissionsResponse } from '../../shared/models/permission';
import {
	HideGlobalSpinner,
	ShowGlobalSpinner
} from '../../shared/state/shared.actions';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { AppStateSlices } from '../../state/app.state';
import { FiledCreditCard } from '../components/filed-cards/set-creditcard.models';
import { BillingAddress } from '../shared/billing-and-payment/billing-and-payment.models';
import { UserManagementEffectsEnum } from './effects.enum';
import {
	LoadBillingAccountInfoFailure,
	LoadBillingAccountInfoSuccess,
	LoadCardsFailure,
	LoadCardsSuccess,
	LoadInvoicesSuccess,
	LoadSelectedKeysPermissionsSuccess,
	MakePrimaryCard,
	MakePrimaryCardFailure,
	MakePrimaryCardSuccess,
	UpdateUserPicture,
	UpdateUserPictureFailure,
	UpdateUserPictureSuccess,
	UserManagementTypes
} from './user-management.actions';
import {
	getBillingAccountInfo,
	getCards,
	getInvoices,
	getKeysForAllPermissionsState,
	UserManagementState
} from './user-management.reducer';

@Injectable()
export class UserManagementEffects {
	constructor(
		private actions: Actions,
		private adAccountService: AdAccountApiService,
		private userServiceApi: UserServiceApi,
		private userServiceSubscription: UserServiceSubscription,
		private backOfficeService: BackOfficeService,
		private store: Store<UserManagementState>,
		private toastNotification: ToastNotificationService,
		private errorsLoggingService: ErrorsLoggingService
	) {}

	@Effect()
	LoadSubscriptions$: Observable<LoadInvoicesSuccess> = this.actions.pipe(
		ofType(UserManagementTypes.loadInvoices),
		withLatestFrom(this.store.pipe(select(getInvoices))),
		exhaustMap(([action, invoices]) => {
			if (invoices) {
				return EMPTY;
			}
			return this.userServiceSubscription.getInvoiceDetails().pipe(
				map(
					(invoices: InvoiceDetails) =>
						new LoadInvoicesSuccess(invoices)
				),
				catchError(error => {
					this.errorsLoggingService.logEffectError(
						AppStateSlices.UserManagement,
						UserManagementEffectsEnum.LoadSubscriptions,
						[action],
						error
					);
					this.failure();
					return EMPTY;
				})
			);
		})
	);

	@Effect()
	LoadPermissions$: Observable<LoadSelectedKeysPermissionsSuccess> = this.actions.pipe(
		ofType(UserManagementTypes.loadSelectedKeysPermissions),
		withLatestFrom(this.store.pipe(select(getKeysForAllPermissionsState))),
		switchMap(([action, keys]) => {
			if (keys && !action['payload']) {
				return EMPTY;
			}
			return this.adAccountService.getBusinessOwnerPermissions().pipe(
				map(
					(permissions: GetPermissionsResponse) =>
						new LoadSelectedKeysPermissionsSuccess(permissions)
				),
				catchError(error => {
					this.errorsLoggingService.logEffectError(
						AppStateSlices.UserManagement,
						UserManagementEffectsEnum.LoadPermissions$,
						[action],
						error
					);
					this.failure();
					return EMPTY;
				})
			);
		})
	);

	@Effect()
	LoadCards$: Observable<LoadCardsSuccess> = this.actions.pipe(
		ofType(UserManagementTypes.loadCards),
		withLatestFrom(this.store.pipe(select(getCards))),
		switchMap(([action, cards]) => {
			if (cards) {
				return EMPTY;
			}
			return this.backOfficeService.getBusinessOwnerCards().pipe(
				map((cards: FiledCreditCard[]) => new LoadCardsSuccess(cards)),
				catchError(error => {
					new LoadCardsFailure(
						this.errorsLoggingService.getCodeFromHttpError(error)
					);
					this.errorsLoggingService.logEffectError(
						AppStateSlices.UserManagement,
						UserManagementEffectsEnum.LoadCards,
						[action],
						error
					);
					this.failure();
					return EMPTY;
				})
			);
		})
	);

	@Effect()
	UpdateUserPicture$: Observable<UpdateUserPictureSuccess> = this.actions.pipe(
		ofType<UpdateUserPicture>(UserManagementTypes.updateUserPicture),
		exhaustMap(action => {
			this.store.dispatch(new ShowGlobalSpinner());
			return this.userServiceApi
				.updateUserPicture(action.payload.pictureForm)
				.pipe(
					map((updatedUser: User) => {
						this.toastNotification.sendSuccessToast('Image saved');
						this.store.dispatch(new HideGlobalSpinner());
						return new UpdateUserPictureSuccess(updatedUser);
					}),
					catchError(error => {
						new UpdateUserPictureFailure(
							this.errorsLoggingService.getCodeFromHttpError(
								error
							)
						);
						this.errorsLoggingService.logEffectError(
							AppStateSlices.UserManagement,
							UserManagementEffectsEnum.UpdateProfilePicture,
							[action],
							error
						);
						this.store.dispatch(
							new UpdateUserPictureFailure(
								this.errorsLoggingService.getCodeFromHttpError(
									error
								)
							)
						);
						this.failure();
						return EMPTY;
					})
				);
		})
	);

	@Effect()
	makePrimaryCard$: Observable<MakePrimaryCardSuccess> = this.actions.pipe(
		ofType<MakePrimaryCard>(UserManagementTypes.makePrimaryCard),
		exhaustMap(action => {
			this.store.dispatch(new ShowGlobalSpinner());
			return this.backOfficeService.makeCardPrimary(action.payload).pipe(
				map(() => {
					this.toastNotification.sendSuccessToast(
						'This card will now be your default payment method'
					);
					this.store.dispatch(new HideGlobalSpinner());
					return new MakePrimaryCardSuccess(action.payload);
				}),
				catchError(error => {
					new UpdateUserPictureFailure(
						this.errorsLoggingService.getCodeFromHttpError(error)
					);
					this.errorsLoggingService.logEffectError(
						AppStateSlices.UserManagement,
						UserManagementEffectsEnum.MakePrimaryCard,
						[action],
						error
					);
					this.store.dispatch(
						new MakePrimaryCardFailure(
							this.errorsLoggingService.getCodeFromHttpError(
								error
							)
						)
					);
					this.failure();
					return EMPTY;
				})
			);
		})
	);

	private failure() {
		this.store.dispatch(new HideGlobalSpinner());
		this.toastNotification.sendErrorToast(
			'Something went wrong, please contact support'
		);
	}

	@Effect()
	LoadBillingAccountInfo$: Observable<LoadBillingAccountInfoSuccess> = this.actions.pipe(
		ofType(UserManagementTypes.loadBillingAccountInfo),
		withLatestFrom(this.store.pipe(select(getBillingAccountInfo))),
		switchMap(([action]) => {
			return this.backOfficeService.getBillingAccountDetails().pipe(
				map(
					(billingInfo: BillingAddress) =>
						new LoadBillingAccountInfoSuccess(billingInfo)
				),
				catchError(error => {
					new LoadBillingAccountInfoFailure(
						this.errorsLoggingService.getCodeFromHttpError(error)
					);
					this.errorsLoggingService.logEffectError(
						AppStateSlices.UserManagement,
						UserManagementEffectsEnum.LoadBillingAccountInfo,
						[action],
						error
					);
					this.failure();
					return EMPTY;
				})
			);
		})
	);
}
