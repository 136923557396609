export function unsafeKeys<T extends object>(obj: T): (keyof T)[] {
	return Object.keys(obj) as (keyof T)[];
}

export function unsafeEntries<T extends object>(
	obj: T
): [keyof T, T[keyof T]][] {
	return Object.entries(obj) as [keyof T, T[keyof T]][];
}

export * from './size';
export * from './types';
export * from './url-builder';
export * from './url-builder-helpers';
export * from './network';
export * from './rx';
