<section class="user-management-navigation">
	<nav mat-tab-nav-bar>
		<ng-container *ngFor="let nav of navLinks">
			<div class="outer-link" [matTooltip]="getTooltipTitle(nav)">
				<a
					#rla="routerLinkActive"
					(click)="activateLink(nav.label)"
					[routerLinkActiveOptions]="{ exact: false }"
					[routerLink]="nav.link"
					mat-tab-link
					[routerLinkActive]="'active'"
					class="{{ nav.label === activeLink ? 'custom-active-link' : '' }}"
				>
					{{ nav.label | titlecase }}
				</a>
			</div>
		</ng-container>

		<ng-content></ng-content>
	</nav>
</section>
