<app-lolly-spinning-loader *ngIf="loading"></app-lolly-spinning-loader>
<div *ngIf="!loading" class="container-bills" style="font-family: Gilroy;" #wrapper>
	<div class="content-wrapper">
		<div class="plans">
			<div class="header">
				<button class="back-button" (click)="backToBilling.emit()">
					<img alt="" class="ii" src="assets/icons/user-management/mobile-back-button.svg" />
				</button>
				<div class="about">
					<p class="title">All Plans</p>
				</div>
			</div>
			<div class="plans-wrapper">
				<div class="slider">
					<mat-icon (click)="slide('left')" *ngIf="planStartIndex !== 0">
						keyboard_arrow_left
					</mat-icon>
				</div>
				<div
					class="plan"
					*ngFor="let plan of plans.slice(planStartIndex, planEndIndex)"
					(click)="changePlan(plan)"
					[class.selected]="selectedPlan?.product_price_id == plan.product_price_id"
				>
					<div class="circle"></div>
					<div class="plan-head">
						<span>
							{{ plan.product_name + ' - ' + plan.nick_name }}
							<!-- <span class="popular">
                                Most popular
                            </span> -->
							<span *ngIf="currPlan?.product_price_id == plan.product_price_id" class="active">Active</span>
						</span>
						<div class="amount">
							<h4>
								{{ currency }}{{ plan.product_price }}
								<span>/{{ plan.product_price_interval === 'month' ? 'mo' : 'yr' }}</span>
							</h4>
						</div>
					</div>
					<div class="plan-body" [class.selected-input]="selectedPlan?.product_price_id == plan.product_price_id">
						<!-- <p class="desc">
							All the same goodies as our Standard Version + Powerful Blog Wizard with text editor feature in it that will speed up
						</p> -->
						<ul class="item-lists">
							<li>
								<em class="k-icon k-i-check k-i-checkmark"></em>
								Available Search Credits : {{ plan.search_credits }}
							</li>
							<li>
								<em class="k-icon k-i-check k-i-checkmark"></em>
								Available Campaign Creation Limit : {{ plan.campaign_limit }}
							</li>
							<li>
								<em class="k-icon k-i-check k-i-checkmark"></em>
								Available Influencers Per Campaign : {{ plan.influencer_per_campaign }}
							</li>
							<li>
								<em class="k-icon k-i-check k-i-checkmark"></em>
								Available Sub Users : 5
							</li>
							<li *ngFor="let feature of plan.support">
								<em class="k-icon k-i-check k-i-checkmark"></em>
								{{ feature }}
							</li>
						</ul>
					</div>
				</div>
				<div class="slider">
					<mat-icon (click)="slide('right')" *ngIf="planEndIndex !== plans.length">
						keyboard_arrow_right
					</mat-icon>
				</div>
			</div>
		</div>
		<div class="side-details">
			<button class="for-mobile">Next</button>
			<div class="card-toggle">
				<div class="card-body">
					<div class="custom-switch">
						<label class="{{ plans[0].product_price_interval === 'month' ? 'active' : 'inactive' }}">Monthly</label>
						<app-switch [value]="false" (toggle)="onCheckToggleView($event)"></app-switch>
						<label class="{{ plans[0].product_price_interval === 'year' ? 'active' : 'inactive' }}">Annually</label>
					</div>
				</div>
			</div>
			<div class="card review-holder">
				<div class="card-body review">
					<h4 class="section-title">Current plan</h4>
					<div class="summary-section">
						<div class="summary">
							<div class="label">
								<span class="title">Plan Type</span>
								<span>{{ currPlan?.product_price_interval }}</span>
							</div>
							<div class="value price">
								{{ currency }}
								{{ currPlan?.product_price }}
								<!-- /{{ currPlan?.product_price_interval === 'month' ? 'mo' : 'yr' }} -->
							</div>
						</div>
						<div class="summary">
							<div class="label">
								<div class="d-flex k-align-items-center">
									<span class="title">Subusers</span>
									<span class="m-m">
										<app-custom-tooltip [text]="'The amount of team members you can invite to Lolly'"></app-custom-tooltip>
									</span>
								</div>
								<span>5</span>
							</div>
							<div class="value">Included</div>
						</div>
						<div class="summary total">
							<div class="label">
								<div class="d-flex k-align-items-center">
									<span class="title">Total</span>
									<span class="m-m">
										<app-custom-tooltip [text]="'Your recurring subscription cost'"></app-custom-tooltip>
									</span>
								</div>
							</div>
							<div class="value price" *ngIf="true">
								{{ currency }}
								{{ currPlan?.product_price }}
								/{{ currPlan?.product_price_interval === 'month' ? 'mo' : 'yr' }}
							</div>
						</div>
						<div class="summary due">
							<div class="label">
								<div class="d-flex k-align-items-center">
									<span class="title">Due today</span>
									<span class="m-m">
										<app-custom-tooltip [text]="'Total amount due at checkout'"></app-custom-tooltip>
									</span>
								</div>
							</div>
							<div class="value price" *ngIf="true">{{ currency }}{{ 0 }}</div>
							<div *ngIf="false">
								<img src="assets/icons/user-management/loading.svg" alt="" class="fa fa-spin simple-loader" />
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="false" [ngStyle]="{ width: '100%' }">
					<app-lolly-spinning-loader [rotating]="true"></app-lolly-spinning-loader>
				</div>
			</div>
			<div class="foot-button" #target>
				<app-primary-button
					[buttonLable]="'Apply Changes'"
					[buttonType]="'Primary'"
					[isPeddingReduceSmall]="true"
					(buttonClicked)="applyChanges()"
					[isDisabled]="selectedPlan?.product_price_id == currPlan?.product_price_id"
				></app-primary-button>

				<p class="stripe">Payments are secured by Stripe</p>
			</div>
		</div>
	</div>
	<div class="footer" *ngIf="true">
		<!-- cancel container hidden (temporary) -->
		<div class="cancel-con" style="opacity: 0;">
			<p class="p1">Cancel Subscription</p>
			<p class="p2">
				Please note that canceling your subscription will revert you back to Basic Mode. You will lose all content created under your current
				subscription level
			</p>
			<p class="cta">
				<img src="assets/icons/user-management/trash-mini.svg" alt="" />
				Cancel Subscription
			</p>
		</div>
		<div class="info-box">
			<p class="title">Why is the amount due today less than my monthly subscription cost?</p>
			<p class="para">When making a change to your plan, the amount due today is prorated based on the cost of your previous plan.</p>
		</div>
	</div>
</div>
