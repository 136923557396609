import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UiTextComponent, ColumnComponent } from 'components';

@Component({
	selector: 'banner-advantage',
	template: `
		<mat-icon class="material-symbols-outlined">grade</mat-icon>
		<column [style.gap]="'8px'">
			<div class="title">
				Full platform
				<br />
				overview
			</div>
			<div class="description">
				See how to find the
				<br />
				best influencers
			</div>
		</column>
	`,
	styles: [
		`
			@use 'common';
			@use 'colors' as *;

			:host {
				@include common.row();
				justify-content: start;
				align-items: start;
				gap: 8px;
				flex: unset;
				padding-bottom: 16px;

				mat-icon {
					color: #ef9f52;
				}

				.title {
					color: $text-black;

					font-family: Gilroy;
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;
				}

				.description {
					color: $text-gray;

					/* Text small */
					font-family: Gilroy;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 125% */
				}
			}
		`
	],
	imports: [UiTextComponent, ColumnComponent, MatIconModule],
	standalone: true
})
export class BannerAdvantage {
	@Input() title!: string;
	@Input() description!: string;
}
