import { Component, Input, OnInit } from '@angular/core';
import { TooltipComponent } from './tooltip';

@Component({
	selector: 'i-tooltip',
	template: `
		<tooltip
			[text]="text"
			[linkText]="linkText"
			[link]="link"
			[position]="position"
		>
			<img class="tooltip-icon" src="assets/icons/i-tooltip.svg" />
		</tooltip>
	`,
	styles: [``],
	imports: [TooltipComponent],
	standalone: true
})
export class ITooltipComponent implements OnInit {
	@Input() text!: string;
	@Input() link?: string;
	@Input() linkText: string = 'Learn more';
	@Input() position: string = 'center-right';

	constructor() {}

	ngOnInit(): void {}
}
