import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import {
	Component,
	ElementRef,
	Inject,
	OnInit,
	ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DiscoveryListModel } from '../models/discovery/discovery-list.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { ToastV2TypeEnum } from 'src/app/toast-v2/models/toast-v2.model';

@Component({
	selector: 'app-add-list-to-campaign',
	templateUrl: './add-list-to-campaign.component.html',
	styleUrls: ['./add-list-to-campaign.component.scss']
})
export class AddListToCampaignComponent implements OnInit {
	@ViewChild('scrollContainer') container: ElementRef;

	public campaignId: number;
	public lists: DiscoveryListModel[] = [];
	public filteredLists: DiscoveryListModel[] = [];
	public selectedLists = new SelectionModel<DiscoveryListModel>(true, []);
	public loading: boolean = false;
	public searchControl = new UntypedFormControl();
	private unsubscriber$ = new Subject<void>();
	public showBubblePointer: boolean = true;

	constructor(
		private matDialog: MatDialog,
		private router: Router,
		public dialogRef: MatDialogRef<AddListToCampaignComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private sharedService: SharedService,
		private toastNotificationService: ToastNotificationService
	) {
		this.showBubblePointer =
			data?.showBubblePointer != null ? data?.showBubblePointer : true;
		this.campaignId = data?.campaignId;
	}

	ngOnInit(): void {
		this.getAllLists();
		this.initListeners();
	}

	public initListeners(): void {
		this.searchControl.valueChanges
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(val => {
				if (!val || val.trim() == '') {
					this.filteredLists = this.lists;
				} else {
					this.filteredLists = this.lists.filter(j =>
						j.name
							.toLowerCase()
							.replace(/ /g, '')
							.trim()
							.includes(
								val.toLowerCase().replace(/ /g, '').trim()
							)
					);
				}
			});

		this.selectedLists.changed
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				const containerElement = this.container?.nativeElement;

				if (containerElement) {
					const isAtBottom =
						Math.floor(
							containerElement.scrollHeight -
								containerElement.scrollTop
						) === containerElement.clientHeight;

					if (isAtBottom) {
						setTimeout(() => {
							this.container.nativeElement.scrollTop =
								this.container.nativeElement.scrollHeight;
						}, 50);
					}
				}
			});
	}

	public getAllLists(): void {
		this.loading = true;
		let queryParams = new HttpParams();
		queryParams = queryParams.append('page_number', 1);
		queryParams = queryParams.append('page_size', 250);
		this.sharedService
			.getAllLists(queryParams)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res) {
						this.lists = res.data;
						this.filteredLists = this.lists;
						this.loading = false;
					}
				},
				err => {
					this.toastNotificationService.sendCustomToast(
						'Please try again later',
						ToastV2TypeEnum.ERROR,
						5000,
						'Something went wrong'
					);
					this.loading = false;
				}
			);
	}

	public onSave(): void {
		let selectedListIds = this.selectedLists.selected.map(x => x.id);
		const payload = {
			list_ids: selectedListIds,
			campaign_id: this.campaignId
		};
		this.loading = true;
		this.sharedService
			.addListToCampaign(payload)
			.pipe(take(1))
			.subscribe(
				res => {
					this.loading = false;
					this.router.navigate(['outreach'], {
						queryParams: { show_automation_dialog: true }
					});
					this.dialogRef.close();
				},
				err => {
					this.toastNotificationService.sendCustomToast(
						'Please try again later',
						ToastV2TypeEnum.ERROR,
						5000,
						'Something went wrong'
					);
					this.loading = false;
					this.dialogRef.close();
				}
			);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
