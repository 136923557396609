import {
	Component,
	ElementRef,
	Inject,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { LollyLoadingDialogService } from 'src/app/shared-components/lolly-loading-dialog/lolly-loading-dialog.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { URL_REGEXP, UtilsService, getFormData } from 'src/app/shared/utils';
import { ToastV2TypeEnum } from 'src/app/toast-v2/models/toast-v2.model';

@Component({
	selector: 'app-welcome-screen',
	templateUrl: './welcome-screen.component.html',
	styleUrls: ['./welcome-screen.component.scss']
})
export class WelcomeScreenComponent implements OnInit {
	@ViewChild('imgFileSelect') imgFileSelect: ElementRef;
	public loading: boolean = false;
	public submitLoading: boolean = false;
	public userForm: UntypedFormGroup;
	public SUPPORTED_IMG_FILES = ['image/jpeg', 'image/jpg', 'image/png'];
	public validNumber: boolean = true;
	public uploadMoreCount: number = 0;
	public step: number = 0;

	constructor(
		public dialogRef: MatDialogRef<WelcomeScreenComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { name?: string },
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		public backOfficeService: BackOfficeService,
		private lollyLoadingDialogService: LollyLoadingDialogService,
		private sharedService: SharedService,
		private toastNotification: ToastNotificationService
	) {}

	ngOnInit(): void {
		this.initForm();
		this.fetchStore();
	}

	public closeDialog(flag = false): void {
		this.dialogRef.close({ response: flag });
	}

	public redirectTo(route, queryParams?): void {
		// this.closeDialog(true);
		// this.router.navigate([route], queryParams);
	}

	private initForm(): void {
		this.userForm = this.formBuilder.group({
			company_website: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern(URL_REGEXP)
			]),
			company_logo: new UntypedFormControl(null)
			// image: new UntypedFormControl('', Validators.required)
		});
	}

	private onError(err): void {
		const title = 'Oops, something went wrong!';
		const msg = 'Please try again!';

		this.toastNotification.sendCustomToast(
			msg,
			ToastV2TypeEnum.ERROR,
			2000,
			title
		);
	}

	private fetchStore(): void {
		this.loading = true;
		this.backOfficeService
			.getProfileDetails(localStorage.getItem(StorageKey.token))
			.subscribe(
				res => {
					this.userForm.patchValue({
						company: res?.company
					});
				},
				err => this.onError
			)
			.add(() => (this.loading = false));
	}

	public saveDetails(cb?): void {
		const payload: any = {
			...this.userForm.value
		};
		const formData = getFormData(payload);

		this.submitLoading = true;
		this.backOfficeService
			.updateProfileDetails(formData)
			.subscribe(
				res => {
					if (res.status) {
						this.disableFirstTimerWelcomeScreen();
						if (cb) cb();
						this.closeDialog();
					}
				},
				err => this.onError
			)
			.add(() => {
				this.submitLoading = false;
			});
	}

	private disableFirstTimerWelcomeScreen(): void {
		const payload = {
			first_time_landing_page: false
		};
		this.sharedService
			.disableFirstTimeValues(payload)
			.pipe(take(1))
			.subscribe(res => {});
		const firsTimerValue = this.sharedService.firstTimerData$.value;
		firsTimerValue.first_time_landing_page = false;
		this.sharedService.firstTimerData$.next(firsTimerValue);
	}

	public numberChange(val: string): void {
		/^([\s0-9-]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(val)
			? (this.validNumber = true)
			: (this.validNumber = false);
	}
	// !end

	public openAddImgFilesDialog() {
		const e = this.imgFileSelect.nativeElement;
		e.addEventListener('change', this.onImgFileEleChange.bind(this));
		this.uploadMoreCount = 0;
		e.click();
	}

	public onImgFileEleChange(event: any): void {
		if (this.uploadMoreCount == 1) {
			return;
		}
		this.uploadMoreCount = 1;
		const file = event.target.files[0];
		if (file) {
			if (this.checkFileType(file, this.SUPPORTED_IMG_FILES)) {
				this.uploadImgFile(file);
				return;
			}
			this.toastNotification.sendErrorToast(
				'Unsupported file type found!'
			);
		}
	}

	public uploadImgFile(file: File): void {
		this.lollyLoadingDialogService.showLoader();
		this.sharedService
			.uploadFile(file)
			.pipe(take(1))
			.subscribe(
				res => {
					this.lollyLoadingDialogService.dismissLoader();
					this.userForm.get('company_logo').patchValue(res?.data);
				},
				err => {
					this.lollyLoadingDialogService.dismissLoader();
					this.toastNotification.sendErrorToast(
						'Something went wrong. Try again later.'
					);
				}
			);
	}

	public checkFileType(file: File, types: string[]): boolean {
		const fileType = file.type;
		return types.some(type => {
			return fileType === type;
		});
	}

	public gotoStep(step): void {
		this.step = step;
	}
}
