export enum UserPlanLimitCodesEnum {
	DISCOVERY_SEARCH = 1,
	DISCOVERY_PROFILE = 2,
	DISCOVERY_EMAILS = 3,
	TEAM_SIZE = 4,
	EMAILS_INTEGRATION = 5,
	EXPORTS = 6,
	RESETED_COUNTERS = 7,
	ADVANCED_SEARCH = 8,
	LISTS_COUNT = 9,
	LIST_SIZE = 10,
	INTEGRATED_DOMAINS = 11,
	TEMPLATE_NOT_FOUND = 12,
	AI_CREDITS = 13
}

export enum UserPaymentSubscriptionCodesEnum {
	SUB_EXPIRED = 1,
	TRIAL_EXPIRED = 2,
	PAYMENT_FAILED = 3,
	SUB_NOT_FOUND = 4,
	EMAIL_IS_NOT_CONFIRMED = 5
}
