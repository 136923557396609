import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { LollySpinnerLoaderComponent } from './lolly-spinning-loader/lolly-spinning-loader.component';

@Component({
	selector: 'blur-loader',
	template: `
		<div class="loader-content">
			<ng-content></ng-content>
		</div>
		<app-lolly-spinning-loader *ngIf="loading"></app-lolly-spinning-loader>
	`,
	styles: [
		`
			:host {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
			}

			.loader-content {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				flex: 1;
			}

			:host.loading {
				.loader-content {
					filter: blur(4px);
				}
			}

			app-lolly-spinning-loader {
				position: absolute;
			}
		`
	],
	imports: [LollySpinnerLoaderComponent, CommonModule],
	standalone: true
})
export class BlurLoaderComponent {
	@HostBinding('class.loading')
	@Input()
	loading?: boolean;
}
