<div class="modalContainer">
	<div class="header">
		<i class="k-icon k-i-close close" (click)="closeDialog()"></i>
	</div>
	<div class="body">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div class="title">{{ modelDetail.title }}</div>
		<div class="text">
			{{ modelDetail.text }}
		</div>
		<div class="input">
			<input type="text" class="custom-input" placeholder="Enter here ..." [ngClass]="{ 'error-input': error }" [(ngModel)]="name" />
			<button class="btn-gcb-04 action-button save-btn" (click)="onSubmit()">Save</button>
		</div>
	</div>
</div>
