import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ChangeDetectorRef
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import {
	DropdownOption,
	DropdownOptionType
} from '../../models/dropdown.interface';

@Component({
	selector: 'app-select-drp-w-filter-w-input',
	templateUrl: './select-drp-w-filter-w-input.component.html',
	styleUrls: ['./select-drp-w-filter-w-input.component.scss']
})
export class SelectDrpWFilterWInputComponent implements OnInit, OnDestroy {
	@Input() label?: string;
	@Input() placeholder: string = 'Search..';
	@Input() optionsLabel?: string;
	@Input() options: DropdownOption[] = [];
	@Input() optionTypesLabel?: string;
	@Input() optionTypes: DropdownOptionType[] = [];
	@Input() defaultOptionType?: DropdownOptionType;
	@Input() disabled: boolean = false;
	@Input() inputControl: UntypedFormControl;
	@Input() isOptionsLoading: boolean = false;
	@Input() showTooltip: boolean = false;

	@Output() emitOptionSelection: EventEmitter<DropdownOption> =
		new EventEmitter<DropdownOption>();
	@Output() emitOptionTypeSelection: EventEmitter<DropdownOptionType> =
		new EventEmitter<DropdownOptionType>();

	public optionType: DropdownOptionType;
	public filteredOptions: Observable<DropdownOption[]>;

	public unsubscriber$ = new Subject<void>();

	constructor() {}

	ngOnInit(): void {
		this.optionType = this.defaultOptionType
			? this.defaultOptionType
			: this.optionTypes[0];

		this.filteredOptions = this.inputControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				return value?.name
					? this.filter(value.name as string)
					: value
					? this.filter(value as string)
					: this.options.slice();
			}),
			takeUntil(this.unsubscriber$)
		);
	}

	private filter(name: string): any[] {
		const filterValue = name.toLowerCase();
		return this.options.filter(option =>
			option.name.toLowerCase().includes(filterValue)
		);
	}

	public isOptionTypeSelected(optionType: DropdownOptionType): boolean {
		return this.optionType.id == optionType.id;
	}

	public onOptionTypeSelected(event, optionType: DropdownOptionType): void {
		this.optionType = optionType;
		this.emitOptionTypeSelection.emit(optionType);
	}

	public onOptionSelected(event, option: DropdownOption): void {
		this.filteredOptions = this.inputControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				return value?.name
					? this.filter(value.name as string)
					: value
					? this.filter(value as string)
					: this.options.slice();
			}),
			takeUntil(this.unsubscriber$)
		);
		this.emitOptionSelection.emit(option);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
