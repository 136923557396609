import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ShowNavigationSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { NavCardDetails } from '../../models/nav-card-details.interface';

@Component({
	selector: 'app-nav-cards',
	templateUrl: './nav-cards.component.html',
	styleUrls: ['./nav-cards.component.scss']
})
export class NavCardsComponent implements OnInit {
	@Input() public details: NavCardDetails;

	constructor(private router: Router, private sharedStore: Store<SharedState>) {}

	ngOnInit(): void {}

	public navigate(link: string): void {
		this.sharedStore.dispatch(new ShowNavigationSpinner());
		this.router.navigateByUrl(link);
	}
}
