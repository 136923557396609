import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
	NgxSkeletonLoaderConfig,
	NgxSkeletonLoaderConfigTheme,
	NgxSkeletonLoaderModule
} from 'ngx-skeleton-loader';

@Component({
	selector: 'skeleton-loader',
	template: `
		<ng-container *ngIf="loading; else content">
			<ngx-skeleton-loader *ngIf="loading"></ngx-skeleton-loader>
		</ng-container>
		<ng-template #content>
			<ng-content></ng-content>
		</ng-template>
	`,
	styles: [``],
	imports: [NgIf, NgxSkeletonLoaderModule],
	standalone: true
})
export class SkeletonLoader {
	@Input() loading!: boolean;
	@Input() theme?: NgxSkeletonLoaderConfigTheme;
	@Input() appearance?: NgxSkeletonLoaderConfig['appearance'];
}
