import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { SignupSettingsAccordionComponent } from '../accordion';
import {
	BaseModule,
	CheckboxComponent,
	CheckboxState,
	TextComponent
} from 'components';

import { SignupFieldInputComponent } from './custom-signup-field-input';
import { CustomSignupFieldComponent } from './custom-signup-field';

export type SignupFieldControl = FormGroup<{
	appEnabled: FormControl<CheckboxState>;
	name: FormControl<string>;
	id: FormControl<number | undefined>;
}>;

@Component({
	selector: 'signup-fields-accordion',
	template: `
		<signup-settings-accordion
			title="Custom Signup Fields"
			[expanded]="expanded"
			(toggle)="toggle.emit()"
		>
			<row *ngFor="let field of mandatoryFields">
				<checkbox value="on" [disabled]="true"></checkbox>
				<text variant="p2">{{ field }}</text>
			</row>

			<custom-signup-field
				*ngFor="let field of form.controls; let i = index"
				(delete)="onFieldRemove(i)"
				(edit)="onFieldEdit(i)"
				(edit-done)="onEditDone(i)"
				[title]="field.value.name ?? ''"
				[editable]="editableIndex === i"
				[form]="field"
			></custom-signup-field>
			<mat-divider></mat-divider>
			<custom-signup-field-input
				[form]="newSignupFieldForm"
				(fieldSave)="onFieldSave($event)"
			></custom-signup-field-input>
		</signup-settings-accordion>
	`,
	imports: [
		SignupSettingsAccordionComponent,
		CheckboxComponent,
		TextComponent,
		MatDividerModule,
		CommonModule,
		SignupFieldInputComponent,
		CustomSignupFieldComponent,
		BaseModule
	],
	styles: [
		`
			@use 'common';

			:host {
				@include common.row;
			}

			row {
				justify-content: left;
			}
		`
	],
	standalone: true
})
export class SignUpFieldsComponent {
	mandatoryFields = ['Full name', 'Last name', 'Email Address', 'Password'];

	@Input()
	form = this.formBuilder.array<SignupFieldControl>([]);

	newSignupFieldForm: SignupFieldControl = this.formBuilder.group({
		appEnabled: this.formBuilder.control<CheckboxState>('on'),
		name: this.formBuilder.control<string>(''),
		id: this.formBuilder.control<number | undefined>(0)
	});
	editableIndex?: number;

	@Input() expanded?: boolean;

	@Output() fieldSave = new EventEmitter<string>();
	@Output() toggle = new EventEmitter<void>();

	constructor(private formBuilder: NonNullableFormBuilder) {
		this.onFieldSave('Company');
	}

	onFieldSave(newField: string) {
		this.fieldSave.emit(newField);

		this.form.push(this.newField(newField));
	}

	onFieldEdit(index: number) {
		this.editableIndex = index;
	}

	onEditDone(index: number) {
		this.editableIndex = undefined;
	}

	onFieldRemove(index: number) {
		this.removeField(index);
	}

	removeField(index: number) {
		this.form.removeAt(index);
		this.form.markAsDirty();
	}

	newField(value: string): SignupFieldControl {
		const group = this.formBuilder.group({
			appEnabled: this.formBuilder.control('on' as CheckboxState),
			name: this.formBuilder.control(value),
			id: undefined as number | undefined
		});

		this.newSignupFieldForm.reset({
			appEnabled: 'on' as CheckboxState,
			name: '',
			id: undefined as number | undefined
		});
		group.controls.name.markAsDirty();
		return group;
	}
}
