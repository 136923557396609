import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-settings-con',
	templateUrl: './settings-con.component.html',
	styleUrls: ['./settings-con.component.scss']
})
export class SettingsConComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
