<section class="dialog-root">
	<!-- * Close button that floats outside the dialog -->
	<div class="close-btn" mat-dialog-close>
		<mat-icon>clear</mat-icon>
	</div>

	<div class="dialog-wrapper">
		<!-- * Header -->
		<div class="dialog-header">
			<div class="logo-wrapper">
				<img class="logo" src="assets/images/paypal-logo.png" />
			</div>

			<div class="d-flex flex-column flex-1" style="gap: 5px;">
				<div class="title">PayPal Payouts API</div>
				<div class="subtitle">
					Integrate with PayPal Payouts API to facilitate direct PayPal payouts.
				</div>
			</div>
		</div>

		<!-- * Body -->
		<div class="dialog-body">
			<div class="desc">
				Integrate PayPal Payouts with Lolly &nbsp;
				<a [href]="ZOHO_PAYPAL_KB_LINK" target="_blank">Learn How</a>
			</div>

			<div class="form-wrapper">
				<div class="input-wrapper">
					<div class="label">Client ID</div>

					<ng-container *ngIf="loading">
						<ngx-skeleton-loader
							appearance="line"
							style="height: 42px; width: 100%;"
							[theme]="{ height: '42px', width: '100%' }"
						></ngx-skeleton-loader>
					</ng-container>
					<ng-container *ngIf="!loading">
						<div class="copy-input-wrapper">
							<input
								type="text"
								class="custom-input-name {{ isConnected ? 'extra-padding' : '' }}"
								[formControl]="paypalCredentialsForm.get('client_id')"
							/>
							<img
								*ngIf="isConnected"
								src="assets/discovery-v2/copy.svg"
								class="copy-icon"
								(click)="copyToClipboard(paypalCredentialsForm.get('client_id').value)"
							/>
						</div>
						<div class="error-wrapper" *ngIf="paypalCredentialsForm.get('client_id')?.touched && paypalCredentialsForm.get('client_id')?.invalid">
							This is a required field
						</div>
					</ng-container>
				</div>

				<div class="input-wrapper" style="padding-bottom: 20px;">
					<div class="label">Client Secret</div>
					<ng-container *ngIf="loading">
						<ngx-skeleton-loader
							appearance="line"
							style="height: 42px; width: 100%;"
							[theme]="{ height: '42px', width: '100%' }"
						></ngx-skeleton-loader>
					</ng-container>

					<ng-container *ngIf="!loading">
						<div class="copy-input-wrapper">
							<input
								type="text"
								class="custom-input-name {{ isConnected ? 'extra-padding' : '' }}"
								[formControl]="paypalCredentialsForm.get('client_secret')"
							/>
							<img
								*ngIf="isConnected"
								src="assets/discovery-v2/copy.svg"
								class="copy-icon"
								(click)="copyToClipboard(paypalCredentialsForm.get('client_secret').value)"
							/>
						</div>
						<div
							class="error-wrapper"
							*ngIf="paypalCredentialsForm.get('client_secret')?.touched && paypalCredentialsForm.get('client_secret')?.invalid"
						>
							This is a required field
						</div>
					</ng-container>
				</div>

				<!-- ! NOT NEEDED FOR NOW BUT MAY NEED IN FUTURE (WEBHOOK)
                <div class="input-wrapper">
                    <div class="label">Webhook ID</div>
                    <input type="text" class="custom-input-name" formControlName="webhook_id">
                </div>
    
                <div class="input-wrapper">
                    <div class="label">Webhook endpoint URL</div>
                    <div class="copy-input-wrapper">
                        <input type="text" class="custom-input-name" disabled>
                        <img *ngIf="!isCopied" src="assets/discovery-v2/copy.svg" class="copy-icon" (click)="copyToClipboard()" matTooltip="copy">
                        <img *ngIf="isCopied" src="assets/discovery-v2/copy-active.svg" class="copy-icon" matTooltip="copied">
                    </div>
                </div> -->

				<ng-container *ngIf="loading">
					<ngx-skeleton-loader appearance="line" style="height: 72px; width: 100%;" [theme]="{ height: '72px', width: '100%' }"></ngx-skeleton-loader>
				</ng-container>

				<ng-container *ngIf="!loading">
					<ng-container *ngIf="!isConnected">
						<mat-checkbox class="custom-checkbox-black" [formControl]="paypalCredentialsForm.get('confirmation_check')">
							I understand that Lolly will have access to PayPal API and to transfer funds using these credentials (when you give the
							confirmation)
						</mat-checkbox>
					</ng-container>
				</ng-container>
			</div>
		</div>

		<div class="dialog-footer">
			<ng-container *ngIf="loading">
				<ngx-skeleton-loader appearance="line" style="height: 33px; width: 100%;" [theme]="{ height: '33px', width: '100%' }"></ngx-skeleton-loader>
			</ng-container>

			<ng-container *ngIf="!loading">
				<ng-container *ngIf="!isConnected">
					<div class="note">
						Note: If the save is successful it means that the Client ID and Client Secret is correct, no other verification is required.
					</div>
				</ng-container>
			</ng-container>

			<div class="d-flex w-100 k-align-items-center k-justify-content-end">
				<ng-container *ngIf="loading">
					<ngx-skeleton-loader appearance="line" style="height: 48px; width: 100%;" [theme]="{ height: '48px', width: '100%' }"></ngx-skeleton-loader>
				</ng-container>

				<ng-container *ngIf="!loading">
					<button *ngIf="!isConnected" class="primary-btn" [disabled]="paypalCredentialsForm?.invalid" (click)="savePaypalInfo()">
						Connect to PayPal
					</button>
					<button *ngIf="isConnected" class="secondary-btn" (click)="onClickDisconnect()">Disconnect</button>
				</ng-container>
			</div>
		</div>
	</div>
</section>
