import { ButtonComponent } from './button.component';
import { ResetButtonComponent } from './reset-button.component';
import { UploadButtonComponent } from './upload-button.component';
import { SmallButtonComponent } from './small-button.component';
import { ExportButtonComponent } from './export-button.component';
import { NgModule } from '@angular/core';
import { BackButtonComponent } from './back-button.component';
import { UnlockButtonComponent } from './unlock-button.component';
import { SaveButtonComponent } from './save-button.component';
import { EditSavedButtonComponent } from './edit-saved-button.component';
import { DeleteButtonComponent } from './delete-button.component';
import { AiButtonComponent } from './ai-button.component';

export {
	ButtonComponent,
	ResetButtonComponent,
	BackButtonComponent,
	UploadButtonComponent,
	SmallButtonComponent,
	ExportButtonComponent,
	UnlockButtonComponent,
	SaveButtonComponent,
	EditSavedButtonComponent,
	DeleteButtonComponent,
	AiButtonComponent
};

export const importExport = [
	ButtonComponent,
	BackButtonComponent,
	ResetButtonComponent,
	UploadButtonComponent,
	SmallButtonComponent,
	ExportButtonComponent,
	UnlockButtonComponent,
	SaveButtonComponent,
	EditSavedButtonComponent,
	DeleteButtonComponent
];

@NgModule({
	imports: importExport,
	exports: importExport
})
export class ButtonsModule {}
