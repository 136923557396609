import { Observable, Observer, of } from 'rxjs';
import { EnumerationEx } from '../../_models/enumeration';
import { UpdateProfile } from '../../user-management/models/update-profile';
import { AddUser } from '../../_models/user-models/register-details';
import { exhaust, map, take } from 'rxjs/operators';
import { SetPassword } from '../../_models/identity-models/set-password';
import { ChangePassword } from '../../_models/identity-models/change-password';
import { User } from '../../_models/user-models/user';
import { UpdateDetails } from '../../_models/user-models/update-details';
import { Injectable } from '@angular/core';
import {
	HttpClient,
	HttpHeaders,
	HttpParams,
	HttpResponse
} from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
import { UserRejectedReason } from './user-rejected-reason.enum';
import { NewUserModel } from '../../user-management/models/new-user-model';
import { UserStateEnum } from './user-state.enum';
import {
	SubUser,
	SubUserV2
} from 'src/app/user-management/models/sub-user.interface';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
	providedIn: 'root'
})
export class UserServiceApi {
	private readonly baseURL = `${BaseApiUrl.Users}users`;

	constructor(protected http: HttpClient) {}

	public register(registerDetails: AddUser): Observable<object> {
		return this.http.post(`${this.baseURL}/`, registerDetails);
	}

	public changePassword(changePassword: ChangePassword): Observable<object> {
		return this.http
			.put(`${this.baseURL}/change-password`, changePassword)
			.pipe(
				map(() => {
					return of({});
				})
			);
	}

	public getUserActivity(): Observable<any[]> {
		return this.http.get<any[]>(`${this.baseURL}/account-activities`);
	}

	public updateUserPicture(file: FormData): Observable<object> {
		return this.http.put(`${this.baseURL}/update-picture`, file);
	}

	public deactivateUserNew(user: any): Observable<object> {
		return this.http.put(`${this.baseURL}/disable-user`, user);
	}

	public activateUser(userId: number): Observable<object> {
		return this.http.put(`${this.baseURL}/${userId}/enable-user`, {
			userId: userId
		});
	}

	public getAllSubUsers(): Observable<{ data: SubUserV2[] }> {
		return this.http.get<{ data: SubUserV2[] }>(
			`${BaseApiUrl.SocialMessengerPython}subusers`
		);
	}

	public addSubUser(payload: any): Observable<any> {
		return this.http.post<any>(
			`${BaseApiUrl.SocialMessengerPython}subusers`,
			payload
		);
	}

	public editSubUser(id: number, payload: any): Observable<any> {
		return this.http.put<any>(
			`${BaseApiUrl.SocialMessengerPython}subusers/${id}`,
			payload
		);
	}

	public deleteSubUser(id: number): Observable<any> {
		return this.http.delete<any>(
			`${BaseApiUrl.SocialMessengerPython}subusers/${id}`
		);
	}
}
