import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LollyLiteFullscreenComponent } from '../../../projects/workshop/src/app/docs/pages/lolly-lite-fullscreen/lolly-lite-fullscreen.component';
import { AuthPaymentService } from '../authentication/services/auth-payment.service';
import { LollyLoadingDialogService } from '../shared-components/lolly-loading-dialog/lolly-loading-dialog.service';
import { ToastNotificationService } from '../shared/toast-notification/toast-notification.service';
import { MyPlanService } from '../user-management/new-sprint/components/my-plan/my-plan.service';

@Injectable({
	providedIn: 'root'
})
export class BookingService {
	private unSubscriber$ = new Subject<void>();

	constructor(
		private router: Router,
		private dialog: MatDialog,
		public authPaymentService: AuthPaymentService,
		public myPlanService: MyPlanService,
		private lollyLoadingDialogService: LollyLoadingDialogService,
		private toastNotificationService: ToastNotificationService
	) {}

	public viewPricing() {
		this.router.navigate(['user-management/manage-plan'], {
			queryParams: { openPlans: true }
		});
	}

	public bookDemo() {
		window.open('https://calendly.com/lolly-com/book-a-demo', '_blank');
	}

	public redirectStripeApi(planName: string): void {
		this.lollyLoadingDialogService.showLoader();
		this.myPlanService
			.getStripePlanByAlias(planName)
			.pipe(takeUntil(this.unSubscriber$))
			.subscribe({
				next: plan => {
					this.authPaymentService.redirectToStripe(plan).subscribe({
						next: res => {
							this.lollyLoadingDialogService.dismissLoader();
							window.open(res, '_self');
						},
						error: () => {
							this.lollyLoadingDialogService.dismissLoader();
							this.toastNotificationService.sendErrorToast(
								'Some Error Occurred. Try Again Later.'
							);
						}
					});
				},
				error: () => {
					this.lollyLoadingDialogService.dismissLoader();
					this.toastNotificationService.sendErrorToast(
						'Some Error Occurred. Try Again Later.'
					);
				}
			});
	}

	openFullscreenBanner() {
		const ref = this.dialog.open(LollyLiteFullscreenComponent, {
			width: '100vw',
			height: '100%',
			maxWidth: '1600px',
			maxHeight: '100%',
			enterAnimationDuration: '300ms',
			exitAnimationDuration: '0ms',
			position: { right: '0' },
			panelClass: ['sharp-dialog-no-shadow', 'slide', 'slideInRight']
		});

		ref.componentInstance.close.subscribe(() => ref.close());
		ref.componentInstance.bookDemo.subscribe(() => {
			this.bookDemo();
		});
		ref.componentInstance.viewPricing.subscribe(() => {
			this.viewPricing();
			ref.close();
		});

		return ref;
	}
}
