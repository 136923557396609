<div class="wrapper">
	<div class="body">
		<div class="container">
			<main>
				<div class="main-wrapper">
					<div class="card list-wrapper">
						<div class="list-header">
							<header>
								<nav>
									<div class="left-nav">
										<span class="page-title">
											Email Integration
										</span>
									</div>

									<div class="left-nav right-nav">
										<button
											class="primary-btn"
											appRoleBasedControl
											*ngIf="!loading"
											(click)="linkMailbox()"
										>
											Link mailbox
										</button>
									</div>
								</nav>
							</header>
							<div class="list-header-wrapper">
								{{ mailboxes.length }}/{{
									emailsLimit ? emailsLimit : maxOutString
								}}
								linked
							</div>
						</div>
						<div class="list-body">
							<div class="table-wrapper">
								<!-- *skeleton loader -->
								<div class="loader-wrapper" *ngIf="loading">
									<ngx-skeleton-loader
										appearance="line"
										style="height: 56px; width: 100%;"
										[theme]="{
											height: '56px',
											width: '100%'
										}"
										*ngFor="let user of [1, 2, 3, 4, 5]"
									></ngx-skeleton-loader>
								</div>

								<table
									class="table"
									#automationTable
									*ngIf="!loading"
								>
									<thead class="table__thead">
										<tr class="table__head">
											<th class="table__th">Mailbox</th>
											<th class="table__th" width="20%">
												Last synced
											</th>
											<th
												class="table__th"
												width="3%"
											></th>
											<th
												class="table__th"
												width="5%"
											></th>
										</tr>
									</thead>
									<tbody class="table__tbody">
										<tr
											class="table__tr {{
												item?.default === true
													? 'default'
													: ''
											}}"
											*ngFor="
												let item of mailboxes;
												let i = index
											"
										>
											<td class="table__td" width="">
												<span class="table__value">
													<div class="mail">
														<img
															*ngIf="
																item.type ==
																'outlook'
															"
															src="assets\crm\mailboxes\svgexport-1 (7).svg"
															alt=""
														/>
														<img
															*ngIf="
																item.type ==
																'gmail'
															"
															src="assets\crm\mailboxes\svgexport-1 (13).svg"
															alt=""
														/>
														{{ item?.email }}
													</div>
												</span>
											</td>

											<td class="table__td">
												<span class="table__value">
													<span class="date">
														{{
															item?.created_at
																? getFormattedDate(
																		item?.created_at
																  )
																: '-'
														}}
													</span>
												</span>
											</td>

											<td class="table__td">
												<span class="table__value">
													<img
														*ngIf="
															!item.is_available
														"
														[matTooltip]="
															item.fail_reason
														"
														src="assets/icons/warning.svg"
														height="20"
														width="20"
														style="
															filter: invert(21%)
																sepia(89%)
																saturate(5581%)
																hue-rotate(1deg)
																brightness(102%)
																contrast(101%);
														"
													/>
												</span>
											</td>

											<td class="table__td">
												<span class="table__value">
													<div class="action">
														<button
															mat-icon-button
															[matMenuTriggerFor]="
																menu
															"
															aria-label="Example icon-button with a menu"
														>
															<img
																src="assets\crm\automation\Icon feather-more-vertical.svg"
																alt=""
															/>
														</button>
														<mat-menu
															#menu="matMenu"
															yPosition="below"
															xPosition="before"
															[overlapTrigger]="
																true
															"
														>
															<button
																mat-menu-item
																(click)="
																	makeDefault(
																		item
																	)
																"
																appRoleBasedControl
															>
																<img
																	src="assets\crm\mailboxes\Icon feather-check-circle.svg"
																	alt=""
																/>
																<span>
																	Make Default
																</span>
															</button>
															<button
																mat-menu-item
																(click)="
																	uninstallTemplate(
																		item
																	)
																"
																appRoleBasedControl
															>
																<img
																	src="assets\crm\mailboxes\Icon open-link-broken.svg"
																	alt=""
																/>
																<span>
																	Unlink
																	Mailbox
																</span>
															</button>
														</mat-menu>
													</div>
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>

		<div
			*ngIf="integrationsLoading"
			class="d-flex flex-column w-100"
			style="gap: 30px; padding-top: 30px;"
		>
			<ngx-skeleton-loader
				*ngFor="let item of [1, 2, 3]"
				appearance="line"
				style="height: 200px; width: 100%;"
				[theme]="{ height: '200px', width: '100%' }"
			></ngx-skeleton-loader>
		</div>

		<ng-container *ngIf="!integrationsLoading">
			<!-- !payment integration -->
			<app-payment-integrations
				[paymentIntegrationsData]="integrationsData?.payouts"
			></app-payment-integrations>

			<!-- !ecommerce integration -->
			<app-setup-ecommerce-integration
				[ecommerceIntegrationsData]="integrationsData?.ecommerce"
			></app-setup-ecommerce-integration>

			<!-- !pixel integration -->
			<app-pixel-integrations
				[ecommerceIntegrationsData]="integrationsData?.ecommerce"
				[pixelClientId]="integrationsData?.pixel_client_id"
			></app-pixel-integrations>

			<!-- !disclosure -->
			<app-google-disclosure></app-google-disclosure>
		</ng-container>
	</div>
</div>
