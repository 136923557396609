import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { CustomSearchBarComponent } from './custom-search-bar/custom-search-bar.component';
import { CustomDropdownComponent } from './search-bar-dropdown/custom-dropdown.component';
import { SearchBarButtonComponent } from './search-bar-button/search-bar-button.component';
import { SearchBarInnerTextComponent } from './search-bar-inner-text/search-bar-inner-text.component';
import { MaterialModule } from 'src/app/material.module';
import { SmiReusedModule } from 'src/app/social-media-influencer/smi-reused.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSelectModule } from '@angular/material/select';
import { GenericDropdownComponent } from './generic-dropdown/generic-dropdown.component';

@NgModule({
	declarations: [
		SearchBarComponent,
		CustomSearchBarComponent,
		SearchBarButtonComponent,
		SearchBarInnerTextComponent,
		CustomDropdownComponent,
		GenericDropdownComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		SmiReusedModule,
		SharedModule,
		MatSelectModule
	],
	exports: [SearchBarComponent]
})
export class SearchBarModule {}
