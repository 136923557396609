<div class="dialog-root">
	<mat-icon class="close-icon" mat-dialog-close>clear</mat-icon>

	<div class="title">Are you sure you want to cancel subscription ?</div>

	<div class="input-wrapper">
		<label>Reason to cancel -</label>
		<input class="custom-input-name" [formControl]="reasonControl" />
		<div *ngIf="reasonControl?.invalid && reasonControl?.touched" class="error-txt">
			Please enter at least 20 characters
		</div>
	</div>

	<div class="footer">
		<button class="primary-btn" (click)="secondaryAction()">
			Go Back
		</button>

		<button class="secondary-btn" (click)="primaryAction()">
			Confirm
		</button>
	</div>
</div>
