<div class="modalContainer">
	<div class="header">
		<i class="k-icon k-i-close close" (click)="closeDialog()"></i>
	</div>
	<div class="body">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div class="title">{{ modelDetail.title }}</div>
		<div class="text">
			{{ modelDetail.text }}
		</div>
	</div>
	<div class="footer">
		<app-primary-button
			[buttonLable]="'CANCEL'"
			[buttonType]="'Secondary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="closeDialog()"
			*ngIf="modelDetail.closeBtn"
		></app-primary-button>

		<app-primary-button
			[buttonLable]="modelDetail.actionBtnText"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="actionButtonClick()"
			*ngIf="modelDetail.actionBtn"
		></app-primary-button>
	</div>
</div>
