import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericInputDataModel } from 'src/app/shared-components/models/generic.interface';
import { EcommerceImplService } from 'src/app/shared/services/ecommerce-impl.service';
import { EcommercePlatformsEnum } from 'src/app/social-media-influencer/modules/ecommerce-v2/models/ecommerce.enum';

@Component({
	selector: 'app-setup-ecommerce-integration',
	templateUrl: './setup-ecommerce-integration.component.html',
	styleUrls: ['./setup-ecommerce-integration.component.scss']
})
export class SetupEcommerceIntegrationComponent implements OnInit {
	@Input() public ecommerceIntegrationsData: any;
	public loading: boolean = false;
	public isConnected: boolean = false;
	public isPanelOpen: boolean = true;

	public ecommerceCards: GenericInputDataModel[] = [
		{
			id: 1,
			name: 'Shopify',
			value: 'shopify',
			image: 'assets/icons/shopify-icon.svg',
			connected: false,
			is_pixel_installed: false
		},
		{
			id: 2,
			name: 'BigCommerce',
			value: 'bigcommerce',
			image: 'assets/icons/bigcommerce-icon.svg',
			connected: false,
			is_pixel_installed: false
		}
	];

	private unsubs$ = new Subject<void>();

	constructor(private ecommerceImplService: EcommerceImplService) {}

	ngOnInit(): void {
		if (this.ecommerceIntegrationsData?.length) {
			this.ecommerceCards.forEach(ecommerceCard => {
				const matchedIntegrationEl = this.ecommerceIntegrationsData.find(
					integrationEl => ecommerceCard.value === integrationEl.alias
				);
				if (matchedIntegrationEl != undefined) {
					ecommerceCard.connected = true;
					ecommerceCard.is_pixel_installed =
						matchedIntegrationEl.is_pixel_installed;
				}
			});
		}
	}

	public onCardClick(card: GenericInputDataModel): void {
		if (card?.connected) {
			this.ecommerceImplService
				.disconnectEcommercePlatformFlow()
				.then(res => {
					card.connected = false;
				});
		} else {
			this.connectPlatform(card.value as EcommercePlatformsEnum);
		}
	}

	private connectPlatform(type: EcommercePlatformsEnum): void {
		switch (type) {
			case EcommercePlatformsEnum.BIGCOMMERCE:
				this.ecommerceImplService.connectToBigcommerceFlow();
				break;

			case EcommercePlatformsEnum.SHOPIFY:
				this.ecommerceImplService.connectToShopifyFlow();
				break;
		}
	}

	public changePanelState(value: boolean): void {
		this.isPanelOpen = value;
	}

	ngOnDestroy(): void {
		this.unsubs$.next();
		this.unsubs$.complete();
	}
}
