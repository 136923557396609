export class DiscoveryConstants {
	public static MAX_COUNT = 1000000000;
	public static MIN_COUNT = 1000;
	public static MAX_PERCENT = 20;
	public static MIN_PERCENT = 1;
	public static MAX_AGE = 65;
	public static MIN_AGE = 18;
	public static MAX_REELS_PLAY = 500000;
	public static MIN_REELS_PLAY = 3000;
	public static MIN_GROWTH_RATE_INTERVAL = 1;
	public static MAX_GROWTH_RATE_INTERVAL = 6;
	public static MIN_GROWTH_RATE_PERCENT = 10;
	public static MAX_GROWTH_RATE_PERCENT = 60;
}

export const contactInformationList = [
	{
		id: 1,
		name: 'Has BBM'
	},
	{
		id: 2,
		name: 'Has Email'
	},
	{
		id: 3,
		name: 'Has Facebook'
	},
	{
		id: 4,
		name: 'Has iTunes'
	},
	{
		id: 5,
		name: 'Has Kakao'
	},
	{
		id: 6,
		name: 'Has Kik'
	},
	{
		id: 7,
		name: 'Has Linkedin'
	},
	{
		id: 8,
		name: 'Has Linktree'
	},
	{
		id: 9,
		name: 'Has Pinterest'
	},
	{
		id: 10,
		name: 'Has Sarahah'
	},
	{
		id: 11,
		name: 'Has Sayat'
	},
	{
		id: 12,
		name: 'Has Skype'
	},
	{
		id: 13,
		name: 'Has Snapchat'
	},
	{
		id: 14,
		name: 'Has TikTok'
	},
	{
		id: 15,
		name: 'Has Tumblr'
	},
	{
		id: 16,
		name: 'Has TwitchTv'
	},
	{
		id: 17,
		name: 'Has Twitter'
	},
	{
		id: 18,
		name: 'Has VK'
	},
	{
		id: 19,
		name: 'Has Wechat'
	}
];
export const lastPostList = [
	{
		id: 1,
		name: '30 days'
	},
	{
		id: 2,
		name: '3 months'
	},
	{
		id: 3,
		name: '6 months'
	}
];
export const growthRateDurationList = [
	{
		id: 1,
		name: '1 month',
		value: 1
	},
	{
		id: 2,
		name: '2 months',
		value: 2
	},
	{
		id: 3,
		name: '3 months',
		value: 3
	},
	{
		id: 4,
		name: '4 months',
		value: 4
	},
	{
		id: 5,
		name: '5 months',
		value: 5
	},
	{
		id: 6,
		name: '6 months',
		value: 6
	}
];
export const growthRatePercentageList = [
	{
		id: 1,
		name: '>10%',
		value: 10
	},
	{
		id: 2,
		name: '>20%',
		value: 20
	},
	{
		id: 3,
		name: '>30%',
		value: 30
	},
	{
		id: 4,
		name: '>40%',
		value: 40
	},
	{
		id: 5,
		name: '>50%',
		value: 50
	},
	{
		id: 6,
		name: '>60%',
		value: 60
	}
];
export const averageReelsPlayList = [
	{
		id: 1,
		name: '3000'
	},
	{
		id: 2,
		name: '5000'
	},
	{
		id: 3,
		name: '10000'
	},
	{
		id: 4,
		name: '15000'
	},
	{
		id: 5,
		name: '25000'
	},
	{
		id: 6,
		name: '35000'
	},
	{
		id: 7,
		name: '50000'
	},
	{
		id: 8,
		name: '75000'
	},
	{
		id: 9,
		name: '100000'
	},
	{
		id: 10,
		name: '125000'
	},
	{
		id: 11,
		name: '150000'
	},
	{
		id: 12,
		name: '175000'
	},
	{
		id: 13,
		name: '200000'
	},
	{
		id: 14,
		name: '250000'
	},
	{
		id: 15,
		name: '300000'
	},
	{
		id: 16,
		name: '350000'
	},
	{
		id: 17,
		name: '500000'
	},
	{
		id: 18,
		name: '1000000'
	},
	{
		id: 19,
		name: '2000000'
	}
];
export const genderList = [
	{
		id: 2,
		name: 'Male'
	},
	{
		id: 3,
		name: 'Female'
	}
];
export const ageList = [
	{
		id: 1,
		name: 18
	},
	{
		id: 2,
		name: 25
	},
	{
		id: 3,
		name: 35
	},
	{
		id: 4,
		name: 45
	},
	{
		id: 5,
		name: 65
	}
];
export const largeNumberList = [
	{ id: 1, name: '1K', value: 1000 },
	{ id: 2, name: '5K', value: 5000 },
	{ id: 3, name: '10K', value: 10000 },
	{ id: 4, name: '15K', value: 15000 },
	{ id: 5, name: '30K', value: 30000 },
	{ id: 6, name: '50K', value: 50000 },
	{ id: 7, name: '75K', value: 75000 },
	{ id: 8, name: '100K', value: 100000 },
	{ id: 9, name: '150K', value: 150000 },
	{ id: 10, name: '200K', value: 200000 },
	{ id: 11, name: '300K', value: 300000 },
	{ id: 12, name: '400K', value: 400000 },
	{ id: 13, name: '500K', value: 500000 },
	{ id: 14, name: '1M+', value: 1000000 }
];
export const engagementRateList = [
	{
		id: 1,
		name: '1%',
		value: 1
	},
	{
		id: 2,
		name: '2%',
		value: 2
	},
	{
		id: 3,
		name: '3%',
		value: 3
	},
	{
		id: 4,
		name: '4%',
		value: 4
	},
	{
		id: 5,
		name: '5%',
		value: 5
	},
	{
		id: 6,
		name: '6%',
		value: 6
	},
	{
		id: 7,
		name: '7%',
		value: 7
	},
	{
		id: 8,
		name: '8%',
		value: 8
	},
	{
		id: 91,
		name: '9%',
		value: 9
	},
	{
		id: 10,
		name: '10%',
		value: 10
	},
	{
		id: 11,
		name: '11%',
		value: 11
	},
	{
		id: 12,
		name: '12%',
		value: 12
	},
	{
		id: 13,
		name: '13%',
		value: 13
	},
	{
		id: 14,
		name: '14%',
		value: 14
	},
	{
		id: 15,
		name: '15%',
		value: 15
	},
	{
		id: 16,
		name: '16%',
		value: 16
	},
	{
		id: 17,
		name: '17%',
		value: 17
	},
	{
		id: 18,
		name: '18%',
		value: 18
	},
	{
		id: 19,
		name: '19%',
		value: 19
	},
	{
		id: 20,
		name: '20%+',
		value: 20
	}
];
export const fakeFollowersList = [
	{
		id: 1,
		name: '≤ 25%',
		value: 25
	},
	{
		id: 2,
		name: '≤ 50%',
		value: 50
	},
	{
		id: 3,
		name: '≤ 75%',
		value: 75
	}
];
