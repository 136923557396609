import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'subscription-cancellation-warning',
	templateUrl: './subscription-cancellation-warning.component.html',
	styleUrls: ['./subscription-cancellation-warning.component.scss']
})
export class SubscriptionCancellationWarningComponent {
	@Input()
	cancellationDate = 1263789162232;

	@Output()
	cancel = new EventEmitter();
}
