import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-performance-based',
	templateUrl: './performance-based.component.html',
	styleUrls: ['./performance-based.component.scss']
})
export class PerformacedBasedComponent implements OnInit {
	@Input() data: any;

	constructor() {}

	ngOnInit(): void {}
}
