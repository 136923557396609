import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
	FormControl,
	NonNullableFormBuilder,
	ReactiveFormsModule
} from '@angular/forms';
import { unsafeKeys } from '../../../utils';
import { FieldErrorComponent } from './field-error.component';
import { TextareaComponent } from './textarea.component';

@Component({
	selector: 'textarea-field',
	template: `
		<textarea app [formControl]="control"></textarea>
		<error>{{ errorText }}</error>
	`,
	styles: [
		`
			:host {
				display: flex;
				flex-direction: column;
			}

			error {
				min-height: 1.4em;
			}
		`
	],
	imports: [
		ReactiveFormsModule,
		TextareaComponent,
		CommonModule,
		FieldErrorComponent
	],
	standalone: true
})
export class TextareaFieldComponent<
	T extends {
		[key: string]: string;
	}
> {
	@Input() control: FormControl<string> = this.builder.control('');
	@Input() errors?: T;

	constructor(private builder: NonNullableFormBuilder) {}

	get error() {
		return this.control.touched && this.control.errors !== null;
	}

	get errorText(): string | undefined {
		if (this.error) {
			const errorKeys = unsafeKeys(this.control.errors!);
			return this.errors?.[errorKeys[0]];
		}
	}
}
