import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';
import { TextComponent } from '../base';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'no-selected-file',
	template: `
		<text>
			Drag and drop your file here or
			<text
				class="link"
				[inline]="true"
				color="accent"
				(click)="onFileSelect()"
			>
				select a file to upload
			</text>
		</text>
		<text>Only .PDF & .doc files are supported</text>
	`,
	styles: [
		//language=scss
		`
			@use 'common';

			:host {
				@include common.column;
				background: #f5f4f7;
				padding: 20px 16px;
				border-radius: 4px;
				border-style: dashed;
				border-color: #dbdbdb;
				align-items: center;
				justify-content: center;

				&.drag-over {
					background: lightblue;
				}
			}

			.link {
				@include common.clickable;
			}
		`
	],
	imports: [TextComponent, CommonModule],
	standalone: true
})
export class NoSelectedFileComponent {
	@Input() selectedFile?: File;

	@Input()
	@HostBinding('class.drag-over')
	draggingOver = false;

	@Output() fileSelect = new EventEmitter();

	onFileSelect() {
		this.fileSelect.emit();
	}
}
