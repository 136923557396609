import _ from 'lodash';

export function snakeCase<T extends string>(v: T): SnakeCase<T> {
	return _.snakeCase(v) as SnakeCase<T>;
}

export type SnakeCase<T extends string> = T extends `${infer Head}${infer Tail}`
	? Tail extends Uncapitalize<Tail>
		? `${Lowercase<Head>}${SnakeCase<Tail>}`
		: `${Lowercase<Head>}_${SnakeCase<Uncapitalize<Tail>>}`
	: T;
