<div class="wrapper">
	<div class="body">
		<div class="container">
			<p class="upto">
				<ng-container *ngIf="!loading">
					<ng-container *ngIf="planDetails?.users?.total">
						Invite
						{{
							planDetails?.users?.total > 10000
								? 'unlimited'
								: 'up to ' + (planDetails?.users?.total || 5)
						}}
						members of your team
					</ng-container>
				</ng-container>
				<!-- *skeleton loader -->
				<ngx-skeleton-loader
					*ngIf="loading"
					appearance="line"
					[theme]="{ height: '19px', width: '300px' }"
				></ngx-skeleton-loader>
			</p>
			<div class="">
				<div class="card-wrapper">
					<!-- *skeleton loader -->
					<ng-container *ngIf="loading">
						<ngx-skeleton-loader
							appearance="line"
							style="height: 249px; width: 198px;"
							[theme]="{ height: '249px', width: '198px' }"
							*ngFor="let user of [1, 2, 3, 4, 5]"
						></ngx-skeleton-loader>
					</ng-container>
					<ng-container *ngIf="!loading">
						<!-- *member card -->
						<div
							class="card"
							*ngFor="
								let user of subUsers;
								let userSequence = index
							"
						>
							<div
								class="action {{
									(subUsers[currentUserIndex]?.role ==
										'admin' ||
										subUsers[currentUserIndex]?.role ==
											'owner') &&
									userSequence != currentUserIndex
										? ''
										: 'hidden'
								}}"
							>
								<button
									mat-icon-button
									[matMenuTriggerFor]="menu"
									aria-label="Example icon-button with a menu"
								>
									<img
										src="assets\crm\automation\Icon feather-more-vertical.svg"
										alt=""
									/>
								</button>
								<mat-menu
									#menu="matMenu"
									yPosition="below"
									xPosition="before"
									[overlapTrigger]="true"
									class="providers-menu"
								>
									<button
										mat-menu-item
										(click)="openCreateModal(user)"
										class="{{
											user?.role == 'owner'
												? 'disabled'
												: ''
										}}"
									>
										<img
											src="assets\crm\automation\Icon material-edit.svg"
											alt=""
										/>
										<span>Edit</span>
									</button>
									<button
										mat-menu-item
										(click)="removeSubUser(user)"
										class="{{
											user?.role == 'owner'
												? 'disabled'
												: ''
										}}"
									>
										<img
											src="assets\crm\automation\Icon feather-trash.svg"
											alt=""
										/>
										<span>Remove</span>
									</button>
								</mat-menu>
							</div>

							<div class="content">
								<img
									src="assets\profile\Icon awesome-user-circle.svg"
									alt=""
								/>
								<span class="name">
									{{ user?.name }}
								</span>
								<span class="email">
									{{ user.email }}
								</span>
								<span class="role">
									{{ getTitleCaseName(user?.role) }}
								</span>
							</div>
						</div>
						<!-- *add member -->
						<div
							class="card add-member"
							*ngIf="
								subUsers?.length < planDetails?.users?.total &&
								(subUsers[currentUserIndex]?.role == 'admin' ||
									subUsers[currentUserIndex]?.role == 'owner')
							"
						>
							<div class="content">
								<button
									class="primary-btn"
									(click)="openCreateModal()"
								>
									<img
										src="assets/discovery-v2/plus-white.svg"
										class="img-hover"
									/>
								</button>
								<span>Add Member</span>
							</div>
						</div>
					</ng-container>
				</div>

				<!-- <table *ngIf="!loading" class="table">
					<thead>
						<tr>
							<th>MEMBER</th>
							<th class="center">ROLE</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let user of subUsers; let userSequence = index">
							<td class="mobile-padding">
								<div class="name-parent">
									<div class="name">
										<label>{{ user?.name }}</label>
										<span class="email">{{ user.email }}</span>
									</div>
								</div>
							</td>
							<td class="mobile-padding td-chip center">
								<span class="role-chip">
									{{ getTitleCaseName(user?.role) }}
								</span>
							</td>
							<td>
								<div *ngIf="userSequence != 0" class="actions hide-mobile">
									<button class="clear" (click)="openCreateModal(user)">
										<em class="fas fa-pen"></em>
										&nbsp;EDIT
									</button>
									<button (click)="removeSubUser(user)" class="clear">&nbsp;REMOVE</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table> -->
			</div>

			<!-- <div class="k-text-right non-mobile" *ngIf="!loading && subUsers.length < 6">
				<app-primary-button [buttonLable]="'+ Add User'" [buttonType]="'Primary'" (buttonClicked)="openCreateModal()"
					[isDisabled]="userLimitExceed"></app-primary-button>
			</div> -->

			<!-- <div class="k-text-right mobile-none" *ngIf="!loading && subUsers.length < 6">
				<app-primary-button [buttonLable]="'+ Add User'" [buttonType]="'Primary'" (buttonClicked)="openCreateModal()"
					[isPeddingReduce]="true" [isDisabled]="userLimitExceed" [isPeddingReduceSmall]="true"></app-primary-button>
			</div> -->
			<div class="mt-m"></div>
		</div>
	</div>
</div>
