import { Component } from '@angular/core';

@Component({
	selector: 'error',
	template: `
		<ng-content></ng-content>
	`,
	styles: [
		`
			:host {
				color: red;
				font-size: 12px;
				font-family: inherit;
			}
		`
	],
	standalone: true
})
export class FieldErrorComponent {}
