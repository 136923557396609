import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import {
	SubUser,
	SubUserV2
} from 'src/app/user-management/models/sub-user.interface';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { SelectionModel } from '@angular/cdk/collections';
import {
	PermissionModel,
	PermissionsEnum
} from 'src/app/shared-components/permission.model';
import { take } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { ToastV2TypeEnum } from 'src/app/toast-v2/models/toast-v2.model';

@Component({
	selector: 'app-add-new-team',
	templateUrl: './add-new-team.component.html',
	styleUrls: ['./add-new-team.component.scss']
})
export class AddNewTeamComponent implements OnInit, OnDestroy {
	public heightFull: boolean = false;
	private unsubscriber$ = new Subject<void>();
	public teamForm: UntypedFormGroup;
	public roles: string[] = ['admin', 'standard', 'read_only'];
	public emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	public selection = new SelectionModel<PermissionModel>(true, []);
	public permissions: PermissionModel[] = [
		{
			id: 1,
			name: 'Create Campaign',
			value: PermissionsEnum.CAMPAIGN_CREATE,
			disabled: false
		},
		{
			id: 2,
			name: 'Upload Creators',
			value: PermissionsEnum.CREATOR_UPLOAD,
			disabled: false,
			tooltip: true
		},
		{
			id: 3,
			name: 'Delete Campaign',
			disabled: false,
			value: PermissionsEnum.CAMPAIGN_DELETE
		},
		{
			id: 4,
			name: 'Create Job',
			disabled: false,
			value: PermissionsEnum.JOB_CREATE
		},
		{
			id: 5,
			name: 'Edit Automation',
			disabled: false,
			value: PermissionsEnum.AUTOMATION_EDIT
		},
		{
			id: 6,
			name: 'Delete Job',
			disabled: false,
			value: PermissionsEnum.JOB_DELETE
		},
		{
			id: 7,
			name: 'Edit Campaign',
			disabled: false,
			value: PermissionsEnum.CAMPAIGN_EDIT
		},
		{
			id: 8,
			name: 'Marketplace Access',
			disabled: false,
			value: PermissionsEnum.MARKETPLACE,
			tooltip: true
		},
		{
			id: 9,
			name: 'Discovery Search',
			disabled: false,
			value: PermissionsEnum.DISCVOVERY
		},
		{
			id: 10,
			name: 'Edit Job',
			disabled: false,
			value: PermissionsEnum.JOB_EDIT
		},
		{
			id: 11,
			name: 'Download Creators',
			disabled: false,
			value: PermissionsEnum.CREATOR_DOWNLOAD
		},
		{
			id: 12,
			name: 'Create List',
			disabled: false,
			value: PermissionsEnum.LIST_CREATE
		},
		{
			id: 13,
			name: 'Close Campaign',
			disabled: false,
			value: PermissionsEnum.LIST_CREATE
		},
		{
			id: 14,
			name: 'Chat With Creators',
			disabled: false,
			tooltip: true,
			value: PermissionsEnum.MESSAGE
		},
		{
			id: 15,
			name: 'Shortlist Creators',
			disabled: false,
			value: PermissionsEnum.CREATOR_SHORTLIST
		},
		{
			id: 16,
			name: 'Close Job',
			disabled: false,
			value: PermissionsEnum.JOB_CLOSE
		},
		{
			id: 17,
			name: 'Creative Approval',
			disabled: false,
			value: PermissionsEnum.CREATIVE
		},
		{
			id: 18,
			name: 'Send Jobs',
			disabled: false,
			value: PermissionsEnum.JOB_SEND
		},
		{
			id: 19,
			name: 'Post URL Approval',
			disabled: false,
			value: PermissionsEnum.CREATIVE_POSTURL
		},
		{
			id: 20,
			name: 'Payment',
			disabled: false,
			value: PermissionsEnum.PAYMENT
		},
		{
			id: 21,
			name: 'E-commerce Integration',
			disabled: false,
			value: PermissionsEnum.ECOMMERCE_INTEGRATION
		},
		{
			id: 22,
			name: 'Stripe Integration',
			disabled: false,
			value: PermissionsEnum.STRIPE_INTEGRATION
		},
		{
			id: 23,
			name: 'Email Integration',
			disabled: false,
			value: PermissionsEnum.EMAIL_INTEGRATION
		}
	];
	public loading = false;

	constructor(
		private dialogRef: MatDialogRef<AddNewTeamComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { isEdit: boolean; user: SubUserV2 | undefined },
		private fb: UntypedFormBuilder,
		private authService: AuthenticationService,
		private toastService: ToastNotificationService,
		private userService: UserServiceApi,
		public titleCasePipe: TitleCasePipe,
		private toastNotification: ToastNotificationService
	) {}

	ngOnInit(): void {
		this.initForm();
		if (this.data.isEdit) {
			this.setPreviousUserData();
		} else {
			this.setDefaultsForAdmin();
		}
	}

	public setPreviousUserData(): void {
		this.teamForm.get('email').patchValue(this.data.user.email);
		this.teamForm.get('role').patchValue(this.data.user.role);
		this.onRoleChange({ value: this.data.user.role });
	}

	public initForm(): void {
		this.teamForm = this.fb.group({
			email: new UntypedFormControl(
				this.data?.isEdit ? this.data.user.email : '',
				{
					validators: [
						Validators.required,
						Validators.maxLength(70),
						Validators.pattern(this.emailValidator)
					]
				}
			),
			role: new UntypedFormControl(
				this.data?.isEdit ? this.data.user.role : this.roles[0],
				[Validators.required]
			)
		});
	}

	get allSelected(): boolean {
		return this.selection.selected.length === this.permissions.length;
	}

	public toggleHeight(): void {
		this.heightFull = !this.heightFull;
		if (!this.heightFull) {
			this.dialogRef.updateSize('870px', '319px');
		} else {
			this.dialogRef.updateSize('870px', '754px');
		}
	}

	public onRoleChange(event): void {
		const value = event.value;
		switch (value) {
			case this.roles[0]:
				this.setDefaultsForAdmin();
				break;
			case this.roles[1]:
				this.setDefaultsForStandard();
				break;
			case this.roles[2]:
				this.setDefaultsForReadOnly();
				break;
			default:
				this.setDefaultsForAdmin();
				break;
		}
	}

	public setDefaultsForAdmin(): void {
		this.enableAll();
		this.deselectAll();
		this.selectAll();
	}

	public setDefaultsForStandard(): void {
		this.enableAll();
		this.deselectAll();
		this.disableStandardUserPermissions();
		this.selectAllEnabled();
	}

	public setDefaultsForReadOnly(): void {
		this.disableAll();
		this.deselectAll();
		this.enablePermissionsForReadOnlyUsers();
		this.selectAllEnabled();
	}

	public disableStandardUserPermissions(): void {
		this.permissions[19].disabled = true;
		this.permissions[20].disabled = true;
		this.permissions[21].disabled = true;
		this.permissions[22].disabled = true;
	}

	public enablePermissionsForReadOnlyUsers(): void {
		this.permissions[7].disabled = false;
		this.permissions[8].disabled = false;
		this.permissions[10].disabled = false;
		this.permissions[13].disabled = false;
	}

	public selectAllEnabled(): void {
		this.selection.select(...this.permissions.filter(x => !x.disabled));
	}

	public selectAll(): void {
		this.selection.select(...this.permissions);
	}

	public deselectAll(): void {
		this.selection.clear();
	}

	public disableAll(): void {
		this.permissions.forEach(x => {
			x.disabled = true;
		});
	}

	public enableAll(): void {
		this.permissions.forEach(x => {
			x.disabled = false;
		});
	}

	public validateForm(): boolean {
		if (!this.teamForm.valid) {
			this.teamForm.markAllAsTouched();
			return false;
		}
		return true;
	}

	public sendInvite(): void {
		if (!this.validateForm()) {
			return;
		}
		const payload = {
			emails: [this.teamForm.value.email],
			role: this.teamForm.value.role,
			permissions: this.selection.selected.map(x => x.value)
		};
		this.loading = true;
		this.userService
			.addSubUser(payload)
			.pipe(take(1))
			.subscribe(
				res => {
					const errorInviters = res?.error_invites || [];
					if (errorInviters.length > 0) {
						for (const error_inviter of errorInviters) {
							this.toastNotification.sendErrorToast(
								`Error, the account already exists: ${error_inviter}`
							);
						}
					}
					const successInviters = res?.success_invites || [];
					if (successInviters.length > 0) {
						this.toastService.sendCustomToast(
							'Your invite is successfully sent!',
							ToastV2TypeEnum.SUCCESS,
							2000,
							'Invited!'
						);
						this.dialogRef.close({ reload: true });
					}
				},
				err => {
					this.toastService.sendErrorToast('Some Error Occurred!');
				}
			)
			.add(() => {
				this.loading = false;
			});
	}

	public updateUser(): void {
		if (!this.validateForm()) {
			return;
		}
		const payload = {
			emails: [this.teamForm.value.email],
			role: this.teamForm.value.role,
			permissions: this.selection.selected.map(x => x.value)
		};
		this.loading = true;
		this.userService
			.editSubUser(this.data.user.id, payload)
			.pipe(take(1))
			.subscribe(
				res => {
					this.toastService.sendSuccessToast(
						'User Updated Successfully!'
					);
					this.dialogRef.close({ reload: true });
				},
				err => {
					this.toastService.sendErrorToast('Some Error Occurred!');
				}
			)
			.add(() => {
				this.loading = false;
			});
	}

	public getTitleCaseName(str: string): string {
		let updatedStr = str.replace(/_/g, ' ');
		updatedStr = this.titleCasePipe.transform(updatedStr);
		return updatedStr;
	}

	public closeDialog(): void {
		this.dialogRef.close('');
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
