export class UnknownError {
	error: unknown;

	constructor(error: unknown) {
		this.error = error;
	}

	public static check(error: unknown): UnknownError | undefined {
		return new UnknownError(error);
	}
}
