import { Component } from '@angular/core';

@Component({
	template: ``,
	styles: [
		//language=scss
		`
			@use '../../../../../src/assets/styles/common';

			:host {
				@include common.row;
				border-bottom: 1px solid black;
			}
		`
	],
	standalone: true
})
export class DividerComponent {}
