<section class="dialog-root">
	<!-- * Close button that floats outside the dialog -->
	<div class="close-btn" mat-dialog-close>
		<mat-icon>clear</mat-icon>
	</div>

	<div class="dialog-wrapper">
		<!-- * Header -->
		<div class="dialog-header">
			<div class="d-flex flex-column flex-1" style="gap: 5px;">
				<div class="title">SMTP Mailbox integration</div>
				<div class="subtitle">
					Integrate your mailbox through SMTP settings
				</div>
			</div>
		</div>

		<!-- * Body -->
		<div class="dialog-body">
			<div class="desc">
				Integrate your mailbox through SMTP&nbsp;
			</div>

			<div class="form-wrapper">
				<div class="input-wrapper">
					<div class="label">Username</div>
					<div class="copy-input-wrapper">
						<input type="text" class="custom-input-name" [formControl]="mailboxCredentialsForm.get('username')" placeholder="mailbox@lolly.com" />
					</div>
				</div>

				<div class="input-wrapper" style="padding-bottom: 20px;">
					<div class="label">Password</div>
					<div class="copy-input-wrapper">
						<input type="text" class="custom-input-name" [formControl]="mailboxCredentialsForm.get('password')" />
					</div>
				</div>

				<div class="input-wrapper" style="padding-bottom: 20px;">
					<div class="label">Incoming server</div>
					<div class="copy-input-wrapper">
						<input
							type="text"
							class="custom-input-name"
							[formControl]="mailboxCredentialsForm.get('incoming_host')"
							placeholder="imap-mail.your-provider.com"
						/>
					</div>
				</div>

				<div class="input-wrapper" style="padding-bottom: 20px;">
					<div class="label">IMAP port</div>
					<div class="copy-input-wrapper">
						<input type="text" class="custom-input-name" [formControl]="mailboxCredentialsForm.get('imap_port')" placeholder="993" />
					</div>
				</div>

				<div class="input-wrapper" style="padding-bottom: 20px;">
					<div class="label">Outgoing server</div>
					<div class="copy-input-wrapper">
						<input
							type="text"
							class="custom-input-name"
							[formControl]="mailboxCredentialsForm.get('outgoing_host')"
							placeholder="smtp-mail.your-provider.com"
						/>
					</div>
				</div>

				<div class="input-wrapper" style="padding-bottom: 20px;">
					<div class="label">SMTP port</div>
					<div class="copy-input-wrapper">
						<input type="text" class="custom-input-name" [formControl]="mailboxCredentialsForm.get('smtp_port')" placeholder="465" />
					</div>
				</div>

				<ng-container *ngIf="loading">
					<ngx-skeleton-loader appearance="line" style="height: 72px; width: 100%;" [theme]="{ height: '72px', width: '100%' }"></ngx-skeleton-loader>
				</ng-container>
			</div>
		</div>

		<div class="dialog-footer">
			<button class="primary-btn" [disabled]="mailboxCredentialsForm?.invalid" (click)="saveSmtpSettings()">
				Save
			</button>
		</div>
	</div>
</section>
