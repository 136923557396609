import { TooltipPosition } from '@angular/material/tooltip';
import { SocialPlatformsEnum } from 'src/app/shared-components/enums/social-platforms.enum';

export interface GenericTableRowsModel {
	id?: string | any;
	isExpanded?: boolean;
	disableExpand?: boolean;
	rowStyles?: any;
	values: GenericTableDatasetModel[];
	children?: GenericTableRowsModel[];
	data?: any; // To store anything you wish
	hidden?: boolean;
}

export interface GenericTableDatasetModel {
	type: GenericTableDatasetTypeEnum;
	datasetStyles?: any;
	expandCollapseIconVisibility?: ExpandCollapseIconVisibilityEnum; // IF NO VALUE, HIDE FROM DOM
	datasetCallback?: Function;

	tooltipText?: string;
	tooltipAlign?: TooltipPosition;

	// Title Attributes
	title?: string | any;
	titleStyles?: any;
	titleCallback?: Function;
	titleView?: string | any;

	// Prefix Attributes
	prefixIcon?: string;
	prefixContainerStyles?: any;
	prefixCallback?: Function;
	prefixRestrictByRole?: boolean;
	prefixIconStyles?: any;

	// Suffix Attributes
	suffixIconsData?: SuffixIconsDataModel;

	// Menu Attributes
	menuOptions?: any[];
	menuOptionSelectCallback?: Function;
	menuOptionsRestrictByRole?: boolean;

	// Platforms Attributes
	platforms?: SocialPlatformsEnum[];
	platformIconStyle?: any;

	// Profile Data Attributes
	profileData?: ProfileDatasetModel; // Specifically for creator's profile data

	// Checkbox Attributes
	isCheckboxDisabled?: boolean;
	isCheckboxChecked?: boolean;
	isCheckboxIndeterminate?: boolean;
	checkboxChange?: Function;

	// Unlock Email Attributes
	unlockEmailData?: UnlockEmailAttributeDataModel;

	isDatasetHidden?: boolean;

	//Dropdown Tags Attributes
	dropDownData?: DropDataAttributeDataModel;
}

export interface ProfileDatasetModel {
	name?: string;
	nameStyles?: any;
	image?: string;
	imageStyles?: any;
	verifiedSymbols?: SocialPlatformsEnum[];
	verifiedSymbolStyles?: any;
}

export interface GenericTableColumnsModel {
	id?: string | any;
	data?: any;
	type: GenericTableColumnTypeEnum;
	columnStyles?: any;
	columnCallback?: Function;
	expandCollapseIconVisibility?: ExpandCollapseIconVisibilityEnum; // IF NO VALUE, HIDE FROM DOM

	// Title Attributes
	title?: string | any;
	titleStyles?: any;

	// Suffix Attributes
	suffixIcon?: string;
	suffixContainerStyles?: any;
	suffixCallback?: Function;
	suffixIconStyles?: any;

	// Tooltip Attribute
	tooltipData?: TooltipAttributeModel;

	// Sort & Filter Menu Attributes
	sortAndFilterMenu?: SortFilterMenuDataModel;

	// Checkbox Attributes
	isCheckboxDisabled?: boolean;
	isCheckboxChecked?: boolean;
	isCheckboxIndeterminate?: boolean;
	checkboxChange?: Function;

	isColumnHidden?: boolean;
	markSortableArrows?: boolean;
}

export interface TooltipAttributeModel {
	showTooltip?: boolean;
	tooltipText?: string;
}

export enum GenericTableColumnTypeEnum {
	TEXT = 'text',
	MENU = 'menu',
	CHECKBOX = 'checkbox'
}

export enum GenericTableDatasetTypeEnum {
	TEXT = 'text',
	MENU = 'menu',
	CHECKBOX = 'checkbox',
	PLATFORMS = 'platforms',
	CREATOR_PROFILE = 'creator_profile',
	UNLOCK_EMAIL = 'unlock_email',
	DROPDOWN = 'dropdown'
}

export enum ExpandCollapseIconVisibilityEnum {
	SHOW = 'show',
	HIDE = 'hide',
	INVISIBLE = 'invisible'
}

export interface SuffixIconsDataModel {
	suffixIconList?: SuffixIconDetailModel[];
	suffixIconContainerStyles?: any;
	showOnlyOnHover?: boolean;
}

export interface SuffixIconDetailModel {
	suffixIcon?: string;
	suffixType?: IconSourceTypeEnum;
	suffixCallback?: Function;
	suffixRestrictByRole?: boolean;
	suffixIconStyles?: any;
}

export enum IconSourceTypeEnum {
	MAT_ICON = 'mat-icon',
	IMAGE = 'img'
}

export interface SortFilterMenuDataModel {
	hideFilterSearch?: boolean;
	hideSortOptions?: boolean;
	hideFilterOptions?: boolean;
	primaryTitle?: string;
	secondaryTitle?: string;
	sortBy?: SortByOptionsEnum;
	sortByCallback?: Function;
	list?: FilteringListItemModel[];
	filteredList?: FilteringListItemModel[];
	applyFilterCallback?: Function;
	clearFilterCallback?: Function;
}

export enum SortByOptionsEnum {
	ASC = 'ascending',
	DSC = 'descending'
}

export interface FilteringListItemModel {
	name?: string;
	selected?: boolean;
	[key: string]: any;
}

export interface UnlockEmailAttributeDataModel {
	unlocked?: boolean;
	unlockLoading?: boolean;
	email?: string;
	copied?: boolean;
	callback?: Function;
}

export interface DropDataAttributeDataModel {
	deleteOutliened?: string;
	cross?: string;
	arrow?: string;
	isOpen?: boolean;
	selectedValue?: string;
	selectedValueBackgroundColor?: string;
	selectedValueBorderColor?: string;
	selectedValuedColor?: string;
	titleButton?: string;
	createTag?: Function;
	assignTag?: Function;
	approveDeleteTags?: Function;
	deleteTags?: Function;
	dropDownStyles?: Function;
}
