import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-pay-per-post',
	templateUrl: './pay-per-post.component.html',
	styleUrls: ['./pay-per-post.component.scss']
})
export class PayPerPostComponent implements OnInit {
	@Input() data: any;

	constructor() {}

	ngOnInit(): void {}
}
