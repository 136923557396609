import { Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonSizes } from './icon-button-base.component';

@Component({
	selector: 'button[export]',
	template: `
		<mat-icon>ios_share</mat-icon>
	`,
	styleUrls: ['./icon-button.scss'],
	imports: [MatIconModule],
	standalone: true
})
export class ExportButtonComponent {
	@Input() size: ButtonSizes = 'medium';

	@HostBinding('class.medium')
	private isMedium() {
		return this.size === 'medium';
	}
}
