import { Component, Input } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
	selector: 'app-picked-color',
	template: `
		<input
			[formControl]="control"
			type="color"
			class="signup-color-picker"
			readonly
		/>
	`,
	styles: [
		`
			.signup-color-picker {
				display: block;
				width: 22px;
				height: 22px;
				border-radius: 4px;
				border: none;
			}

			:host {
				height: 24px;
				width: 24px;
				display: flex;

				border: 1px solid #4a4a4a;
				border-radius: 4px;
			}
		`
	],
	imports: [ColorPickerModule, FormsModule, ReactiveFormsModule],
	standalone: true
})
export class PickedColorComponent {
	@Input() color = '#055ED7';
	@Input() control = new FormControl<string>(this.color);
}
