import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'button[banner]',
	template: `
		<ng-content></ng-content>
	`,
	styles: [
		`
			@use 'common';
			@use './banner';

			:host {
				@include banner.button-text;
				@include common.clickable;
			}
		`
	],
	styleUrls: ['banner.scss'],
	standalone: true
})
export class BannerButtonComponent {
	@HostBinding('class.banner-button') buttonClass = true;
}
