import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-input-cancellation-reason-dialog',
	templateUrl: './input-cancellation-reason-dialog.component.html',
	styleUrls: ['./input-cancellation-reason-dialog.component.scss']
})
export class InputCancellationReasonDialogComponent implements OnInit {
	public reasonControl: UntypedFormControl;

	constructor(public dialogRef: MatDialogRef<InputCancellationReasonDialogComponent>) {}

	ngOnInit(): void {
		this.reasonControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(20)]);
	}

	public primaryAction(): void {
		if (this.reasonControl?.invalid) {
			this.reasonControl.markAsTouched();
			return;
		}
		this.dialogRef.close({ primaryAction: 'true', reason: this.reasonControl.value });
	}

	public secondaryAction(): void {
		this.dialogRef.close({ secondaryAction: 'true' });
	}
}
