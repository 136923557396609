// import { GenericPredefinedPopupTypesEnum } from '../shared-components/generic-side-popup/models/generic-side-popup-data.model';
// import { TrialDataModel } from '../shared/models/first-timer-user.model';
// import {
// 	UserPaymentSubscriptionCodesEnum,
// 	UserPlanLimitCodesEnum
// } from '../shared/models/plan-limits.model';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericPredefinedPopupTypesEnum } from '../shared-components/generic-side-popup/models/generic-side-popup-data.model';
import { TrialDataModel } from '../shared/models/first-timer-user.model';
import {
	UserPaymentSubscriptionCodesEnum,
	UserPlanLimitCodesEnum
} from '../shared/models/plan-limits.model';
import { GenericSidePopupService } from '../shared/services/generic-side-popup.service';
import { GenericSmDialogService } from '../shared/services/generic-sm-dialog.service';
import { SharedService } from '../shared/services/shared.service';
import { UtilsService } from '../shared/utils';

@Injectable({
	providedIn: 'root'
})
export class LimitErrorsService {
	constructor(
		private router: Router,
		private genericSmDialogService: GenericSmDialogService,
		private genericSidePopupService: GenericSidePopupService,
		private sharedService: SharedService
	) {}

	handlePaymentErrors(error): void {
		if (this.router.url === '/user-management/manage-plan') {
			return;
		}
		switch (error?.error?.detail?.error_code) {
			case UserPaymentSubscriptionCodesEnum.TRIAL_EXPIRED:
				const trialPeriodExpired: TrialDataModel = {
					days_left: 0,
					had_trial_period: true,
					is_on_trial: false,
					subscription_status: 'trialing'
				};
				this.sharedService.trialPeriodData$.next(trialPeriodExpired);
				this.handleTrialExpiredError();
				this.router.navigate(['/']);
				break;
			case UserPaymentSubscriptionCodesEnum.PAYMENT_FAILED:
				this.showPaymentFailedDialog();
				this.router.navigate(['/']);
				break;
			case UserPaymentSubscriptionCodesEnum.SUB_EXPIRED:
			case UserPaymentSubscriptionCodesEnum.SUB_NOT_FOUND:
			default:
				this.showSubscriptionCancelledDialog();
				this.router.navigate(['/']);
				break;
		}
	}

	private handleTrialExpiredError(): void {
		this.showPredefinedSidePopup();
	}

	handleLimitErrors(error_code: number | undefined): void {
		if (this.router.url === '/user-management/manage-plan') {
			return;
		}
		let popupType: GenericPredefinedPopupTypesEnum =
			GenericPredefinedPopupTypesEnum.HOME;

		if (error_code === UserPlanLimitCodesEnum.AI_CREDITS) {
			this.genericSmDialogService.showPredefinedPopups('limit');
			return;
		}

		switch (error_code) {
			case UserPlanLimitCodesEnum.DISCOVERY_SEARCH:
			case UserPlanLimitCodesEnum.DISCOVERY_PROFILE:
			case UserPlanLimitCodesEnum.EXPORTS:
			case UserPlanLimitCodesEnum.DISCOVERY_EMAILS:
				popupType = GenericPredefinedPopupTypesEnum.DISCOVERY;
				break;
			case UserPlanLimitCodesEnum.ADVANCED_SEARCH:
				popupType = GenericPredefinedPopupTypesEnum.ADVANCED_SEARCH;
				break;
			case UserPlanLimitCodesEnum.LISTS_COUNT:
			case UserPlanLimitCodesEnum.LIST_SIZE:
				popupType = GenericPredefinedPopupTypesEnum.LIST;
				break;
			case UserPlanLimitCodesEnum.EMAILS_INTEGRATION:
				popupType = GenericPredefinedPopupTypesEnum.OUTREACH;
				break;
			case UserPlanLimitCodesEnum.INTEGRATED_DOMAINS:
				popupType = GenericPredefinedPopupTypesEnum.ECOMMERCE;
				break;
			default:
				return;
		}
		this.showPredefinedSidePopup(popupType, error_code, true);
	}

	private showSubscriptionCancelledDialog(): void {
		this.genericSmDialogService
			.showPredefinedPopups('sub_expired', true)
			.then(res => {
				if (res && res?.primaryAction === 'true') {
					window.open(UtilsService.BOOK_DEMO_URL, '_blank');
				}

				if (res && res?.secondaryAction === 'true') {
					this.router.navigate(['user-management/manage-plan'], {
						queryParams: { openPlans: true }
					});
				}
			});
	}

	private showPaymentFailedDialog(): void {
		this.genericSmDialogService
			.showPredefinedPopups('payment_fail', true)
			.then(res => {
				if (res && res?.primaryAction === 'true') {
					this.router.navigate(['user-management/manage-plan'], {
						queryParams: { openPlans: true }
					});
				}
			});
	}

	private showPredefinedSidePopup(
		type?: GenericPredefinedPopupTypesEnum | string,
		limitCode?: UserPlanLimitCodesEnum,
		showLimitData = false
	): void {
		let name = type ?? GenericPredefinedPopupTypesEnum.HOME;
		if (!type) {
			const splitUrl = this.router.url.split('/');
			if (splitUrl.length > 0) {
				name = splitUrl[1];
			}
		}
		this.genericSidePopupService
			.showPredefinedSidePopups(name, limitCode, showLimitData)
			.then(res => {
				if (res?.primary === true) {
					this.router.navigate(['user-management/manage-plan'], {
						queryParams: { openPlans: true }
					});
				}
				if (res?.secondary === true) {
					window.open(UtilsService.BOOK_DEMO_URL, '_blank');
				}
			});
	}
}
