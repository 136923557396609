<ng-container *ngIf="withLazyFocus">
	<div
		class="action-button-2 cursor-pointer {{ disabled ? 'disabled' : '' }}"
		[matTooltip]="showTooltip ? inputControl.value?.name : null"
		matTooltipPosition="below"
		matTooltipClass="title-custom-tooltip"
	>
		<div class="abs-label" *ngIf="label">{{ label }}</div>
		<mat-form-field
			appearance="outline"
			class="outline {{ disabled ? 'disabled-mat' : '' }}"
			[floatLabel]="'never'"
		>
			<input
				type="text"
				class="font-change"
				lazyAutofocus
				[placeholder]="placeholder"
				matInput
				[formControl]="inputControl"
				[matAutocomplete]="auto"
			/>
			<mat-icon matSuffix>keyboard_arrow_down</mat-icon>
			<mat-autocomplete
				[displayWith]="getOptionText"
				(optionSelected)="onOptionSelected($event)"
				class="auto-panel"
				#auto="matAutocomplete"
			>
				<mat-option
					*ngFor="let item of filteredList | async"
					[value]="item"
					[matTooltip]="showTooltip ? item?.name : null"
					matTooltipPosition="right"
					matTooltipClass="title-custom-tooltip"
				>
					<div class="d-flex justify-content-between">
						<span class="auto-search-drp-name w-100">
							{{ item?.name }}
						</span>
					</div>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</ng-container>

<ng-container *ngIf="!withLazyFocus">
	<div
		class="action-button-2 cursor-pointer {{ disabled ? 'disabled' : '' }}"
		[matTooltip]="showTooltip ? inputControl.value?.name : null"
		matTooltipPosition="right"
		matTooltipClass="title-custom-tooltip"
	>
		<div class="abs-label" *ngIf="label">{{ label }}</div>
		<mat-form-field
			appearance="outline"
			class="outline {{ disabled ? 'disabled-mat' : '' }}"
			[floatLabel]="'never'"
		>
			<input
				type="text"
				class="font-change"
				[placeholder]="placeholder"
				matInput
				[formControl]="inputControl"
				[matAutocomplete]="auto"
			/>
			<mat-icon matSuffix>keyboard_arrow_down</mat-icon>
			<mat-autocomplete
				[displayWith]="getOptionText"
				(optionSelected)="onOptionSelected($event)"
				class="auto-panel"
				#auto="matAutocomplete"
			>
				<mat-option
					*ngFor="let item of filteredList | async"
					[value]="item"
					[matTooltip]="showTooltip ? item?.name : null"
					matTooltipPosition="right"
					matTooltipClass="title-custom-tooltip"
				>
					<div class="d-flex justify-content-between">
						<span class="auto-search-drp-name w-100">
							{{ item?.name }}
						</span>
					</div>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</ng-container>
