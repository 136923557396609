import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class MyPlanService {
	constructor(private _http: HttpClient) {}

	public getActiveSubs(token: string): Observable<any> {
		return this._http.get(
			`${BaseApiUrl.SocialMessengerPython}subscriptions`,
			{
				headers: new HttpHeaders({ Authorization: token })
			}
		);
	}

	public getStripePlans(): Observable<any> {
		return this._http.get(
			`${BaseApiUrl.SocialMessengerPython}subscriptions/stripe_plans`
		);
	}

	public getStripePlanByAlias(alias: string): Observable<any> {
		return this._http.get(
			`${BaseApiUrl.SocialMessengerPython}subscriptions/stripe_plans/${alias}`
		);
	}

	public updatePlan(
		priceId: string,
		planBit: number,
		token: string
	): Observable<any> {
		return this._http.get(
			`${BaseApiUrl.SocialInfluencerPython}subscriptions/change-plan?price_id=${priceId}&plan_bit=${planBit}`,
			{
				headers: new HttpHeaders({ Authorization: token })
			}
		);
	}

	public cancelSubscriptionChange() {
		return this._http.get(
			`${BaseApiUrl.SocialInfluencerPython}subscriptions/cancel-plan-change`
		);
	}

	public getAllInvoices(token: string): Observable<any> {
		return this._http.get(
			`${BaseApiUrl.SocialInfluencerPython}subscriptions/get-all-subscriptions`,
			{
				headers: new HttpHeaders({ Authorization: token })
			}
		);
	}

	public getStripeUrl(token: string) {
		return this._http.get(
			`${BaseApiUrl.SocialInfluencerPython}subscriptions/billing-portal`,
			{
				headers: new HttpHeaders({ Authorization: token })
			}
		);
	}
}
