import { Component } from '@angular/core';
import { DotComponent } from './dot';

@Component({
	selector: 'banner-tag',
	template: '<dot></dot><ng-content></ng-content>',
	styles: [
		`
			@use 'colors' as *;
			@use 'primary-typography' as t;

			@use 'common';
			:host {
				@include common.row;
				@include t.paragraph-2;
				gap: 8px;
				justify-content: start;
				white-space: nowrap;
				color: $text-primary-purple;
				background: $secondary-purple;
				padding: 8px 16px;
				border-radius: 100px;
				color: var(--Text-purple-light, #a489e5);

				/* tag */
				font-family: Gilroy;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px; /* 133.333% */
			}
		`
	],
	imports: [DotComponent],
	standalone: true
})
export class BannerTag {}
