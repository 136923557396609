<div
	class="dialog-root justify-content-center h-100 d-flex flex-column align-items-center"
>
	<div class="video-wrapper" (click)="togglePlayPause()">
		<video
			#videoPlayer
			poster="https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png"
			src="https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4"
			controls
		></video>
		<mat-icon
			class="open-in-new-tab"
			(click)="
				$event.stopPropagation();
				openInNewTab(
					'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4'
				)
			"
		>
			open_in_new
		</mat-icon>
		<img
			*ngIf="videoPlayer?.paused"
			src="assets/icons/video-play.svg"
			class="center-icon {{ isFirstTimePlay ? 'visibleimp' : '' }}"
		/>
		<img
			*ngIf="!videoPlayer?.paused"
			src="assets/icons/video-pause.svg"
			class="center-icon"
		/>
	</div>

	<div class="d-flex flex-column k-align-items-center" [style.gap]="'10px'">
		<h4 class="font-l ls-0">Welcome to Lolly</h4>
		<h5 class="font-m ls-0">
			We'd love to show you around. It'll be fast, Promise
		</h5>
	</div>

	<div class="d-flex flex-column" [style.padding-top]="'8px'">
		<button class="primary-btn" (click)="closeDialog(true)">
			Start tour
		</button>
		<a class="no-thanks" (click)="closeDialog(false)">
			Thanks, I know it already
		</a>
	</div>
</div>
