<div
	class="card-wrapper"
	[ngClass]="{
		'mid-height': isPanelOpen
	}"
>
	<div class="card-header">
		<div
			class="d-flex h-100 w-100 k-align-items-center k-justify-content-between bor-bot"
		>
			<div class="d-flex k-align-items-center" [style.gap]="'10px'">
				<h5>Payment Integrations</h5>
				<i-tooltip
					[text]="'Seamlessly integrate secure payment options'"
					[position]="'bottom-right'"
					[position]="'center-right'"
				></i-tooltip>
			</div>

			<div
				*ngIf="!loading"
				class="d-flex k-align-items-center"
				style="gap: 10px"
			>
				<a
					class="quaternary-btn"
					style="color: #005ed8"
					href="https://lolly.zohodesk.in/portal/en/kb/articles/set-up-paypal"
					target="_blank"
				>
					Installation Instructions
				</a>

				<ng-container>
					<mat-icon
						class="cursor-pointer"
						*ngIf="isPanelOpen"
						(click)="changePanelState(false)"
					>
						keyboard_arrow_up
					</mat-icon>
					<mat-icon
						class="cursor-pointer"
						*ngIf="!isPanelOpen"
						(click)="changePanelState(true)"
					>
						keyboard_arrow_down
					</mat-icon>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="card-body">
		<div class="d-flex w-100 k-align-items-center" [style.gap]="'24px'">
			<div class="acc-card" (click)="openPaypalIntegrationDialog()">
				<img src="assets/icons/payment-icons/paypal-logo.svg" />

				<img
					*ngIf="isConnected"
					src="assets/icons/green-check-icon.svg"
					class="check"
				/>
			</div>
		</div>
	</div>
</div>
