<div class="container">
	<div class="content-wrapper">
		<h4 class="section-title">Enter your phone number</h4>
		<p class="sub-title">You will get a OTP on this number to verify number.</p>
		<form class="d-inline-flex">
			<div>
				<div class="input">
					<div class="input-group">
						<div class="number-grp">
							<mat-form-field class="cc">
								<mat-select [(value)]="selectedCountry" (selectionChange)="selectCountry($event)">
									<mat-select-trigger (filteredReturn)="filteredList = $event">
										{{ selectedCountry?.dial_code }}
									</mat-select-trigger>
									<!-- <mat-select-filter
										[array]="countries"
										(filteredReturn)="filteredList = $event"
										[displayMember]="'name'"
									></mat-select-filter> -->
									<!-- <input matInput type="text" [matAutocomplete]="auto" />
									<mat-autocomplete #auto="matAutocomplete">
									</mat-autocomplete> -->
									<mat-option [class.hide]="!isFiltered(opt)" *ngFor="let opt of countries" [value]="opt">
										{{ opt.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<form [formGroup]="numberForm">
								<input type="text" formControlName="number" class="custom-input" placeholder="Phone number" />
							</form>
						</div>

						<app-primary-button
							[buttonLable]="'Send OTP'"
							[buttonType]="'Primary'"
							(buttonClicked)="requestOtp()"
							*ngIf="!isOtpCalled"
						></app-primary-button>

						<button class="otp-sent-btn" *ngIf="isOtpCalled">
							<em style="margin-bottom: 4px; margin-right: 8px;" class="k-icon k-i-check k-i-checkmark"></em>
							Sent
						</button>
					</div>

					<div *ngIf="false" class="error-field">
						<span>This field is required.</span>
					</div>
				</div>
			</div>
		</form>

		<div [class.disabled-otp]="!isOtpCalled">
			<h4 class="otp-title">Enter OTP</h4>
			<p class="otp-subtitle" *ngIf="isOtpCalled">
				We sent an OTP on your phone number
				<span>*** **** *{{ selectedNumber.slice(-2) }}</span>
			</p>

			<app-primary-button
				[buttonLable]="'Verify'"
				[buttonType]="'Primary'"
				[isDisabled]="!isOtpCalled"
				[isPeddingReduceSmall]="true"
			></app-primary-button>
		</div>
	</div>
</div>
