import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignupSettingsAccordionComponent } from '../accordion';
import { BaseModule, RowComponent, TextComponent } from 'components';
import { LogoPickerComponent } from './logo-picker';

@Component({
	selector: 'logo-accordion',
	template: `
		<signup-settings-accordion
			title="Logo"
			[expanded]="expanded"
			(toggle)="toggle.emit()"
		>
			<text>Company Logo</text>
			<logo-picker
				[image]="logo"
				(imageRemove)="logoRemove.emit()"
				(uploadClick)="onLogoUploadClick()"
			></logo-picker>
			<text>Favicon</text>
			<logo-picker
				[image]="favicon"
				(imageRemove)="faviconRemove.emit()"
				(uploadClick)="onFaviconUploadClick()"
			></logo-picker>
			<row [style.justify-content]="'end'">
				<button app-reset (click)="logoReset.emit()"></button>
			</row>
		</signup-settings-accordion>
	`,
	imports: [
		SignupSettingsAccordionComponent,
		TextComponent,
		LogoPickerComponent,
		RowComponent,
		BaseModule
	],
	standalone: true
})
export class LogoPickerAccordion {
	@Input() expanded?: boolean;

	@Input() logo?: string;
	@Input() favicon?: string;

	@Output() toggle = new EventEmitter<void>();
	@Output() logoUpload = new EventEmitter();
	@Output() faviconUpload = new EventEmitter();

	@Output() logoRemove = new EventEmitter();
	@Output() faviconRemove = new EventEmitter();

	@Output() logoReset = new EventEmitter();

	onLogoUploadClick() {
		this.logoUpload.emit();
	}

	onFaviconUploadClick() {
		this.faviconUpload.emit();
	}
}
