import { Component, HostBinding, HostListener } from '@angular/core';
import { AccordionService } from './accordion.service';

@Component({
	selector: `accordion-title`,
	template: '<ng-content></ng-content>',
	styles: [
		//language=scss
		`
			@use 'common';

			:host {
				@include common.clickable;
				padding: 0.75em 1em;
				display: block;
			}
		`
	],
	standalone: true
})
export class AccordionTitleContainer {
	constructor(private accordion: AccordionService) {}

	@HostListener('click')
	onClick() {
		this.accordion.titleClicked();
	}
}

@Component({
	selector: `accordion-content`,
	template: '<ng-content></ng-content>',
	styles: [
		`
			:host {
				::ng-deep > * {
					min-height: 0;
				}

				width: 100%;
			}

			:host {
				display: grid;
				grid-template-rows: 0fr;

				transition: padding 0.4s, grid-template-rows 0.4s;
				overflow: clip;
				padding-left: 1em;
				padding-right: 1em;
				&.expanded {
					grid-template-rows: 1fr;
					padding-top: 0.75em;
					padding-bottom: 1em;
				}
			}
		`
	],
	standalone: true
})
export class AccordionContentContainer {
	@HostBinding('class.expanded')
	get expanded() {
		return this.accordion.isOpen;
	}
	constructor(private accordion: AccordionService) {}
}

@Component({
	selector: 'accordion',
	template: `
		<ng-content select="accordion-title"></ng-content>
		<ng-content select="accordion-content"></ng-content>
	`,
	styles: [
		//language=scss
		`
			@use 'common';

			:host {
				@include common.paper;
				display: block;
			}
		`
	],
	providers: [AccordionService],
	imports: [],
	standalone: true
})
export class AccordionComponent {
	@HostBinding('class.expanded')
	get expanded() {
		return this.accordion.isOpen;
	}

	public open() {
		if (!this.accordion.isOpen) this.accordion.toggle();
	}

	public close() {
		if (this.accordion.isOpen) this.accordion.toggle();
	}

	constructor(private accordion: AccordionService) {
		accordion.titleClick.subscribe(() => accordion.toggle());
	}
}
