import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	BaseModule,
	MobileSignupFormComponent,
	SignupFormComponent,
	SignupFormConfig
} from 'components';
import { PreviewButtonComponent } from './preview-button.component';
import {
	PreviewDeviceSelectorComponent,
	SignupPreviewDevice
} from './device-selector.component';

@Component({
	template: `
		<column>
			<row>
				<preview-button
					[expanded]="true"
					(click)="requestClose()"
				></preview-button>
				<device-selector
					[selected]="mode"
					(click)="changeMode()"
				></device-selector>
			</row>

			<div
				[class]="
					mode === 'desktop' ? 'form-wrapper' : 'form-wrapper-mobile'
				"
			>
				<signup-form
					*ngIf="mode == 'desktop'"
					[config]="config"
					[autocomplete]="false"
				></signup-form>
				<signup-form-mobile
					*ngIf="mode == 'phone'"
					[config]="config"
					[autocomplete]="false"
				></signup-form-mobile>
			</div>
		</column>
	`,
	styles: [
		`
			@use 'common';

			$preview-mode-transition: 0.2s;
			:host {
				@include common.column;

				background: white;

				width: 100vw;
				height: 100vh;

				display: flex;

				align-items: center;
				overflow: auto;
			}

			column {
				padding: 16px;
				justify-content: center;
				align-items: center;
				width: min-content;
			}

			row {
				justify-content: space-between;
				flex: 1;
				width: 100%;
			}

			.form-wrapper {
				border: 1px solid #ebebeb;
				border-radius: 4px;
				padding: 64px 128px;
				transition: $preview-mode-transition;
			}

			.form-wrapper-mobile {
				@include common.paper;
				border-radius: 4px;
				padding: 24px 24px;
				transition: $preview-mode-transition;
			}
			signup-form {
				width: 400px;
			}
		`
	],
	imports: [
		BaseModule,
		SignupFormComponent,
		PreviewButtonComponent,
		PreviewDeviceSelectorComponent,
		MobileSignupFormComponent
	],
	standalone: true
})
export class FullscreenSignupPreviewComponent {
	mode: SignupPreviewDevice = 'desktop';

	@Output() close = new EventEmitter<void>();

	@Input()
	config: SignupFormConfig = {
		logoUrl: '',
		button: {
			textColor: '#000000',
			color: '#F55ED7',
			text: 'Hello'
		},
		title: 'Hello',
		description: 'Hello',
		fields: [
			{ id: undefined, enabled: true, title: 'Company Custom Field' }
		]
	};

	requestClose() {
		this.close.emit();
	}

	changeMode() {
		this.mode = this.mode === 'desktop' ? 'phone' : 'desktop';
	}
}
