import { Component } from '@angular/core';
import { ButtonComponent, ButtonVariant } from './button.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'button[unlock]',
	template: `
		<mat-icon>lock_open</mat-icon>
		<ng-content></ng-content>
	`,
	styleUrls: ['./button.scss'],
	imports: [MatIconModule],
	standalone: true
})
export class UnlockButtonComponent extends ButtonComponent {
	variant: ButtonVariant = 'secondary';
}
