<div id="inner-container">
	<div class="grid-container-4">
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/spend.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">Total Spend</p>
				<p class="font-bold">{{ data.spends_total }}</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/revenue.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">Total Revenue</p>
				<p class="font-bold">{{ data.revenue }}</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/no-creators.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">No. of Creators</p>
				<p class="font-bold">{{ data.creators }}</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/no-job.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">No. of Jobs</p>
				<p class="font-bold">{{ data.jobs }}</p>
			</div>
		</div>
	</div>
</div>
