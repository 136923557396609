<section
	#mainSection
	*ngIf="!megaMenuOpen && showSidenav"
	[ngClass]="{ 'expanded-menu': expandMenu }"
	class="side-nav closed"
>
	<mat-sidenav #sidenav role="navigation" mode="side" opened="true">
		<div
			[ngClass]="{ 'no-menu-margin': !dropdownIsVisible }"
			class="menu-list"
			*ngIf="accountState !== userStateEnum.BillPendingRestrictedAccess"
		>
			<mat-list *ngFor="let mainItem of filteredMenuList; let i = index">
				<mat-list-item
					(click)="menuItemTrigger(i)"
					[disabled]="showSpinner"
					[ngStyle]="
						(mainItem.isSelected && !mainItem.childOpen) ||
						(mainItem.childOpen && !expandMenu)
							? menuItemSelectedEffect()
							: {}
					"
					[ngStyle]="
						mainItem.hoverOver && !mainItem.isSelected
							? mouseEnterMenuEffect()
							: {}
					"
					(mouseleave)="mouseLeaveMenu(i)"
					(mouseenter)="mouseEnterMenu(i)"
					[ngClass]="{
						'mat-list-item-content-expanded': expandMenu,
						locked: mainItem?.disabled === true
					}"
					class="menu-item"
					matTooltipPosition="after"
					id="{{ 'side-' + mainItem.name }}"
				>
					<div
						class="dot"
						*ngIf="mainItem?.name == 'onboarding'"
					></div>
					<img
						*ngIf="mainItem?.disabled === true"
						class="lock-icon"
						src="assets/icons/module-lock.svg"
					/>
					<img
						[ngClass]="{
							'filter-filed-purple':
								(mainItem.isSelected && !mainItem.childOpen) ||
								(mainItem.childOpen && !expandMenu)
						}"
						class="filter-dark"
						[src]="mainItem.icon"
					/>
					<span
						[ngStyle]="
							(mainItem.isSelected && !mainItem.childOpen) ||
							(mainItem.childOpen && !expandMenu)
								? { color: primaryColor }
								: {}
						"
						class="route-text"
					>
						{{ mainItem.title }}
					</span>
					<img
						style="width: 10px; height: 6.25px; margin-right: 16px"
						*ngIf="
							!mainItem.isSelected &&
							mainItem.hasChildren &&
							expandMenu
						"
						src="assets/icons/nav-menu/arrow-down.svg"
					/>
					<img
						style="width: 10px; height: 6.25px; margin-right: 16px"
						*ngIf="
							mainItem.isSelected &&
							mainItem.hasChildren &&
							expandMenu
						"
						src="assets/icons/nav-menu/arrow-up.svg"
					/>
				</mat-list-item>
				<ng-container
					*ngIf="
						filteredSubMenuList &&
						filteredSubMenuList.length > 0 &&
						mainItem.hasChildren &&
						mainItem.isSelected &&
						expandMenu
					"
				>
					<mat-list
						*ngFor="let item of filteredSubMenuList; let j = index"
					>
						<mat-list-item
							*ngIf="item?.show != false"
							(click)="menuItemSubTrigger(j)"
							[ngStyle]="
								item.isSelected ? menuItemSelectedEffect() : {}
							"
							[ngStyle]="
								item.hoverOver && !item.isSelected
									? mouseEnterMenuEffect()
									: {}
							"
							(mouseleave)="mouseLeaveSubMenu(j)"
							(mouseenter)="mouseEnterSubMenu(j)"
							[ngClass]="{
								'mat-list-item-content-expanded': expandMenu
							}"
							class="menu-item"
						>
							<div style="width: 16px; height: 16px"></div>
							<span
								[ngStyle]="
									item.isSelected
										? { color: primaryColor }
										: {}
								"
								class="route-text"
							>
								{{ item.title }}
							</span>
						</mat-list-item>
					</mat-list>
				</ng-container>
			</mat-list>
		</div>
	</mat-sidenav>
</section>

<section
	[ngClass]="{ 'side-container-wide': expandMenu }"
	class="side-container closed {{ showSidenav ? '' : 'hide-m-r' }}"
>
	<div
		*ngIf="showSidenav"
		[ngClass]="{ 'header-wide': expandMenu }"
		class="header closed"
	>
		<mat-toolbar class="header_toolbar">
			<div
				*ngIf="!megaMenuOpen"
				(click)="redirectToHome()"
				class="top-logo-img cursor-pointer"
			>
				<img src="assets/icons/nav-menu/header/lolly-black-logo.svg" />
			</div>

			<span fxFlex class="d-flex k-align-items-center"></span>

			<div *ngIf="isImpersonated" class="impersonation-exit">
				<div class="impersonation-content">
					<p class="impersonation-text">
						You are in Impersonation Mode. Any changes you make will
						impact your client.
					</p>

					<app-primary-button
						[buttonLable]="'EXIT'"
						[buttonType]="'Primary'"
						[isPeddingReduceSmall]="true"
						(buttonClicked)="exitImpersonation()"
					></app-primary-button>
				</div>
			</div>

			<div class="header_icons">
				<div
					*ngIf="trialPeriodData?.is_on_trial && !getStartedLoading"
					class="common-header-cols d-flex k-align-items-center"
					[style.gap]="'16px'"
					[style.padding-right]="'24px'"
				>
					<div
						class="d-flex k-align-items-center"
						[style.gap]="'8px'"
					>
						<div class="text">
							<b>{{ trialPeriodData?.days_left }} days</b>
							left to explore
						</div>

						<div class="upgrade-btn" (click)="goToUpgradePlan()">
							<div>View pricing</div>

							<mat-icon>star</mat-icon>
						</div>
					</div>

					<div class="vertical-line"></div>
				</div>
				<div
					(click)="openKnowledgeBase()"
					id="side-knowledgebase"
					class="q-icon"
				>
					<tooltip
						text="Get it right the first time! Reach out to Lolly through Ticket Support or utilize our Knowledge base if you have any queries around the workflow over our Platform"
						position="bottom-left"
						class="filter-toltip"
					>
						<img src="assets/icons/knowledge-base.svg" />
					</tooltip>
				</div>
				<div
					#settingsIcon
					id="side-settings"
					(click)="toggleProfileAction()"
					class="profile-icon"
				>
					<img src="assets/icons/nav-menu/header/user.svg" />
				</div>
			</div>
		</mat-toolbar>
	</div>

	<div class="main-container closed {{ showSidenav ? '' : 'main-full' }}">
		<mat-sidenav-container>
			<mat-sidenav-content
				(scroll)="onScroll($event)"
				style="background: white"
			>
				<router-outlet></router-outlet>
				<app-lolly-spinning-loader
					[rotating]="true"
					class="loading-indicator"
					*ngIf="showSpinner"
				></app-lolly-spinning-loader>
			</mat-sidenav-content>
		</mat-sidenav-container>
	</div>
</section>

<!-- !profile card -->
<div #settingsPopup class="profile-card" *ngIf="profileOpen">
	<div class="avatar-personal">
		<div class="avatar" (click)="gotoProfile()">
			{{ brandName?.charAt(0) }}
		</div>
		<div class="personal">
			<div class="top">{{ brandName }}</div>
			<div class="bottom">{{ userEmail }}</div>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div (click)="goToSettings()" class="action">
		<img src="assets/icons/nav-menu/settings.svg" />
		<div class="text">{{ 'Settings' }}</div>
	</div>
	<mat-divider></mat-divider>
	<div (click)="logout()" class="action">
		<img src="assets/icons/nav-menu/signout.svg" />
		<div class="text">{{ 'Sign out' }}</div>
	</div>
</div>
<div
	class="status-bar"
	*ngIf="
		trialPeriodData?.subscription_status !== 'active' &&
		!trialPeriodData?.is_on_trial &&
		trialPeriodData?.had_trial_period &&
		!getStartedLoading &&
		isStatusBarVisible
	"
>
	<div class="d-flex flex-1 k-align-items-center" [style.gap]="'25px'">
		<div class="warning-container">
			<img
				src="assets/icons/warning-danger-white-icon.svg"
				height="18"
				width="18"
			/>
		</div>
		<p>
			Your free trial has expired! Upgrade for complete control over your
			Influencer marketing needs.
		</p>
	</div>

	<div class="d-flex k-align-items-center" [style.gap]="'21px'">
		<a
			class="subscribe-btn"
			[routerLink]="['/user-management/manage-plan']"
			[queryParams]="{ openPlans: true }"
		>
			Subscribe Now
		</a>
	</div>
</div>
<app-hints></app-hints>
<app-priority-toaster class="toast-position-priority"></app-priority-toaster>
<!-- temporary -->
<ng-template #firstTimeLogin>
	<h2 mat-dialog-title>Thanks for using Lolly !</h2>
	<mat-dialog-content class="mat-typography restrict-users-dialog">
		<h3>
			The platform is undergoing development for our upcoming release and
			therefore new users don’t have access just yet. Please rest assured
			Dexter is still working in the background to analyse your
			campaign(s). Please get in touch with your Client Success Manager
			regarding any questions.
		</h3>
		<app-button
			(buttonClicked)="logout()"
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			buttonLabel="Logout"
		></app-button>
	</mat-dialog-content>
</ng-template>
