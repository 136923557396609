import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { EditorModule } from '@progress/kendo-angular-editor';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ProgressBarModule,
		GaugesModule,
		TooltipModule,
		GridModule,
		EditorModule,
		DropDownsModule,
		LabelModule,
		InputsModule,
		DateInputsModule,
		FlexLayoutModule,
		MatIconModule,
		RouterModule,
		MaterialModule,
		SharedModule
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		ProgressBarModule,
		GaugesModule,
		TooltipModule,
		GridModule,
		EditorModule,
		LabelModule,
		InputsModule,
		DateInputsModule,
		FlexLayoutModule,
		MatIconModule
	],
	providers: []
})
export class SmiReusedModule {}
