import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-select-drp-wo-input',
	templateUrl: './select-drp-wo-input.component.html',
	styleUrls: ['./select-drp-wo-input.component.scss']
})
export class SelectDrpWoInputComponent<T> implements OnInit {
	@Input('styleClass') public styleClass: string;
	@Input('cssStyle') public cssStyle: any;
	@Input('dropdownLabel') public dropdownLabel: string;
	@Input() public inputPlaceholder: string = 'Select';
	@Input() public dropdownControl = this.builder.control<T | null>(undefined);
	@Input() public options: T[];
	@Input('disabled') public disabled: boolean = false;
	@Input('showNullOption') public showNullOption: boolean = true;
	@Input('titleKey') public titleKey: string = 'name';
	@Input('firstOptionName') public firstOptionName: string = 'Select';

	@Input() compareKey?: string & keyof T;

	@Output() public selectionEvent = new EventEmitter<T>();

	constructor(private builder: FormBuilder) {}

	ngOnInit(): void {}

	public optionSelected(event: any) {
		this.selectionEvent.emit(event);
	}
}
