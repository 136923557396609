import { createFeatureSelector, createSelector } from '@ngrx/store';

import _ from 'lodash';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { PublicDateModel } from '../calendar/calendar.models';
import { ViewName } from '../elements/elements-metrics/models/metrics-view.enum';
import { ColumnViews } from '../elements/elements-metrics/models/views-column.interface';
import { BreakdownsResponseInterface } from '../models/breakdowns-response.interface';
import { LocalStorageService } from '../services/local-storage.service';
import { SharedActions, SharedActionTypes } from './shared.actions';
import {
	CachedAdAccountsModel,
	SelectedAdAccountModel
} from './state-entities/cached-ad-accounts.model';
import {
	DataInterface,
	NullDataInterface
} from './state-entities/data-interface.model';
import { FiledUsersApiCodesEnum } from './state-entities/errors/api-codes.enum';
import {
	FiledSubscriptionApiValidationCodesEnum,
	FiledUsersApiValidationCodesEnum
} from './state-entities/errors/api-validation.enum';
import { FiledApiModels } from './state-entities/errors/errors.model';
import {
	GeneralSettingsSlice,
	initialState as generalSettingsInitialState
} from './state-entities/general-settings/general-settings-slice.model';
import { PublishStatusInterface } from './state-entities/publish/publish-status.interface';

export interface SharedState {
	globalDateSelection: PublicDateModel;
	cachedAdAccounts: DataInterface<CachedAdAccountsModel[]>;
	selectedAdAccount: SelectedAdAccountModel;
	generalSettings: GeneralSettingsSlice;
	globalSpinnerShown: boolean;
	navigationSpinnerShown: boolean;
	filedApiEnums: FiledApiModels;
	measureSettings: {
		reportTypes: DataInterface<any[]>;
		dimensions: DataInterface<any>;
		metrics: DataInterface<any[]>;
		breakdowns: DataInterface<BreakdownsResponseInterface>;
	};
	globalElementsView: string;
	globalElementsViewAds: string;
	toggleElementsGraph: boolean;
	toggleElementsMetrics: boolean;
	toggleElementsRecommendations: boolean;
	publish: PublishStatusInterface;
	selectedMetrics: {
		isLoading: boolean;
		Columns: ColumnViews[];
	};
}

export const initialSharedState: SharedState = {
	globalDateSelection: new PublicDateModel(),
	cachedAdAccounts: {
		isLoaded: false,
		errorCode: null,
		data: []
	},
	selectedAdAccount: {
		adAccount: (LocalStorageService.tryGetItem<any[]>(
			'selectedAdAccounts'
		) || [])[0],
		sourceChannel: (+localStorage.getItem('source_channel') ||
			null) as SourceChannel
	},
	generalSettings: generalSettingsInitialState,
	globalSpinnerShown: false,
	navigationSpinnerShown: false,
	filedApiEnums: {
		filedApiUsersState: {
			filedUsersApiCodesState: [
				{
					code: FiledUsersApiCodesEnum.InitialPasswordWasAlreadySetup,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.Filed__Domain__NA__SetPassword__IsInvalidPasswordMismatch,
					message: 'Password mismatch'
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresDigit,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresLower,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresNonAlphanumeric,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresUniqueChars,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresUpper,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordTooShort,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SignIn_IdentityIsLockedOut,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SignIn_IdentityIsNotAllowed,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SignIn_IdentityRequiresTwoFactor,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserId_HasUserWithNoRolesDefined,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserId_IsDifferentInRouteAndJwt,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserId_IsNotClientEmployeeForCurrentUser,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWasNotFound_ByBusinessOwnerFacebookId,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWasNotFound_ByEmail,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWasNotFound_ById,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWithSameEmail_AlreadyExists,
					message: ''
				}
			],
			filedUsersApiValidationCodesState: [
				{
					code: FiledUsersApiValidationCodesEnum.BusinessOwnerFacebookId_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.BusinessOwnerGoogleId_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeeFirstName_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeeLastName_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeePermissions_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeePhoneNumber_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.InputFile_HasNoContent,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.InputFile_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.FileContentType_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.FileContentType_IsNotImageOrVideo,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.FileName_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_CurrentPassword_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_NewPassword_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_ConfirmNewPassword_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_NewPassword_DiffersFomConfirmNewPassword,
					message: ''
				}
			]
		},
		filedSubscriptionApi: {
			filedSubscriptionApiModel: [
				{
					code: FiledSubscriptionApiValidationCodesEnum.Filed__Domain__NA__PhoneNumber_ContactPhoneNumber__AreDifferent,
					message:
						'The phone number entered does not match the phone number of the account'
				},
				{
					code: FiledSubscriptionApiValidationCodesEnum.Filed__Domain__NA__Contact_RemoveAt__IsNotNull,
					message: 'This account subscription is already cancelled'
				}
			]
		}
	},
	measureSettings: {
		reportTypes: _.cloneDeep(NullDataInterface),
		dimensions: _.cloneDeep(NullDataInterface),
		metrics: _.cloneDeep(NullDataInterface),
		breakdowns: _.cloneDeep(NullDataInterface)
	},
	globalElementsView: ViewName.Performance,
	globalElementsViewAds: ViewName.Performance,
	toggleElementsGraph: true,
	toggleElementsMetrics: true,
	publish: null,
	toggleElementsRecommendations: true,
	selectedMetrics: {
		isLoading: false,
		Columns: []
	}
};

export const getSharedState = createFeatureSelector<SharedState>('shared');

export const getApiEnums = createSelector(
	getSharedState,
	state => state.filedApiEnums
);

createSelector(getSharedState, state => state.cachedAdAccounts?.data);
createSelector(getSharedState, state => state.selectedAdAccount);

createSelector(getSharedState, state =>
	state.cachedAdAccounts?.data?.find(
		cachedAdAccount =>
			cachedAdAccount.sourceChannel === SourceChannel.Facebook
	)
);
createSelector(getSharedState, state =>
	state.cachedAdAccounts?.data?.find(
		cachedAdAccount =>
			cachedAdAccount.sourceChannel === SourceChannel.Google
	)
);
createSelector(getSharedState, state => state.publish);

createSelector(getSharedState, state => state.generalSettings.shared.isLoaded);
createSelector(
	getSharedState,
	state => state.generalSettings.adsManager.isLoaded
);
createSelector(
	getSharedState,
	state => state.generalSettings.campaignBuilder.isLoaded
);
createSelector(getSharedState, state => state.globalSpinnerShown);
export const getnavigationSpinnerStatus = createSelector(
	getSharedState,
	state => state.navigationSpinnerShown
);

export function sharedReducer(
	state = initialSharedState,
	action: SharedActions
): SharedState {
	switch (action.type) {
		case SharedActionTypes.LoadReportTypes: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					reportTypes: {
						...state.measureSettings.reportTypes,
						isLoading: true
					}
				}
			};
		}
		case SharedActionTypes.LoadReportTypesFailure: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					reportTypes: {
						...state.measureSettings.reportTypes,
						errorCode: action.payload,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		}

		case SharedActionTypes.SetGlobalElementsView: {
			return {
				...state,
				globalElementsView: action.payload
			};
		}

		case SharedActionTypes.SetGlobalElementsViewAds: {
			return {
				...state,
				globalElementsViewAds: action.payload
			};
		}

		case SharedActionTypes.SetToggleElementsGraph: {
			return {
				...state,
				toggleElementsGraph: action.payload
			};
		}

		case SharedActionTypes.SetToggleElementsMetrics: {
			return {
				...state,
				toggleElementsMetrics: action.payload
			};
		}

		case SharedActionTypes.SetToggleElementsRecommendations: {
			return {
				...state,
				toggleElementsRecommendations: action.payload
			};
		}

		case SharedActionTypes.SetSelectedElementsMetrics: {
			return {
				...state,
				selectedMetrics: action.payload
			};
		}

		case SharedActionTypes.LoadCachedCampaigns:
		case SharedActionTypes.LoadCachedAdSets:
		case SharedActionTypes.LoadCachedCampaignsSuccess:
		case SharedActionTypes.LoadCachedAdSetsSuccess:

		case SharedActionTypes.LoadMetrics: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					metrics: {
						...state.measureSettings.metrics,
						isLoading: true
					}
				}
			};
		}

		case SharedActionTypes.LoadMetricsFailure: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					metrics: {
						...state.measureSettings.metrics,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadDimensions: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					dimensions: {
						...state.measureSettings.dimensions,
						isLoading: true
					}
				}
			};
		}

		case SharedActionTypes.LoadDimensionsSuccess: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					dimensions: {
						...state.measureSettings.dimensions,
						data: action.payload,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		}

		case SharedActionTypes.LoadDimensionsFailure: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					dimensions: {
						...state.measureSettings.dimensions,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadBreakdowns: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					breakdowns: {
						...state.measureSettings.breakdowns,
						isLoading: true
					}
				}
			};
		}

		case SharedActionTypes.LoadBreakdownsSuccess: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					breakdowns: {
						...state.measureSettings.breakdowns,
						data: action.payload,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		}

		case SharedActionTypes.LoadBreakdownsFailure: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					breakdowns: {
						...state.measureSettings.breakdowns,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.ShowGlobalSpinner: {
			return {
				...state,
				globalSpinnerShown: true
			};
		}
		case SharedActionTypes.HideGlobalSpinner: {
			return {
				...state,
				globalSpinnerShown: false
			};
		}

		case SharedActionTypes.ShowNavigationSpinner: {
			return {
				...state,
				navigationSpinnerShown: true
			};
		}
		case SharedActionTypes.HideNavigationSpinner: {
			return {
				...state,
				navigationSpinnerShown: false
			};
		}

		case SharedActionTypes.SetGlobalDate: {
			return {
				...state,
				globalDateSelection: action.payload
			};
		}

		case SharedActionTypes.SetAdAccountSelection: {
			return {
				...state,
				selectedAdAccount: action.payload
			};
		}

		case SharedActionTypes.LoadFacebookAdAccounts:

		case SharedActionTypes.LoadGoogleAdAccounts: {
			return {
				...state,
				cachedAdAccounts: {
					...state.cachedAdAccounts,
					errorCode: null,
					data: [],
					isLoaded: true
				}
			};
		}
		case SharedActionTypes.LoadCachedAdAccountsSuccess: {
			let adAccounts = _.cloneDeep(state.cachedAdAccounts?.data) || [];
			const sourceChannel = action.payload?.sourceChannel;
			if (
				(state.cachedAdAccounts && sourceChannel) ||
				sourceChannel === SourceChannel.Facebook
			) {
				const duplicateIndex = state.cachedAdAccounts?.data?.findIndex(
					cachedAdAccount =>
						cachedAdAccount.sourceChannel === sourceChannel
				);
				if (duplicateIndex && duplicateIndex !== -1) {
					adAccounts[duplicateIndex] = action.payload;
				} else {
					adAccounts.push(action.payload);
				}
			}

			return {
				...state,
				cachedAdAccounts: {
					...state.cachedAdAccounts,
					data: adAccounts
				}
			};
		}
		case SharedActionTypes.LoadCachedAdAccountsFailure: {
			return {
				...state,
				cachedAdAccounts: {
					...state.cachedAdAccounts,
					errorCode: action.payload
				}
			};
		}

		case SharedActionTypes.LoadSharedSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						data: action.payload,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}

		case SharedActionTypes.LoadSharedSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadAdsManagerSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}
		case SharedActionTypes.LoadAdsManagerSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						data:
							action.payload ||
							initialSharedState.generalSettings.adsManager.data
					}
				}
			};
		}
		case SharedActionTypes.LoadAdsManagerSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.LoadCampaignBuilderSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}
		case SharedActionTypes.LoadCampaignBuilderSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						data:
							action.payload ||
							initialSharedState.generalSettings.campaignBuilder
								.data
					}
				}
			};
		}
		case SharedActionTypes.LoadCampaignBuilderSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.UpdateSharedSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}
		case SharedActionTypes.UpdateSharedSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.UpdateSharedSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.UpdateAdsManagerSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						isLoaded: true,
						errorCode: null
					}
				}
			};
		}
		case SharedActionTypes.UpdateAdsManagerSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.UpdateAdsManagerSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.UpdateCampaignBuilderSettings: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						isLoaded: true
					}
				}
			};
		}
		case SharedActionTypes.UpdateCampaignBuilderSettingsSuccess: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.UpdateCampaignBuilderSettingsFailure: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.SetSharedSettingsState: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		}

		case SharedActionTypes.SetAdsManagerState: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					adsManager: {
						...state.generalSettings.adsManager,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.SetCampaignBuilderState: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					campaignBuilder: {
						...state.generalSettings.campaignBuilder,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		}
		case SharedActionTypes.SaveUserDashboards: {
			return {
				...state,
				generalSettings: {
					...state.generalSettings,
					shared: {
						...state.generalSettings.shared,
						data: {
							...state.generalSettings.shared.data,
							userDashboards: action.payload
						}
					}
				}
			};
		}

		case SharedActionTypes.UpdatePublishStatus: {
			return {
				...state,
				publish: action.payload
			};
		}

		default:
			return state;
	}
}
