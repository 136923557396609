import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreditLimitComponent } from '../shared/credit-limit/credit-limit.component';
import { LandingPageModule } from './modules/landing-page/landing-page.module';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => LandingPageModule
	},
	{
		path: 'campaign-create',
		loadChildren: () =>
			import(
				'./modules/campaign-create-v3/campaign-create-v3.module'
			).then(m => m.CampaignCreateV3Module)
	},
	{
		path: 'campaign',
		loadChildren: () =>
			import('./modules/dashboard/dashboard.module').then(
				m => m.DashboardModule
			)
	},
	{
		path: 'discovery',
		loadChildren: () =>
			import('./modules/discovery-v2/discovery-v2.module').then(
				m => m.DiscoveryV2Module
			),
		data: { preload: true }
	},
	{
		path: 'list',
		loadChildren: () =>
			import('./modules/discovery-v2/discovery-v2.module').then(
				m => m.DiscoveryV2Module
			),
		data: { preload: true }
	},
	{
		path: 'outreach',
		loadChildren: () =>
			import('./modules/outreach-v2/outreach-v2.module').then(
				m => m.OutreachV2Module
			)
	},
	{
		path: 'content-review',
		loadChildren: () =>
			import('./modules/content-review/content-review.module').then(
				m => m.ContentReviewModule
			)
	},
	{
		path: 'reporting',
		loadChildren: () =>
			import('./modules/reporting-v3/reporting-v3.module').then(
				m => m.ReportingV3Module
			)
	},
	{
		path: 'payments',
		loadChildren: () =>
			import('./modules/payments-v2/payments-v2.module').then(
				m => m.PaymentsV2Module
			)
	},
	{
		path: 'e-commerce',
		loadChildren: () =>
			import('./modules/ecommerce-v2/ecommerce-v2.module').then(
				m => m.EcommerceV2Module
			)
	},
	{
		path: 'creators',
		loadChildren: () =>
			import('./modules/creators/creators.module').then(
				m => m.CreatorsModule
			)
	},
	{
		path: 'terms-and-conditions',
		loadChildren: () =>
			import(
				'./modules/terms-and-conditions/terms-and-conditions.module'
			).then(m => m.TermsAndConditionsModule)
	},
	{
		path: 'asset-management',
		loadChildren: () =>
			import('./modules/asset-management/asset-management.module').then(
				m => m.AssetManagementModule
			)
	},
	{
		path: 'adv-search',
		loadChildren: () =>
			import('./modules/adv-search/adv-search.module').then(
				m => m.AdvSearchModule
			)
	},
	{
		path: 'onboarding',
		loadChildren: () =>
			import('./modules/onboarding/onboarding.module').then(
				m => m.OnboardingModule
			)
	},
	{
		path: 'expired',
		component: CreditLimitComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SocialMediaInfluencerRoutingModule {}
