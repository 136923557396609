import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'echarts/theme/macarons.js';
import { LicenseManager } from 'ag-grid-enterprise';
import { BaseApiUrl } from 'components';

LicenseManager.setLicenseKey(
	'CompanyName=ABSTRACT HOLDINGS LIMITED,LicensedApplication=Filed.com,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-017120,ExpiryDate=20_August_2022_[v2]_MTY2MDk1MDAwMDAwMA==e4cc84f0a38826f6ad8925417539bf41'
);

namespace global {
	interface JQuery {
		dayScheduleSelector(): any;
	}
}

BaseApiUrl.replace(environment);

if (environment.Flags.production) {
	enableProdMode();
	injectCLarity();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));

function injectCLarity() {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.text = `
		(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "qpla2wiem3");
	`;
	document.body.appendChild(script);
}
