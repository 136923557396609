import { Component } from '@angular/core';

@Component({
	selector: 'lolly-input',
	template: `
		text
	`,
	styleUrls: ['./lolly-input.component.scss'],
	standalone: true
})
export class LollyInputComponent {}
