import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlanDetails, PlanVariants } from 'src/app/shared/models/plan-details.model';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { MyPlanService } from '../../../my-plan/my-plan.service';

@Component({
	selector: 'app-billing-plans',
	templateUrl: './billing-plans.component.html',
	styleUrls: ['./billing-plans.component.scss']
})
export class BillingPlansComponent implements OnInit, OnDestroy {
	@Output() public backToBilling = new EventEmitter<void>();
	@Input('currPlanPriceId') public currPlanPriceId: string;
	public plans: PlanDetails[] = [];
	public allPlans: PlanVariants;
	public selectedPlan: PlanDetails;
	private unsubscriber$ = new Subject<void>();
	public currPlan: PlanDetails;
	public loading: boolean = false;
	public currency: string = '$';
	public planStartIndex = 0;
	public planEndIndex = 2;

	constructor(private paymentService: PaymentServiceApi, private planService: MyPlanService, private toast: ToastNotificationService) {}

	ngOnInit(): void {
		this.loading = true;
		this.paymentService
			.fetchAllPlans()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((res: PlanVariants) => {
				this.allPlans = res;
				for (let i in this.allPlans) {
					if (this.allPlans[i].product_price_interval === 'month') {
						this.plans.push(this.allPlans[i]);
					}
					if (this.allPlans[i].product_price_id == this.currPlanPriceId) {
						this.currPlan = this.allPlans[i];
						this.selectedPlan = this.currPlan;
					}
				}
				this.loading = false;
				this.currency = '$';
			});
	}

	public changePlan(plan: PlanDetails): void {
		this.selectedPlan = plan;
	}

	public onCheckToggleView(event: boolean): void {
		let cond = event ? 'year' : 'month';
		this.plans = [];
		for (let i in this.allPlans) {
			if (this.allPlans[i].product_price_interval === cond) {
				this.plans.push(this.allPlans[i]);
			}
		}
	}

	public applyChanges(): void {
		let token = localStorage.getItem(StorageKey.token);
		let plan = this.selectedPlan;
		if (this.currPlan.product_price_id == plan.product_price_id) {
			return;
		} else if (this.currPlan.product_price_interval === 'month' && plan.product_price_interval === 'year') {
			this.updatePlan(plan, 1, token);
		} else if (this.currPlan.product_price_interval === 'year' && plan.product_price_interval === 'month') {
			this.updatePlan(plan, 2, token);
		} else if (+this.currPlan.product_price < +plan.product_price) {
			this.updatePlan(plan, 1, token); // upgrade
		} else {
			this.updatePlan(plan, 2, token); // downgrade
		}
	}

	public updatePlan(plan: PlanDetails, planBit: number, token: string): void {
		this.loading = true;
		this.planService.updatePlan(plan.product_price_id, planBit, token).subscribe(
			res => {
				if (res?.status) {
					this.toast.sendSuccessToast('Plan was Successfully Updated');
					this.currPlan = plan;
					this.currPlanPriceId = plan.product_price_id;
				} else {
					this.toast.sendErrorToast('Something went wrong, Please try again later');
				}
				this.loading = false;
			},
			err => {
				this.loading = false;
				this.toast.sendErrorToast('Something went wrong, Please try again later');
			}
		);
	}

	public slide(direction: string): void {
		if (direction === 'right') {
			if (this.planEndIndex + 2 >= this.plans.length) {
				this.planStartIndex = this.plans.length - 2;
				this.planEndIndex = this.plans.length;
				return;
			}
			this.planStartIndex = this.planStartIndex + 2;
			this.planEndIndex = this.planEndIndex + 2;
		} else {
			if (this.planStartIndex - 2 <= 0) {
				this.planStartIndex = 0;
				this.planEndIndex = 2;
				return;
			}
			this.planStartIndex = this.planStartIndex - 2;
			this.planEndIndex = this.planEndIndex - 2;
		}
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
