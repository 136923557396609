import { NgModule } from '@angular/core';
import { SignupFormComponent } from './branding-signup/signup-form.component';
import { MobileSignupFormComponent } from './branding-signup/mobile-signup-form.component';

const importExport = [SignupFormComponent, MobileSignupFormComponent];

@NgModule({
	imports: importExport,
	exports: importExport
})
export class SharedComponentsModule {}

export * from './branding-signup/mobile-signup-form.component';
export * from './branding-signup/signup-form.component';
