import { takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { default as countries } from '../../../../../../src/assets/jsons/CountryCodes.json';
import { Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-two-fa',
	templateUrl: './two-fa.component.html',
	styleUrls: ['./two-fa.component.scss']
})
export class TwoFAComponent implements OnInit {
	@Output() public sendFeedback: EventEmitter<boolean> = new EventEmitter();
	countries = [...countries];
	isOtpCalled = false;
	selectedCountry = this.countries[1]; // default united states
	number: number;
	countryControl: UntypedFormControl = new UntypedFormControl();
	private unsubscriber$ = new Subject<void>();
	numberForm: UntypedFormGroup;
	public selectedNumber: string;
	public filteredList = this.countries.slice();

	constructor(
		private router: Router,
		private backOfficeApiService: BackOfficeService,
		private fb: UntypedFormBuilder,
		private toastNotificationService: ToastNotificationService
	) {}

	ngOnInit(): void {
		this.numberForm = this.fb.group({
			number: new UntypedFormControl(null, [Validators.required])
		});
	}

	public goBack(): void {
		this.router.navigate(['settings/security/']);
	}

	onCodeChanged(event: string) {}

	public isFiltered(permission): any {
		return this.countries.find(item => item.dial_code === permission?.value);
	}

	onCodeCompleted(event: string) {
		let payload = new FormData();
		payload.append('phone_number', this.selectedNumber);
		payload.append('code', event);

		this.backOfficeApiService;
		// .verifyOtp(payload)
		// .pipe(takeUntil(this.unsubscriber$))
		// .subscribe(
		// 	response => {
		// 		if (response) {
		// 			this.sendFeedback.emit(true);
		// 			this.toastNotificationService.sendSuccessToast('Two step Authentication Enabled');
		// 		} else {
		// 			this.toastNotificationService.sendErrorToast('Please enter the correct OTP');
		// 		}
		// 	},
		// 	() => {}
		// );
	}

	public selectCountry(event: Event): void {
		console.log(event);
	}

	public requestOtp(): void {
		if (!this.numberForm.valid) {
			return;
		}
		let payload = new FormData();
		payload.append('phone_number', this.selectedCountry['dial_code'] + this.numberForm.controls.number.value);
		this.selectedNumber = this.selectedCountry['dial_code'] + this.numberForm.controls.number.value;

		this.backOfficeApiService;
		// .getOtp(payload)
		// .pipe(takeUntil(this.unsubscriber$))
		// .subscribe(
		// 	response => {
		// 		if (response) {
		// 			this.isOtpCalled = true;
		// 			this.toastNotificationService.sendSuccessToast('Otp has been sent!');
		// 		} else {
		// 			this.toastNotificationService.sendErrorToast('Please enter a valid number');
		// 		}
		// 	},
		// 	() => {}
		// );
	}
}
