import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'search-bar-button',
	templateUrl: './search-bar-button.component.html',
	styleUrls: ['./search-bar-button.component.scss']
})
export class SearchBarButtonComponent implements OnInit {
	@Output()
	click = new EventEmitter<string>();

	constructor() {}

	ngOnInit(): void {}

	onClick() {
		this.click.emit('');
	}
}
