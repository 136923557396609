import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
	MatDialogConfig
} from '@angular/material/dialog';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericSmDialogComponent } from 'src/app/shared-components/generic-sm-dialog/generic-sm-dialog.component';
import moment from 'moment';
import { KnowledgeBaseComponent } from 'src/app/sidenav/component-pieces/knowledge-base/components/knowledge-base/knowledge-base.component';
import { EmailDataModel } from 'src/app/shared-components/models/mailbox.model';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/shared/utils';
import { SharedService } from 'src/app/shared/services/shared.service';
import { GenericSmDialogService } from 'src/app/shared/services/generic-sm-dialog.service';
import { UserIntegrationsDataModel } from 'src/app/shared-components/models/user-integrations.model';
import { SmtpSetupDialogComponent } from '../../smtp-setup-dialog/smtp-setup-dialog.component';
import { ToastV2TypeEnum } from 'src/app/toast-v2/models/toast-v2.model';

@Component({
	selector: 'app-email-integrations',
	templateUrl: './email-integrations.component.html',
	styleUrls: ['./email-integrations.component.scss']
})
export class EmailIntegrationsComponent implements OnInit {
	public loading: boolean = true;
	public integrationsLoading: boolean = true;
	public emailsLimit: number = 0;
	public maxOutString: string = 'Unlimited';
	public mailboxes: EmailDataModel[] = [];
	private openDialog: boolean = false;
	public firstTimeTemplates: boolean = false;
	public integrationsData: UserIntegrationsDataModel;
	// * Google Auth Constants
	private GOOGLE_BASE_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
	private GOOGLE_CLIENT_ID = environment.Google.CrmClientId;
	private GOOGLE_SCOPES = environment.Google.Scopes;
	private GOOGLE_REDIRECT_URL = environment.LOLLY_URL;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private genericSmDialogService: GenericSmDialogService,
		public router: Router,
		public dialog: MatDialog,
		public location: Location,
		private authService: AuthenticationService,
		private sharedService: SharedService,
		private toast: ToastNotificationService,
		private matDialog: MatDialog,
		public activatedRoute: ActivatedRoute
	) {
		const show_dialog =
			this.activatedRoute.snapshot.queryParamMap.get('openMailbox');
		if (show_dialog === 'true') {
			this.openDialog = true;
		}
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: { openMailbox: null },
			queryParamsHandling: 'merge', // merge with existing query params
			replaceUrl: true // replace current URL without adding to browser history
		});
	}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe(x => {
			let status = x['status'];

			if (status) {
				this.showEmailConnectStatus(status);
				this.clearQueryParams();
			}
		});
		this.getConnectedEmails();
		this.getUserIntegrations();
	}

	private getUserIntegrations(): void {
		this.integrationsLoading = true;
		this.sharedService
			.getUserIntegrations()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.integrationsData = res;
					this.integrationsLoading = false;
				},
				err => {
					this.integrationsLoading = false;
				}
			);
	}

	private clearQueryParams(): void {
		this.router.navigate([], {
			queryParams: {
				status: null
			},
			queryParamsHandling: 'merge'
		});
	}

	private showEmailConnectStatus(status: string) {
		switch (status) {
			case '201':
				this.toast.sendSuccessToast(
					'Email connected successfully!',
					10000
				);
				break;
			case '409':
				this.toast.sendWarningToast(
					'This email is already associated with another user account. Please try to disconnect it first before adding it to this account!',
					10000
				);
				break;
			case '400':
			default:
				this.toast.sendErrorToast('Failed to connect email!', 10000);
				break;
		}
	}

	private onError(err): void {
		const title = 'Oops, something went wrong!';
		const msg = 'Please try again!';

		this.toast.sendCustomToast(msg, ToastV2TypeEnum.ERROR, 2000, title);
	}

	private handleEmailData(res): void {
		this.mailboxes = res.data;
		this.emailsLimit = res.emails_limit;
		if (this.openDialog) {
			this.linkMailbox();
		}
	}

	public getConnectedEmails(): void {
		this.loading = true;
		this.authService
			.getConnectedEmails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.handleEmailData(res);
					this.loading = false;
				},
				err => {
					this.loading = false;
				}
			);
	}

	public getPayload(): string {
		// const pathname = window.location.pathname?.replace(/^\//gi, '');

		return `?redirect_url=${location.origin}/user-management/integrations`;
	}

	public signInWithGoogle(): void {
		// ! Previous Implementation
		// const payload = this.getPayload();
		// this.loading = true;

		// this.authService
		// 	.authGoogle(payload)
		// 	.subscribe(
		// 		res => {
		// 			if (res?.data) {
		// 				window.location.href = res.data;
		// 			}
		// 		},
		// 		err => {
		// 			this.onError(err);
		// 		}
		// 	)
		// 	.add(() => {
		// 		this.loading = false;
		// 	});

		// ! NEW IMPLEMENTATION
		window.location.href = `${this.GOOGLE_BASE_URL}?client_id=${this.GOOGLE_CLIENT_ID}&scope=${this.GOOGLE_SCOPES}&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=${this.GOOGLE_REDIRECT_URL}`;
	}

	public signInWithOutlook(): void {
		const payload = this.getPayload();
		this.loading = true;

		this.authService
			.authPreInstall('outlook', payload)
			.subscribe(
				res => {
					if (res?.data) {
						window.location.href = res.data;
					}
				},
				err => {
					this.onError(err);
				}
			)
			.add(() => {
				this.loading = false;
			});
	}

	private showConfirm(data, config, cb): void {
		let matDialogConfig: MatDialogConfig = {
			panelClass: ['center-dialog-no-shadow', 'mailbox-dialog'],
			...config,
			backdropClass: 'light-backdrop',
			data: data
		};
		let dialogRef = this.matDialog.open(
			GenericSmDialogComponent,
			matDialogConfig
		);

		if (cb)
			dialogRef
				.afterClosed()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(res => {
					cb(res);
				});
	}

	public makeDefault(template: EmailDataModel): void {
		this.loading = true;
		let payload = {
			id: template?.id,
			email: template?.type
		};
		this.authService
			.authMakeDefault(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.toast.sendSuccessToast(
						'Default Mail Changed Successfully',
						1200
					);
					this.getConnectedEmails();
				},
				err => {
					this.loading = false;
				}
			);
	}

	private showLimitReachedPopup(): void {
		this.genericSmDialogService
			.showPredefinedPopups('limit', true)
			.then(res => {
				if (res && res?.primaryAction === 'true') {
					this.router.navigate(['user-management/manage-plan'], {
						queryParams: { openPlans: true }
					});
				}

				if (res && res?.secondaryAction === 'true') {
					window.open(UtilsService.BOOK_DEMO_URL, '_blank');
				}
			});
	}

	public linkMailbox(): void {
		if (
			this.emailsLimit != undefined &&
			this.mailboxes.length >= this.emailsLimit
		) {
			this.showLimitReachedPopup();
			return;
		}

		let matDialogData = {
			icon: 'assets/crm/mailboxes/Group 54191.svg',
			title: `Which Account do you want to connect?`,
			primaryPrefix: 'assets/crm/mailboxes/svgexport-1 (9).svg',
			secondaryPrefix: 'assets/crm/mailboxes/svgexport-1 (8).svg',
			primaryActionTitle: 'Outlook',
			secondaryActionTitle: 'Gmail',
			secondaryActionClass: 'secondary-btn'
		};
		let config = {
			minHeight: '338px', // Default Height For 1366px Resolution
			width: '495px'
		};
		this.showConfirm(matDialogData, config, res => {
			if (res?.primaryAction === 'true') {
				this.signInWithOutlook();
			}
			if (res?.secondaryAction === 'true') {
				this.signInWithGoogle();
			}
			if (res?.alternativeAction === 'true') {
				this.openSmtpSetupDialog();
			}
		});
	}

	public uninstallTemplate(item: EmailDataModel): void {
		this.loading = true;
		this.authService
			.authUnInstallV2(item.type, item.email)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.toast.sendSuccessToast(
						`Disconnected ${item.type} Successfully`,
						1200
					);
					this.getConnectedEmails();
				},
				err => {
					this.loading = false;
				}
			);
	}

	// !utilities
	public goBack(): void {
		this.location.back();
	}

	public getFormattedDate(date: string): string {
		return moment(date).format('ddd, MMM DD [at] h:mm A');
	}

	public getUnavailableEmailTooltip(): string {
		return "Can't send emails from this mailbox";
	}
	// !end

	public openSmtpSetupDialog(): void {
		let matDialogConfig: MatDialogConfig = {
			panelClass: ['sharp-dialog-no-shadow', 'slide', 'slideInRight'],
			height: '100%',
			width: '514px',
			backdropClass: 'light-backdrop',
			scrollStrategy: new NoopScrollStrategy(),
			position: {
				right: '0'
			}
		};
		let dialogRef = this.matDialog.open(
			SmtpSetupDialogComponent,
			matDialogConfig
		);
		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				this.getConnectedEmails();
			});
	}
}

@Component({
	selector: 'email-integrations-dialog',
	templateUrl: 'email-integrations-dialog.component.html',
	styleUrls: ['./email-integrations.component.scss']
})
export class EmailIntegrationsDialogComponent implements OnInit, OnDestroy {
	private token = localStorage.getItem(StorageKey.token);
	private unsubscriber$ = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<EmailIntegrationsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { exist: boolean },
		private oAuthService: AuthenticationService
	) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}

	public signInWithGoogle(): void {
		this.oAuthService.authGoogle().subscribe(res => {
			if (res?.data) {
				window.location.href = res.data;
			}
		});
	}

	public signInWithOutlook(): void {
		this.oAuthService.authPreInstall('outlook').subscribe(res => {
			if (res?.data) {
				window.location.href = res.data;
			}
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
