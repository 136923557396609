<form class="flex-grow">
	<search-custom-dropdown
		(updateType)="setType($event)"
	></search-custom-dropdown>
	<custom-search-bar
		class="search-bar"
		[query]="query"
		(queryChange)="setQuery($event)"
	>
		<search-bar-inner-text></search-bar-inner-text>
	</custom-search-bar>
	<search-bar-button (click)="onSearchClick()"></search-bar-button>
</form>
