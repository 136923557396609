const names = ['B', 'KB', 'MB', 'GB', 'TB'] as const;

export function prettyFileSize(size: number) {
	const [remainder, oom] = ordersOfMagnitude(size);
	return `${remainder} ${names[oom]}`;
}

export function ordersOfMagnitude(bytes: number): [number, number] {
	let oom = 0;

	let n = bytes;
	let remainder = bytes;
	while (n > 0) {
		remainder = n;
		n = Math.floor(n / 1024);
		if (n > 0) {
			oom += 1;
		}
	}

	return [remainder, oom];
}
