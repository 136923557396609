import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconButtonBaseComponent } from './icon-button-base.component';

@Component({
	selector: 'button[delete]',
	template: `
		<mat-icon>delete</mat-icon>
	`,
	styleUrls: ['icon-button.scss', 'button.scss'],
	imports: [MatIconModule],
	standalone: true
})
export class DeleteButtonComponent extends IconButtonBaseComponent {}
