import { SignupFormComponent } from './signup-form.component';
import { Component } from '@angular/core';
import { BaseModule, CheckboxComponent } from '../../base';
import { InputComponent } from '../../base/inputs/input';
import { LinkComponent } from '../../base/link.component';

@Component({
	selector: 'signup-form-mobile',
	template: `
		<column>
			<img *ngIf="config.logoUrl" [src]="config.logoUrl" />
			<text variant="h1">{{ config.title }}</text>
			<text variant="p3">{{ config.description }}</text>
		</column>

		<row>
			<input-app placeholder="Full Name"></input-app>
		</row>

		<input-app placeholder="Last Name"></input-app>
		<input-app placeholder="Email Address"></input-app>
		<input-app placeholder="Create Password"></input-app>

		<input-app
			*ngFor="let field of config.fields"
			[placeholder]="field.title"
		></input-app>

		<row>
			<checkbox></checkbox>
			<text>
				I acknowledge that I have thoroughly read and fully accept the
				<app-link>Terms and Conditions</app-link>
				.
			</text>
		</row>

		<row>
			<checkbox></checkbox>
			<text>
				I agree to receive email notifications (like when I earn a
				commission) and other important emails regarding the affiliate
				program
			</text>
		</row>

		<button
			app
			[style.background]="config.button.color"
			[style.color]="config.button.textColor"
		>
			{{ config.button.text }}
		</button>

		<text class="bottom-text" variant="p3">
			Already have an account?
			<text color="accent" class="link" [inline]="true">Login</text>
		</text>
	`,
	styles: [
		//language=scss
		`
			@use '../../../../../../src/assets/styles/common';

			:host {
				@include common.column;
				gap: 1rem;
				width: 400px;
			}

			column {
				align-items: start;
			}

			button {
				margin-top: 1rem;
			}

			.link {
			}

			.bottom-text {
				align-self: center;
			}

			img {
				@include common.icon(40px);
			}
		`
	],
	imports: [BaseModule, CheckboxComponent, InputComponent, LinkComponent],
	standalone: true
})
export class MobileSignupFormComponent extends SignupFormComponent {}
