import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalInterface } from 'src/app/user-management/models/DatatableEnum';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-input-modal',
	templateUrl: './input-modal.component.html',
	styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent implements OnInit, OnChanges {
	@Input() public modelDetail: ModalInterface;
	public error: boolean;
	public name: string;
	constructor(private dialogRef: MatDialogRef<InputModalComponent>) {}

	ngOnInit(): void {}

	ngOnChanges(): void {}

	public closeDialog(): void {
		this.dialogRef.close(false);
	}

	public onSubmit(): void {
		if (!this.name || this.name?.length == 0) {
			this.error = true;
		}
		this.dialogRef.close({ value: this.name });
	}

	public actionButtonClick(): void {
		this.dialogRef.close();
	}
}
