import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	NonNullableFormBuilder,
	ReactiveFormsModule
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SignupFieldControl } from './sign-up-fields';
import {
	BaseModule,
	CheckboxState,
	InputComponent,
	CheckboxComponent
} from 'components';

@Component({
	selector: 'custom-signup-field-input',
	template: `
		<form (submit)="onSubmit()">
			<row [left]="true">
				<checkbox [formControl]="form.controls.appEnabled"></checkbox>
				<text variant="p2">Custom Field</text>
			</row>
			<row *ngIf="form.value.appEnabled === 'on'">
				<input-app
					title="Enter field"
					placeholder="Field name"
					[control]="form.controls.name"
				>
					<button app-small type="submit">Save</button>
				</input-app>
			</row>
		</form>
	`,
	styles: [
		`
			@use 'common';

			:host,
			form {
				@include common.column;
				gap: 16px;
			}
		`
	],
	imports: [
		ReactiveFormsModule,
		CommonModule,
		BaseModule,
		InputComponent,
		CheckboxComponent
	],
	standalone: true
})
export class SignupFieldInputComponent {
	@Input()
	form: SignupFieldControl = this.formBuilder.group({
		appEnabled: 'off' as CheckboxState,
		name: '',
		id: undefined as number | undefined
	});

	@Output() fieldSave = new EventEmitter<string>();

	protected readonly JSON = JSON;

	constructor(private formBuilder: NonNullableFormBuilder) {}

	onSubmit() {
		if (this.form.valid) {
			this.fieldSave.emit(this.form.value.name ?? undefined);
		}
		return false;
	}
}
