import { Fields } from '../../utils/types';

export type InputCustomBrandingField = {
	id?: number;
	enabled: boolean;
	title: string;
};

export type CustomBrandingField = {
	id: number;
	enabled: boolean;
	title: string;
};

export type AgreementMetadata = {
	name: string;
	size: number;
	url: string;
};

export type InputBrandingSettings = Partial<{
	logo: File;
	favicon: File;
	title: string;
	description: string;

	brandingFields: InputCustomBrandingField[];

	primaryButtonColor: string;
	primaryButtonTextColor: string;
	buttonText: string;

	agreement: File;

	termsAndConditionsUrl: string;

	removeFields: (keyof Omit<InputBrandingSettings, 'removeFields'>)[];
}>;

type test = Fields<InputBrandingSettings, File | undefined>;

const a: test = 'logo';

export type ServerBrandingSettings = {
	logo_url?: string;
	favicon_url?: string;

	title: string;
	description: string;

	branding_fields: CustomBrandingField[];

	primary_button_color: string;
	primary_button_text_color: string;
	button_text: string;

	agreement_metadata?: AgreementMetadata;

	terms_and_conditions_url?: string;
};
