import { Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonVariant } from './button.component';

export type ButtonSizes = 'small' | 'almost-medium' | 'medium' | 'large';
export type Border = 'black' | 'gray' | 'none';

@Component({
	template: `
		<mat-icon>ios_share</mat-icon>
	`,
	imports: [MatIconModule],
	standalone: true
})
export class IconButtonBaseComponent {
	@Input() size: ButtonSizes = 'medium';
	@Input() border: Border = 'none';
	@Input() variant: ButtonVariant = 'secondary';

	@HostBinding('class.medium')
	private isMedium() {
		return this.size === 'medium';
	}

	@HostBinding('class.almost-medium')
	private get isAlmostMedium() {
		return this.size === 'almost-medium';
	}

	@HostBinding('class.border-black')
	get isBorderBlack() {
		return this.border === 'black';
	}

	@HostBinding('class.border-gray')
	get isBorderGray() {
		return this.border === 'gray';
	}

	@HostBinding('class.border-none')
	get isBorderNone() {
		return this.border === 'none';
	}

	@HostBinding('class.secondary-button')
	get isSecondaryButton() {
		return this.variant === 'secondary';
	}

	@HostBinding('class.primary-button')
	get isPrimaryButton() {
		return this.variant === 'primary';
	}
}
