import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FieldService<T> {
	public control: FormControl<T> | undefined;

	public controlUpdates = new BehaviorSubject<typeof this['control']>(
		undefined
	);

	constructor() {}

	public setControl(control: FormControl<T>) {
		this.controlUpdates.next(control);
		this.control = control;
	}
}
