import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PaginationQuery, PaginationResponseModel } from '../models';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PaginationService {
	options: number[] = [10, 25, 50];

	query: PaginationQuery = {
		page: 0,
		size: 10
	};

	private _pageChange = new Subject<PaginationQuery>();

	get pageChange(): Observable<PaginationQuery> {
		return this._pageChange;
	}

	get page() {
		return this.query.page;
	}

	get size() {
		return this.query.size;
	}

	get totalCount(): number | undefined {
		return this.response?.total_records;
	}

	response?: PaginationResponseModel;

	pageEvent(pageEvent: PageEvent) {
		this.query = {
			page: pageEvent.pageIndex,
			size: pageEvent.pageSize
		};

		this._pageChange.next(this.query);
	}
}
