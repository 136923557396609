import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { NotificationResponse } from '../models/notifications.model';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {
	private unsubscriber$ = new Subject<void>();

	constructor(private http: HttpClient) {}

	public getAllNotifications(
		pageSize: number = 10,
		pageNumber: number = 1
	): Observable<NotificationResponse> {
		return this.http.get<NotificationResponse>(
			`${BaseApiUrl.SocialMessengerPython}notifications?page_size=${pageSize}&page_number=${pageNumber}`
		);
	}

	public deleteNotification(notificationId: number): Observable<any> {
		return this.http.delete<any>(
			`${BaseApiUrl.SocialMessengerPython}notifications/${notificationId}`
		);
	}

	public muteNotification(notificationType: string): Observable<any> {
		return this.http.post<any>(
			`${BaseApiUrl.SocialMessengerPython}notifications/settings/${notificationType}`,
			{}
		);
	}

	public unmuteNotification(notificationType: string): Observable<any> {
		return this.http.delete<any>(
			`${BaseApiUrl.SocialMessengerPython}notifications/settings/${notificationType}`,
			{}
		);
	}

	public readNotifications(payload: any): Observable<any> {
		return this.http.patch<any>(
			`${BaseApiUrl.SocialMessengerPython}notifications`,
			payload
		);
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
