import { Component, HostBinding, Input } from '@angular/core';
import { Border } from './icon-button-base.component';

export type ButtonColor = 'primary' | 'pink';
export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

@Component({
	selector: 'button[app]',
	template: `
		<ng-content></ng-content>
	`,
	styleUrls: [`./button.scss`],
	standalone: true
})
export class ButtonComponent {
	@Input() variant: ButtonVariant = 'primary';
	@Input() color: ButtonColor = 'primary';

	@Input() border?: Border;
	@Input() hover?: boolean;

	@HostBinding('class.primary-button')
	get isPrimary() {
		return this.variant === 'primary';
	}

	@HostBinding('class.secondary-button')
	get isSecondary() {
		return this.variant === 'secondary';
	}

	@HostBinding('class.tertiary-button')
	get isTertiary() {
		return this.variant === 'tertiary';
	}

	@HostBinding('class.quaternary-button')
	get isQuaternary() {
		return this.variant === 'quaternary';
	}

	@HostBinding('class.hover')
	get isHover() {
		return this.hover;
	}

	@HostBinding('class.border-black')
	get isBorderBlack() {
		return this.border === 'black';
	}

	@HostBinding('class.border-gray')
	get isBorderGray() {
		return this.border === 'gray';
	}

	@HostBinding('class.border-none')
	get isBorderNone() {
		return this.border === 'none';
	}
}
