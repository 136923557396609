<div class="container">
	<div class="body">
		<!-- !basic -->
		<form
			class="body-form less-gap card"
			[class.disabled]="isEditActive2 || isEditActive3"
			[formGroup]="userForm"
		>
			<!-- * COMPANY NAME -->
			<div class="forms">
				<div class="name-con h-med">
					<label class="label">Company Name</label>
					<!-- *skeleton loader -->
					<ngx-skeleton-loader
						*ngIf="loading"
						appearance="line"
						style="height: 22px; width: 100%"
						[theme]="{ height: '22px', width: '40%' }"
					></ngx-skeleton-loader>

					<ng-container *ngIf="!loading">
						<label *ngIf="!isEditActive">
							{{ this.userForm.get('current_company').value }}
						</label>
						<input
							*ngIf="isEditActive"
							formControlName="company"
							type="text"
							class="custom-input"
							placeholder="Name"
						/>
					</ng-container>
				</div>

				<p
					*ngIf="!isEditActive && !loading"
					appRoleBasedControl
					class="edit-btn"
					(click)="editName()"
				>
					<em class="fas fa-pen hoverable"></em>
					Edit
				</p>
			</div>

			<!-- * WEBSITE -->
			<div class="forms">
				<div class="name-con h-med">
					<label class="label">Website</label>
					<!-- *skeleton loader -->
					<ngx-skeleton-loader
						*ngIf="loading"
						appearance="line"
						style="height: 22px; width: 100%"
						[theme]="{ height: '22px', width: '40%' }"
					></ngx-skeleton-loader>

					<ng-container *ngIf="!loading">
						<label *ngIf="!isEditActive">
							{{
								this.userForm.get('current_company_website')
									?.value
							}}
						</label>
						<app-website-input
							style="width: 372px"
							*ngIf="isEditActive"
							[inputControl]="userForm.get('company_website')"
						></app-website-input>
					</ng-container>
				</div>
			</div>

			<!-- * LOGO -->
			<div class="forms">
				<div class="name-con h-med">
					<label class="label">Logo</label>
					<!-- *skeleton loader -->
					<ngx-skeleton-loader
						*ngIf="loading"
						appearance="line"
						style="height: 22px; width: 100%"
						[theme]="{ height: '22px', width: '40%' }"
					></ngx-skeleton-loader>

					<ng-container *ngIf="!loading">
						<img
							class="logo-img"
							*ngIf="
								!isEditActive &&
								this.userForm.get('current_company_logo')?.value
							"
							[src]="
								this.userForm.get('current_company_logo')?.value
							"
						/>
						<div
							*ngIf="isEditActive"
							class="custom-logo-input-wrapper width-fix"
							(click)="openAddImgFilesDialog()"
						>
							<img
								class="uploaded-logo"
								*ngIf="userForm.get('company_logo')?.value"
								[src]="userForm.get('company_logo')?.value"
							/>
							<span
								class="txt-logo"
								*ngIf="!userForm.get('company_logo')?.value"
								height="24px"
								width="24px"
							>
								Logo
							</span>
							<img
								class="upload-icon"
								src="assets/icons/upload-round-black.svg"
							/>
						</div>
					</ng-container>
				</div>

				<div class="submit-con" *ngIf="isEditActive">
					<button class="secondary-btn" (click)="toggleEdit()">
						Cancel
					</button>
					<button class="primary-btn" (click)="saveBasicDetails()">
						Save
					</button>
				</div>
			</div>
		</form>

		<!-- !address -->
		<form
			class="body-form card {{ isEditActive2 ? 'edit-active' : '' }}"
			[class.disabled]="isEditActive || isEditActive3"
			[style.gap]="'0px'"
			[formGroup]="contactForm"
		>
			<div class="forms">
				<div class="name-con h-med">
					<label class="label">Email Address</label>
					<!-- *skeleton loader -->
					<ngx-skeleton-loader
						*ngIf="loading"
						appearance="line"
						style="height: 22px; width: 100%"
						[theme]="{ height: '22px', width: '60%' }"
					></ngx-skeleton-loader>

					<ng-container *ngIf="!loading">
						<label *ngIf="!isEditActive2">
							{{ this.contactForm.get('current_email')?.value }}
						</label>
						<input
							*ngIf="isEditActive2"
							[ngStyle]="{
								border: this.contactForm.get('email').valid
									? ''
									: '1px solid red'
							}"
							formControlName="email"
							type="text"
							class="custom-input"
							placeholder="Email"
							disabled
						/>
					</ng-container>
				</div>

				<!-- <p *ngIf="!isEditActive2 && !loading" class="edit-btn" (click)="editName2()">
					<em class="fas fa-pen hoverable"></em>
					Edit
				</p> -->
			</div>

			<div class="forms">
				<div class="submit-con" *ngIf="isEditActive2">
					<button class="secondary-btn" (click)="toggleEdit2()">
						Cancel
					</button>
					<button class="primary-btn" (click)="saveAddressDetails()">
						Save
					</button>
				</div>
			</div>
		</form>

		<!-- !security -->
		<form
			class="body-form card {{ isEditActive3 ? 'edit-active' : '' }}"
			[class.disabled]="isEditActive || isEditActive2"
			[formGroup]="securityForm"
			*ngIf="userForm.get('is_google_auth').value == false"
		>
			<div class="forms">
				<div class="name-con">
					<label class="label">Security</label>
					<div class="password" *ngIf="isEditActive3">
						<app-custom-password-field
							[control]="securityForm.get('current')"
							[placeholder]="'Current password'"
						></app-custom-password-field>
					</div>
				</div>

				<p
					*ngIf="!isEditActive3"
					class="edit-btn"
					appRoleBasedControl
					(click)="editName3()"
				>
					Change Password
				</p>
			</div>

			<ng-container *ngIf="isEditActive3">
				<div class="forms">
					<div class="name-con">
						<label class="label"></label>
						<app-custom-password-field
							[control]="securityForm.get('password')"
							[placeholder]="'New password'"
						></app-custom-password-field>
					</div>
				</div>

				<div class="forms">
					<div class="name-con">
						<label class="label"></label>
						<div class="input-wrap">
							<app-custom-password-field
								[control]="securityForm.get('confirmPassword')"
								[placeholder]="'Repeat new password'"
							></app-custom-password-field>
							<span class="info">
								{{
									securityForm.controls['confirmPassword']
										.touched &&
									securityForm.controls[
										'confirmPassword'
									].hasError('NoPasswordMatch')
										? "Password didn't match"
										: ''
								}}
							</span>
						</div>
					</div>
				</div>

				<div class="forms">
					<div class="name-con">
						<label class="label"></label>
						<div class="password-info">
							<div
								class="item {{
									securityForm.controls['password'].touched &&
									!securityForm.controls['password'].hasError(
										'required'
									) &&
									!securityForm.controls['password'].hasError(
										'minlength'
									)
										? ''
										: 'disabled'
								}}"
							>
								<img
									class="disable"
									src="assets\profile\Icon awesome-check-circle.svg"
								/>
								<img
									class="active"
									src="assets\profile\Icon awesome-check-circle (1).svg"
								/>
								<span>
									Password must be at least 8 characters long
								</span>
							</div>
							<div
								class="item {{
									securityForm.controls['password'].touched &&
									!securityForm.controls['password'].hasError(
										'required'
									) &&
									!securityForm.controls['password'].hasError(
										'hasSmallCase'
									)
										? ''
										: 'disabled'
								}}"
							>
								<img
									class="disable"
									src="assets\profile\Icon awesome-check-circle.svg"
								/>
								<img
									class="active"
									src="assets\profile\Icon awesome-check-circle (1).svg"
								/>
								<span>One lowercase character</span>
							</div>
							<div
								class="item {{
									securityForm.controls['password'].touched &&
									!securityForm.controls['password'].hasError(
										'required'
									) &&
									!securityForm.controls['password'].hasError(
										'hasCapitalCase'
									)
										? ''
										: 'disabled'
								}}"
							>
								<img
									class="disable"
									src="assets\profile\Icon awesome-check-circle.svg"
								/>
								<img
									class="active"
									src="assets\profile\Icon awesome-check-circle (1).svg"
								/>
								<span>One uppercase character</span>
							</div>
							<div
								class="item {{
									securityForm.controls['password'].touched &&
									!securityForm.controls['password'].hasError(
										'required'
									) &&
									!securityForm.controls['password'].hasError(
										'hasSpecialCharacters'
									)
										? ''
										: 'disabled'
								}}"
							>
								<img
									class="disable"
									src="assets\profile\Icon awesome-check-circle.svg"
								/>
								<img
									class="active"
									src="assets\profile\Icon awesome-check-circle (1).svg"
								/>
								<span>One number or special character</span>
							</div>
						</div>
					</div>
				</div>

				<div class="forms">
					<div class="name-con">
						<label class="label"></label>
						<div class="submit-con">
							<button
								class="primary-btn"
								(click)="savePassword()"
								[disabled]="securityForm.invalid"
							>
								Save
							</button>
							<button class="secondary-btn" (click)="editName3()">
								Cancel
							</button>
						</div>
					</div>
				</div>
			</ng-container>
		</form>
	</div>
</div>
<input
	#imgFileSelect
	(click)="$event.target.value = null"
	class="d-none"
	type="file"
	accept=".jpg, .jpeg, .png"
/>
