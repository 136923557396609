import {
	Component,
	ElementRef,
	Inject,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from 'moment';
import { TitleCasePipe } from '@angular/common';
import { OutreachCreatorModel } from 'src/app/social-media-influencer/modules/outreach-v2/models/outreach-creators.model';
import { OutreachService } from 'src/app/social-media-influencer/modules/outreach-v2/services/outreach.service';

@Component({
	selector: 'app-bulk-email-dialog',
	templateUrl: './bulk-email-dialog.component.html',
	styleUrls: ['./bulk-email-dialog.component.scss']
})
export class BulkEmailDialogComponent implements OnInit {
	@ViewChild('fileSelect') fileSelect: ElementRef;
	public loading: boolean = false;
	public subject?: string;
	public body?: string;
	public formGroup: UntypedFormGroup;
	public uploadMoreCount: number = 0;
	public acceptableExtensions: string[] = [
		'txt',
		'pdf',
		'jpg',
		'png',
		'jpeg',
		'gif',
		'tif',
		'xlsx',
		'doc',
		'docx',
		'xls',
		'ppt',
		'pptx',
		'zip'
	];
	public emailFromOptions: any[] = [];
	public modules = {
		toolbar: [
			['bold', 'italic', 'underline'] // toggled buttons
		]
	};
	public unsubscriber$ = new Subject<void>();
	public creators: OutreachCreatorModel[] = [];
	public selectedFiles: File[] = [];
	constructor(
		private toastNotificationService: ToastNotificationService,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			creators: OutreachCreatorModel[];
			subject?: string;
			body?: string;
		},
		private dialogRef: MatDialogRef<BulkEmailDialogComponent>,
		private authService: AuthenticationService,
		private titleCasePipe: TitleCasePipe,
		private outreachService: OutreachService
	) {
		this.creators = _.cloneDeep(this.data.creators);
	}

	ngOnInit(): void {
		this.initForm();
		this.getAllEmails();
	}

	// ! UNCOMMENT IF WANT SCROLL FROM MOUSE
	// ngAfterViewInit() {
	// 	const scrollContainer = document.getElementById('custom_chip_list');
	// 	if (!scrollContainer) {
	// 		return;
	// 	}
	// 	scrollContainer.addEventListener('wheel', evt => {
	// 		evt.preventDefault();
	// 		scrollContainer.scrollLeft += evt.deltaY;
	// 	});
	// }

	public getAllEmails(): void {
		this.loading = true;
		this.authService
			.getConnectedEmails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res?.data?.length) {
						this.emailFromOptions = res.data;
					} else {
						this.toastNotificationService.sendInfoToast(
							"You don't have any emails connected yet."
						);
					}
					this.loading = false;
				},
				err => {
					this.loading = false;
				}
			);
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public initForm(): void {
		this.formGroup = new UntypedFormGroup({
			emailTo: new UntypedFormControl(this.creators ?? [], [
				Validators.required
			]),
			emailFrom: new UntypedFormControl(null, [Validators.required]),
			emailSubject: new UntypedFormControl(this.data?.subject, [
				Validators.required
			]),
			emailBody: new UntypedFormControl(this.data?.body, [
				Validators.required
			])
		});
	}

	public changedEditor(
		event: EditorChangeContent | EditorChangeSelection
	): void {
		// console.log(this.formGroup.get('emailBody').value);
	}

	public remove(creator: OutreachCreatorModel): void {
		const msgToValue = this.formGroup.get('emailTo')?.value;
		if (msgToValue?.length == 1) {
			return this.toastNotificationService.sendInfoToast(
				'You must have at least one creator to send email to'
			);
		}
		const index = msgToValue.findIndex(item => item.email == creator.email);
		if (index < 0) {
			return;
		}
		msgToValue.splice(index, 1);
	}

	public removeFile(fileIndex: number): void {
		this.selectedFiles.splice(fileIndex, 1);
	}

	public openAddFilesDialog() {
		const e = this.fileSelect.nativeElement;
		e.addEventListener('change', this.onFileEleChange.bind(this));
		this.uploadMoreCount = 0;
		e.click();
	}

	public onFileEleChange(event: any): void {
		if (this.uploadMoreCount == 1) {
			return;
		}
		this.uploadMoreCount = 1;
		const file: File = event.target.files[0];
		const sizeInMB = parseInt(this.sizeInMb(file.size));
		const type = file.name.split('.').pop();

		if (sizeInMB > 8) {
			// size greater than 8 MB
			this.toastNotificationService.sendErrorToast(
				'Size of the attachment cannot exceed 8 MB!'
			);
			return;
		}
		if (!this.acceptableExtensions.includes(type)) {
			this.toastNotificationService.sendErrorToast(
				'Unsupported File Type!'
			);
			return;
		}

		this.selectedFiles.push(file);
	}

	public sendMail(): void {
		let formData = new FormData();
		const toEmails = this.formGroup
			.get('emailTo')
			?.value.map(x => x.email)
			.filter(x => x != null);
		formData.append('subject', this.formGroup.get('emailSubject')?.value);
		formData.append('text_body', this.formGroup.get('emailBody').value);
		formData.append('to_emails', JSON.stringify(toEmails));
		formData.append('sender_is_user', 'true');
		formData.append(
			'from_email',
			this.formGroup.get('emailFrom')?.value?.email
		);
		formData.append(
			'mail_decider',
			this.titleCasePipe.transform(
				this.formGroup.get('emailFrom')?.value?.type
			)
		);
		formData.append('date', moment().format('MM/DD/YYYY'));
		formData.append('time', moment().format('hh:mm:ss'));
		this.selectedFiles.forEach(x => {
			formData.append('attachments', x);
		});
		this.loading = true;
		this.outreachService
			.sendEmail(formData)
			.pipe(take(1))
			.subscribe(
				res => {
					this.toastNotificationService.sendSuccessToast(
						'Email sent successfully.'
					);
					this.loading = false;
					this.dialogRef.close();
				},
				err => {
					this.loading = false;
					this.toastNotificationService.sendErrorToast(
						'Something went wrong while sending your email. Try again later.'
					);
				}
			);
	}

	public sizeInMb(sizeInBytes: number): string {
		let sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		return sizeInMB;
	}

	public openFile(file: File): void {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const link = document.createElement('a');
			link.setAttribute('target', '_blank');
			link.setAttribute('href', reader.result.toString());
			link.setAttribute('download', file.name);
			document.body.appendChild(link);
			link.click();
			link.remove();
		};
	}
}
