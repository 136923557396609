import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RowComponent } from '../layout';
import { TextComponent } from '../text.component';

@Component({
	selector: 'input-app',
	template: `
		<input
			*ngIf="formControl"
			class="custom-input"
			[placeholder]="placeholder"
			[formControl]="formControl"
		/>
		<row *ngIf="formControl?.value.length > 0">
			<ng-content></ng-content>
			<mat-icon *ngIf="closeButton" (click)="onClose()">close</mat-icon>
		</row>

		<text class="inside">{{ title }}</text>
	`,
	styles: [
		//language=scss
		`
			@use 'typography2';
			@use 'common';

			:host {
				@include common.row;
				position: relative;
				border: 1px solid #d9d9d9;
				border-radius: 5px;
			}

			.custom-input {
				@include typography2.header-h2;
				font-weight: 400;

				display: flex;
				padding-left: 12px;
				border-radius: 5px;
				height: 48px;
				border: none;
				width: 100%;

				&:focus-visible {
					outline: 0;
				}
			}

			row {
				gap: 1px;
				padding-right: 12px;
			}

			.inside {
				color: #a6a6a6;
				position: absolute;
				left: 20px;
				top: -3px;
				line-height: 3px;
				padding: 1px;
				background: white;
			}

			mat-icon {
				@include common.icon(19px);
				@include common.clickable;
			}
		`
	],
	imports: [
		TextComponent,
		RowComponent,
		ReactiveFormsModule,
		CommonModule,
		MatIconModule
	],
	standalone: true
})
export class InputComponent {
	@Input('control') formControl: FormControl = new FormControl('');

	@Input() placeholder = '';
	@Input() title?: string;
	@Input() closeButton = true;

	@Output() close = new EventEmitter();

	onClose() {
		this.close.emit();
		this.formControl.reset();
		this.formControl.setValue('', { emitEvent: true });
	}
}
