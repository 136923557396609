import {
	CUSTOM_ELEMENTS_SCHEMA,
	NgModule,
	NO_ERRORS_SCHEMA
} from '@angular/core';
import { MaterialModule } from '../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilterTextPipe } from './pipes/filterText';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContainsPipe } from './pipes/contains';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
	CommonModule,
	CurrencyPipe,
	DatePipe,
	DecimalPipe,
	TitleCasePipe
} from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CamelCaseToTextPipe } from './pipes/humanize';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { AgGridModule } from 'ag-grid-angular';
import { GridsterModule } from 'angular-gridster2';
import { DragAndDropDirective } from '../user-management/directives/drag-and-drop.directive';
import { UserServiceApi } from '../_services/user/user.api.service';
import { NoResourceComponent } from './404/no-resource.component';
import { ButtonComponent } from './button-component/button.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NgbToMomentToString } from './calendar/calendar.pipe';
import { CalendarService } from './calendar/calendar.service';
import { DropdownSearchSelectComponent } from './dropdown-search-select/dropdown-search-select.component';
import { ErrorMessageService } from './form-input/error-message.service';
import { GenericDropdownComponent } from './form-input/generic-dropdown/generic-dropdown.component';
import { InputCardNumberComponent } from './form-input/input-card-number/input-card-number.component';
import { InputNumberCardDirective } from './form-input/input-number-card.directive';
import { InputCvcComponent } from './form-input/input-cvc/input-cvc.component';
import { InputBillingDateComponent } from './form-input/input-date/input-billing-date.component';
import { InputNumberValueDirective } from './form-input/input-number-value.directive';
import { InputNumberComponent } from './form-input/input-number/input-number.component';
import { InputPasswordComponent } from './form-input/input-password/input-password.component';
import { InputTextComponent } from './form-input/input-text/input-text.component';
import { InputTextareaComponent } from './form-input/input-textarea/input-textarea.component';
import { CheckPermissionsDirective } from './permisions/check-permissions.directive';
import { PermissionsService } from './permisions/permissions.service';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { LimitLengthPipe } from './pipes/limit-length.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html';
import { SnakeCaseToHuman } from './pipes/snake-case-to-human.pipe';
import { ArraySortPipe } from './pipes/sort';
import { sharedReducer } from './state/shared.reducer';
import { userReducer } from './state/user/user.reducer';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';

import { SafeURLPipe } from './pipes/safe-url.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import {
	FormFieldModule,
	InputsModule,
	NumericTextBoxModule,
	TextBoxModule
} from '@progress/kendo-angular-inputs';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { SuccessDialogComponent } from './dialogs/success-dialog/success-dialog.component';
import { DiscoveryDrpComponent } from './discovery-drp/discovery-drp.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LollySpinnerLoaderComponent } from '../shared-components/loaders/lolly-spinning-loader/lolly-spinning-loader.component';
import { DropdownWithInputComponent } from '../shared-components/custom-dropdowns/dropdown-with-input/dropdown-with-input.component';
import { DropdownWoInputComponent } from '../shared-components/custom-dropdowns/dropdown-wo-input/dropdown-wo-input.component';
import { SwitchComponent } from '../shared-components/switch/switch.component';
import { GlobalPopupComponent } from './dialogs/popup01/popup01.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { FiledFontComponent } from './filed-font/filed-font.component';
import { MinusPipe } from '../shared-components/graphs-eCharts/pipes/minus.pipe';
import { ShortNumberPipe } from './pipes/shortnumber-pipe';
import { CustomTooltipComponent } from '../shared-components/custom-tooltip/custom-tooltip.component';
import { QuillModule } from 'ngx-quill';
import { PrimaryButtonComponent } from './buttons/primary-button/primary-button.component';
import { PasswordoutlineTbComponent } from './form-input/passwordoutline-tb/passwordoutline-tb.component';
import { OutlineTbComponent } from './form-input/outline-tb/outline-tb.component';
import { MultiSelectAutoDropdownComponent } from '../shared-components/multi-select-auto-dropdown/multi-select-auto-dropdown.component';
import { SelectDrpWoInputComponent } from '../shared-components/custom-dropdowns/select-drp-wo-input/select-drp-wo-input.component';
import { AutoMultiDrpWithChipsComponent } from '../shared-components/custom-dropdowns/auto-multi-drp-with-chips/auto-multi-drp-with-chips.component';
import { AutoSearchMultiDrpChipComponent } from '../shared-components/custom-dropdowns/auto-search-multi-drp-chip/auto-search-multi-drp-chip.component';
import { GenericSmDialogComponent } from '../shared-components/generic-sm-dialog/generic-sm-dialog.component';
import { SimpleTextInputComponent } from '../shared-components/simple-text-input/simple-text-input.component';
import { DropzoneDirective } from '../shared-components/directives/dropzone.directive';
import { LollyLoadingDialogComponent } from '../shared-components/lolly-loading-dialog/lolly-loading-dialog.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from '../shared-components/providers/custom-paginator';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AutoInputChipComponent } from '../shared-components/custom-dropdowns/auto-input-chip/auto-input-chip.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CustomCalendarDatePickerComponent } from '../shared-components/custom-calendar-date-picker/custom-calendar-date-picker.component';
import { MultiDrpLocationsComponent } from '../shared-components/multi-drp-locations/multi-drp-locations.component';
import { SelectDrpWInputComponent } from '../shared-components/custom-dropdowns/select-drp-w-input/select-drp-w-input.component';
import { CreditLimitComponent } from './credit-limit/credit-limit.component';
import { SelectDrpWithCustomSearchComponent } from '../shared-components/custom-dropdowns/select-drp-with-custom-search/select-drp-with-custom-search.component';
import { GmailLandingComponent } from '../shared-components/gmail-landing/gmail-landing.component';
import { PriorityToasterComponent } from '../shared-components/priority-toaster/priority-toaster.component';
import { CustomSideStepperComponent } from '../shared-components/custom-side-stepper/custom-side-stepper.component';
import { NewBudgetInputComponent } from '../shared-components/new-budget-input/new-budget-input.component';
import { BulkEmailDialogComponent } from '../shared-components/bulk-email-dialog/bulk-email-dialog.component';
import { HintsComponent } from '../shared-components/hints/hints.component';
import { NewGenericDialogComponent } from '../shared-components/new-generic-dialog/new-generic-dialog.component';
import { AddListToCampaignComponent } from '../shared-components/add-list-to-campaign/add-list-to-campaign.component';
import { GenericSidePopupComponent } from '../shared-components/generic-side-popup/generic-side-popup.component';
import { GenericTableComponent } from '../shared-components/tables/generic-table/generic-table.component';
import { GoogleAccountLandingComponent } from '../shared-components/google-account-landing/google-account-landing.component';
import { WebsiteInputComponent } from '../shared-components/website-input/website-input.component';
import { GenericInputDialogComponent } from '../shared-components/generic-input-dialog/generic-input-dialog.component';
import { AutofocusDirective } from '../shared-components/directives/autofocus.directive';
import { GenericLineGraphComponent } from '../shared-components/charts/generic-line-graph/generic-line-graph.component';
import { GenericBarGraphComponent } from '../shared-components/charts/generic-bar-graph/generic-bar-graph.component';
import { GenericPieGraphComponent } from '../shared-components/charts/generic-pie-graph/generic-pie-graph.component';
import { InfiniteScrollDirective } from '../shared-components/directives/infinite-scroll.directive';
import { GenericTableToggleColumnComponent } from '../shared-components/generic-table-toggle-column/generic-table-toggle-column.component';
import { CustomDateRangePickerComponent } from '../shared-components/custom-date-range-picker/custom-date-range-picker.component';
import { ProgressBarComponent } from '../shared-components/progress-bar/progress-bar.component';
import { CustomTextInputComponent } from '../shared-components/custom-text-input/custom-text-input.component';
import { ManageSubscriptionComponent } from '../shared-components/manage-subscription/manage-subscription.component';
import { GenericTwoInputDialogComponent } from '../shared-components/generic-two-input-dialog/generic-two-input-dialog.component';
import { SelectDropdownV2Component } from '../shared-components/dropdowns-v2/select-dropdown-v2/select-dropdown-v2.component';
import { SelectDrpWFilterWInputComponent } from '../shared-components/custom-dropdowns/select-drp-w-filter-w-input/select-drp-w-filter-w-input.component';
import { SelectDrpWButtonComponent } from '../shared-components/custom-dropdowns/select-drp-w-button/select-drp-w-button';
import { RoleBasedControlDirective } from '../shared-components/directives/role-based-control.directive';
import { CustomDateRangePickerButtonComponent } from '../shared-components/custom-date-range-picker-button/custom-date-range-picker-button.component';
import { CustomReplyQuillEditorComponent } from '../social-media-influencer/modules/outreach-v2/component-pieces/custom-reply-quill-editor/custom-reply-quill-editor.component';
import { TabBarWithUnderlineIndicatorComponent } from '../shared-components/tab-bar-with-underline-indicator/tab-bar-with-underline-indicator.component';
import { TabBarBlueComponent } from '../shared-components/tab-bars/tab-bar-blue/tab-bar-blue.component';
import { SequenceTableModule } from '../social-media-influencer/modules/outreach-v2/components/outreach-sequence/sequence-table/sequence-table.module';
import { AvatarComponent } from '../social-media-influencer/modules/dashboard/components/avatar.component';
import { ITooltipComponent, TooltipComponent } from 'components';

const importExport = [LollySpinnerLoaderComponent];

@NgModule({
	imports: [
		ITooltipComponent,
		MaterialModule,
		NgxDaterangepickerMd,
		CommonModule,
		StoreModule.forFeature('shared', sharedReducer),
		StoreModule.forFeature('user', userReducer),
		AgGridModule,
		MatButtonToggleModule,
		NgSelectModule,
		TranslateModule,
		NgbDatepickerModule,
		GridsterModule,
		DragDropModule,
		MatMenuModule,
		NgxEchartsModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		MatSnackBarModule,
		ScrollingModule,
		InputsModule,
		FormFieldModule,
		NumericTextBoxModule,
		TextBoxModule,
		RouterModule,
		GenericSmDialogComponent,
		MatSortModule,
		DropDownsModule,
		DialogsModule,
		QuillModule,
		NgxSkeletonLoaderModule,
		SequenceTableModule,
		...importExport,
		AvatarComponent,
		TooltipComponent
	],
	declarations: [
		TabBarBlueComponent,
		TabBarWithUnderlineIndicatorComponent,
		RoleBasedControlDirective,
		SelectDropdownV2Component,
		ManageSubscriptionComponent,
		CustomTextInputComponent,
		ProgressBarComponent,
		CustomDateRangePickerComponent,
		CustomDateRangePickerButtonComponent,
		GenericTableToggleColumnComponent,
		GenericPieGraphComponent,
		GenericBarGraphComponent,
		GenericLineGraphComponent,
		InfiniteScrollDirective,
		GenericTableComponent,
		GenericInputDialogComponent,
		GenericTwoInputDialogComponent,
		WebsiteInputComponent,
		GoogleAccountLandingComponent,
		GenericSidePopupComponent,
		AddListToCampaignComponent,
		NewGenericDialogComponent,
		HintsComponent,
		BulkEmailDialogComponent,
		PriorityToasterComponent,
		NewBudgetInputComponent,
		CustomSideStepperComponent,
		GmailLandingComponent,
		SelectDrpWithCustomSearchComponent,
		CustomCalendarDatePickerComponent,
		AutoInputChipComponent,
		LollyLoadingDialogComponent,
		MultiDrpLocationsComponent,
		DropzoneDirective,
		AutofocusDirective,
		SimpleTextInputComponent,

		AutoSearchMultiDrpChipComponent,
		AutoMultiDrpWithChipsComponent,
		SelectDrpWoInputComponent,
		SelectDrpWInputComponent,
		SelectDrpWFilterWInputComponent,
		MultiSelectAutoDropdownComponent,
		CustomTooltipComponent,
		MinusPipe,
		PasswordoutlineTbComponent,
		OutlineTbComponent,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		ButtonComponent,
		NoResourceComponent,
		CalendarComponent,
		NgbToMomentToString,
		FilterTextPipe,
		ContainsPipe,
		ConfirmationDialogComponent,
		CamelCaseToTextPipe,
		InputTextComponent,
		ArraySortPipe,
		InputNumberComponent,
		InputNumberValueDirective,
		CheckPermissionsDirective,
		PrimaryButtonComponent,
		InputTextareaComponent,
		InputPasswordComponent,
		DropdownSearchSelectComponent,
		InputCardNumberComponent,
		InputNumberCardDirective,
		InputCvcComponent,
		InputBillingDateComponent,
		DragAndDropDirective,
		FilterArrayPipe,
		LimitLengthPipe,
		GenericDropdownComponent,
		SafeURLPipe,
		ConfirmDialogComponent,
		SuccessDialogComponent,
		DiscoveryDrpComponent,
		DropdownWithInputComponent,
		DropdownWoInputComponent,
		SwitchComponent,
		ButtonComponent,
		GlobalPopupComponent,
		FiledFontComponent,
		CreditLimitComponent,
		SelectDrpWButtonComponent,
		CustomReplyQuillEditorComponent
	],
	exports: [
		TabBarBlueComponent,
		TabBarWithUnderlineIndicatorComponent,
		RoleBasedControlDirective,
		SelectDropdownV2Component,
		ManageSubscriptionComponent,
		CustomTextInputComponent,
		ProgressBarComponent,
		CustomDateRangePickerComponent,
		CustomDateRangePickerButtonComponent,
		GenericTableToggleColumnComponent,
		GenericPieGraphComponent,
		GenericBarGraphComponent,
		GenericLineGraphComponent,
		InfiniteScrollDirective,
		GenericTableComponent,
		GenericInputDialogComponent,
		WebsiteInputComponent,
		GoogleAccountLandingComponent,
		GenericSidePopupComponent,
		AddListToCampaignComponent,
		NewGenericDialogComponent,
		HintsComponent,
		BulkEmailDialogComponent,
		PriorityToasterComponent,
		NewBudgetInputComponent,
		CustomSideStepperComponent,
		NgxDaterangepickerMd,
		DragDropModule,
		SelectDrpWInputComponent,
		SelectDrpWFilterWInputComponent,
		MultiDrpLocationsComponent,
		CustomCalendarDatePickerComponent,
		AutoInputChipComponent,
		LollyLoadingDialogComponent,
		DropzoneDirective,
		AutofocusDirective,
		SimpleTextInputComponent,
		GenericSmDialogComponent,
		AutoSearchMultiDrpChipComponent,
		AutoMultiDrpWithChipsComponent,
		SelectDrpWoInputComponent,
		MultiSelectAutoDropdownComponent,
		QuillModule,
		CustomTooltipComponent,
		FiledFontComponent,
		DialogsModule,
		GlobalPopupComponent,
		ITooltipComponent,
		PasswordoutlineTbComponent,
		OutlineTbComponent,
		MinusPipe,
		LollySpinnerLoaderComponent,
		CheckPermissionsDirective,
		MatInputModule,
		MatNativeDateModule,
		MatRippleModule,
		MatDatepickerModule,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		NoResourceComponent,
		TranslateModule,
		NgbDatepickerModule,
		MatButtonToggleModule,
		MatMenuModule,
		AgGridModule,
		NgxEchartsModule,
		CalendarComponent,
		FilterTextPipe,
		ContainsPipe,
		CamelCaseToTextPipe,
		PrimaryButtonComponent,
		// FormatTextPipe,
		MaterialModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		MatSnackBarModule,
		ScrollingModule,
		InputTextComponent,
		ArraySortPipe,
		GridsterModule,
		ButtonComponent,
		InputNumberComponent,
		InputTextareaComponent,
		InputPasswordComponent,
		DropdownSearchSelectComponent,
		NgSelectModule,
		InputCardNumberComponent,
		InputCvcComponent,
		InputBillingDateComponent,
		DragAndDropDirective,
		FilterArrayPipe,
		LimitLengthPipe,
		GenericDropdownComponent,
		DropDownsModule,
		SafeURLPipe,
		MatSortModule,
		ConfirmationDialogComponent,
		ConfirmDialogComponent,
		DiscoveryDrpComponent,
		DropdownWithInputComponent,
		DropdownWoInputComponent,
		SwitchComponent,
		ButtonComponent,
		NgxSkeletonLoaderModule,
		SelectDrpWithCustomSearchComponent,
		SelectDrpWButtonComponent,
		CustomReplyQuillEditorComponent,
		SequenceTableModule,
		...importExport
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [
		ShortNumberPipe,
		DecimalPipe,
		PermissionsService,
		ErrorMessageService,
		TitleCasePipe,
		SnakeCaseToHuman,
		DatePipe,
		CurrencyPipe,
		CalendarService,
		UserServiceApi,
		CheckPermissionsDirective,
		InterceptorsProvider,
		{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'outline' }
		}
	]
})
export class SharedModule {}
