import { Component } from '@angular/core';
import { ButtonComponent, ButtonVariant } from './button.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'button[app-upload]',
	template: `
		<mat-icon>upload</mat-icon>
		Upload
	`,
	styleUrls: ['./button.scss'],
	imports: [MatIconModule],
	standalone: true
})
export class UploadButtonComponent extends ButtonComponent {
	variant: ButtonVariant = 'secondary';
}
