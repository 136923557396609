<div
	class="card-wrapper"
	[ngClass]="{
		'mid-height': isPanelOpen && !isConnected,
		'large-height': isPanelOpen && isConnected
	}"
>
	<div class="card-header">
		<div
			class="d-flex h-100 w-100 k-align-items-center k-justify-content-between bor-bot"
		>
			<div class="d-flex k-align-items-center" [style.gap]="'10px'">
				<h5>Ecommerce Integrations</h5>
				<i-tooltip
					[text]="'Effortlessly connect and manage your online store'"
					[position]="'bottom-right'"
					[position]="'center-right'"
				></i-tooltip>
			</div>

			<div
				*ngIf="!loading"
				class="d-flex k-align-items-center"
				style="gap: 10px"
			>
				<a
					class="quaternary-btn"
					style="color: #005ed8"
					href="https://lolly.zohodesk.in/portal/en/kb/articles/set-up-paypal"
					target="_blank"
				>
					Installation Instructions
				</a>

				<ng-container>
					<mat-icon
						class="cursor-pointer"
						*ngIf="isPanelOpen"
						(click)="changePanelState(false)"
					>
						keyboard_arrow_up
					</mat-icon>
					<mat-icon
						class="cursor-pointer"
						*ngIf="!isPanelOpen"
						(click)="changePanelState(true)"
					>
						keyboard_arrow_down
					</mat-icon>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="card-body">
		<div class="d-flex w-100 k-align-items-center" [style.gap]="'24px'">
			<div
				appRoleBasedControl
				class="acc-card {{ card?.disabled ? 'disabled' : '' }}"
				*ngFor="let card of ecommerceCards"
				(click)="onCardClick(card)"
			>
				<img [src]="card.image" />
				<img
					*ngIf="card?.connected"
					src="assets/icons/green-check-icon.svg"
					class="check"
				/>
			</div>
		</div>
	</div>
</div>
