import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { SearchBarInnerTextComponent } from '../search-bar-inner-text/search-bar-inner-text.component';

@Component({
	selector: 'custom-search-bar',
	templateUrl: './custom-search-bar.component.html',
	styleUrls: ['./custom-search-bar.component.scss']
})
export class CustomSearchBarComponent implements OnInit {
	@ViewChild(SearchBarInnerTextComponent)
	innerText: SearchBarInnerTextComponent;

	@Input()
	query: string = '';

	@Output()
	queryChange = new EventEmitter<string>();

	constructor() {}

	ngOnInit(): void {}
}
