import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class SMIJobsService {
	constructor(private http: HttpClient) {}

	public payInfluencer(
		taskId: string,
		contentId: string,
		platformId: string,
		channelPostType: string
	): Observable<any> {
		let body: any;
		return this.http.post<any>(
			`${BaseApiUrl.SocialInfluencerCS}PaymentManager/pay/task/${taskId}/content/${contentId}/platform/${platformId}/${channelPostType}`,
			body
		);
	}
}
