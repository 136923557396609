import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UtilsService } from 'src/app/shared/utils';

@Component({
	selector: 'app-smtp-setup-dialog',
	templateUrl: './smtp-setup-dialog.component.html',
	styleUrls: ['./smtp-setup-dialog.component.scss']
})
export class SmtpSetupDialogComponent implements OnInit {
	public loading: boolean = false;
	public mailboxCredentialsForm: UntypedFormGroup;
	private unsubscriber$ = new Subject<void>();

	constructor(private sharedService: SharedService, private toast: ToastNotificationService, public dialogRef: MatDialogRef<SmtpSetupDialogComponent>) {}

	ngOnInit(): void {
		this.createForm();
	}

	private createForm(): void {
		this.mailboxCredentialsForm = new UntypedFormGroup({
			username: new UntypedFormControl('', [Validators.required, UtilsService.noWhitespaceAtStartValidator]),
			password: new UntypedFormControl('', [Validators.required, UtilsService.noWhitespaceAtStartValidator]),
			incoming_host: new UntypedFormControl('', [Validators.required, UtilsService.noWhitespaceAtStartValidator]),
			imap_port: new UntypedFormControl('', [Validators.required, UtilsService.noWhitespaceAtStartValidator]),
			outgoing_host: new UntypedFormControl('', [Validators.required, UtilsService.noWhitespaceAtStartValidator]),
			smtp_port: new UntypedFormControl('', [Validators.required, UtilsService.noWhitespaceAtStartValidator])
		});
	}

	public saveSmtpSettings(): void {
		if (this.mailboxCredentialsForm.invalid) {
			this.mailboxCredentialsForm.markAllAsTouched();
			return;
		}
		const mailboxInfo = {
			username: this.mailboxCredentialsForm.get('username').value,
			password: this.mailboxCredentialsForm.get('password').value,
			incoming_host: this.mailboxCredentialsForm.get('incoming_host').value,
			imap_port: this.mailboxCredentialsForm.get('imap_port').value,
			outgoing_host: this.mailboxCredentialsForm.get('outgoing_host').value,
			smtp_port: this.mailboxCredentialsForm.get('smtp_port').value
		};
		this.loading = true;
		this.sharedService
			.saveSmtpSettings(mailboxInfo)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					console.log(res);
					this.loading = false;
					let toastMsg;
					if (res?.is_success) {
						toastMsg = res?.message || 'Mailbox connected successfully';
						this.toast.sendSuccessToast(toastMsg);
						this.dialogRef.close();
					} else {
						toastMsg = res?.message || 'Something went wrong';
						this.toast.sendErrorToast(toastMsg);
					}
				},
				err => {
					console.log(err);
					this.loading = false;
					this.toast.sendErrorToast(err.error.detail);
				}
			);
	}

	private onError(): void {
		this.toast.sendErrorToast('Some error occurred. Try again later');
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
