export interface PermissionModel {
	id: number;
	name: string;
	disabled: boolean;
	tooltip?: boolean;
	value: PermissionsEnum;
}

export enum PermissionsEnum {
	CAMPAIGN_CREATE = 'campaign_create',
	CREATOR_UPLOAD = 'creators_upload',
	CAMPAIGN_DELETE = 'campaign_delete',
	JOB_CREATE = 'job_create',
	AUTOMATION_EDIT = 'automation_edit',
	JOB_CLOSE = 'job_close',
	JOB_DELETE = 'job_delete',
	JOB_EDIT = 'job_edit',
	CAMPAIGN_EDIT = 'campaign_edit',
	MARKETPLACE = 'marketplace',
	DISCVOVERY = 'discvovery',
	LIST_CREATE = 'list_create',
	CREATOR_DOWNLOAD = 'creator_download',
	CAMPAIGN_CLOSE = 'campaign_close',
	LIST_ADD = 'list_add',
	CREATIVE_POSTURL = 'creative_posturl',
	JOB_SEND = 'job_send',
	CREATOR_SHORTLIST = 'creator_shortlist',
	MESSAGE = 'message',
	CREATIVE = 'creative',
	PAYMENT = 'payment',
	EMAIL_INTEGRATION = 'ecommerce_integration',
	ECOMMERCE_INTEGRATION = 'ecommerce_integration',
	STRIPE_INTEGRATION = 'stripe'
}
