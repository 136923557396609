<div class="container">
	<ng-content select="[selectedValue]"></ng-content>
	<mat-icon matSuffix>keyboard_arrow_down</mat-icon>
	<div
		class="dropdown-panel"
		*ngIf="dropdownOpened"
		(click)="onPanelClick($event)"
	>
		<ng-content select="[option]"></ng-content>
	</div>
</div>

<div
	class="on-click-intercept"
	(click)="onBackgroundClick($event)"
	*ngIf="dropdownOpened"
></div>
