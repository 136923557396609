import { Component, OnInit } from '@angular/core';
import { MyPlanService } from '../../../my-plan/my-plan.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UtilsService } from 'src/app/shared/utils';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InputCancellationReasonDialogComponent } from '../input-cancellation-reason-dialog/input-cancellation-reason-dialog.component';
import {
	FormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { LollyLoadingDialogService } from 'src/app/shared-components/lolly-loading-dialog/lolly-loading-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanCreditUsageModel } from 'src/app/shared/models/plan.model';

export type SubscriptionChange =
	| {
			changeDate: number;
			changeType: 'downgrade';

			nextSubscriptionPlan: {
				title: string;
			};
	  }
	| {
			changeDate: number;
			changeType: 'cancellation';
	  }
	| null;

@Component({
	selector: 'app-manage-plan',
	templateUrl: './manage-plan.component.html',
	styleUrls: ['./manage-plan.component.scss']
})
export class ManagePlanComponent implements OnInit {
	public step: number = 0;
	public isSubUser: boolean = true;
	public planData: PlanCreditUsageModel;
	public subscriptionData: any;
	public subscriptionLoading: boolean = false;
	public loading: boolean = false;
	public maxOutString: string = 'Unlimited';
	public formGroup: UntypedFormGroup;
	public APPOINTMENT_URL: string = UtilsService.BOOK_DEMO_URL;
	public unsubscriber$ = new Subject<void>();
	private openPlans: boolean = false;
	private powerRoles: string[] = ['owner', 'admin'];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		private toast: ToastNotificationService,
		private _myPlan: MyPlanService,
		private sharedService: SharedService,
		private lollyLoadingDialogService: LollyLoadingDialogService,
		private matDialog: MatDialog
	) {
		const show_dialog = this.route.snapshot.queryParamMap.get('openPlans');
		if (show_dialog === 'true') {
			this.openPlans = true;
		}
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { openPlans: null },
			queryParamsHandling: 'merge', // merge with existing query params
			replaceUrl: true // replace current URL without adding to browser history
		});
	}

	ngOnInit(): void {
		const decodedJwt = JSON.parse(
			localStorage.getItem(StorageKey.decodedJwtIo)
		);
		this.isSubUser = decodedJwt?.sub_user === true ? true : false;

		this.initForm();
		this.getActiveSubscriptions();
		this.getPlanDetails();
	}

	public initForm(): void {
		this.formGroup = this.formBuilder.group({
			stars_count: new UntypedFormControl(0, [Validators.required]),
			reason_to_cancel: new UntypedFormControl(null, [
				Validators.required
			]),
			will_return: new UntypedFormControl(null, [Validators.required]),
			suggestion: new UntypedFormControl(null),
			reminder_email: new UntypedFormControl(null, [Validators.required])
		});
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	isSubscriptionChangePlanned(): boolean {
		return (
			!this.subscriptionLoading &&
			this.subscriptionData?.upcoming_change != null
		);
	}

	canCancelSubscription(): boolean {
		return (
			!this.isSubscriptionChangePlanned() &&
			this.subscriptionData?.is_modify_allow &&
			(this.subscriptionData?.status === 'active' ||
				(this.subscriptionData?.status === 'trialing' &&
					this.subscriptionData?.is_without_cc != true))
		);
	}

	public getPlanDetails(): void {
		this.loading = true;
		this.sharedService
			.getPlanDetails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				this.planData = res.data;
			})
			.add(() => {
				this.loading = false;
			});
	}

	public getActiveSubscriptions(): void {
		this.subscriptionLoading = true;
		const token = localStorage.getItem(StorageKey.token);
		this._myPlan
			.getActiveSubs(token)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.subscriptionData = {
						...res
					};
					if (this.openPlans) {
						this.gotoStep(1);
					}
				},
				err => {
					if (this.openPlans) {
						this.gotoStep(1);
					}
				}
			)
			.add(() => {
				this.subscriptionLoading = false;
			});
	}

	public getPercentage(value = 0, total = 0): number {
		return (value / total) * 100;
	}

	public goBackToFirstStepAndReset(): void {
		this.formGroup.reset();
		this.step = 0;
	}

	public gotoStep(step: number): void {
		this.step = step;
	}

	public onOneDollar(event): void {
		this.formGroup.reset();
		this.step = 0;
	}

	cancelSubscriptionChange(): void {
		this._myPlan.cancelSubscriptionChange().subscribe(_response => {
			this.getActiveSubscriptions();
		});
	}

	public cancelSubscription(): void {
		this.loading = true;
		this.subscriptionLoading = true;
		if (this.formGroup?.invalid) {
			this.formGroup.markAllAsTouched();
			this.toast.sendErrorToast('Please fill the form details');
			return;
		}
		const payload = this.formGroup.value;
		this.lollyLoadingDialogService.showLoader();
		this.sharedService
			.cancelPlan(payload)
			.pipe(take(1))
			.subscribe(res => {
				this.planData = null;
				this.subscriptionData = null;
				this.gotoStep(0);
				this.formGroup.reset();
				this.getActiveSubscriptions();
				this.getPlanDetails();
				this.lollyLoadingDialogService.dismissLoader();
			});
	}

	public confirmCancelSubscription(): void {
		let matDialogConfig: MatDialogConfig = {
			panelClass: 'center-dialog-no-shadow',
			minHeight: '223px', // Default Height For 1366px Resolution
			width: '500px',
			backdropClass: 'light-backdrop'
		};
		let dialogRef = this.matDialog.open(
			InputCancellationReasonDialogComponent,
			matDialogConfig
		);
		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				if (res && res?.primaryAction === 'true') {
					this.cancelSubscription();
					this.sendCancellationReasonEmail(res?.reason);
				}
			});
	}

	private sendCancellationReasonEmail(reason: string): void {
		const storageJwt = JSON.parse(
			localStorage.getItem(StorageKey.decodedJwtIo)
		);
		let username = storageJwt['username'];
		const payload = {
			name: 'Some User',
			subject: 'Cancellation Reason',
			body: 'Email - ' + username + '; Reason - ' + reason,
			email: 'support@lolly.com'
		};
		this.sharedService
			.sendTestEmail(payload)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public bookAppointment(): void {
		window.open(this.APPOINTMENT_URL, '_blank');
	}

	private isManageAllowed(userRole: string): boolean {
		return (
			this.powerRoles.includes(userRole) &&
			this.subscriptionData?.is_modify_allow
		);
	}
}
