import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { PlansDataModel } from 'src/app/shared-components/models/plans-data.model';

@Injectable({
	providedIn: 'root'
})
export class AuthPaymentService {
	constructor(private http: HttpClient) {}

	public redirectToStripe(plan: PlansDataModel): Observable<any> {
		return this.http.post(
			`${BaseApiUrl.SocialMessengerPython}subscriptions/session/new`,
			plan
		);
	}

	public redirectToStripeUpdate(product_price_id: string): Observable<any> {
		return this.http.post(
			`${BaseApiUrl.SocialMessengerPython}subscriptions/${product_price_id}/upgrade-downgrade`,
			{}
		);
	}
}
