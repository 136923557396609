import { Component, OnInit } from '@angular/core';
import { SignupSettingsComponent } from './signup-settings.component';
import { SignupPreviewComponent } from './preview/signup-preview.component';

import { BrandingSettingsService } from '../../services/branding-settings.service';
import {
	BaseModule,
	InputBrandingSettings,
	ServerBrandingSettings,
	SignupFormConfig
} from 'components';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgIf } from '@angular/common';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { FullscreenSignupPreviewComponent } from './preview/fullscreen-signup-preview.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';

@Component({
	template: `
		<column>
			<row>
				<column
					paper="true"
					class="signup-settings-loader"
					*ngIf="loading.get || loading.post"
				>
					<app-lolly-spinning-loader></app-lolly-spinning-loader>
				</column>
				<ng-container *ngIf="!loading.get && !loading.post">
					<signup-settings
						(save)="onSave($event)"
						[defaultValue]="settings"
						(logoChange)="onLogoChange($event)"
						(formChange)="onFormChange($event)"
					></signup-settings>
					<signup-preview
						[config]="config"
						(previewRequest)="openOverlay()"
					></signup-preview>
				</ng-container>
			</row>
		</column>
	`,
	styles: [
		`
			@use 'common';

			row {
				padding: 20px;
				align-items: start;
			}

			column {
				height: 100%;

				flex: 1;
			}

			signup-settings {
				flex: 1;
			}

			signup-preview {
				@include common.paper;
				flex: 2;
			}
		`
	],
	imports: [
		SignupSettingsComponent,
		SignupPreviewComponent,
		NgxSkeletonLoaderModule,
		NgIf,
		BaseModule
	],
	standalone: true
})
export class BrandingPageComponent implements OnInit {
	settings?: ServerBrandingSettings;
	fullscreen?: OverlayRef;

	config: SignupFormConfig = {
		title: "Let's get started",
		description: 'some description',
		button: {
			color: '#e44398',
			textColor: '#000000',
			text: 'Super text'
		},
		fields: [],
		logoUrl: 'assets/icons/logo.svg'
	};

	get loading() {
		return this.brandingService.loading;
	}

	constructor(
		private brandingService: BrandingSettingsService,
		private overlay: Overlay,
		private toast: ToastNotificationService
	) {
		this.request();
	}

	request() {
		this.brandingService.getBrandingSettings().subscribe(settings => {
			this.settings = settings;

			this.config = {
				...settings,
				button: {
					color: settings.primary_button_color,
					textColor: settings.primary_button_text_color,
					text: settings.button_text
				},
				fields: settings.branding_fields,
				logoUrl: settings.logo_url,
				termsAndConditions: settings.terms_and_conditions_url
			};
		});
	}

	ngOnInit() {}

	openOverlay() {
		const ref = this.overlay.create({});
		this.fullscreen = ref;
		const portal = new ComponentPortal(FullscreenSignupPreviewComponent);

		const componentRef = ref.attach(portal);
		componentRef.instance.config = this.config;
		componentRef.instance.close.subscribe(() => {
			this.fullscreen?.dispose();
		});
	}

	onFormChange(event: InputBrandingSettings | undefined) {
		this.config = {
			...this.config,
			button: {
				color: event?.primaryButtonColor ?? this.config.button.color,
				textColor:
					event?.primaryButtonTextColor ??
					this.config.button.textColor,
				text: event?.buttonText ?? this.config.button.text
			},
			title: event?.title ?? this.config.title,
			description: event?.description ?? this.config.description,
			fields: event?.brandingFields ?? this.config.fields
		};
	}

	onLogoChange(newUrl: string | undefined) {
		this.config.logoUrl = newUrl;
	}

	onSave(settings?: InputBrandingSettings) {
		if (settings) {
			this.brandingService
				.postBrandingSettings(settings)
				.subscribe(result => {
					this.toast.sendSuccessToast('Branding settings are saved');
					this.request();
				});
		}
	}
}
