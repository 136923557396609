import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-download-invoice',
	templateUrl: './download-invoice.component.html',
	styleUrls: ['./download-invoice.component.scss']
})
export class DownloadInvoiceComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
