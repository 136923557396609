import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnComponent, RowComponent, TextComponent } from '../base';
import { MatIconModule } from '@angular/material/icon';
import { prettyFileSize } from '../../utils';

@Component({
	selector: 'selected-file',
	template: `
		<row>
			<img src="assets/icons/upload-files/pdf.svg" />
			<column>
				<row [left]="false">
					<text variant="p2">{{ filename }}</text>
					<mat-icon (click)="close.emit()">close</mat-icon>
				</row>

				<text class="size" variant="p4">
					{{ prettyFileSize(size) }}
				</text>
			</column>
		</row>
	`,
	styles: [
		//language=scss
		`
			@use 'common';

			:host {
				@include common.row;
				background: #f5f4f7;
				padding: 0px 16px;
				border-radius: 4px;
				border-style: dashed;
				border-color: #dbdbdb;
				gap: 16px;
			}

			row {
				justify-content: space-between;
				flex: 1;
			}

			:host > row {
				padding-top: 20px;
				padding-bottom: 20px;
			}

			column {
				gap: 0;
				width: 100%;
			}

			img {
				@include common.icon(40px);
			}

			mat-icon {
				@include common.icon(12px);
				@include common.clickable;

				color: #667085;
			}

			text {
				@include common.ellipsis;
			}

			.size {
				color: #667085;
			}
		`
	],
	imports: [RowComponent, TextComponent, ColumnComponent, MatIconModule],
	standalone: true
})
export class SelectedFileComponent {
	@Input() filename!: string;
	@Input() size!: number;

	@Output() close = new EventEmitter();
	protected readonly prettyFileSize = prettyFileSize;
}
