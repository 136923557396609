import { NgModule } from '@angular/core';
import { DotComponent } from './dot';
import { UiTextComponent } from './text';

const importExport = [UiTextComponent, DotComponent];

@NgModule({
	imports: importExport,
	exports: importExport
})
export class PrimaryUiKit {}

export * from './text';
export * from './dot';
