import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DotComponent, RowComponent, ColumnComponent } from 'components';
import { PlanAdvantage } from './advantage';

export type PlanDescription = {
	name: string;
	description: string;
	price: string;
	period: string;
	buttonText: string;
	buttonLink: string;
	advantages: string[];
};

@Component({
	selector: 'plan',
	template: `
		<column class="price-container">
			<column class="name-container">
				<row [left]="true">
					<dot></dot>
					<div class="name">{{ plan.name }}</div>
				</row>
				<div class="description">{{ plan.description }}</div>
			</column>
			<row [left]="true" [style.gap]="0">
				<div class="price">{{ plan.price }}</div>
				<div class="period">/{{ plan.period }}</div>
			</row>
		</column>

		<div class="button" (click)="offerClick.emit()">
			<div class="button-text">{{ plan.buttonText }}</div>
			<div class="icon-container">
				<mat-icon class="button-icon">chevron_right</mat-icon>
			</div>
		</div>

		<column class="advantages">
			<plan-advantage
				*ngFor="let advantage of plan.advantages; let isLast = last"
				[advantage]="advantage"
				[divider]="!isLast"
			></plan-advantage>
		</column>
	`,
	styles: [
		`
			@use 'common';
			:host {
				@include common.column;

				font-style: normal;
				font-family: Gilroy;
				border-radius: 15px;
				border: 1px solid #2a2a2a;
				background: #fff;
				padding: 24px 24px 8px 24px;
				width: 320px;
				color: #2a2a2a;

				gap: 24px;
			}

			.name {
				font-size: 32px;
				font-weight: 700;
				line-height: 40px; /* 125% */
			}

			.description {
				font-size: 20px;
				font-weight: 500;
				line-height: 26px; /* 130% */
			}

			.price {
				font-size: 28px;
				font-weight: 700;
				line-height: 32px; /* 114.286% */
			}

			.period {
				color: #7a7a7a;
				font-size: 24px;

				font-weight: 500;
				line-height: 28px; /* 116.667% */
			}

			.button-text {
				color: #fff;
				text-align: center;
				font-size: 16px;
				font-weight: 700;
				line-height: 20px; /* 125% */
			}

			.advantage-text {
				font-size: 14px;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
			}

			.button {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 100px;
				background: #2a2a2a;
				padding: 12px;
				height: 56px;
				@include common.clickable;
			}

			.icon-container {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 100px;
				right: 8px;
				top: 8px;
				bottom: 8px;
				padding: 8px;
				background: white;
			}
			.button-icon {
				left: 0;
				color: #000;
			}

			.name-container {
				gap: 12px;

				row {
					gap: 8px;
				}
			}

			.price-container {
				gap: 16px;
			}

			.advantages {
				gap: 0;
			}
		`
	],
	imports: [
		DotComponent,
		CommonModule,
		PlanAdvantage,
		RowComponent,
		ColumnComponent,
		MatIconModule
	],
	standalone: true
})
export class PlanComponent {
	@Input() plan!: PlanDescription;

	@Output() offerClick = new EventEmitter<void>();
}
