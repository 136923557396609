import {
	CUSTOM_ELEMENTS_SCHEMA,
	NgModule,
	NO_ERRORS_SCHEMA
} from '@angular/core';
import { UserManagementRoutingModule } from './user-management-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';
import { userManagementReducer } from './state/user-management.reducer';
import { UserManagementEffects } from './state/user-management.effects';
import { EffectsModule } from '@ngrx/effects';
import { PermissionsResolver } from '../_services/user-management/resolvers/permissions.resolver';
import { FiledUsersResolver } from '../_services/user-management/resolvers/filed-users.resolver';
import { UpdateUserResolver } from '../_services/user-management/resolvers/update-user.resolver';
import { FiledCardsResolver } from '../_services/user-management/resolvers/filed-card-resolver.resolver';
import { AccountResolver } from '../_services/user-management/resolvers/account.resolver';
import { BillingAddressResolver } from '../_services/user-management/resolvers/billing-address.resolver';

import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
import { AuthenticationModule } from '../authentication/authentication.module';
import { PipesModule } from '../shared/shared.pipes.module';

@NgModule({
	imports: [
		ReactiveFormsModule,
		SharedModule,
		RouterModule,
		UserManagementRoutingModule,
		TranslateModule,
		FormsModule,
		MatButtonModule,
		MatDialogModule,
		StoreModule.forFeature('userManagement', userManagementReducer),
		EffectsModule.forFeature([UserManagementEffects]),
		AuthenticationModule,
		PipesModule
	],
	declarations: [],
	exports: [FormsModule, ReactiveFormsModule],
	providers: [
		PermissionsResolver,
		FiledUsersResolver,
		UpdateUserResolver,
		FiledCardsResolver,
		AccountResolver,
		BillingAddressResolver,
		InterceptorsProvider
	],
	schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class UserManagementModule {}
