import { NgModule } from '@angular/core';
import { RowComponent } from './row';
import { ColumnComponent } from './column';
import { BoxComponent } from './box';

const importExport = [RowComponent, ColumnComponent, BoxComponent];

@NgModule({
	imports: importExport,
	exports: importExport
})
export class LayoutModule {}

export { RowComponent, ColumnComponent, BoxComponent };
