import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	Output
} from '@angular/core';
import {
	MatCheckboxChange,
	MatCheckboxModule
} from '@angular/material/checkbox';
import {
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';
import { CommonModule } from '@angular/common';

export type CheckboxState = 'on' | 'off' | 'unknown';

@Component({
	selector: 'checkbox',
	template: `
		<mat-checkbox
			class="custom-checkbox-shorter"
			[disabled]="disabled"
			[checked]="value === 'on'"
			[indeterminate]="value === 'unknown'"
			(change)="onCheckboxClicked($event)"
		></mat-checkbox>
	`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true
		}
	],
	imports: [
		MatCheckboxModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule
	],
	standalone: true
})
export class CheckboxComponent implements ControlValueAccessor {
	@Input() disabled = false;
	@Input() value: CheckboxState = 'off';
	@Output() selection = new EventEmitter<CheckboxState>();

	onChange: (value: CheckboxState) => void = () => {};
	onTouched: () => void = () => {};

	onCheckboxClicked(event: MatCheckboxChange) {
		this.value = event.checked ? 'on' : 'off';
		this.onChange(this.value);
		this.selection.emit(this.value);
	}

	writeValue(obj: CheckboxState): void {
		this.value = obj;
	}

	registerOnChange(fn: (value: CheckboxState) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
