export interface PlatformModel {
	name: string;
	post_types: PostTypeModel[];
}

export interface PostTypeModel {
	name: string;
	count: number;
}

export interface CampaignIntegrationsDataModel {
	data: CampaignIntegrationsData;
}

export interface CampaignIntegrationsData {
	ecommerce?: string;
	payouts?: CampaignPayoutsIntegration[];
	pixel?: string;
}

export interface CampaignPayoutsIntegration {
	platform: string;
	user_integration_id: number;
}

export enum CampaignTypeResponseEnum {
	ALL = 'all',
	PAY_PER_POST = 'pay_per_post',
	UGC = 'user_generated_content',
	PERFORMANCE_ECOMMERCE = 'performance_based_ecommerce',
	PERFORMANCE_AFFILIATE = 'performance_based_affiliate',
	OUTREACH = 'outreach'
}

export interface PostType {
	id: number;
	name: string;
	icon: string;
	value: string;
	count: number;
}

export interface Platform {
	id: number;
	name: string;
	title: string;
	icon: string;
	value: string;
	postTypes: PostType[];
}
