import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'button[ai]',
	template: `
		<mat-icon class="material-symbols-outlined">hotel_class</mat-icon>
		<div>AI</div>
	`,
	styles: [
		//language=scss
		`
			$size: 16px;
			:host {
				cursor: pointer;
				mat-icon {
					width: $size;
					height: $size;
					font-size: $size;
					line-height: $size;
				}
				font-family: Gilroy;
				font-weight: 600;
				font-size: $size;
				line-height: $size;
				letter-spacing: 0;
				color: #ffffff;
				text-align: center;
				background: linear-gradient(
					90deg,
					rgba(3, 104, 208, 0.6) 0%,
					rgba(74, 124, 206, 0.6) 50%,
					rgba(75, 69, 217, 0.6) 100%
				);
				gap: 4px;
				outline: none;
				border: none;
				padding: 8px 16px;
				border-radius: 5px;

				display: flex;
				align-items: center;
			}
		`
	],
	imports: [MatIconModule],
	standalone: true
})
export class AiButtonComponent {}
