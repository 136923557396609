import {
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectedFileComponent } from './selected-file.component';
import { NoSelectedFileComponent } from './no-selected-file.component';
import { FileSelectionService } from '../../services/file-selection.service';

export type FileMetadata = {
	name: string;
	size: number;
};

@Component({
	selector: 'file-picker',
	template: `
		<selected-file
			*ngIf="selectedFile"
			(close)="onClose()"
			[filename]="selectedFile!.name"
			[size]="selectedFile!.size"
		></selected-file>
		<no-selected-file
			*ngIf="!selectedFile"
			(fileSelect)="onFileSelect()"
			[draggingOver]="draggingOver"
		></no-selected-file>
	`,
	imports: [CommonModule, SelectedFileComponent, NoSelectedFileComponent],
	standalone: true
})
export class FileDragAndDropComponent {
	@Input() selectedFile?: FileMetadata;

	@Output() close = new EventEmitter();
	@Output() file = new EventEmitter<File>();
	@Output() fileSelect = new EventEmitter();

	@HostBinding('class.drag-over')
	draggingOver = false;

	@HostListener('dragenter')
	onDragEnter() {
		this.draggingOver = true;
	}

	@HostListener('dragleave')
	onDragLeave(event: DragEvent) {
		this.draggingOver = false;
	}

	@HostListener('dragend', ['$event'])
	onDragEnd(event: DragEvent) {
		event.preventDefault();
		this.draggingOver = false;
	}

	@HostListener('dragover', ['$event'])
	onDragOver(event: DragEvent) {
		this.draggingOver = true;
		event.preventDefault();
	}

	@HostListener('drop', ['$event'])
	onDrop(event: DragEvent) {
		this.draggingOver = false;
		event.preventDefault();

		const files = event.dataTransfer?.files;

		if (files) {
			const file = files.item(0);
			if (file) {
				const extension = FileSelectionService.extension(file.name);
				if (extension === 'pdf' || extension === 'doc') {
					this.file.emit(file);
				}
			}
		}
	}

	onClose() {
		this.close.emit();
	}

	onFileSelect() {
		this.fileSelect.emit();
	}
}
