<ng-container>
	<div class="action-button cursor-pointer">
		<mat-form-field
			(menuClosed)="onMenuClosed()"
			[matMenuTriggerFor]="selectMenu"
			#selectMenuTrigger="matMenuTrigger"
			appearance="outline"
			class="outline"
			[floatLabel]="'never'"
		>
			<input
				matInput
				class="search-text"
				type="text"
				[placeholder]="placeholder"
				[formControl]="drpFormControl"
				autocomplete="random-some"
			/>
		</mat-form-field>
	</div>

	<mat-menu #selectMenu="matMenu" class="drp-menu" [ngStyle]="styleMenu()">
		<div
			(click)="$event.stopPropagation()"
			*ngFor="let listItem of filteredList; let itemSequence = index"
			class="menu-list-item {{ listItem?.desc ? 'list-item-lg' : '' }}"
		>
			<mat-checkbox
				class="custom-checkbox-black"
				*ngIf="
					listItem?.selected != undefined ||
					listItem?.selected != null
				"
				id="item-{{ itemSequence }}"
				type="checkbox"
				[(ngModel)]="listItem.selected"
				(ngModelChange)="toggleSelection(listItem, itemSequence)"
				[labelPosition]="'before'"
			>
				<span
					class="search-drp-name w-100"
					matTooltip="{{ listItem?.name }}"
					matTooltipPosition="left"
				>
					{{ listItem?.name }}
					<div class="desc" *ngIf="listItem?.desc">
						{{ listItem?.desc }}
					</div>
				</span>
			</mat-checkbox>
		</div>
	</mat-menu>
</ng-container>
