import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
	FiledPermissions,
	ModulesPermissions
} from 'src/app/shared/permisions/enums/filed-permissions';
import { MiscellaneousPermissions } from 'src/app/shared/permisions/enums/miscellaneous-permissions';
import { SettingsPermissions } from 'src/app/shared/permisions/enums/settings-permissions';
import {
	getFiledId,
	getPermissions,
	UserState
} from 'src/app/shared/state/user/user.reducer';
import { GuardNavLinks } from 'src/app/user-management/models/nav-link';
import { NewSettingsPageLabelsEnum } from 'src/app/user-management/shared/enums/navigation.enum';
import {
	UserManagementState,
	getNewNavigationUserManagementState,
	getActiveBillingPageUserManagement,
	getActiveUserPage
} from 'src/app/user-management/state/user-management.reducer';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { UserStateEnum } from 'src/app/_services/user/user-state.enum';
import * as userManagementActions from 'src/app/user-management/state/user-management.actions';
import { select, Store } from '@ngrx/store';
import { Modules } from 'src/app/shared/permisions/enums/modules';
import { StorageKey } from 'src/app/_models/local-storage-key';

@Component({
	selector: 'app-user-header',
	templateUrl: './user-header.component.html',
	styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {
	public currToken = JSON.parse(
		localStorage.getItem(StorageKey.decodedJwtIo)
	);
	public navLinks: GuardNavLinks[] = [
		{
			index: 0,
			label: 'My Profile',
			link: '/user-management/my-profile',
			guard: null,
			disable: false
		},
		// {
		// 	"index": 2,
		// 	"label": "MY PLAN",
		// 	"link": "/user-management/my-plan",
		// 	"guard": [
		// 		2
		// 	]
		// },
		{
			index: 2,
			label: 'Team',
			link: '/user-management/teams',
			guard: null,
			disable: this.currToken.sub_user
		},
		{
			index: 3,
			label: 'Integrations',
			link: '/user-management/integrations',
			guard: null,
			disable: false
		},
		{
			index: 4,
			label: 'Branding',
			link: '/user-management/branding',
			guard: null,
			disable: false
		},
		{
			index: 5,
			label: 'Manage Plan',
			link: '/user-management/manage-plan',
			guard: [2],
			disable: this.currToken.sub_user
		}
		// ! UNCOMMENT AFTER
		// {
		// 	index: 1,
		// 	label: 'Billing',
		// 	link: '/user-management/payment-and-billing',
		// 	guard: [2],
		// 	disable: this.currToken.sub_user
		// }

		// {
		// 	index: 1,
		// 	label: 'USER ADMIN',
		// 	link: '/user-management/user-admin',
		// 	guard: [2],
		// 	disable: true
		// },
		// {
		// 	index: 5,
		// 	label: 'SECURITY',
		// 	link: '/user-management/security',
		// 	guard: null,
		// 	disable: false
		// }
	];
	public selectedTabName: string;
	public activeLink: string = this.navLinks[0].label;

	private billingPageIndex: number;
	private userPageStatus: boolean;
	private allPermissions: FiledPermissions<ModulesPermissions>[];

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private store: Store<UserManagementState>,
		private router: Router,
		private userStore: Store<UserState>,
		private backOfficeService: BackOfficeService
	) {
		// this.store.dispatch(new userManagementActions.ToggleActiveUserManagementPage(true));
	}

	ngOnInit() {
		for (let link of this.navLinks) {
			if (link.link === location.pathname) {
				this.activateLink(link.label);
			}
		}
	}

	ngOnDestroy() {
		this.store.dispatch(
			new userManagementActions.ToggleActiveUserManagementPage(false)
		);

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public activateLink(label: string) {
		this.activeLink = label;
	}

	public getTooltipTitle(nav: GuardNavLinks): string {
		let str = '';
		switch (nav.label) {
			case 'My Profile':
				str =
					'Add your personal info like Name, Company Name, Email ID, & Contact number';
				break;
			case 'Team':
				str =
					'Add and edit the members of your team you’d like to have access to Lolly';
				break;
			case 'Integrations':
				str =
					'Integrate your Google or outlook account and start communicating with the creators';
				break;
			case 'Manage Plan':
				str = 'View/Upgrade/Downgrade your plan';
				break;
			case 'Billing':
				str = 'Edit your payment details, review invoices';
				break;
		}
		return str;
	}
}
