<div class="card-wrapper large-height">
	<div class="card-header">
		<div
			class="d-flex h-100 w-100 k-align-items-center k-justify-content-between bor-bot"
		>
			<div class="d-flex k-align-items-center" [style.gap]="'10px'">
				<h5>Pixel Integrations</h5>
				<i-tooltip
					[text]="
						'Manage Pixel integrations to track user activity and analytics'
					"
					[position]="'bottom-right'"
					[position]="'center-right'"
				></i-tooltip>
			</div>

			<div
				*ngIf="!loading"
				class="d-flex k-align-items-center"
				style="gap: 10px"
			>
				<a
					class="quaternary-btn"
					style="color: #005ed8"
					href="https://lolly.zohodesk.in/portal/en/kb/articles/set-up-paypal"
					target="_blank"
				>
					Installation Instructions
				</a>

				<ng-container>
					<mat-icon
						class="cursor-pointer"
						*ngIf="isPanelOpen"
						(click)="changePanelState(false)"
					>
						keyboard_arrow_up
					</mat-icon>
					<mat-icon
						class="cursor-pointer"
						*ngIf="!isPanelOpen"
						(click)="changePanelState(true)"
					>
						keyboard_arrow_down
					</mat-icon>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="card-body">
		<div>
			<table class="table" #automationTable *ngIf="!loading">
				<thead class="table__thead">
					<tr class="table__head">
						<th class="table__th">Domain</th>
						<th class="table__th" width="20%">Is integrated</th>
					</tr>
				</thead>
				<tbody class="table__tbody">
					<tr
						class="table__tr"
						*ngFor="let item of integratedDomains"
					>
						<td class="table__td" width="">
							<span class="table__value">
								<a class="link">{{ item }}</a>
							</span>
						</td>
						<td class="table__td">
							<span class="table__value">
								<img
									src="assets/icons/green-check-icon.svg"
									class="check"
								/>
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="tabs-wrapper">
			<div
				class="white-slab {{ selectedTab == 'auto' ? '' : 'right' }}"
			></div>
			<div class="tab" (click)="changeTab('auto')">
				Install with a website builder or CMS
			</div>
			<div class="tab" (click)="changeTab('manual')">
				Install Manually
			</div>
		</div>

		<ng-container [ngSwitch]="selectedTab">
			<ng-container
				*ngSwitchCase="'auto'"
				[ngTemplateOutlet]="auto_template"
			></ng-container>
			<ng-container
				*ngSwitchDefault
				[ngTemplateOutlet]="manual_template"
			></ng-container>
		</ng-container>
	</div>
</div>

<ng-template #auto_template>
	<div>
		<div class="d-flex w-100" [style.gap]="'24px'">
			<div
				appRoleBasedControl
				class="acc-card {{
					card?.is_pixel_installed ? 'disabled' : ''
				}}"
				*ngFor="let card of ecommerceCards"
				(click)="onCardClick(card)"
			>
				<img
					*ngIf="card?.is_pixel_installed"
					src="assets/icons/green-check-icon.svg"
					class="check"
				/>
				<img [src]="card.image" />
			</div>
		</div>
	</div>
</ng-template>

<ng-template #manual_template>
	<div class="d-flex w-100 flex-column" [style.gap]="'24px'">
		<div class="value">
			Below is the Pixel code for your site. Copy and paste it in the code
			of every page of your website, immediately after the &lt;head&gt;
			element.
		</div>

		<div class="value bordered-card">
			{{ pixelManualCode }}

			<mat-icon class="copy-icon" (click)="copyTag()">
				content_copy
			</mat-icon>
		</div>

		<div class="d-flex w-50 k-align-items-center mt-2" [style.gap]="'24px'">
			<input
				type="text"
				class="custom-input-name"
				placeholder="https://yourdomain.com"
				[formControl]="domainControl"
			/>
			<button
				class="secondary-btn"
				appRoleBasedControl
				(click)="verifyPixelInstall()"
			>
				Verify
			</button>
		</div>
	</div>
</ng-template>
