import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RowComponent } from '../../layout';

@Component({
	selector: 'input-search',
	template: `
		<mat-icon class="icon">search</mat-icon>
		<input
			type="text"
			[placeholder]="placeholder"
			[formControl]="control"
		/>
		<row *ngIf="control.value?.length > 0">
			<ng-content></ng-content>
			<mat-icon
				class="icon close"
				*ngIf="clearButton"
				(click)="onClose()"
			>
				close
			</mat-icon>
		</row>
	`,
	styles: [
		//language=scss
		`
			@use 'primary-typography';
			@use 'common';

			:host {
				display: flex;
				height: 42px;
				padding: 12px 15px;
				align-items: center;
				gap: 8px;
				border-radius: 5px;
				background: #f5f4f7;
				width: 100%;

				input {
					@include primary-typography.paragraph-5;
					border: none;
					background: none;
					width: 100%;

					&::placeholder {
						@include primary-typography.paragraph-5;
						color: #cbc8d1;
					}
				}

				.icon {
					@include common.icon(1.5rem);
					color: #cbc8d1;

					&.close {
						@include common.clickable;
					}
				}
			}
		`
	],
	imports: [RowComponent, MatIconModule, ReactiveFormsModule, NgIf],
	standalone: true
})
export class InputSearchComponent {
	@Input() control: FormControl = new FormControl('');
	@Input() clearButton = true;
	@Input() placeholder = 'Search';

	public onClose() {
		this.control.reset('');
	}
}
