import { Component, OnInit } from '@angular/core';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { Chart, registerables } from 'chart.js';
import { TrialDataModel } from '../../../../../shared/models/first-timer-user.model';
import { UpperCaseMediaPlatform } from '../../../discovery-v2/models/discovery-filter.model';
import { LandingPageService } from '../../services/landing-page.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CampaignService } from '../../../dashboard/services/campaign.service';

import { WalkthroughDashboardComponent } from '../../component-pieces/walkthrough-dashboard/walkthrough-dashboard.component';
import { MatDialog } from '@angular/material/dialog';
import { ShepherdService } from 'angular-shepherd';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import {
	NotificationActionEnum,
	NotificationModel
} from 'src/app/shared/models/notifications.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UtilsService } from 'src/app/shared/utils';
import { GenericSidePopupService } from 'src/app/shared/services/generic-side-popup.service';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';
import { UserJWTDetailsInterface } from 'src/app/authentication-v2/models/auth.model';
import {
	SearchRequest,
	SocialMedia
} from './search-bar/search-bar/search-bar.component';
import { DiscoveryService } from '../../../discovery-v2/services/discovery.service';
Chart.register(...registerables);

export interface CardsV1Model {
	name: string;
	value: string;
	image: string;
	icon: string;
	desc: string;
}

@Component({
	selector: 'app-landing-page',
	templateUrl: './landing-page.component.html',
	styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
	userName: string = '';
	public loading: boolean = true;
	public filter_selected: string = 'all';
	public lineGraphType: string = 'daily';
	public lineGraphFilter: string = 'impressions';
	private unsub$ = new Subject<void>();
	public barChart: any = Chart;
	public donutChart: any = Chart;
	public lineChart: any = Chart;
	public brand_campaigns: any = [];
	public brand_jobs: any = [];
	public brand_creator: any = [];
	public dashboradData: any = [];
	public revenueLable: any = [];
	public revenueData: any = [];
	public revenueValue: any = [];
	public lineGraphAllData: any = [];
	public lineGraphLable: any = [];
	public lineGraphData: any = [];

	public userDetails: UserJWTDetailsInterface;
	public performance = [];
	public pending = [];
	public donChart: any = Chart;
	public donChartColors = ['#9774D9', '#80DBD8', '#E97777'];
	public topCards: any[] = [];
	public performanceLoading = false;
	public pendingLoading = false;
	public topCardsLoading = false;
	public trialPeriodData: TrialDataModel;

	public cardsV1: CardsV1Model[] = [
		{
			desc: 'Browse 77M+ influencer profiles',
			image: 'assets/landing-page/discover-creators-graphic.png',
			icon: 'assets/landing-page/search-icon.png',
			name: 'Discovery',
			value: 'discovery'
		},
		{
			desc: 'Build your team of creators',
			image: 'assets/landing-page/lists-graphic.png',
			icon: 'assets/landing-page/lists-icon.png',
			name: 'List',
			value: 'lists'
		},
		{
			desc: 'Begin your promotion',
			image: 'assets/landing-page/create-campaign-graphic.png',
			icon: 'assets/landing-page/graph-icon.png',
			name: 'Create a campaign',
			value: 'campaign'
		},
		{
			desc: 'Connect with your creators',
			image: 'assets/landing-page/engage-graphic.png',
			icon: 'assets/landing-page/display-icon.png',
			name: 'Messaging',
			value: 'outreach'
		}
	];
	private APPOINTMENT_URL: string = UtilsService.BOOK_DEMO_URL;

	constructor(
		private landingPageService: LandingPageService,
		private discoveryService: DiscoveryService,
		private router: Router,
		private matDialog: MatDialog,
		private shepherdService: ShepherdService,
		private genericSidePopupService: GenericSidePopupService,
		private campaignService: CampaignService,
		private sharedService: SharedService,
		private notificationService: NotificationsService,
		private authV2Service: AuthenticationV2Service
	) {}

	ngOnInit() {
		this.userDetails = this.authV2Service.getCurrentDecodedToken();
		this.checkOnboardingFlowAndShowWelcomePopup();
		this.listenToTrialPeriodSubject();
		this.getAllData();
	}

	private checkOnboardingFlowAndShowWelcomePopup(): void {
		this.sharedService.firstTimerData$
			.pipe(takeUntil(this.unsub$))
			.subscribe(res => {
				// Showing welcome popup only after demo is booked
				if (res?.first_time_book_demo === false) {
					if (this.userDetails?.first_time_user) {
						this.openWalkthroughDialogFirst();
						this.disableFirstTimeUser();
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.unsub$.next();
		this.unsub$.complete();
	}

	private listenToTrialPeriodSubject(): void {
		this.sharedService.trialPeriodData$
			.pipe(takeUntil(this.unsub$))
			.subscribe(res => {
				this.trialPeriodData = res;
			});
	}
	public onAdvSearchClick(): void {
		if (
			this.sharedService?.userPlanRestrictions$?.value
				?.advanced_search === true
		) {
			this.router.navigate(['/adv-search']);
			return;
		}
		this.openBookADemoDialog();
	}

	public onEcommerceClick(): void {
		this.router.navigate(['/e-commerce']);
		return;
	}

	onDiscoverySearchRequested(request: SearchRequest) {
		this.discoveryService.updateFilter({
			selectedPlatform: this.platformToUpperCase(request.type),
			name: request.query
		});
		this.routeTo('discovery');
	}

	platformToUpperCase(platform: SocialMedia): UpperCaseMediaPlatform {
		switch (platform) {
			case 'instagram':
				return 'Instagram';
			case 'tiktok':
				return 'TikTok';
			case 'youtube':
				return 'Youtube';
		}

		return 'Instagram';
	}

	public routeTo(value: string): void {
		switch (value) {
			case 'discovery':
				this.router.navigate(['discovery']);
				break;
			case 'outreach':
				this.router.navigate(['outreach']);
				break;
			case 'campaign':
				this.router.navigate(['campaign']);
				break;
			case 'lists':
				this.router.navigate(['list'], {
					queryParams: { tab: 'lists' }
				});
				break;
		}
	}

	// previous version api
	public getAllData(): void {
		this.loading = true;
		this.sharedService.userProfileData$
			.pipe(takeUntil(this.unsub$))
			.subscribe(res => {
				if (res) {
					this.dashboradData = res;
					this.loading = false;
				}
			});
	}

	// pending actions api
	public getAllNotifications(): void {
		this.pendingLoading = true;
		this.notificationService
			.getAllNotifications()
			.subscribe((res: any) => {
				if (res) {
					const data = res?.data?.notifications.slice(0, 8);

					this.pending = data.map(item => ({
						...item,
						action: item.action.replace(/_/gi, ' ')
					}));

					// console.log('notifications', this.pending);
				}
			})
			.add(() => {
				this.pendingLoading = false;
			});
	}

	public makeAction(item): void {
		this.implementAction(item);
	}

	public implementAction(notification: NotificationModel): void {
		switch (notification.type) {
			case NotificationActionEnum.BRAND_MESSAGE_RECEIVED:
				this.router.navigate(['messages'], {
					queryParams: { creatorId: notification.created_by }
				});
				break;
			case NotificationActionEnum.BRAND_EMAIL_RECEIVED:
				this.router.navigate(['messages'], {
					queryParams: { emailCreatorId: notification.created_by }
				});
				break;
			case NotificationActionEnum.BRAND_NOTIFY_PENDING_PAYMENT:
			case NotificationActionEnum.BRAND_PAYMENT_SUCCESSFUL:
			case NotificationActionEnum.BRAND_PAYMENT_FAILED:
				this.router.navigate(['payments']);
				break;
			case NotificationActionEnum.BRAND_JOB_ACCEPTED:
			case NotificationActionEnum.BRAND_JOB_DECLINED:
			case NotificationActionEnum.BRAND_JOB_APPLIED:
				this.router.navigate([
					'marketplace',
					notification.resource_id,
					'view'
				]);
				break;
			case NotificationActionEnum.BRAND_CREATIVE_UPLOADED:
			case NotificationActionEnum.BRAND_POST_UPLOADED:
				this.router.navigate([
					'creatives/view',
					notification.resource_id
				]);
				break;
			case NotificationActionEnum.BRAND_CRM_EMAIL_SENT:
				this.router.navigate(['outreach']);
				break;
			case NotificationActionEnum.BRAND_CAMPAIGN_LIMIT:
			case NotificationActionEnum.BRAND_JOB_LIMIT_PER_CAMPAIGN:
			case NotificationActionEnum.BRAND_INFLUENCER_LIMIT_PER_JOB:
			case NotificationActionEnum.BRAND_DISCOVERY_CREDIT_FINISH:
				this.router.navigate(['user-management/payment-and-billing']);
				break;
			case NotificationActionEnum.BRAND_THIRD_PARTY_CONNECTION:
				this.router.navigate(['campaign/setupIntegration']);
				break;
			case NotificationActionEnum.BRAND_EMAIL_CONNECTED:
				this.router.navigate(['user-management/integrations']);
				break;
			case NotificationActionEnum.BRAND_SUBUSER_ACCEPTED_INVITE:
				this.router.navigate(['user-management/teams']);
				break;
		}
	}

	public scrollLeft(): void {
		this.scroll('left');
	}

	public scrollRight(): void {
		this.scroll('right');
	}

	private scroll(dir): void {
		var container = document.getElementById('pending-cards');
		this.sideScroll(container, dir, 25, 100, 10);
	}

	private sideScroll(element, direction, speed, distance, step): void {
		let scrollAmount = 0;
		var slideTimer = setInterval(function () {
			if (direction == 'left') {
				element.scrollLeft -= step;
			} else {
				element.scrollLeft += step;
			}
			scrollAmount += step;
			if (scrollAmount >= distance) {
				window.clearInterval(slideTimer);
			}
		}, speed);
	}

	private getPercentage(value, total): number {
		return (value / total) * 100;
	}

	public selectCampaign(selectCampaign): void {
		this.campaignService.selectedCampaignUrl.next(selectCampaign);
		this.router.navigate(['/campaign'], {
			queryParams: { destination: 'job' }
		});
	}

	// performance api
	private getPerformanceData(): void {
		this.performanceLoading = true;
		this.landingPageService
			.getCampaignPerformance()
			.subscribe(res => {
				// console.log('performance', res);

				const data = res.data;
				this.performance = data.map(item => ({
					...item,
					data: {
						instagram: this.getPercentage(
							item.platforms.instagram,
							item.platforms.total
						),
						tiktok: this.getPercentage(
							item.platforms.tiktok,
							item.platforms.total
						),
						youtube: this.getPercentage(
							item.platforms.youtube,
							item.platforms.total
						)
					},
					chart: Chart
				}));

				this.performance.map(item => {
					// draw chart
					const data = {
						labels: ['Instagram', 'TikTok', 'YouTube'],
						datasets: [
							{
								label: 'Total Posts',
								borderRadius: 100,
								borderWidth: 0,
								spacing: -40,
								cutout: '75%',
								data: Object.values(item.data),
								backgroundColor: this.donChartColors,
								hoverBackgroundColor: this.donChartColors,
								hoverOffset: 0
							}
						]
					};

					setTimeout(() => {
						this.generateDonGraph(item.chart, item.name, data);
					}, 100);
				});
			})
			.add(() => {
				this.performanceLoading = false;
			});
	}

	// doughnut chart for performance details
	private generateDonGraph(chart, id, data): void {
		const tooltipData = {};

		chart = new Chart(id, {
			type: 'doughnut',
			data: data,
			options: {
				responsive: true,
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						enabled: false,
						position: 'nearest',
						external: context =>
							this.customTooltip(
								context,
								'',
								tooltipData,
								'doughnut'
							)
					}
				}
			}
		});
	}

	// custom tooltip
	private customTooltip(context, title, tooltipData, type?): void {
		// Tooltip Element
		const id = 'chartjs-tooltip-home';
		let tooltipEl = document.getElementById(id);

		// Create element on first render
		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.id = id;
			tooltipEl.innerHTML =
				'<div class="tooltiptext"><div class="container"></div></div>';
			document.body.appendChild(tooltipEl);
		}

		if (type == 'line') {
			tooltipEl.classList.add('line-chart');
		} else {
			tooltipEl.classList.remove('line-chart');
		}

		// Hide if no tooltip
		const tooltipModel = context.tooltip;
		if (tooltipModel.opacity === 0) {
			tooltipEl.style.opacity = '0';
			return;
		}

		// Set caret Position
		tooltipEl.classList.remove('above', 'below', 'no-transform');
		if (tooltipModel.yAlign) {
			tooltipEl.classList.add(tooltipModel.yAlign);
		} else {
			tooltipEl.classList.add('no-transform');
		}

		function getBody(bodyItem) {
			return bodyItem.lines;
		}

		// set data
		const data = tooltipModel?.dataPoints[0];
		const label = data.label;
		const value = parseInt(data.formattedValue);

		// Set Text
		if (tooltipModel.body) {
			const titleLines = tooltipModel.title || [];
			const bodyLines = tooltipModel.body.map(getBody);

			let innerHtml = '<div class="wrapper">';

			bodyLines.forEach((body, i) => {
				innerHtml +=
					'<div class="details"><div class="icon ' +
					label.toLowerCase() +
					'"></div><span>' +
					value +
					'</span></div>';
			});
			innerHtml += '</div>';

			let tableRoot = tooltipEl.querySelector('.container');
			tableRoot.innerHTML = innerHtml;
		}

		const position = context.chart.canvas.getBoundingClientRect();

		// Display, position, and set styles for font
		tooltipEl.style.opacity = '1';
		tooltipEl.style.position = 'absolute';
		tooltipEl.style.left =
			position.left + window.pageXOffset + tooltipModel.caretX + 'px';
		tooltipEl.style.top =
			position.top + window.pageYOffset + tooltipModel.caretY + 'px';
		tooltipEl.style.padding = 10 + 'px ' + 10 + 'px';
		tooltipEl.style.pointerEvents = 'none';
		tooltipEl.style.zIndex = '9999';
	}

	public onVideoTutorialClick(page?: string): void {
		let url: string;
		switch (page) {
			case 'landing-page':
				url = UtilsService.LANDING_PAGE_KB_VIDEO_URL;
				break;
			case 'discovery':
				url = UtilsService.DISCOVERY_KB_VIDEO_URL;
				break;
			case 'lists':
				url = UtilsService.DISCOVERY_LIST_KB_VIDEO_URL;
				break;
			case 'campaign':
				url = UtilsService.CAMPAIGN_KB_VIDEO_URL;
				break;
			default:
				url = UtilsService.LANDING_PAGE_KB_VIDEO_URL;
		}
		window.open(url, '_blank');
	}

	// !old code
	public initiateShepherdTour(): void {
		this.shepherdService.defaultStepOptions = {
			classes: 'shepherd-elements',
			scrollTo: { behavior: 'smooth', block: 'nearest' },
			cancelIcon: {
				enabled: false
			}
		};
		this.shepherdService.modal = true;
		this.shepherdService.confirmCancel = false;
		this.shepherdService.requiredElements = [];
		this.shepherdService.addSteps([
			{
				id: 'side-dashboard',
				attachTo: {
					element: '#side-home',
					on: 'right-start'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-secondary',
						text: `Watch video tutorial`,
						action: () => {
							this.onVideoTutorialClick('landing-page');
						}
					},
					{
						classes: 'shepherd-button-primary',
						text: `Next`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'Home',
				text: [
					'View key actions like booking demos, discovering influencers, and managing campaigns from this dashboard.'
				]
			},
			{
				id: 'side-discovery',
				attachTo: {
					element: '#side-discovery',
					on: 'right-start'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-secondary',
						text: `Watch video tutorial`,
						action: () => {
							this.onVideoTutorialClick('discovery');
						}
					},
					{
						classes: 'shepherd-button-primary',
						text: `Next`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'Discovery',
				text: [
					'Use this space to search and identify influencers perfectly suited for your projects.'
				]
			},
			{
				id: 'side-list',
				attachTo: {
					element: '#side-list',
					on: 'right-start'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-secondary',
						text: `Watch video tutorial`,
						action: () => {
							this.onVideoTutorialClick('lists');
						}
					},
					{
						classes: 'shepherd-button-primary',
						text: `Next`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'List',
				text: [
					'Create and manage lists of influencers, grouping them into collections for organized access.'
				]
			},
			{
				id: 'side-campaign',
				attachTo: {
					element: '#side-campaign',
					on: 'right-start'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-secondary',
						text: `Watch video tutorial`,
						action: () => {
							this.onVideoTutorialClick('campaign');
						}
					},
					{
						classes: 'shepherd-button-primary',
						text: `Next`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'Campaigns',
				text: [
					'Here, initiate and control your Pay Per Post (PPP) and User Generated Content (UGC) campaigns.'
				]
			},
			{
				id: 'side-outreach',
				attachTo: {
					element: '#side-outreach',
					on: 'right-start'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-secondary',
						text: `Watch video tutorial`,
						action: () => {
							this.onVideoTutorialClick('outreach');
						}
					},
					{
						classes: 'shepherd-button-primary',
						text: `Next`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'Messaging',
				text: [
					'Communicate directly with creators to discuss project details and collaborations from here.'
				]
			},
			{
				id: 'side-reporting',
				attachTo: {
					element: '#side-reporting',
					on: 'right-start'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-secondary',
						text: `Watch video tutorial`,
						action: () => {
							this.onVideoTutorialClick('reporting');
						}
					},
					{
						classes: 'shepherd-button-primary',
						text: `Next`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'Analytics',
				text: [
					'View detailed analytics on your projects and track creator performance and outcomes here.'
				]
			},
			{
				id: 'side-payments',
				attachTo: {
					element: '#side-payments',
					on: 'right-start'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-secondary',
						text: `Watch video tutorial`,
						action: () => {
							this.onVideoTutorialClick('payments');
						}
					},
					{
						classes: 'shepherd-button-primary',
						text: `Next`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'Payouts',
				text: [
					'Manage and execute payments to your creators for their commissions and project contributions.'
				]
			},
			{
				id: 'side-adv-search',
				attachTo: {
					element: '#side-adv-search',
					on: 'right-start'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-secondary',
						text: `Watch video tutorial`,
						action: () => {
							this.onVideoTutorialClick('adv-search');
						}
					},
					{
						classes: 'shepherd-button-primary',
						text: `Next`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'Advance Search',
				text: [
					'Leverage AI tools here to expand your influencer database and enhance discovery processes.'
				]
			},
			{
				id: 'side-knowledgebase',
				attachTo: {
					element: '#side-knowledgebase',
					on: 'bottom-start'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-primary',
						text: `Next`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline-vertical',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'Help',
				text: [
					"Need assistance? Click on our handy help icon on all pages to find the support you're looking for in a breeze!\n\nDive into our comprehensive library of articles covering a wide range of topics. From troubleshooting to FAQs, we've got you covered!\n\nExplore, Discover and Work Seamlessly on Lolly! We are here to assist you at every step, Let’s start with the Video Tutorial Now"
				]
			},
			{
				id: 'side-settings',
				attachTo: {
					element: '#side-settings',
					on: 'bottom'
				},
				buttons: [
					{
						classes: 'shepherd-button-close',
						text: `<img src='assets/icons/close.svg'>`,
						action: () => this.shepherdService.cancel()
					},
					{
						classes: 'shepherd-button-primary',
						text: `Finish`,
						action: () => this.shepherdService.next()
					}
				],
				cancelIcon: {
					enabled: false
				},
				classes: 'shepherd-elements mr-inline-vertical',
				highlightClass: 'shepherd-highlight',
				scrollTo: false,
				title: 'Profile',
				text: [
					"Here are key actions you can take from your profile:\n• Subscription Management: Easily adjust your plan to fit your needs. Upgrade, or downgrade at any time\n• Team Collaboration: Invite team members, assign roles, and collaborate efficiently.\n• Account Settings: Update your profile details, manage email settings, and change your password to keep your account secure.\n\nWatch our video tutorial for a step-by-step guide on managing your profile, and click 'Finish' to start exploring your dashboard."
				]
			}
		]);
		this.shepherdService.start();
		this.shepherdService.tourObject.on('complete', () => {
			this.sharedService.triggerFirstTimeGetStarted.next();
		});
		this.shepherdService.tourObject.on('cancel', () => {
			this.sharedService.triggerFirstTimeGetStarted.next();
		});
	}

	public bookADemoLink(): void {
		window.open(this.APPOINTMENT_URL, '_blank');
	}

	public onBookADemoClick(): void {
		this.bookADemoLink();
	}

	public openBookADemoDialog(): void {
		this.genericSidePopupService
			.showPredefinedSidePopups('adv-search')
			.then(res => {
				if (res?.primary === true) {
					this.router.navigate(['user-management/manage-plan'], {
						queryParams: { openPlans: true }
					});
				}
				if (res?.secondary === true) {
					this.bookADemoLink();
				}
			});
	}

	public openWalkthroughDialogFirst(): void {
		let userFName = this.userDetails['user_firstname'];
		let userLName = this.userDetails['user_lastname'];
		this.matDialog
			.open(WalkthroughDashboardComponent, {
				width: '720px',
				height: '582px',
				disableClose: true,
				panelClass: 'center-dialog-no-shadow',
				backdropClass: 'light-backdrop',
				data: {
					name: userFName + ' ' + userLName
				}
			})
			.afterClosed()
			.pipe(takeUntil(this.unsub$))
			.subscribe(res => {
				if (res?.response === true) {
					this.initiateShepherdTour();
				} else {
					this.sharedService.triggerFirstTimeGetStarted.next();
				}
			});
	}

	public disableFirstTimeUser(): void {
		localStorage.setItem(StorageKey.first_time_lolly_user, 'false');
		this.landingPageService
			.disableFirstTimeUser()
			.pipe(takeUntil(this.unsub$))
			.subscribe(res => {
				this.authV2Service.updateAccessTokenFromServer();
			});
	}
}
