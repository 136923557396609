<mat-form-field class="no-padding-field" aria-label="never">
	<div class="column">
		<div class="row">
			<mat-icon class="icon">search</mat-icon>
			<input
				class="search-input"
				[value]="query"
				(input)="queryChange.emit($event.target.value)"
				matInput
				value=""
				placeholder="Search by Keyword Match, Influencers..."
			/>
			<ng-content></ng-content>
		</div>
	</div>
</mat-form-field>
