import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-datatable-pagination',
	templateUrl: './datatable-pagination.component.html',
	styleUrls: ['./datatable-pagination.component.scss']
})
export class DatatablePaginationComponent {
	@Input() public totalPage: number = 1;
	@Output() public eventClick = new EventEmitter<any>();

	public currentPage: number = 1;
	public isOnFirstPage: boolean;
	public isOnLastPage: boolean;

	private unsubscriber$: Subject<void> = new Subject<void>();

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	get getTotalPage() {
		return Math.ceil(this.totalPage / 10);
	}
	public nextPage(): void {
		if (this.currentPage >= this.getTotalPage) {
			this.currentPage = this.currentPage;
		} else {
			const oldPage = this.currentPage;
			this.currentPage += 1;
			this.eventClick.emit({
				oldPage: oldPage,
				newPage: this.currentPage
			});
		}
		this.checkFirstAndLastPage();
	}
	public previousPage(): void {
		if (this.currentPage <= 1) {
			this.currentPage = this.currentPage;
		} else {
			const oldPage = this.currentPage;
			this.currentPage -= 1;
			this.eventClick.emit({
				oldPage: oldPage,
				newPage: this.currentPage
			});
		}
		this.checkFirstAndLastPage();
	}

	public checkFirstAndLastPage(): void {
		this.isOnFirstPage = this.currentPage <= 1 ? true : false;
		this.isOnLastPage =
			this.currentPage >= this.getTotalPage ? true : false;
	}
}
