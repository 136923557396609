import { Component, Input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgIf } from '@angular/common';

@Component({
	selector: 'table-loader',
	template: `
		<ng-container *ngIf="loading; else content">
			<ngx-skeleton-loader
				appearance="line"
				[theme]="{ height: '52px' }"
			></ngx-skeleton-loader>
			<ngx-skeleton-loader
				appearance="line"
				[count]="9"
				[theme]="{ height: '48px' }"
			></ngx-skeleton-loader>
		</ng-container>

		<ng-template #content>
			<ng-content></ng-content>
		</ng-template>
	`,
	imports: [NgxSkeletonLoaderModule, NgIf],
	standalone: true
})
export class TableLoaderComponent {
	@Input() loading?: boolean;
}
