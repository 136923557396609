<div class="custom-quill-wrapper">
	<quill-editor
		[modules]="modules"
		[formControl]="control"
		trackChanges="all"
		class="custom-quill-editor"
		[placeholder]="placeholder"
		customToolbarPosition="center"
		*ngIf="!rawActive"
		#editor
	></quill-editor>
	<div class="raw-editor" *ngIf="rawActive">
		<textarea [formControl]="control" id="raw-editor"></textarea>
	</div>
	<div class="attachments-view" *ngIf="attachedFilesForm?.value.length > 0">
		<div
			class="attach-item"
			*ngFor="let attachItem of attachedFilesForm?.value; let i = index"
		>
			<img class="attach-img" [src]="getIconPath(attachItem.name)" />
			<div class="attach-info">
				<p class="attach-title">{{ attachItem.name }}</p>
				<p class="attach-size">{{ sizeInMb(attachItem.size) }} MB</p>
			</div>
			<mat-icon (click)="removeFile(i)" class="attach-button">
				close
			</mat-icon>
		</div>
	</div>
	<div class="footer-actions">
		<div class="custom-actions">
			<!-- attachment -->
			<button
				class="action-button"
				(click)="attachFile()"
				#tool="matTooltip"
				matTooltip="Attach Files"
				[disabled]="rawActive"
				*ngIf="activeModules.attachment"
			>
				<mat-icon class="icon-button">attach_file</mat-icon>
			</button>
			<!-- link -->
			<button
				class="action-button"
				[matMenuTriggerFor]="linkMenu"
				#selectMenuTrigger="matMenuTrigger"
				#tool="matTooltip"
				matTooltip="Insert Link"
				[disabled]="rawActive"
				*ngIf="activeModules.link"
			>
				<mat-icon class="icon-button">link</mat-icon>
			</button>
			<!-- image -->
			<button
				class="action-button"
				(click)="showImageUpload()"
				#tool="matTooltip"
				matTooltip="Upload Image"
				[disabled]="rawActive"
				*ngIf="activeModules.image"
			>
				<mat-icon class="icon-button">insert_photo</mat-icon>
			</button>
			<!-- bold -->
			<button
				class="action-button {{ boldActive ? 'active' : '' }}"
				(click)="toggleBold()"
				#tool="matTooltip"
				matTooltip="Bold"
				[disabled]="rawActive"
				*ngIf="activeModules.bold"
			>
				<mat-icon class="icon-button">format_bold</mat-icon>
			</button>
			<!-- italic -->
			<button
				class="action-button {{ italicActive ? 'active' : '' }}"
				(click)="toggleItalic()"
				#tool="matTooltip"
				matTooltip="Italic"
				[disabled]="rawActive"
				*ngIf="activeModules.italic"
			>
				<mat-icon class="icon-button">format_italic</mat-icon>
			</button>
			<!-- underline -->
			<button
				class="action-button {{ underlineActive ? 'active' : '' }}"
				(click)="toggleUnderline()"
				#tool="matTooltip"
				matTooltip="Underline"
				[disabled]="rawActive"
				*ngIf="activeModules.underline"
			>
				<mat-icon class="icon-button">format_underlined</mat-icon>
			</button>
			<!-- html -->
			<button
				class="action-button {{ rawActive ? 'active' : '' }}"
				(click)="toggleRawHtml()"
				#tool="matTooltip"
				matTooltip="Raw HTML"
				*ngIf="activeModules.html"
			>
				<mat-icon class="icon-button">code</mat-icon>
			</button>
		</div>
		<div class="send-actions">
			<button
				(click)="clearEditor()"
				mat-icon-button
				class="delete-btn"
				matTooltip="Discard"
			>
				<mat-icon class="icon-button">delete_outline</mat-icon>
			</button>
			<button class="primary-btn send-btn" (click)="onClickSubmit()">
				{{ submitLabel }}
			</button>
		</div>
	</div>

	<mat-menu #linkMenu="matMenu" yPosition="above" class="template-link">
		<div class="wrapper" (click)="$event.stopPropagation()">
			<div class="input">
				<img
					src="assets\crm\templates\new-template\Icon material-insert-link.svg"
					alt=""
				/>
				<input
					type="text"
					placeholder="Paste or type link here"
					[formControl]="linkForm.controls['link']"
				/>
				<img
					src="assets\discovery-v2\profile\info.svg"
					alt=""
					title="Please add the complete URL link ex. https://lolly.com"
					matTooltip="Please add the complete URL link, ex. https://lolly.com"
				/>
			</div>
			<div class="input">
				<img
					src="assets\crm\templates\new-template\Path 21740.svg"
					alt=""
				/>
				<input
					type="text"
					placeholder="Type to display"
					[formControl]="linkForm.controls['name']"
				/>
			</div>
			<div class="footer">
				<button
					class="secondary-btn"
					(click)="insertLink()"
					[disabled]="!(linkForm.value.name && linkForm.value.link)"
				>
					Save Link
				</button>
			</div>
		</div>
	</mat-menu>
</div>

<input #fileSelect class="d-none" type="file" />
