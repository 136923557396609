import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-ugc-components',
	templateUrl: './ugc.component.html',
	styleUrls: ['./ugc.component.scss']
})
export class UgcComponent implements OnInit {
	@Input() data: any;

	constructor() {}

	ngOnInit(): void {}
}
