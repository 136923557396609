import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import {
	FormControl,
	ReactiveFormsModule,
	UntypedFormControl
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

import { unsafeKeys } from '../../../utils';

@Component({
	selector: 'dropdown',
	template: `
		<div
			class="dropdown {{ styleClass }} {{ disabled ? 'disabled' : '' }}"
			[style]="cssStyle"
		>
			<div class="abs-label" *ngIf="label">
				{{ label }}
			</div>
			<mat-form-field
				appearance="outline"
				class="{{ disabled ? 'disabled-mat' : '' }}"
			>
				<mat-select
					class="drp-select"
					panelClass="drp2"
					[disableOptionCentering]="true"
					[formControl]="control"
					[placeholder]="placeholder"
					(selectionChange)="optionSelected($event)"
				>
					<mat-option *ngIf="showNullOption" [value]="null">
						{{ firstOptionName }}
					</mat-option>
					<mat-option
						*ngFor="let key of unsafeKeys(options)"
						[value]="valueKey ? options[key][valueKey] : key"
					>
						<div class="d-flex justify-content-between">
							<span class="title">
								{{
									titleKey
										? options[key][titleKey]
										: options[key]
								}}
							</span>
							<span
								*ngIf="control?.value == key"
								class="fas fa-check d-flex k-align-items-center"
								style="color: #4a4a4a;"
							></span>
						</div>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
				padding-top: 0.5rem;
			}

			.dropdown {
				font-family: 'Gilroy' !important;
				min-width: 100px;
				max-width: 628px;
				width: 100%;
				position: relative;

				.abs-label {
					position: absolute;
					top: -11px;
					left: 10px;
					padding-inline: 5px;
					z-index: 1;
					color: #4a4a4a99;
					font-size: 14px;
					font-weight: 400;
					background-color: #fff;
				}

				::ng-deep .mat-form-field-outline-start {
					border-radius: 5px 0 0 5px !important;
				}

				::ng-deep .mat-form-field-outline-end {
					border-radius: 0 5px 5px 0 !important;
				}

				::ng-deep
					.mat-form-field-appearance-outline
					.mat-form-field-infix {
					padding: 0 !important;
				}

				::ng-deep .mat-form-field-appearance-outline {
					.mat-form-field-outline {
						opacity: 1 !important;
						top: 0 !important;
						color: #87878747 !important;
					}

					&.mat-focused {
						.mat-form-field-outline {
							color: #4a4a4a !important;
							opacity: 1 !important;
						}
					}

					.mat-form-field-wrapper {
						padding: 0 !important;
						margin: 0 !important;
					}

					.mat-form-field-flex {
						// height: 48px !important;
						// padding: 1em 22px 1em 19px !important;
						// margin-top: 0px !important;
						align-items: center !important;
						height: 42px !important;
						padding: 0 22px 12px 14px !important;
						margin-top: 0 !important;
					}

					.mat-select {
						margin-top: 0 !important;
					}

					.mat-select-value {
						vertical-align: top !important;
						color: #4a4a4a;
						font-size: 14px !important;
						line-height: 26px !important;
						letter-spacing: 0.14px !important;
						padding: 0 !important;
						font-weight: 500 !important;
					}

					.mat-select-value-text {
						font-family: 'Gilroy' !important;
					}

					.mat-select-placeholder {
						font-family: 'Gilroy' !important;
						letter-spacing: 0.14px !important;
						font-size: 14px !important;
						font-weight: 500 !important;
						color: #4a4a4a !important;
					}

					.mat-select-arrow-wrapper {
						transform: translateY(0%);
					}

					.mat-select-arrow {
						color: #4a4a4a !important;
						margin: 0px -5px 0px 0px !important;
					}
				}

				::ng-deep
					.mat-form-field-appearance-outline
					.mat-form-field-outline-thick
					.mat-form-field-outline-start,
				::ng-deep
					.mat-form-field-appearance-outline
					.mat-form-field-outline-thick
					.mat-form-field-outline-end,
				::ng-deep
					.mat-form-field-appearance-outline
					.mat-form-field-outline-thick
					.mat-form-field-outline-gap {
					border-width: 1px !important;
				}

				&.disabled {
					pointer-events: none;
					background-color: #f5f4f7 !important;

					::ng-deep .mat-form-field-appearance-outline {
						.mat-select-placeholder {
							color: #4a4a4a66 !important;
						}

						.mat-select-arrow {
							color: #4a4a4a66 !important;
						}
					}
				}
			}

			::ng-deep .drp2 .mat-option:not(.mat-active) {
				.mat-option-text {
					font-size: 14px !important;
					line-height: 24px !important;
					letter-spacing: 0.14px !important;
					font-weight: 500 !important;
				}
			}

			::ng-deep .drp2 .mat-option {
				height: 40px !important;
				padding: 0 10px !important;
				color: #4a4a4a !important;
				font-weight: 500 !important;
				line-height: 24px !important;
			}

			::ng-deep .drp2 .mat-option.mat-active {
				background: #ffffff !important;

				.mat-option-text {
					font-size: 14px !important;
					font-weight: 500 !important;
					line-height: 24px !important;
					letter-spacing: 0.14px !important;
				}
			}

			::ng-deep .drp2.mat-select-panel {
				min-width: calc(100% + 40px) !important;
				max-width: 603px !important;
				box-shadow: 0 3px 26px rgba(0, 0, 0, 0.1607843137) !important;
				margin-left: 0 !important;
				padding: 10px !important;
				margin-top: 34px !important;

				.mat-option:hover {
					background-color: #f7f7f7 !important;
					color: #4a4a4a !important;
					border-radius: 5px !important;

					span {
						color: #4a4a4a !important;
					}
				}
			}
		`
	],
	imports: [
		MatFormFieldModule,
		MatOptionModule,
		MatSelectModule,
		NgForOf,
		NgIf,
		ReactiveFormsModule
	],
	standalone: true
})
export class DropdownComponent<T extends object, Key extends keyof T = keyof T>
	implements OnInit
{
	constructor() {}
	@Input() public styleClass!: string;
	@Input() public cssStyle?: unknown;
	@Input() label!: string;
	@Input() placeholder: string = 'Select';
	@Input() control: FormControl<Key> = new UntypedFormControl();
	@Input() options: T = {} as T;
	@Input() public disabled: boolean = false;
	@Input() public showNullOption: boolean = true;
	@Input() titleKey?: string;
	@Input() valueKey?: string;
	@Input() public firstOptionName: string = 'Select';

	@Output() public select = new EventEmitter<MatSelectChange>();

	protected readonly unsafeKeys = unsafeKeys;

	ngOnInit(): void {}

	public optionSelected(event: MatSelectChange) {
		this.select.emit(event);
	}
}
