import { Component, HostBinding, Input } from '@angular/core';

export const rowHostStyle = `
	:host {
  		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		flex-direction: row;

		&.justify-right {
			justify-content: right;
		}
	}

	:host.left {
		justify-content: left;
	}
`;

@Component({
	selector: 'row',
	template: '<ng-content></ng-content>',
	styles: [rowHostStyle],
	styleUrls: ['./row.scss'],
	standalone: true
})
export class RowComponent {
	@HostBinding('class.paper')
	@Input()
	paper?: boolean;

	@HostBinding('class.left')
	@Input('left')
	left = false;

	@HostBinding('class.justify-right')
	@Input('justify-right')
	right = false;
}
