export interface OutreachCampaignModel {
	id?: number;
	name?: string;
	jobs?: number;
}

export interface OutreachCampaignTypeModel {
	id: number;
	name: string;
	value: OutreachCampaignTypesEnum;
}

export enum OutreachCampaignTypesEnum {
	PayPerPostOrUgc = 'pay_per_post_or_ugc',
	PerformanceBased = 'performance_based',
	Outreach = 'outreach'
}
