import { Injectable, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { BackOfficeService } from './back-office/back-office.service';
import { Observable, Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { takeUntil } from 'rxjs/operators';
import {
	HideGlobalSpinner,
	ShowGlobalSpinner
} from '../shared/state/shared.actions';
import { select, Store } from '@ngrx/store';
import { SharedState } from '../shared/state/shared.reducer';
import { ToastNotificationService } from '../shared/toast-notification/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import {
	getInvoiceHistory,
	UserManagementState
} from '../user-management/state/user-management.reducer';
import { UtilsService } from '../shared/utils';

@Injectable()
export class PopupDialogService {
	@Input() public invoiceHistoryDetails: InvoiceHistory;
	public unsubscriber$ = new Subject<void>();
	public dataSource: InvoiceHistory[];

	constructor(
		private matDialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private store: Store<UserManagementState>,
		private sharedStore: Store<SharedState>,
		private toastNotificationService: ToastNotificationService,
		private translate: TranslateService
	) {}

	public confirm(
		title: string,
		message: string,
		width: string = '500px',
		height: string = 'auto',
		btnOkText: string = 'YES',
		btnOkColor: string = 'warn',
		btnCancelText: string = 'NO',
		btnCancelColor: string = ''
	): Observable<boolean> {
		const modalRef = this.matDialog.open(ConfirmationDialogComponent, {
			width: width,
			height: height
		});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.message = message;
		modalRef.componentInstance.btnOkText = btnOkText;
		modalRef.componentInstance.btnCancelText = btnCancelText;
		modalRef.componentInstance.btnOkColor = btnOkColor;
		modalRef.componentInstance.btnCancelColor = btnCancelColor;

		return modalRef.afterClosed();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}

	public download(id: string): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.backOfficeService
			.downloadInvoiceHistoryPdf(id, 'false')
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(downloadLink: string) => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					UtilsService.downloadUrlFile(downloadLink);
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotificationService.sendErrorToast(
						this.translate.instant(
							'AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'
						)
					);
				}
			);
	}

	private fetchStore(): void {
		this.store
			.pipe(select(getInvoiceHistory), takeUntil(this.unsubscriber$))
			.subscribe(response => {
				this.dataSource = response;
			});
	}
}
