import { Component, Input } from '@angular/core';
import { BaseModule } from 'components';
import { MaterialModule } from '../../../../material.module';

@Component({
	selector: 'preview-button',
	template: `
		<text variant="h2">Preview</text>
		<mat-icon *ngIf="!expanded">open_in_full</mat-icon>
		<mat-icon *ngIf="expanded">close_fullscreen</mat-icon>
	`,
	styles: [
		`
			@use 'common';

			:host {
				@include common.row;
				@include common.clickable;

				justify-content: left;
				align-items: center;
				gap: 8px;
			}

			mat-icon {
				@include common.icon(18px);
			}
		`
	],
	imports: [BaseModule, MaterialModule],
	standalone: true
})
export class PreviewButtonComponent {
	@Input() expanded?: boolean;
}
