import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { EMPTY, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { StorageKey } from '../../_models/local-storage-key';
import { AuthenticationService } from '../authentication.service';
import { GetPermissionsResponse } from 'src/app/shared/models/permission';
import { BaseApiUrl } from '../base-api-urls';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { AccountTypeService } from '../../shared/account-type.service';
import { SetAdAccountSelection } from 'src/app/shared/state/shared.actions';
import { select, Store } from '@ngrx/store';
import {
	getGoogleBusinessOwnerId,
	getUserDetails,
	UserState
} from '../../shared/state/user/user.reducer';
import { SharedState } from 'src/app/shared/state/shared.reducer';

@Injectable()
export class AdAccountApiService implements OnInit {
	public adAccountId: number;
	public adAccountChanged$: Observable<any>;
	public allAdAccounts: Map<SourceChannel, any[]> = new Map<
		SourceChannel,
		any[]
	>();

	private adAccountChangedSubject: ReplaySubject<any> = new ReplaySubject(1);

	private selectedAdAccount: Map<SourceChannel, any> = new Map<
		SourceChannel,
		any
	>();
	private logoutSubscription: Subscription;
	private selectedChannel: SourceChannel;

	private readonly baseURL = BaseApiUrl.FacebookAccount;

	constructor(
		protected http: HttpClient,
		private authenticationService: AuthenticationService,
		private accountTypeService: AccountTypeService,
		private userStore: Store<UserState>,
		private sharedStore: Store<SharedState>
	) {
		this.adAccountChanged$ = this.adAccountChangedSubject.asObservable();

		this.accountTypeService
			.getAccountTypeObservable()
			.subscribe(channel => {
				this.selectedChannel = channel;
			});

		this.addSubscriptions();
	}

	public ngOnInit() {}

	public getBusinessOwnerPermissions(): Observable<GetPermissionsResponse> {
		return this.http.get<GetPermissionsResponse>(
			`${this.baseURL}business-owners/permissions`
		);
	}

	private addSubscriptions = (): void => {
		this.logoutSubscription =
			this.authenticationService.logoutSubject.subscribe(() => {
				this.clearSelectedAdAccounts();
				this.clearAllCachedAdAccounts();
			});
	};

	public preSetSelectedChannel(): any {
		if (this.allAdAccounts.get(0)?.length > 0) {
			let firstAdAccount = this.allAdAccounts.get(0)[0];
			this.setSelectedAdAccounts(firstAdAccount, 0);
			this.getSelectedAdAccount();
		} else {
			return {
				id: 'none',
				name: 'No Facebook Account',
				status: 'ACTIVE',
				businessName: 'None',
				businessId: 977420299067,
				businessIdAsNumber: 9774202990674,
				currency: 'USD',
				createdAt: '2021-06-18T00:30:09.4508412',
				level: 1,
				expandable: false
			};
		}
	}
	public getSelectedAdAccount(): any {
		return this.selectedAdAccount.has(this.selectedChannel)
			? this.selectedAdAccount.get(this.selectedChannel)
			: this.preSetSelectedChannel();
	}

	public setSelectedAdAccounts(
		adAccount: any,
		channel: SourceChannel = null
	): void {
		if (!adAccount || (!channel && this.selectedChannel === null)) {
			return;
		}
		this.selectedAdAccount.set(
			channel !== null ? channel : this.selectedChannel,
			adAccount
		);
		const formattedAccounts = this.selectedAdAccountsToJson();
		this.adAccountChangedSubject.next(adAccount);
		this.sharedStore.dispatch(
			new SetAdAccountSelection({
				sourceChannel: this.selectedChannel,
				adAccount: adAccount
			})
		);
		localStorage.setItem(StorageKey.selectedAdAccounts, formattedAccounts);
	}

	public restoreAdAccountSelectionFromLocalStorage(): void {
		try {
			this.selectedAdAccount = new Map<SourceChannel, any>();
			const channel = localStorage.getItem('source_channel');
			const objFromJson = JSON.parse(
				localStorage.getItem(StorageKey.selectedAdAccounts)
			);
			if (!objFromJson) {
				return;
			}

			for (const [key, value] of Object.entries(objFromJson)) {
				if (key) {
					this.selectedAdAccount.set(parseInt(key), value as any);
				}
			}

			this.sharedStore.dispatch(
				new SetAdAccountSelection({
					sourceChannel: parseInt(channel),
					adAccount: this.selectedAdAccount.get(parseInt(channel))
				})
			);
			this.adAccountChangedSubject.next(
				this.selectedAdAccount.get(parseInt(channel))
			);
		} catch (e) {}
	}
	public clearAllCachedAdAccounts(): void {
		this.allAdAccounts = new Map<SourceChannel, any[]>();
	}

	public clearSelectedAdAccounts(): void {
		this.selectedAdAccount = new Map<SourceChannel, any>();
	}

	private selectedAdAccountsToJson(): string {
		const jsonObj = {};
		this.selectedAdAccount.forEach((value, key) => {
			(jsonObj as any)[key] = value;
		});

		return JSON.stringify(jsonObj);
	}
}
