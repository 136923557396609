import { HttpErrorResponse } from '@angular/common/http';

export type Constructor<T, Args extends unknown[] = unknown[]> = new (
	...args: Args
) => T;

export function statusCheck<T>(
	error: unknown,
	ErrorClass: Constructor<T>,
	statusCode: number
): T | undefined {
	if (error instanceof HttpErrorResponse) {
		if (error.status === statusCode) {
			return new ErrorClass();
		}
	} else if (error instanceof ErrorClass) {
		return error;
	}
	return undefined;
}
