import { statusCheck } from './http-status';

export class ProgrammingError extends Error {
	prog = 'programming error';
	error = 'prog';

	constructor() {
		super();
	}
	public static check(error: unknown): ProgrammingError | undefined {
		const internalError = statusCheck(error, ProgrammingError, 500);
		if (internalError) return internalError;

		const unprocessableEntity = statusCheck(error, ProgrammingError, 422);
		if (unprocessableEntity) return unprocessableEntity;

		return undefined;
	}
}
