import { EventEmitter, Injectable } from '@angular/core';
import { unsafeKeys } from '../social-media-influencer/utils';

export type FileUploadDefinition = {
	[key: string]: {
		accept?: string[];
	};
};

type FileUploads<T> = { [key in keyof T]: FileUpload };

@Injectable()
export class FileSelectionService<T extends FileUploadDefinition> {
	inputs: FileUploads<T> = {} as unknown as FileUploads<T>;
	files: {
		[key in keyof T]?: File;
	} = {};

	static extension(filename: string): string | null {
		const split = filename.split('.');
		if (split.length > 1) {
			return split[split.length - 1];
		}

		return null;
	}

	constructor() {}

	init(tag: keyof T, accept?: string[]) {
		this.inputs[tag] = new FileUpload(accept);
	}

	initDef(definition: T) {
		const keys = unsafeKeys(definition);
		for (const key of keys) {
			this.init(key, definition[key].accept);
			this.inputs[key].fileSelect.subscribe(
				file => (this.files[key] = file)
			);
		}
	}

	fileInput(tag: keyof T): FileUpload {
		return this.inputs[tag];
	}
}

export class FileUpload {
	fileSelect: EventEmitter<File>;

	input: HTMLInputElement;

	constructor(accept?: string[]) {
		this.fileSelect = new EventEmitter<File>();
		this.input = document.createElement('input');
		this.input.type = 'file';
		this.input.accept = accept?.join(',') ?? '';
		this.input.onchange = this.onFileSelected;
	}

	openFileDialog(): void {
		this.input.click();
	}

	trySetFile(file: File) {
		const dataTransfer = new DataTransfer();
		dataTransfer.items.add(file);
		this.input.files = dataTransfer.files;
	}

	onFileSelected = (event: Event) => {
		const input = event.target as HTMLInputElement;
		if (input?.files?.length) {
			const selectedFile = input.files[0];
			this.fileSelect.emit(selectedFile);
		}
	};
}
