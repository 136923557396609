import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignupSettingsAccordionComponent } from '../accordion';

import { PickedColorComponent } from './color.component';
import { RowFilterModule } from '@progress/kendo-angular-grid';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormControl, NonNullableFormBuilder } from '@angular/forms';
import { BaseModule, InputComponent, ResetButtonComponent } from 'components';

@Component({
	selector: 'button-settings-accordion',
	template: `
		<signup-settings-accordion
			title="Buttons"
			[expanded]="expanded"
			(toggle)="toggle.emit()"
		>
			<row>
				<text>Primary Button Color</text>

				<app-picked-color
					[control]="buttonColorControl"
				></app-picked-color>
			</row>

			<row>
				<text>Primary Button Text Color</text>
				<app-picked-color
					color="#FFFFFF"
					[control]="textColorControl"
				></app-picked-color>
			</row>

			<input-app
				title="Button Text"
				placeholder="Sign up"
				[control]="buttonTextControl"
			></input-app>
			<row>
				<div></div>
				<button app-reset (click)="reset.emit()">Reset</button>
			</row>
		</signup-settings-accordion>
	`,
	styles: [``],
	imports: [
		SignupSettingsAccordionComponent,
		BaseModule,
		PickedColorComponent,
		RowFilterModule,
		ColorPickerModule,
		ResetButtonComponent,
		InputComponent
	],
	standalone: true
})
export class ButtonsSettingsAccordion {
	@Input() buttonColorControl: FormControl<string> =
		this.builder.control('#FFFFFF');
	@Input() textColorControl: FormControl<string> =
		this.builder.control('#000000');
	@Input() buttonTextControl: FormControl<string> =
		this.builder.control('Sign up');

	@Input() expanded?: boolean;

	@Output() reset = new EventEmitter<void>();
	@Output() toggle = new EventEmitter<void>();

	constructor(private builder: NonNullableFormBuilder) {}

	color = '#FFFFFF';
}
