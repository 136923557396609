import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class AccordionService {
	titleClick = new EventEmitter<void>();

	#isOpen = false;

	get isOpen() {
		return this.#isOpen;
	}

	public toggle() {
		this.#isOpen = !this.#isOpen;
	}

	public titleClicked() {
		this.titleClick.emit();
	}
}
