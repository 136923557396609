<div class="modalContainer" *ngIf="!loading">
	<!-- <div> -->
	<mat-icon class="close-icon" mat-dialog-close>clear</mat-icon>
	<div class="body">
		<div class="header">
			<span class="section-title">
				{{ data.isEdit ? 'Edit Member' : 'Add member' }}
			</span>
			<!-- <i class="k-icon k-i-close close" (click)="closeDialog()"></i> -->
		</div>
		<form [formGroup]="teamForm">
			<div class="input-wrapper">
				<div class="input email">
					<input
						formControlName="email"
						type="text"
						class="w-100 custom-input"
						placeholder="Email"
						[readonly]="data.isEdit"
					/>
				</div>

				<div class="input">
					<!-- <div class="label mb-m">
						<p>Role</p>
					</div> -->
					<!-- <div class="user-role text-field">
						<mat-select class="font-medium" formControlName="role" (selectionChange)="onRoleChange($event)"
							disableOptionCentering="true">
							<mat-option *ngFor="let role of roles" [value]="role" style="background: #fff;">
								<div class="d-flex align-items-center justify-content-between mat-options">
									<div class="mat-country-wrapper d-flex align-items-center">
										<span
											[class]="role == teamForm.controls['role'].value ? 'mat-selected-check' : ''">
											{{ getTitleCaseName(role) }}
										</span>
									</div>
									<img *ngIf="role == teamForm.controls['role'].value"
										src="assets\icons\check-blue-icon.svg" />
								</div>
							</mat-option>
						</mat-select>
					</div> -->
					<div class="filter-option">
						<mat-form-field>
							<mat-select
								formControlName="role"
								(selectionChange)="onRoleChange($event)"
								disableOptionCentering="true"
							>
								<mat-option
									*ngFor="let role of roles"
									[value]="role"
								>
									{{ getTitleCaseName(role) }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</div>

			<!-- *advanced section -->
			<div class="advanced-wrapper">
				<div
					class="advanced {{ heightFull ? 'active' : '' }}"
					(click)="toggleHeight()"
				>
					Advanced Options
					<mat-icon *ngIf="!heightFull" class="adv-icon">
						keyboard_arrow_down
					</mat-icon>
					<mat-icon *ngIf="heightFull" class="adv-icon">
						keyboard_arrow_up
					</mat-icon>
				</div>

				<!-- <ng-container *ngIf="!heightFull">
						<div class="btns" *ngIf="!data.isEdit">
							<lolly-btn03 class="custom-button" (click)="sendInvite()">
								<span style="font-family: Gilroy;">Send Invite</span>
								<img style="margin-left: 7px;" src="assets/icons/user-management/icon-send.svg"
									alt="" />
							</lolly-btn03>
						</div>
						<div class="btns" *ngIf="data.isEdit">
							<lolly-btn03 class="custom-button" (click)="updateUser()">
								<span style="font-family: Gilroy;">Update</span>
							</lolly-btn03>
						</div>
					</ng-container> -->
			</div>
			<div class="checkbox-wrapper" *ngIf="heightFull">
				<!-- <mat-checkbox *ngFor="let permission of permissions" class="drp-checkbox-2" type="checkbox"
					[disabled]="permission.disabled" [checked]="selection.isSelected(permission)"
					(change)="selection.toggle(permission)">
					<span class="search-drp-name-2 w-100">
						{{ permission?.name }}
					</span>
				</mat-checkbox> -->
				<mat-checkbox
					*ngFor="let permission of permissions"
					class="custom-checkbox-black"
					[disabled]="permission.disabled"
					[checked]="selection.isSelected(permission)"
					(change)="selection.toggle(permission)"
				>
					<span class="search-drp-name-2 w-100">
						{{ permission?.name }}
					</span>
				</mat-checkbox>
			</div>
		</form>
	</div>

	<!-- *footer -->
	<div class="team-footer">
		<div class="btns" *ngIf="!data.isEdit">
			<button
				class="primary-btn"
				(click)="sendInvite()"
				[disabled]="teamForm.invalid"
			>
				<span>Send Invite</span>
				<img src="assets/icons/user-management/icon-send.svg" alt="" />
			</button>
		</div>
		<div class="btns" *ngIf="data.isEdit">
			<button
				class="primary-btn"
				(click)="updateUser()"
				[disabled]="teamForm.invalid"
			>
				<span>Update</span>
			</button>
		</div>
	</div>
	<!-- </div> -->
</div>

<div
	class="w-100 h-100 d-flex k-align-items-center k-justify-content-center"
	*ngIf="loading"
>
	<app-lolly-spinning-loader [rotating]="true"></app-lolly-spinning-loader>
</div>
