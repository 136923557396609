import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'tooltip',
	template: `
		<div class="tooltip">
			<ng-content></ng-content>
			<div class="tooltiptext {{ position }}">
				<div class="text">{{ text }}</div>
				<a *ngIf="link" class="link" [href]="link" target="_blank">
					{{ linkText }}
				</a>
			</div>
		</div>
	`,
	styles: [
		//language=scss
		`
			@use 'common';
			:host {
				font-family: 'Gilroy';

				.tooltip {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.tooltip .tooltiptext {
					visibility: hidden;
					background-color: #ffffff;
					padding: 12px;
					justify-content: center;
					position: absolute;
					z-index: 99;
					border-radius: 5px;
					box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1019607843);
					border: 1px solid #ebebeb;
					opacity: 0;
					transition: 0.3s all;
					max-width: 321px;
					white-space: wrap;
					display: flex;
					width: max-content;

					&.top-center {
						top: 0%;
						right: 50%;
						margin-bottom: -10px;
						transform: translate(50%, -100%);
					}

					&.center-right {
						top: 50%;
						right: 0%;
						margin-right: -10px;
						transform: translate(100%, -50%);
					}

					&.bottom-left {
						top: 100%;
						right: 100%;
						margin-top: 8px;
						margin-right: -20px;
					}

					&.bottom-right {
						top: 100%;
						left: 100%;
						margin-top: 8px;
						margin-left: -20px;
					}

					.text {
						font-weight: 600;
						color: #121212;
						line-height: 22px;
						font-size: 14px;
						letter-spacing: 0.14px;
					}

					.link {
						@include common.clickable;
						line-height: 22px;
						font-size: 14px;
						letter-spacing: 0.14px;
						font-weight: 600;
						margin-left: 5px;
						color: #2585fe;
						white-space: nowrap;
					}
				}

				.tooltip:hover .tooltiptext {
					opacity: 1;
					visibility: visible;
				}

				@media screen and (min-width: 1920px) {
					.text {
						font-size: 16px !important;
						letter-spacing: 0.16px !important;
					}

					.link {
						font-size: 16px;
						letter-spacing: 0.16px;
					}
				}
			}
		`
	],
	imports: [CommonModule],
	standalone: true
})
export class TooltipComponent {
	@Input() text!: string;
	@Input() position: string = 'center-right';
	@Input() link?: string;
	@Input() linkText?: string;
}
