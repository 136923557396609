export interface OutreachStageModel {
	id?: number;
	name?: string;
	count?: number;
	value?: OutreachStagesEnum;
}

export enum OutreachStagesEnum {
	PROSPECT = 'prospect',
	SENT = 'sent',
	REPLIED = 'replied',
	ACCEPTED = 'accepted',
	PAYOUT_IN_PROGRESS = 'payout_in_progress',
	CONTENT_REVIEW = 'content_review',
	COMPLETION = 'completion'
}
