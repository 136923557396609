import { Component, HostBinding, Input } from '@angular/core';
import { styleClasses } from '../../utils/style-class';

export type TypographyVariant1 = keyof typeof mapping;
export type TypographyColors1 = 'primary' | 'accent';
export type TypographyTextAlign1 = 'left' | 'center' | 'right';

const mapping = {
	h1: 'header-h1',
	h2: 'header-h2',
	h3: 'header-h3',
	h4: 'header-h4',
	p1: 'paragraph-1',
	p2: 'paragraph-2',
	p3: 'paragraph-3',
	p4: 'paragraph-4'
} as const;

const colors = {
	accent: '--primary-dark-blue'
};

@Component({
	selector: 'text',
	template: `
		<ng-content></ng-content>
	`,
	styles: [
		//language=scss
		`
			@use 'typography2';
			@use 'common';

			:host {
				display: block;

				&.header-h1 {
					@include typography2.header-h1;
				}

				&.header-h2 {
					@include typography2.header-h2;
				}

				&.header-h3 {
					@include typography2.header-h3;
				}

				&.header-h4 {
					@include typography2.header-h4;
				}

				&.paragraph-1 {
					@include typography2.paragraph-1;
				}

				&.paragraph-2 {
					@include typography2.paragraph-2;
				}

				&.paragraph-3 {
					@include typography2.paragraph-3;
				}

				&.paragraph-4 {
					@include typography2.paragraph-4;
				}

				&.link {
					color: var(--primary-dark-blue-shade);
				}

				&.disabled-link {
					color: var(--blue-shade-3);
				}

				&.inline {
					display: inline;
				}

				&.left {
					text-align: left;
				}

				&.center {
					text-align: center;
				}

				&.right {
					text-align: right;
				}

				&.clickable {
					@include common.clickable;
				}
			}
		`
	],
	standalone: true
})
export class TextComponent {
	@Input() variant: TypographyVariant1 = 'h4';
	@Input() color: TypographyColors1 = 'primary';
	@Input() textAlign: TypographyTextAlign1 = 'left';
	@Input() disabled?: boolean;

	@HostBinding('class')
	get update() {
		return styleClasses(mapping[this.variant], this.textAlign);
	}

	@HostBinding('class.link')
	get isAccent() {
		return this.color === 'accent';
	}

	@HostBinding('class.disabled-link')
	get isDisabledLink() {
		return this.color === 'accent' && this.disabled;
	}

	@HostBinding('class.inline')
	@Input()
	inline?: boolean;

	@HostBinding('class.clickable')
	@Input()
	clickable?: boolean;
}
