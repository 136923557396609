import { NgModule } from '@angular/core';
import { BlurLoaderComponent } from './blur-loader.component';
import { TableLoaderComponent } from './table-loader.component';
import { LoaderComponent } from './loader.component';
import { LollySpinnerLoaderComponent } from './lolly-spinning-loader/lolly-spinning-loader.component';

export * from './table-loader.component';

export { LoaderComponent, BlurLoaderComponent, LollySpinnerLoaderComponent };
const importExport = [
	TableLoaderComponent,
	LoaderComponent,
	BlurLoaderComponent,
	LollySpinnerLoaderComponent
];

@NgModule({
	imports: importExport,
	exports: importExport
})
export class LoadersModule {}

export * from './skeleton';
