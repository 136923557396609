import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { PermissionsResolver } from 'src/app/_services/user-management/resolvers/permissions.resolver';
import { FiledUsersResolver } from 'src/app/_services/user-management/resolvers/filed-users.resolver';
import { UpdateUserResolver } from 'src/app/_services/user-management/resolvers/update-user.resolver';
import { FiledCardsResolver } from 'src/app/_services/user-management/resolvers/filed-card-resolver.resolver';
import { AccountResolver } from 'src/app/_services/user-management/resolvers/account.resolver';
import { BillingAddressResolver } from 'src/app/_services/user-management/resolvers/billing-address.resolver';

import { InterceptorsProvider } from 'src/app/_services/interceptors/intercepors.provider';
import { UserSprintRoutingModule } from './user-sprint-routing.module';
import { DatatablePaginationComponent } from './components/datatable-pagination/datatable-pagination.component';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { VerifyAddUserComponent } from './components/modals/verify-add-user/verify-add-user.component';
import { CompleteModalComponent } from './components/modals/complete-modal/complete-modal.component';
import { FailedPaymentNotificationComponent } from './components/payment-billing/components/failed-payment-notification/failed-payment-notification.component';
import { PaymentCardsComponent } from './components/payment-billing/components/payment-cards/payment-cards.component';
import { AddCardComponent } from './components/payment-billing/components/add-card/add-card.component';
import { DownloadInvoiceComponent } from './components/payment-billing/components/download-invoice/download-invoice.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { SecurityComponent } from './components/security/security.component';
import { SadComponent } from './components/security/components/sad/sad.component';
import { PausingAccountComponent } from './components/security/components/pausing-account/pausing-account.component';
import { PausingAccountExtraComponent } from './components/security/components/pausing-account-extra/pausing-account-extra.component';
import { AppreciationComponent } from './components/security/components/appreciation/appreciation.component';
import { OfferAcceptedComponent } from './components/security/components/offer-accepted/offer-accepted.component';
import { SadFinalComponent } from './components/security/components/sad-final/sad-final.component';
import { UserActivityComponent } from './components/security/components/user-activity/user-activity.component';
import { ReactivateAccountComponent } from './components/security/components/reactivate-account/reactivate-account.component';
import { EditSetupComponent } from './components/security/components/two-factor-authentication/components/edit-setup/edit-setup.component';
import { SetupComponent } from './components/security/components/two-factor-authentication/components/setup/setup.component';
import { TwoFactorAuthenticationComponent } from './components/security/components/two-factor-authentication/two-factor-authentication.component';
import { OptComponent } from './components/modals/opt/opt.component';
import { PaymentComponent } from './components/payment/payment.component';
import { UserPermissionComponent } from './components/user-admin/components/user-permission/user-permission.component';
import { SelectItemComponent } from './components/select-item/select-item.component';
import { InvoiceDialogComponent } from './components/modals/invoice-dialog/invoice-dialog.component';
import { ConfirmAnotherCardComponent } from './components/payment-billing/components/confirm-another-card/confirm-another-card.component';
import { InputModalComponent } from './components/modals/input-modal/input-modal.component';
import { PayDialogComponent } from './components/pay-dialog/pay-dialog.component';
import { SettingsConComponent } from './components/settings-con/settings-con.component';
import { BillingPlansComponent } from './components/payment-billing/components/billing-plans/billing-plans.component';
import { UserTeamsComponent } from './components/user-teams/user-teams.component';
import { AddNewTeamComponent } from './components/user-teams/add-new-team/add-new-team.component';
import { TwoFAComponent } from './components/two-fa/two-fa.component';
import {
	EmailIntegrationsComponent,
	EmailIntegrationsDialogComponent
} from './components/email-integrations/email-integrations.component';
import { EmailIntegrationsLoadingComponent } from './components/email-integrations/email-integrations-loading/email-integrations-loading.component';
import { GoogleDisclosureComponent } from './components/google-disclosure/google-disclosure.component';
import { CustomPasswordFieldComponent } from './components/custom-password-field/custom-password-field.component';
import { ManagePlanComponent } from './components/payment-billing/components/manage-plan/manage-plan.component';
import { InputCancellationReasonDialogComponent } from './components/payment-billing/components/input-cancellation-reason-dialog/input-cancellation-reason-dialog.component';
import { PaymentIntegrationsComponent } from './components/payment-integrations/payment-integrations.component';
import { CancelSubscriptionComponent } from './components/payment-billing/components/cancel-subscription/cancel-subscription.component';
import { PaypalIntegrationDialogComponent } from './component-pieces/paypal-integration-dialog/paypal-integration-dialog.component';
import { SetupEcommerceIntegrationComponent } from './component-pieces/setup-ecommerce-integration/setup-ecommerce-integration.component';
import { PixelIntegrationsComponent } from './component-pieces/pixel-integrations/pixel-integrations.component';
import { SmtpSetupDialogComponent } from './smtp-setup-dialog/smtp-setup-dialog.component';
import { SubscriptionCancellationWarningComponent } from './components/payment-billing/components/subscription-cancellation-warning/subscription-cancellation-warning.component';
import { SubscriptionDowngradeWarningComponent } from './components/payment-billing/components/subscription-downgrade-warning/subscription-downgrade-warning.component';
import { SubscriptionCancelledWarning as SubscriptionCancelledWarningComponent } from './components/payment-billing/components/subscription-cancelled/subscription-cancelled.component';

@NgModule({
	imports: [
		ReactiveFormsModule,
		SharedModule,
		RouterModule,
		UserSprintRoutingModule,
		TranslateModule,
		FormsModule,
		MatButtonModule,
		MatDialogModule

		// NewSignupModule
	],

	declarations: [
		SubscriptionCancellationWarningComponent,
		SubscriptionCancelledWarningComponent,
		SubscriptionDowngradeWarningComponent,
		UserHeaderComponent,
		DatatablePaginationComponent,
		VerifyAddUserComponent,
		CompleteModalComponent,
		FailedPaymentNotificationComponent,
		PaymentCardsComponent,
		AddCardComponent,
		DownloadInvoiceComponent,
		MyProfileComponent,
		SecurityComponent,
		SadComponent,
		PausingAccountComponent,
		PausingAccountExtraComponent,
		AppreciationComponent,
		OfferAcceptedComponent,
		SadFinalComponent,
		UserActivityComponent,
		ReactivateAccountComponent,
		TwoFactorAuthenticationComponent,
		SetupComponent,
		EditSetupComponent,
		OptComponent,
		PaymentComponent,
		UserPermissionComponent,
		SelectItemComponent,
		InvoiceDialogComponent,
		ConfirmAnotherCardComponent,
		InputModalComponent,
		PayDialogComponent,
		SettingsConComponent,
		BillingPlansComponent,
		UserTeamsComponent,
		AddNewTeamComponent,
		TwoFAComponent,
		EmailIntegrationsComponent,
		EmailIntegrationsDialogComponent,
		EmailIntegrationsLoadingComponent,
		GoogleDisclosureComponent,
		CustomPasswordFieldComponent,
		ManagePlanComponent,
		InputCancellationReasonDialogComponent,
		PaymentIntegrationsComponent,
		CancelSubscriptionComponent,
		PaypalIntegrationDialogComponent,
		SetupEcommerceIntegrationComponent,
		PixelIntegrationsComponent,
		SmtpSetupDialogComponent
	],
	exports: [
		SelectItemComponent,
		UserHeaderComponent,
		PaymentComponent,
		PausingAccountComponent,
		SubscriptionCancellationWarningComponent
	],
	providers: [
		PermissionsResolver,
		FiledUsersResolver,
		UpdateUserResolver,
		FiledCardsResolver,
		AccountResolver,
		BillingAddressResolver,
		InterceptorsProvider
	]
})
export class UserSprintModule {}
