import { PaginationQuery } from '../models';

/**
 * Example:
 *
 * ```ts
 * 	const url = smiUrl()
 * 		.add('campaigns')
 * 		.add(campaignId)
 * 		.pagination()
 * 		.done()
 * ```
 */
export class UrlBuilder {
	url: string;
	hadQuery = false;

	constructor(baseUrl: string) {
		this.url = baseUrl;
	}

	add(path: string | number): UrlBuilder {
		this.url += '/' + String(path);
		return this;
	}

	query(key: string, value: string | number | undefined) {
		if (value) {
			this.querySeparator();
			this.url += `${key}=${String(value)}`;
		}
		return this;
	}

	querySeparator() {
		this.url += this.hadQuery ? '&' : '?';
		this.hadQuery = true;
		return this;
	}

	public trailingSlash() {
		if (this.url.endsWith('/')) {
			return this;
		}
		this.url += '/';
		return this;
	}
	/**
	 * this function expects 0-based pages
	 *
	 * server expects 1-based page numbers
	 */
	pagination(pagination?: PaginationQuery): UrlBuilder {
		if (pagination) {
			this.query('page_size', pagination.size).query(
				'page_number',
				pagination.page + 1
			);
		}

		return this;
	}

	done(): string {
		return this.url;
	}
}
