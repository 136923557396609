import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CustomTextInputTypeEnum } from '../models/custom-text-input.model';

@Component({
	selector: 'app-custom-text-input',
	templateUrl: './custom-text-input.component.html',
	styleUrls: ['./custom-text-input.component.scss']
})
export class CustomTextInputComponent implements OnInit {
	@Input() inputLabel: string = '';
	@Input() inputType: CustomTextInputTypeEnum = CustomTextInputTypeEnum.TEXT;
	@Input() markRequired: boolean = false;
	@Input() showCloseIcon: boolean = false;
	@Input() showCloseIconOnlyOnHover: boolean = false;
	@Input() inputControl: UntypedFormControl = new UntypedFormControl(null, [
		Validators.min(444)
	]);
	@Input() hideNumberArrows: boolean = false;
	@Output() onEnterKeyPressed: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCloseClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() input: EventEmitter<Event> = new EventEmitter<Event>();
	@Output() paste: EventEmitter<ClipboardEvent> =
		new EventEmitter<ClipboardEvent>();

	public isPasswordVisible: boolean = false;
	public CustomTextInputTypeEnum = CustomTextInputTypeEnum;

	constructor() {}

	ngOnInit(): void {}

	public togglePasswordVisibility(): void {
		this.isPasswordVisible = !this.isPasswordVisible;
	}

	public onEnterKeyPressEvent(): void {
		this.onEnterKeyPressed.emit();
	}

	public onCloseBtnClick(): void {
		this.onCloseClick.emit();
	}

	public incrementNumber(): void {
		let value = this.inputControl.value;
		value = value == null ? 0 : value;
		this.inputControl.patchValue(value + 1);
	}

	public decrementNumber(): void {
		let value = this.inputControl.value;
		value = value == null ? 0 : value;
		const newValue = value > 0 ? value - 1 : 0;
		this.inputControl.patchValue(newValue);
	}

	public handleKeyDown(event: KeyboardEvent) {
		if (event.key === 'ArrowUp') {
			event.preventDefault();
			this.incrementNumber();
		} else if (event.key === 'ArrowDown') {
			event.preventDefault();
			this.decrementNumber();
		}
	}
}
