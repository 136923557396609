import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	// imports: [RouterModule.forChild(routes), StripeModule.forRoot()],
	exports: [RouterModule]
})
export class UserManagementRoutingModule {}
