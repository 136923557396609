<div class="card p-1">
	<a (click)="navigate(details.link)" style="text-decoration: none !important;" class="cursor-pointer h-100 w-100">
		<div class="card-body">
			<img class="details-img" [src]="details.imageLink" />
			<div class="card-label p-1">
				<h5 class="details-title">{{ details.title }}</h5>
				<h6 class="details-text">{{ details.text }}</h6>
			</div>
		</div>
	</a>
</div>
