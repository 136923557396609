<generic-dropdown>
	<div class="dropdown-title" selectedValue>
		<img class="media-icon" src="{{ selectedItem.url }}" />
		<div>{{ selectedItem.label }}</div>
	</div>

	<div
		class="dropdown-option dropdown-item"
		option
		*ngFor="let item of data"
		(click)="select($event, item)"
	>
		<img src="{{ item.url }}" class="media-icon" />
		<div>{{ item.label }}</div>
	</div>
</generic-dropdown>
