import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import {
	DiscoveryListCreatorsResponseModel,
	DiscoveryListOwnerModel,
	UnlockBulkEmailResponse
} from 'src/app/shared-components/models/discovery/discovery-list.model';
import { UploadedListDataModel } from 'src/app/shared-components/models/discovery/discovery-uploaded-list-data.model';
import { AddCreatorToCampaignEmailData } from '../component-pieces/add-creator-to-campaign-dialog/add-creator-to-campaign-dialog.component';
import { DiscoveryCreatorApiModel } from '../models/discovery-creator.model';
import { DiscoveryCreditsResponseModel } from '../models/discovery-credits.model';
import {
	DiscoveryFilterModel,
	DiscoveryListsStateModel,
	DiscoveryStateModel
} from '../models/discovery-filter.model';
import { DiscoveryConstants } from '../models/discovery-filters-constants.model';

@Injectable({
	providedIn: 'root'
})
export class DiscoveryService {
	private _filterSubject: BehaviorSubject<DiscoveryFilterModel> =
		new BehaviorSubject<DiscoveryFilterModel>({
			selectedPlatform: 'Instagram'
		});
	public discoveryState: DiscoveryStateModel = {
		discoveryCreatorsPageNumber: 0,
		pageSize: 25,
		discoverySelectedCreatorIds: [],
		bulkSelectCount: 0,
		selectedView: 'table'
	};

	public discoveryListsState: DiscoveryListsStateModel = {
		pageNumber: 0,
		pageSize: 10,
		channel: [],
		listOwners: [],
		sortBy: {
			sort_by_last_update: -1
		}
	};

	constructor(private http: HttpClient) {}

	public updateFilter(model: DiscoveryFilterModel) {
		const selectedPlatform = model.selectedPlatform ?? 'Instagram';
		this._filterSubject.next({ ...model, selectedPlatform });
	}

	public clearFilter() {
		this.updateFilter({});
	}

	get filterSubject(): Observable<DiscoveryFilterModel> {
		return this._filterSubject;
	}

	get filters() {
		return this._filterSubject.value;
	}

	public clearState(): void {
		this.discoveryState = {
			discoveryCreatorsPageNumber: 0,
			pageSize: 25,
			discoverySelectedCreatorIds: [],
			bulkSelectCount: 0,
			selectedView: 'table'
		};
		this.discoveryListsState = {
			pageNumber: 0,
			pageSize: 10,
			channel: [],
			listOwners: [],
			sortBy: {}
		};
		this.updateFilter({ selectedPlatform: 'Instagram' });
	}

	//************************ API(s) FOR DISCOVERY ************************//
	public getDiscoveryCreatorCount(queryParams?: HttpParams): Observable<any> {
		const urlString = `${BaseApiUrl.SocialMessengerPython}profiles/searchable-count`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href, { params: queryParams });
	}

	public getDiscoveryCreators(
		queryParams?: HttpParams
	): Observable<DiscoveryCreatorApiModel> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}profiles/`);
		return this.http.get<DiscoveryCreatorApiModel>(url.href, {
			params: queryParams
		});
	}

	public getAllCampaigns(
		pageNumber: number,
		pageSize: number
	): Observable<any> {
		return this.http.get<any>(
			`${BaseApiUrl.SocialMessengerPython}campaigns?page_number=${pageNumber}&page_size=${pageSize}`
		);
	}

	public getAllJobs(queryParams: HttpParams): Observable<any> {
		return this.http.get<any>(
			`${BaseApiUrl.SocialMessengerPython}campaigns/jobs`,
			{ params: queryParams }
		);
	}

	public getEmailState(id): Observable<any> {
		return this.http.get(
			`${BaseApiUrl.SocialMessengerPython}profiles/creators/available?discovery_id=${id}`
		);
	}

	public sendJobToList(jobIds: number[], listId: number): Observable<any> {
		let payload = { job_ids: jobIds };

		const url = new URL(
			`${BaseApiUrl.SocialMessengerPython}applications/list/${listId}`
		);
		return this.http.post<any>(url.href, payload);
	}

	public sendJobsToCreators(
		jobIds: number[],
		influencerIds: number[],
		bulkSelectCount?: number,
		filterString?: string,
		emailData?: AddCreatorToCampaignEmailData
	): Observable<any> {
		const payload = {
			discovery_ids: influencerIds,
			job_ids: jobIds,
			type: 'individual'
		};
		if (bulkSelectCount) {
			payload['size'] = bulkSelectCount;
			payload['type'] = 'bulk';
		}
		if (filterString) {
			payload['filter'] = filterString;
		}
		if (emailData) {
			payload['email_data'] = emailData;
		}
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}applications`);
		return this.http.post<any>(url.href, payload);
	}

	public getSubCategories(categories: any[]): Observable<any> {
		let url: URL = new URL(
			`${BaseApiUrl.SocialMessengerPython}discovery/subcategories`
		);
		categories.forEach(x => {
			url.searchParams.append('category', x.name);
		});
		return this.http.get<any>(url.href);
	}

	public getHashtags(inputStr?: string): Observable<any> {
		let url: URL = new URL(
			`${BaseApiUrl.SocialMessengerPython}discovery/hashtags`
		);
		if (inputStr) {
			url.searchParams.append('query', inputStr);
		}
		return this.http.get<any>(url.href);
	}

	public advancedSearch(payload): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/advance-search`;
		const url = new URL(urlString);

		return this.http.post<any>(url.href, payload);
	}

	//************************ API(s) FOR DISCOVERY LISTS SECTION ************************//

	public getListOwners(): Observable<{ data: DiscoveryListOwnerModel[] }> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/owners`;
		const url = new URL(urlString);

		return this.http.get<{ data: DiscoveryListOwnerModel[] }>(url.href);
	}

	public createNewList(
		name: string,
		influencerIds: (string | number)[],
		bulkSelectCount?: number,
		filterString?: string
	): Observable<any> {
		const url = `${BaseApiUrl.SocialMessengerPython}lists`;
		let postBody = { name: name, discovery_ids: influencerIds };

		if (bulkSelectCount) {
			postBody['size'] = bulkSelectCount;
		}
		if (filterString) {
			postBody['filter'] = filterString;
		}
		return this.http.post<any>(url, postBody);
	}

	public editListName(
		listId: number,
		name: string,
		description: string
	): Observable<any> {
		const payload = {
			name: name,
			description: description
		};
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}`;
		const url = new URL(urlString);

		return this.http.patch<any>(url.href, payload);
	}

	public deleteList(listId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}`;
		const url = new URL(urlString);

		return this.http.delete<any>(url.href);
	}

	public duplicateList(listId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}`;
		const url = new URL(urlString);

		return this.http.put<any>(url.href, {});
	}

	public getListCreators(
		listId: number,
		queryParams: HttpParams
	): Observable<DiscoveryListCreatorsResponseModel> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}/creators`;
		const url = new URL(urlString);

		return this.http.get<DiscoveryListCreatorsResponseModel>(url.href, {
			params: queryParams
		});
	}

	public unlockEmail(creatorId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/creators/${creatorId}?fields=email`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public getUserTags() {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/tags`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public createTag(payload): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/tags`;
		const url = new URL(urlString);

		return this.http.post(url.href, payload);
	}

	public assignTag(payload): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/tags/assign`;
		const url = new URL(urlString);

		return this.http.post(url.href, payload);
	}

	public deleteTags(tag_id: number, payload) {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/tags/${tag_id}`;
		const url = new URL(urlString);

		return this.http.delete(url.href, payload);
	}

	public bulkUnlockEmail(payload): Observable<UnlockBulkEmailResponse> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/creators/emails`;
		const url = new URL(urlString);

		return this.http.post<UnlockBulkEmailResponse>(url.href, payload);
	}

	public getAllCreatorNames(listId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}lists/${listId}/usernames`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public exportCreators(payload): Observable<Blob> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/export`;
		const url = new URL(urlString);

		return this.http.post(url.href, payload, { responseType: 'blob' });
	}

	public deleteCreators(listId: number, payload): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/${listId}/creators`;
		const url = new URL(urlString);

		return this.http.post<any>(url.href, payload);
	}

	public getDemoFile(): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists/file/sample.csv`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public validateCampName(name: string): Observable<any> {
		return this.http.get<any>(
			`${BaseApiUrl.SocialMessengerPython}campaigns/exists?name=${name}`
		);
	}

	//************************ API(s) FOR DISCOVERY IMPORT INFLUENCERS SECTION ************************//
	public getCredits(): Observable<DiscoveryCreditsResponseModel> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}credits`;
		const url = new URL(urlString);

		return this.http.get<DiscoveryCreditsResponseModel>(url.href);
	}

	public uploadFile(formData: FormData): Observable<UploadedListDataModel> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists`;
		const url = new URL(urlString);

		return this.http.put<UploadedListDataModel>(url.href, formData);
	}

	//************************ API(s) FOR DISCOVERY CREATORS SECTION ************************//
	public getSocial(id, platform): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/metrics?discovery_id=${id}&platform=${platform}`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public getEmailUnlockStatus(id): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/creators/${id}?fields=email`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public getSimilarProfiles(id: any, platform: string): Observable<any> {
		const url = new URL(
			`${BaseApiUrl.SocialMessengerPython}discovery/profile/similar?discovery_id=${id}&platform=${platform}`
		);

		return this.http.get<any>(url.href);
	}

	private objToQueryString(obj: any) {
		if (typeof obj !== 'object' || Array.isArray(obj)) {
			throw new Error('Input must be an object');
		}

		let queryString = '';
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				if (value !== null && value !== undefined) {
					if (Array.isArray(value)) {
						queryString += value
							.map(
								v =>
									`${encodeURIComponent(
										key
									)}=${encodeURIComponent(v)}`
							)
							.join('&');
					} else {
						queryString += `${encodeURIComponent(
							key
						)}=${encodeURIComponent(value)}`;
					}
					queryString += '&';
				}
			}
		}

		if (queryString) queryString = '?' + queryString;

		return queryString.slice(0, -1); // Remove the last '&' character
	}

	public getSimilarProfilesByCat(cat): Observable<any> {
		const data = {
			sortByFollowers: -1,
			hashtags: cat
		};
		const queryString = this.objToQueryString(data);

		let urlString = `${BaseApiUrl.SocialMessengerPython}profiles/${queryString}`;
		const url = new URL(urlString);

		return this.http.get<any>(url.href);
	}

	public getQueryParams(filters: DiscoveryFilterModel): HttpParams {
		let queryParams: HttpParams = new HttpParams();
		for (const [key, value] of Object.entries(filters)) {
			if (!value) continue;
			switch (key) {
				case 'name':
					if (value?.trim()?.length > 1)
						queryParams = queryParams.append(key, value?.trim());
					break;
				case 'languages':
					const languagesObjArr = value as any[];
					languagesObjArr.forEach(x => {
						queryParams = queryParams.append(key, x.id);
					});
					break;
				case 'locations':
					const locationsObjArr = value as any[];
					locationsObjArr.forEach(x => {
						queryParams = queryParams.append(key, x.id);
					});
					break;
				case 'categories':
					const categoriesObjArr = value as any[];
					categoriesObjArr.forEach(x => {
						queryParams = queryParams.append(key, x.value);
					});
					break;
				case 'subcategories':
					const subcategoriesObjArr = value as any[];
					subcategoriesObjArr.forEach(x => {
						queryParams = queryParams.append(key, x);
					});
					break;
				case 'ig_account_type_business':
					queryParams = queryParams.append('ig_account_type', 0);
					break;
				case 'ig_account_type_creator':
					queryParams = queryParams.append('ig_account_type', 1);
					break;
				case 'ig_is_verified':
					queryParams = queryParams.append('ig_is_verified', 1);
					break;
				case 'ig_au_interests':
					const igAuInterestsObjArr = value as any[];
					igAuInterestsObjArr.forEach(x => {
						queryParams = queryParams.append(key, x.name);
					});
					break;
				case 'ig_au_fake_followers':
					queryParams = queryParams.append(key, value.value);
					break;

				//************** SORT FILTERS **************//
				case 'sortByFollowers':
					queryParams = queryParams.append(key, value);
					break;
				case 'sortByEngagementRate':
					queryParams = queryParams.append(key, value);
					break;

				case 'selectedPlatform':
					//***************************************************************/
					//** FILTERS WHICH ARE EITHER COMMON PLATFORM KEYS OR IN PAIR **//
					//***************************************************************/

					//************** FOR ONLY INSTAGRAM **************//
					if (filters?.ig_min_avg_reels_play) {
						queryParams = queryParams.append(
							'ig_min_avg_reels_play',
							filters?.ig_min_avg_reels_play?.name
						);
					}
					if (filters?.ig_max_avg_reels_play) {
						queryParams = queryParams.append(
							'ig_max_avg_reels_play',
							filters?.ig_max_avg_reels_play?.name
						);
					}
					//************** FOR ONLY TIKTOK **************//
					if (filters?.tk_min_engagement_per_post) {
						queryParams = queryParams.append(
							'tk_min_engagement_per_post',
							filters?.tk_min_engagement_per_post?.value?.toString()
						);
					}
					if (filters?.tk_max_engagement_per_post) {
						queryParams = queryParams.append(
							'tk_max_engagement_per_post',
							filters?.tk_max_engagement_per_post?.value?.toString()
						);
					}
					//************** FOR ONLY YOUTUBE **************//
					if (filters?.yt_min_avg_views_per_post) {
						queryParams = queryParams.append(
							'yt_min_avg_views_per_post',
							filters?.yt_min_avg_views_per_post?.value?.toString()
						);
					}
					if (filters?.yt_max_avg_views_per_post) {
						queryParams = queryParams.append(
							'yt_max_avg_views_per_post',
							filters?.yt_max_avg_views_per_post?.value?.toString()
						);
					}
					//********** COMMON FOR ALL PLATFORMS *********//
					const platformKey =
						filters.selectedPlatform === 'Instagram'
							? 'ig_'
							: filters.selectedPlatform === 'TikTok'
							? 'tk_'
							: 'yt_';

					// COMMON MANDATORY PLATFORM ALWAYS TO BE ADDED
					queryParams = queryParams.append(
						'platform',
						filters.selectedPlatform.toLowerCase()
					);

					// COMMON ENGAGEMENT RATE
					if (filters?.min_followers) {
						queryParams = queryParams.append(
							platformKey + 'min_followers',
							filters?.min_followers?.value?.toString()
						);
					}
					if (filters?.max_followers) {
						queryParams = queryParams.append(
							platformKey + 'max_followers',
							filters?.max_followers?.value?.toString()
						);
					}

					// COMMON ENGAGEMENT RATE
					if (filters?.min_engagement_rate) {
						queryParams = queryParams.append(
							platformKey + 'min_engagement_rate',
							filters?.min_engagement_rate?.value?.toString()
						);
					}
					if (filters?.max_engagement_rate) {
						queryParams = queryParams.append(
							platformKey + 'max_engagement_rate',
							filters?.max_engagement_rate?.value?.toString()
						);
					}

					// COMMON AGE RANGE
					if (filters?.age_min) {
						queryParams = queryParams.append(
							'age_min',
							filters?.age_min?.name
						);
					}
					if (filters?.age_max) {
						queryParams = queryParams.append(
							'age_max',
							filters?.age_max?.name
						);
					}

					// COMMON GENDER
					if (filters?.gender) {
						queryParams = queryParams.append(
							'gender',
							filters?.gender?.name?.toLowerCase()
						);
					}

					// COMMON LAST POST
					if (filters?.last_post) {
						queryParams = queryParams.append(
							'last_post',
							filters?.last_post?.name
						);
					}

					// COMMON GROWTH RATE
					if (filters?.growth_rate || filters?.growth_rate_percent) {
						queryParams = queryParams.append(
							'growth_rate',
							filters?.growth_rate?.value ??
								DiscoveryConstants.MIN_GROWTH_RATE_INTERVAL
						);
						queryParams = queryParams.append(
							'growth_rate_percent',
							filters?.growth_rate_percent?.value ??
								DiscoveryConstants.MIN_GROWTH_RATE_PERCENT
						);
					}

					// COMMON HASHTAGS
					if (filters?.hashtags) {
						const hashtagsObjArr = filters?.hashtags as string[];
						hashtagsObjArr.forEach(x => {
							queryParams = queryParams.append('hashtags', x);
						});
					}

					// COMMON BIO/KEYWORDS
					if (filters?.bio_keywords) {
						const bioKeywordsObjArr =
							filters?.bio_keywords as string[];
						bioKeywordsObjArr.forEach(x => {
							queryParams = queryParams.append(
								platformKey + 'bio_keywords',
								x
							);
						});
					}

					// COMMON CONTACT INFORMATION
					if (filters?.contact_information) {
						const contactsObjArr =
							filters?.contact_information as any[];
						contactsObjArr.forEach(x => {
							queryParams = queryParams.append(
								'contact_information',
								x.name
							);
						});
					}

					// COMMON HAS EMAIL
					if (filters?.hasEmail === true) {
						queryParams = queryParams.append('hasEmail', true);
					}

					if (filters?.lolly_verified === true) {
						queryParams = queryParams.append(
							'lolly_verified',
							true
						);
					}

					//* COMMON AUDIENCE FILTERS *//

					// COMMON AUDIENCE AGE
					if (filters?.au_age_min) {
						queryParams = queryParams.append(
							platformKey + 'au_age_min',
							filters?.au_age_min?.name
						);
					}
					if (filters?.au_age_max) {
						queryParams = queryParams.append(
							platformKey + 'au_age_max',
							filters?.au_age_max?.name
						);
					}

					// COMMON AUDIENCE GENDER
					if (filters?.au_gender) {
						queryParams = queryParams.append(
							platformKey + 'au_gender',
							filters?.au_gender?.name?.toLowerCase()
						);
					}

					// COMMON AUDIENCE LOCATION
					if (filters?.au_location) {
						const auLocObjArr = filters?.au_location as any[];
						auLocObjArr.forEach(x => {
							queryParams = queryParams.append(
								platformKey + 'au_location',
								x.id
							);
						});
					}

					// COMMON AUDIENCE LANGUAGE
					if (filters?.au_language) {
						const auLangObjArr = filters?.au_language as any[];
						auLangObjArr.forEach(x => {
							queryParams = queryParams.append(
								platformKey + 'au_language',
								x.id
							);
						});
					}

					break;
			}
		}

		return queryParams;
	}
}
