import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'app-cancel-subscription',
	templateUrl: './cancel-subscription.component.html',
	styleUrls: ['./cancel-subscription.component.scss']
})
export class CancelSubscriptionComponent implements OnInit {
	@Input() public form: UntypedFormGroup;
	@Output() public oneDollarClick = new EventEmitter<void>();

	public reasons: any[] = [
		{
			id: 1,
			name: 'Found a better alternative',
			value: 1
		},
		{
			id: 2,
			name: 'Cost-related issues',
			value: 2
		},
		{
			id: 3,
			name: 'Not satisfied with service',
			value: 3
		},
		{
			id: 4,
			name: 'No longer needed',
			value: 4
		},
		{
			id: 5,
			name: 'Technical Issues',
			value: 5
		},
		{
			id: 6,
			name: 'Other',
			value: 6
		}
	];

	public booleanList: any[] = [
		{
			id: 1,
			name: 'Yes',
			value: 1
		},
		{
			id: 2,
			name: 'No',
			value: 2
		}
	];

	constructor() {}

	ngOnInit(): void {}

	public stayForOneDollar(): void {
		this.oneDollarClick.emit();
	}

	public updateStarCount(count: number): void {
		this.form.get('stars_count').patchValue(count);
	}
}
