import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CustomAutoFocusDirective } from '../../../directives/custom-auto-focus.directive';

@Component({
	selector: 'signup-input',
	template: `
		<div>
			<p *ngIf="label" class="label">{{ label }}</p>
			<div class="inp-con">
				<input
					*ngIf="!inputControl"
					class="influ-inp"
					appCustomAutoFocus
					[focus]="autofocus"
					[style.paddingInlineEnd]="type === 'password' ? '40px' : ''"
					type="{{ inpType }}"
					(keyup.enter)="triggerEnter()"
					[autocomplete]="'no-auto-complete'"
					[placeholder]="placeHolder"
					[attr.disabled]="disabled ? true : null"
				/>
				<input
					class="influ-inp"
					*ngIf="inputControl"
					[formControl]="inputControl"
					appCustomAutoFocus
					[focus]="autofocus"
					[style.borderColor]="
						!this.inputControl?.valid && this.inputControl?.dirty
							? '#DE5241'
							: ''
					"
					[style.paddingInlineEnd]="type === 'password' ? '40px' : ''"
					type="{{ inpType }}"
					(keyup.enter)="triggerEnter()"
					[autocomplete]="!autocomplete ? 'new-password' : 'on'"
					[placeholder]="placeHolder"
					[attr.disabled]="disabled ? true : null"
				/>
				<div class="icon-con" *ngIf="type === 'password'">
					<mat-icon
						(click)="inpType = 'password'"
						*ngIf="inpType === 'text'"
					>
						visibility
					</mat-icon>
					<mat-icon
						(click)="inpType = 'text'"
						*ngIf="inpType === 'password'"
					>
						visibility_off
					</mat-icon>
				</div>
				<div class="icon-con" *ngIf="type === 'search'">
					<mat-icon class="search">search</mat-icon>
				</div>
			</div>
			<p class="err" *ngIf="errorMsg">{{ errorMsg }}</p>
		</div>
	`,
	styleUrls: ['./influencer-input-field.component.scss'],
	imports: [
		ReactiveFormsModule,
		CustomAutoFocusDirective,
		CommonModule,
		MatIconModule
	],
	standalone: true
})
export class InfluencerInputFieldComponent implements OnInit {
	@Input('inputControl') public inputControl: FormControl = new FormControl();
	@Input('label') public label: string = '';
	@Input('errorMsg') public errorMsg?: string = '';
	@Input('placeholder') public placeHolder: string = '';
	@Input('type') public type: string = 'text';
	@Input('autofocus') public autofocus: boolean = false;
	@Input('disabled') public disabled: boolean = false;
	@Input() autocomplete = true;

	@Output('enterKeyup') public enterKeyup = new EventEmitter<void>();

	public inpType = 'text';

	constructor() {}

	ngOnInit(): void {
		if (this.type === 'password') {
			this.inpType = 'password';
		} else if (this.type === 'number') {
			this.inpType = 'number';
		} else {
			this.inpType = 'text';
		}
	}

	public triggerEnter() {
		this.enterKeyup.emit();
	}
}
