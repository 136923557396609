export const objToQueryString = (obj: Record<string, string>) => {
	if (typeof obj !== 'object' || Array.isArray(obj)) {
		throw new Error('Input must be an object');
	}

	let queryString = '';
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const value = obj[key];
			if (value !== null && value !== undefined) {
				if (Array.isArray(value)) {
					queryString += value
						.map(
							v =>
								`${encodeURIComponent(
									key
								)}=${encodeURIComponent(v)}`
						)
						.join('&');
				} else {
					queryString += `${encodeURIComponent(
						key
					)}=${encodeURIComponent(value)}`;
				}
				queryString += '&';
			}
		}
	}

	if (queryString) queryString = '?' + queryString;

	return queryString.slice(0, -1); // Remove the last '&' character
};
