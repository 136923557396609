import { Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RowComponent, ColumnComponent } from 'components';
import { BookingService } from '../../../../../../../src/app/services/booking.service';
import { PlanComponent, PlanDescription } from './plan';

const plans: PlanDescription[] = [
	{
		name: 'Lite',
		description:
			'Essential features for a quick influencer marketing start',
		price: '$149',
		period: 'mo',
		buttonLink: '',
		buttonText: 'Get offer',
		advantages: [
			'50 Searches',
			'100 Detailed Profiles',
			'100 Email IDs',
			'Unlimited Influencers Export',
			'1 Mail Account',
			'1 User'
		]
	},
	{
		name: 'Starter',
		description: 'Core tools for influencer marketing success',
		price: '$199',
		period: 'mo',
		buttonText: 'Choose Plan',
		buttonLink: '',
		advantages: [
			'Unlimited Searches',
			'400 Detailed Profiles',
			'250 Email IDs',
			'Unlimited Influencers Export',
			'1 Mail Account',
			'1 User'
		]
	}
];

@Component({
	selector: 'plan-comparison',
	template: `
		<row class="close-row">
			<mat-icon (click)="close.emit()">close</mat-icon>
		</row>

		<column [style.gap]="'32px'">
			<column [style.gap]="4">
				<div class="title">Grab Your Exclusive Offer</div>
				<div class="description">
					Launch your influencer campaigns instantly at special rates
				</div>
			</column>
			<row [left]="true" [style.gap]="'24px'">
				<plan
					[plan]="plans[0]"
					(offerClick)="onLiteOfferClick()"
				></plan>
				<plan
					[plan]="plans[1]"
					class="starter"
					(offerClick)="onStarterOfferClick()"
				></plan>
			</row>
		</column>
	`,
	styles: [
		`
			@use 'common';
			:host {
				@include common.column;
				gap: 0;
				justify-content: center;
				align-items: center;
			}

			.close-row {
				@include common.clickable;
				width: 100%;
				margin-right: -32px;
				justify-content: end;
			}

			.starter {
				background: linear-gradient(180deg, #2872e1 0%, #695cbe 100%);
				border: none;
				color: white;
			}

			.title {
				color: #2a2a2a;
				text-align: center;

				font-size: 40px;

				font-weight: 700;
				line-height: 44px; /* 110% */
			}

			.description {
				color: #2a2a2a;
				text-align: center;
				font-size: 20px;

				font-weight: 500;
				line-height: 26px; /* 130% */
			}

			:host::ng-deep {
				.starter {
					color: white;
					.period {
						color: #ffffff99;
					}

					.button-text {
						color: #2872e1;
					}

					.button {
						background: white;
					}

					.icon-container {
						background: #2872e1;
					}

					.button-icon {
						color: white;
					}
					dot {
						background: white;
					}
				}
			}
		`
	],
	imports: [RowComponent, PlanComponent, ColumnComponent, MatIconModule],
	standalone: true
})
export class PlanComparisonComponent {
	plans = plans;

	@Output() close = new EventEmitter<void>();

	constructor(private booking: BookingService) {}

	onLiteOfferClick() {
		this.booking.redirectStripeApi('lite');
	}

	onStarterOfferClick() {
		this.booking.redirectStripeApi('starter_monthly');
	}
}
