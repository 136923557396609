import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRoleBusinessGuardService } from 'src/app/shared/guards/user-role-business-guard.service';
import { UserRoleClientGuard } from 'src/app/shared/guards/user-role-client-guard.service';
import { EmailIntegrationsComponent } from './components/email-integrations/email-integrations.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { AddCardComponent } from './components/payment-billing/components/add-card/add-card.component';
import { AppreciationComponent } from './components/security/components/appreciation/appreciation.component';
import { OfferAcceptedComponent } from './components/security/components/offer-accepted/offer-accepted.component';
import { PausingAccountExtraComponent } from './components/security/components/pausing-account-extra/pausing-account-extra.component';
import { PausingAccountComponent } from './components/security/components/pausing-account/pausing-account.component';
import { ReactivateAccountComponent } from './components/security/components/reactivate-account/reactivate-account.component';
import { SadFinalComponent } from './components/security/components/sad-final/sad-final.component';
import { SadComponent } from './components/security/components/sad/sad.component';
import { EditSetupComponent } from './components/security/components/two-factor-authentication/components/edit-setup/edit-setup.component';
import { SetupComponent } from './components/security/components/two-factor-authentication/components/setup/setup.component';
import { TwoFactorAuthenticationComponent } from './components/security/components/two-factor-authentication/two-factor-authentication.component';
import { SecurityComponent } from './components/security/security.component';
import { SettingsConComponent } from './components/settings-con/settings-con.component';
import { UserTeamsComponent } from './components/user-teams/user-teams.component';
import { ManagePlanComponent } from './components/payment-billing/components/manage-plan/manage-plan.component';
import { BrandingPageComponent } from '../components/branding/branding-page.component';

const routes: Routes = [
	{
		path: '',
		component: SettingsConComponent,
		children: [
			{
				canActivate: [UserRoleClientGuard],
				path: 'teams',
				component: UserTeamsComponent
			},
			{
				canActivate: [UserRoleClientGuard],
				path: 'integrations',
				component: EmailIntegrationsComponent
			},
			{
				path: 'branding',
				component: BrandingPageComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'my-profile',
				component: MyProfileComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'manage-plan',
				component: ManagePlanComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'payment-and-billing/add-card',
				component: AddCardComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'payment-and-billing/edit-card',
				component: AddCardComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security',
				component: SecurityComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/sad-tosee-you-go',
				component: SadComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/pausing-account',
				component: PausingAccountComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/pausing-account-extra',
				component: PausingAccountExtraComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/appreciation',
				component: AppreciationComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/offer-accepted',
				component: OfferAcceptedComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/sad-final',
				component: SadFinalComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/reactivate-account',
				component: ReactivateAccountComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/enable-2-factor-authentication',
				component: TwoFactorAuthenticationComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/enable-2-factor-authentication/setup',
				component: SetupComponent
			},
			{
				canActivate: [UserRoleBusinessGuardService],
				path: 'security/enable-2-factor-authentication/edit-setup',
				component: EditSetupComponent
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class UserSprintRoutingModule {}
