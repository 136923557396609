<!-- !manage plan -->
<div class="container" *ngIf="step === 0">
	<div class="header">Plan overview</div>
	<div class="card main">
		<section class="card-header">
			<div class="wrapper">
				<div class="info">
					<!-- *skeleton loader -->
					<ngx-skeleton-loader
						appearance="line"
						style="height: 20px; width: 120px"
						[theme]="{ height: '20px', width: '120px' }"
						*ngIf="subscriptionLoading"
					></ngx-skeleton-loader>

					<span class="title" *ngIf="!subscriptionLoading">
						{{ subscriptionData?.name | titlecase }} ({{
							subscriptionData?.price_interval | titlecase
						}})
					</span>
					<span class="date">
						<!-- *skeleton loader -->
						<ngx-skeleton-loader
							appearance="line"
							style="height: 16px; width: 180px"
							[theme]="{ height: '16px', width: '180px' }"
							*ngIf="subscriptionLoading"
						></ngx-skeleton-loader>
						<subscription-cancelled
							*ngIf="
								!subscriptionLoading &&
								subscriptionData?.status === 'cancelled'
							"
						></subscription-cancelled>
						<div
							*ngIf="
								(!subscriptionLoading &&
									subscriptionData?.status === 'active') ||
								subscriptionData?.status === 'trialing'
							"
						>
							<ng-container
								*ngIf="
									!subscriptionLoading &&
									subscriptionData?.status === 'active'
								"
							>
								<img
									src="assets\crm\automation\Icon feather-calendar.svg"
									alt=""
									style="height: 12px; width: 12px"
								/>
								Next Billing Date -
								{{
									subscriptionData?.current_period_end | date
								}}
							</ng-container>
							<subscription-cancellation-warning
								[cancellationDate]="
									subscriptionData?.upcoming_change
										.change_date
								"
								(cancel)="cancelSubscriptionChange()"
								*ngIf="
									!subscriptionLoading &&
									subscriptionData?.upcoming_change
										?.change_type === 'cancellation'
								"
							></subscription-cancellation-warning>
							<subscription-downgrade-warning
								*ngIf="
									!subscriptionLoading &&
									subscriptionData?.upcoming_change
										?.change_type === 'downgrade'
								"
								(cancel)="cancelSubscriptionChange()"
								[planName]="
									subscriptionData?.upcoming_change
										.next_subscription_plan.title
								"
								[cancellationDate]="
									subscriptionData?.upcoming_change
										.change_date
								"
							></subscription-downgrade-warning>
						</div>

						<ng-container
							*ngIf="
								!subscriptionLoading &&
								(!subscriptionData ||
									subscriptionData?.status === 'cancelled')
							"
						>
							Subscription Cancelled
						</ng-container>
					</span>
				</div>
				<div class="d-flex k-align-items-center" style="gap: 20px">
					<div class="btn-closure tooltip">
						<button
							class="primary-btn"
							appRoleBasedControl
							[roleActionType]="'payment'"
							[disabled]="
								!isManageAllowed(subscriptionData?.role)
							"
							(click)="gotoStep(1)"
						>
							Choose Plan
						</button>
					</div>
				</div>
			</div>
		</section>
		<section
			class="card-body"
			*ngIf="
				subscriptionData?.status === 'active' ||
				subscriptionData?.status === 'trialing' ||
				loading
			"
		>
			<!-- !discovery -->
			<div class="section-row">
				<div class="section-col col-1">
					<span class="col-header">What's Included</span>
					<span class="col-title">Discovery</span>
				</div>
				<div class="section-col col-2">
					<span class="col-header">Usage</span>
					<div class="items">
						<!-- *item 1 -->
						<div class="item">
							<!-- *skeleton loader -->
							<ngx-skeleton-loader
								appearance="line"
								style="height: 20px; width: 100%"
								[theme]="{ height: '20px', width: '100%' }"
								*ngIf="loading"
							></ngx-skeleton-loader>

							<div class="info" *ngIf="!loading">
								<span class="name">
									{{ planData?.searches?.used || 0 }}
								</span>
								<span class="value">
									of
									{{
										planData?.searches?.total
											? planData?.searches?.total
											: maxOutString
									}}
									searches
									{{
										subscriptionData?.price_interval ==
										'month'
											? '/mo'
											: '/mo'
									}}
								</span>
							</div>
							<div class="progress-wrapper">
								<div class="progress">
									<div
										class="progress-done"
										[style]="
											'width: ' +
											getPercentage(
												planData?.searches?.used,
												planData?.searches?.total
											) +
											'%;'
										"
									></div>
								</div>
							</div>
						</div>

						<!-- *item 2 -->
						<div class="item">
							<!-- *skeleton loader -->
							<ngx-skeleton-loader
								appearance="line"
								style="height: 20px; width: 100%"
								[theme]="{ height: '20px', width: '100%' }"
								*ngIf="loading"
							></ngx-skeleton-loader>

							<div class="info" *ngIf="!loading">
								<span class="name">
									{{ planData?.views?.used || 0 }}
								</span>
								<span class="value">
									of
									{{
										planData?.views?.total
											? planData?.views?.total
											: maxOutString
									}}
									detailed profile
									{{
										subscriptionData?.price_interval ==
										'month'
											? '/mo'
											: '/mo'
									}}
								</span>
							</div>
							<div class="progress-wrapper">
								<div class="progress">
									<div
										class="progress-done"
										[style]="
											'width: ' +
											getPercentage(
												planData?.views?.used,
												planData?.views?.total
											) +
											'%;'
										"
									></div>
								</div>
							</div>
						</div>

						<!-- *item 3 -->
						<div class="item">
							<!-- *skeleton loader -->
							<ngx-skeleton-loader
								appearance="line"
								style="height: 20px; width: 100%"
								[theme]="{ height: '20px', width: '100%' }"
								*ngIf="loading"
							></ngx-skeleton-loader>

							<div class="info" *ngIf="!loading">
								<span class="name">
									{{ planData?.emails?.used || 0 }}
								</span>
								<span class="value">
									of
									{{
										planData?.emails?.total
											? planData?.emails?.total
											: maxOutString
									}}
									emails
									{{
										subscriptionData?.price_interval ==
										'month'
											? '/mo'
											: '/mo'
									}}
								</span>
							</div>
							<div class="progress-wrapper">
								<div class="progress">
									<div
										class="progress-done"
										[style]="
											'width: ' +
											getPercentage(
												planData?.emails?.used,
												planData?.emails?.total
											) +
											'%;'
										"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- !lists -->
			<div class="section-row">
				<div class="section-col col-1">
					<span class="col-title">Lists</span>
				</div>
				<div class="section-col col-2">
					<div class="items">
						<div class="item">
							<!-- *skeleton loader -->
							<ngx-skeleton-loader
								appearance="line"
								style="height: 20px; width: 100%"
								[theme]="{ height: '20px', width: '100%' }"
								*ngIf="loading"
							></ngx-skeleton-loader>

							<div class="info" *ngIf="!loading">
								<span class="name">
									{{ planData?.exports?.used || 0 }}
								</span>
								<span class="value">
									of
									{{
										planData?.exports?.total != null
											? planData?.exports?.total
											: maxOutString
									}}
									influencers exports
									{{
										subscriptionData?.price_interval ==
										'month'
											? '/mo'
											: '/mo'
									}}
								</span>
							</div>
							<div class="progress-wrapper">
								<div class="progress">
									<div
										class="progress-done"
										[style]="
											'width: ' +
											getPercentage(
												planData?.exports?.used,
												planData?.exports?.total
											) +
											'%;'
										"
									></div>
								</div>
							</div>
						</div>

						<!-- *item 2 -->
						<div class="item">
							<!-- *skeleton loader -->
							<ngx-skeleton-loader
								appearance="line"
								style="height: 20px; width: 100%"
								[theme]="{ height: '20px', width: '100%' }"
								*ngIf="loading"
							></ngx-skeleton-loader>

							<div class="info" *ngIf="!loading">
								<span class="name">
									{{ planData?.list_created?.used || 0 }}
								</span>
								<span class="value">
									of
									{{
										planData?.list_created?.total
											? planData?.list_created?.total
											: maxOutString
									}}
									created lists
								</span>
							</div>
							<div class="progress-wrapper">
								<div class="progress">
									<div
										class="progress-done"
										[style]="
											'width: ' +
											getPercentage(
												planData?.list_created?.used,
												planData?.list_created?.total
											) +
											'%;'
										"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- !CRM -->
			<div class="section-row">
				<div class="section-col col-1">
					<span class="col-title">CRM</span>
				</div>
				<div class="section-col col-2">
					<div class="items">
						<div class="item">
							<!-- *skeleton loader -->
							<ngx-skeleton-loader
								appearance="line"
								style="height: 20px; width: 100%"
								[theme]="{ height: '20px', width: '100%' }"
								*ngIf="loading"
							></ngx-skeleton-loader>

							<div class="info" *ngIf="!loading">
								<span class="name">
									{{ planData?.crm_email?.used || 0 }}
								</span>
								<span class="value">
									of
									{{
										planData?.crm_email?.total
											? planData?.crm_email?.total
											: maxOutString
									}}
									mail accounts
								</span>
							</div>
							<div class="progress-wrapper">
								<div class="progress">
									<div
										class="progress-done"
										[style]="
											'width: ' +
											getPercentage(
												planData?.crm_email?.used,
												planData?.crm_email?.total
											) +
											'%;'
										"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- !Users -->
			<div class="section-row">
				<div class="section-col col-1">
					<span class="col-title">Users</span>
				</div>
				<div class="section-col col-2">
					<div class="items">
						<div class="item">
							<!-- *skeleton loader -->
							<ngx-skeleton-loader
								appearance="line"
								style="height: 20px; width: 100%"
								[theme]="{ height: '20px', width: '100%' }"
								*ngIf="loading"
							></ngx-skeleton-loader>

							<div class="info" *ngIf="!loading">
								<span class="name">
									{{ planData?.users?.used || 0 }}
								</span>
								<span class="value">
									of
									{{
										planData?.users?.total != null
											? planData?.users?.total == 0
												? 1
												: planData?.users?.total
											: maxOutString
									}}
									users
								</span>
							</div>
							<div class="progress-wrapper">
								<div class="progress">
									<div
										class="progress-done"
										[style]="
											'width: ' +
											getPercentage(
												planData?.users?.used,
												planData?.users?.total
											) +
											'%;'
										"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- ! Subscription Footer -->
		<div
			*ngIf="!loading && isManageAllowed(subscriptionData?.role)"
			class="d-flex k-align-items-center w-100 k-justify-content-between"
			style="padding: 30px"
		>
			<button
				class="primary-btn {{
					subscriptionData?.status === 'trialing' ? '' : 'not-visible'
				}}"
				(click)="bookAppointment()"
			>
				Book a demo
			</button>

			<button
				*ngIf="canCancelSubscription()"
				appRoleBasedControl
				[roleActionType]="'cancel_subscription'"
				class="cancel-btn {{ subscriptionLoading ? 'disabled' : '' }}"
				(click)="gotoStep(2)"
				[disabled]="!subscriptionData"
			>
				Cancel Subscription
			</button>
		</div>
	</div>
</div>

<!-- !manage subscription -->
<div class="container bill" *ngIf="step === 1">
	<div class="header">
		<button class="nav-btn" (click)="gotoStep(0)">
			<img src="assets\discovery-v2\arrow-left.svg" alt="" />
		</button>

		<ng-container *ngIf="subscriptionData?.status == 'trialing'">
			<div class="d-flex flex-column" [style.gap]="'4px'">
				<span>Manage Subscription</span>
			</div>
		</ng-container>

		<ng-container *ngIf="subscriptionData?.status != 'trialing'">
			Manage Subscription
		</ng-container>
	</div>
	<div class="card main">
		<app-manage-subscription
			[isLoggedIn]="true"
			[buttonText]="
				subscriptionData?.status != 'active' ? 'Start Now' : null
			"
			[data]="subscriptionData"
		></app-manage-subscription>
	</div>
</div>

<!-- !cancel subscription -->
<div class="container bill" *ngIf="step === 2">
	<div class="header">
		<button class="nav-btn" (click)="gotoStep(0)">
			<img src="assets\discovery-v2\arrow-left.svg" alt="" />
		</button>

		Cancel Subscription
	</div>
	<div class="card main">
		<app-cancel-subscription
			[form]="formGroup"
			(oneDollarClick)="onOneDollar($event)"
		></app-cancel-subscription>

		<div
			class="d-flex w-100 k-align-items-center k-justify-content-center"
			[style.gap]="'24px'"
			[style.padding-top]="'24px'"
		>
			<button class="primary-btn" (click)="goBackToFirstStepAndReset()">
				Nevermind! Take me back to Lolly.
			</button>
			<button class="secondary-btn" (click)="cancelSubscription()">
				I want to cancel
			</button>
		</div>
	</div>
</div>
