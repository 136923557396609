import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PriorityToasterType } from '../models/priority-toaster-type-modified.enum';
import { PriorityToasterModel } from '../models/priority-toaster.model';

@Injectable({
	providedIn: 'root'
})
export class PriorityToasterService {
	private toastSubject = new Subject<PriorityToasterModel>();
	private dismissSubject = new Subject<void>();

	constructor() {}

	public sendCustomToast(
		message: string,
		type: PriorityToasterType,
		duration?: number,
		title?: string
	): void {
		const toast = new PriorityToasterModel(message, type, duration, title);
		this.toastSubject.next(toast);
	}

	public getToasts(): Observable<PriorityToasterModel> {
		return this.toastSubject.asObservable();
	}

	public getDismissSubject(): Observable<void> {
		return this.dismissSubject.asObservable();
	}

	public sendErrorToast(message: string, duration?: number): void {
		const toast = new PriorityToasterModel(
			message,
			PriorityToasterType.Error,
			duration
		);
		this.toastSubject.next(toast);
	}

	public sendCustomErrorToast(
		message: string,
		duration?: number,
		footerMessage?: string
	): void {
		const toast = new PriorityToasterModel(
			message,
			PriorityToasterType.Error,
			duration,
			PriorityToasterType.Error,
			footerMessage
		);
		this.toastSubject.next(toast);
	}

	public sendSuccessToast(message: string, duration?: number): void {
		const toast = new PriorityToasterModel(
			message,
			PriorityToasterType.Success,
			duration
		);
		this.toastSubject.next(toast);
	}

	public sendWarningToast(message: string, duration?: number): void {
		const toast = new PriorityToasterModel(
			message,
			PriorityToasterType.Warning,
			duration
		);
		this.toastSubject.next(toast);
	}

	public sendInfoToast(message: string, duration?: number): void {
		const toast = new PriorityToasterModel(
			message,
			PriorityToasterType.Info,
			duration
		);
		this.toastSubject.next(toast);
	}

	public dismiss(): void {
		this.dismissSubject.next();
	}
}
