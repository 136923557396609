<div class="wrapper">
	<div class="header">
		<div class="title">
			Almost there! Just need a tad more info!
		</div>
		<!-- <div class="desc">
			Discover the perfect Influencers for your brand
		</div> -->
	</div>
	<div class="main card">
		<!-- !step 2 -->
		<div class="step-1" *ngIf="step == 0">
			<div class="title">Company Details</div>
			<div class="form-wrapper">
				<form [formGroup]="userForm">
					<!-- *skeleton loader -->
					<ngx-skeleton-loader count="2" [theme]="{ height: '48px', width: '100%' }" *ngIf="loading"></ngx-skeleton-loader>

					<ng-container *ngIf="!loading">
						<app-website-input [inputControl]="userForm.get('company_website')"></app-website-input>

						<div class="custom-logo-input-wrapper" (click)="openAddImgFilesDialog()">
							<img class="uploaded-logo" *ngIf="userForm.get('company_logo')?.value" [src]="userForm.get('company_logo')?.value" />
							<span class="txt-logo" *ngIf="!userForm.get('company_logo')?.value" height="24px" width="24px">Logo (Optional)</span>
							<img class="upload-icon" src="assets/icons/upload-round-black.svg" />
						</div>
					</ng-container>
				</form>
				<div class="btn-wrapper">
					<button class="primary-btn" [disabled]="userForm.invalid" (click)="saveDetails()">
						<span *ngIf="!submitLoading">
							Next
						</span>
						<img src="assets\discovery-v2\profile\spinner.gif" alt="" class="loader" *ngIf="submitLoading" />
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<input #imgFileSelect (click)="$event.target.value = null" class="d-none" type="file" accept=".jpg, .jpeg, .png" />
