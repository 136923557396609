<div id="inner-container">
	<div class="grid-container-4">
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/impressions.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">Total Impressions</p>
				<p class="font-bold">{{ data.impressions }}</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/rate.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">Engagement Rate</p>
				<p class="font-bold">{{ data.er }}%</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/spend.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">Total Spend</p>
				<p class="font-bold">{{ data.spends_total }}</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/revenue.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">Total Revenue</p>
				<p class="font-bold">{{ data.total_revenue }}</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/commission.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">Creators Commission</p>
				<p class="font-bold">{{ data.commission }}</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/no-creators.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">No. of Creators</p>
				<p class="font-bold">{{ data.creators }}</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/roi.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">ROI</p>
				<p class="font-bold">{{ data.roi }} %</p>
			</div>
		</div>
		<div class="card-grid-item flex-align-center">
			<img src="assets/dashboard-icon/cpm.svg" class="card-icon" />
			<div class="card-details">
				<p class="font-medium">CPM</p>
				<p class="font-bold">{{ data.cpm }}</p>
			</div>
		</div>
	</div>
</div>
