<div class="forms">
	<div class="switch-main">
		<label class="label">
			Get a text when payments fail?
		</label>
		<div class="switch">
			<div>
				<label>{{ toggleCheckStatus ? 'YES' : 'NO' }}</label>
				<mat-slide-toggle [(ngModel)]="toggleCheckStatus" (change)="onCheckToggle()"></mat-slide-toggle>
			</div>
		</div>
		<div class="sub-title">
			If you turn this on, we'll only text you to let you about billing issues. We'll never call you or share your number.
		</div>
	</div>
	<div *ngIf="toggleCheckStatus">
		<div class="phone-input">
			<div class="title">phone number</div>
			<!-- <intl-tb
				(selectCountry)="onSelectCountry($event)"
				[control]="accountForm.get('phone')"
				[defaultFlagIcon]="defaultCountryCode"
				[validatorMessages]="validationMessages"
				[placeHolderTextBox]="'Enter your phone number'"
				class="input-box telephone"
			></intl-tb> -->
		</div>
		<div class="form-btn">
			<app-primary-button
				[buttonLable]="'EDIT'"
				[buttonType]="'Secondary'"
				[isPeddingReduce]="true"
				[isDisabled]="accountForm.invalid || !toggleCheckStatus"
				*ngIf="isFirstTime"
			></app-primary-button>
		</div>
		<div>
			<app-primary-button
				[buttonLable]="'CONFIRM'"
				[buttonType]="'Primary'"
				(buttonClicked)="onEnableConfirm()"
				[isPeddingReduce]="true"
				*ngIf="!isFirstTime"
				[isDisabled]="accountForm.invalid || !toggleCheckStatus"
			></app-primary-button>
		</div>
	</div>
</div>
