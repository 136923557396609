import { Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UiTextComponent } from 'components';
import { ColumnComponent, RowComponent } from 'components';
import { BannerAdvantage } from './banner-advantage';

@Component({
	selector: 'banner-right',
	template: `
		<row class="close-remove">
			<mat-icon (click)="close.emit()">close</mat-icon>
		</row>
		<column class="main-container">
			<column class="container">
				<div class="title">
					Your shortcut to better
					<br />
					influencer marketing
				</div>
				<div class="description">
					30 minutes, all the answers you need
				</div>
				<img src="assets/images/patrick.jpg" />
				<column [style.gap]="'8px'">
					<div class="expert-name">Patrick Joseph Quinn</div>
					<div class="expert-description">Your influencer expert</div>
				</column>

				<column class="upgrade-box">
					<div class="upgrade">
						Upgrade for instant access or consult expert
					</div>

					<row class="buttons">
						<button
							class="view-pricing"
							(click)="viewPricing.emit()"
						>
							View pricing
						</button>
						<button class="book-a-demo" (click)="bookDemo.emit()">
							Book a demo
						</button>
					</row>
				</column>
			</column>
			<column class="advantages">
				<row [left]="true" [style.gap]="'12px'">
					<banner-advantage
						title="Full platform overview"
						description="See how to find the best influencers"
					></banner-advantage>
					<banner-advantage
						title="Full platform overview"
						description="See how to find the best influencers"
					></banner-advantage>
				</row>
				<row [left]="true" [style.gap]="'12px'">
					<banner-advantage
						title="Full platform overview"
						description="See how to find the best influencers"
					></banner-advantage>
					<banner-advantage
						title="Full platform overview"
						description="See how to find the best influencers"
					></banner-advantage>
				</row>
			</column>
		</column>
	`,
	styles: [
		`
			@use 'colors' as *;
			@use 'common';
			:host {
				@include common.column;

				font-family: Gilroy;
				font-style: normal;
				height: 100%;
				gap: 16px;
				background: var(--Bg-gray, #f3f3f3);
			}

			button {
				pointer-events: all;
			}

			.close-remove {
				justify-content: end;
				margin-right: 24px;
				margin-top: 24px;
				cursor: pointer;
			}

			.close {
				width: 100%;
				justify-content: right;
			}

			img {
				border-radius: 50%;
				width: 100px;
				height: 100px;
				object-fit: cover;
				object-position: 50% 25%;
			}

			.container {
				align-items: center;
				gap: 20px;
				padding: 0px 60px 50px 60px;
			}

			.main-container {
				align-items: center;
				gap: 52px;
			}

			.title {
				color: var(--Text-purple-light, #a489e5);
				text-align: center;

				font-size: 32px;
				font-weight: 600;
				line-height: 40px;
			}

			.description {
				color: var(--Text-gray, #525252);
				text-align: center;

				/* Text default */
				font-family: Gilroy;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 133.333% */
			}

			.expert-name {
				color: var(--Text-black, #000);
				text-align: center;

				/* H2 */
				font-family: Gilroy;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px; /* 133.333% */
			}

			.upgrade-box {
				padding-top: 24px;
				align-items: center;
				border-top: 1px solid var(--Devider, #dcdcdc);
			}

			.expert-description {
				color: var(--Text-gray, #525252);
				text-align: center;

				/* Text default */
				font-family: Gilroy;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 133.333% */
			}

			.buttons {
				gap: 1rem;
				justify-content: start;
			}

			.upgrade {
				color: var(--Text-black, #000);
				text-align: center;

				/* H2 */
				font-family: Gilroy;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px; /* 133.333% */
			}

			.view-pricing {
				border-radius: 4px;
				border: 1px solid $text-primary-purple;
				background: $primary-white;

				//width: 150px;
				height: 52px;
				flex-wrap: nowrap;
				padding: 14px 24px;
				justify-content: center;
				align-items: center;
				gap: 4px;
				color: $text-primary-purple;
				text-align: center;

				/* Button */
				font-family: Gilroy;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 24px; /* 133.333% */
				cursor: pointer;
				min-width: 160px;
			}

			.book-a-demo {
				text-align: center;
				flex-wrap: nowrap;
				/* Button */
				font-family: Gilroy;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 24px;
				border: none;
				outline: none;
				height: 52px;
				color: $primary-white;
				display: flex;
				//width: 150px;
				padding: 14px 24px;
				justify-content: center;
				align-items: center;
				gap: 4px;
				border-radius: 4px;
				background: $primary-purple;
				cursor: pointer;
				min-width: 160px;
			}
		`
	],
	imports: [
		UiTextComponent,
		BannerAdvantage,
		RowComponent,
		ColumnComponent,
		MatIconModule
	],
	standalone: true
})
export class BannerRightPanel {
	@Output() bookDemo = new EventEmitter<void>();
	@Output() viewPricing = new EventEmitter<void>();
	@Output() close = new EventEmitter<void>();
}
