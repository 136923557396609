import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';
import { UserPaymentSubscriptionCodesEnum } from 'src/app/shared/models/plan-limits.model';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { LimitErrorsService } from '../../services/limit-errors.service';
import { HideGlobalSpinner } from '../../shared/state/shared.actions';
import { SharedState } from '../../shared/state/shared.reducer';
import { convertBlobToJsonError, isBlobError } from '../../shared/utils';

@Injectable({
	providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<SharedState>,
		private authV2Service: AuthenticationV2Service,
		private toast: ToastNotificationService,
		private limitErrorsService: LimitErrorsService
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				this.store.dispatch(new HideGlobalSpinner());

				if (error instanceof HttpErrorResponse) {
					if (isBlobError(error)) {
						convertBlobToJsonError(error)
							.then((newErr: HttpErrorResponse) => {
								return this.handleError(newErr);
							})
							.catch(err => {
								return this.handleError(err);
							});
					} else {
						return this.handleError(error);
					}
				} else {
					return throwError(error);
				}
			})
		);
	}

	private handleError(
		error: HttpErrorResponse | undefined
	): Observable<HttpEvent<any>> {
		const status: number = error?.status;
		const error_code: number | undefined = error?.error?.detail?.error_code;
		const message: string | undefined = error?.error?.detail?.message;

		if (status === 401) {
			if (
				UserPaymentSubscriptionCodesEnum.EMAIL_IS_NOT_CONFIRMED ===
				error_code
			) {
				this.toast.sendErrorToast(message);
			} else {
				this.toast.sendErrorToast(
					'Invalid email and password combination'
				);
			}
			this.authV2Service.signoutUser();
		}

		if (status === 402) {
			this.limitErrorsService.handlePaymentErrors(error);
		}

		if (status === 403) {
			this.limitErrorsService.handleLimitErrors(error_code);
		}

		return throwError(() => error);
	}
}
