import { NgModule } from '@angular/core';
import { TableWithPaginatorComponent } from './table-with-paginator.component';

const importExport = [TableWithPaginatorComponent];

@NgModule({
	imports: importExport,
	exports: importExport
})
export class TableModule {}

export * from './table-with-paginator.component';
export * from './header';
