import { Component } from '@angular/core';

@Component({
	selector: 'dot',
	template: '',
	styles: [
		`
			:host {
				display: block;
				border-radius: 100px;
				background-color: #161616;
				width: 16px;
				height: 16px;
			}
		`
	],
	standalone: true
})
export class DotComponent {}
