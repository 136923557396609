import { Component } from '@angular/core';

@Component({
	selector: 'dot',
	template: `
		<div class="white-dot"></div>
	`,
	styles: [
		`
			@use 'colors' as *;
			:host {
				position: relative;
				border-radius: 100px;
				width: 16px;
				height: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $primary-purple;
			}

			.white-dot {
				position: absolute;
				border-radius: 100px;
				top: 4px;
				right: 4px;
				width: 8px;
				height: 8px;
				background: white;
			}
		`
	],
	standalone: true
})
export class DotComponent {}
