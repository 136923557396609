import { merge, Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

export function singleRequest<T, L extends { [key: string]: boolean }>(
	request: Observable<T>,
	loading: [L, keyof L] | undefined,
	...subjects: Subject<void>[]
) {
	for (const subject of subjects) {
		subject.next();
	}

	if (loading) {
		const [val, key] = loading;
		(val[key] as boolean) = true;
	}

	const merged = merge(...subjects);

	return request.pipe(takeUntil(merged)).pipe(
		finalize(() => {
			if (loading) {
				const [val, key] = loading;
				(val[key] as boolean) = false;
			}
		})
	);
}
