import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnComponent, RowComponent } from 'components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthPaymentService } from '../../../../../../../src/app/authentication/services/auth-payment.service';
import { BookingService } from '../../../../../../../src/app/services/booking.service';
import { LollyLoadingDialogService } from '../../../../../../../src/app/shared-components/lolly-loading-dialog/lolly-loading-dialog.service';
import { ToastNotificationService } from '../../../../../../../src/app/shared/toast-notification/toast-notification.service';
import { MyPlanService } from '../../../../../../../src/app/user-management/new-sprint/components/my-plan/my-plan.service';
import { PlanComparisonComponent } from '../plan-comparison/plan-comparison-banner';
import { BannerButtonComponent } from './banner-button.component';

@Component({
	selector: 'lolly-lite-banner',
	template: `
		<row class="container shadow">
			<column [style.justify-content]="'space-between'">
				<row [left]="true">
					<column [style.gap]="'0px'">
						<div class="banner-big-text">Limited-Time Offer:</div>
						<row>
							<div class="banner-big-text">
								Get Lolly Lite for Just
							</div>
							<div class="price-container banner-big-text">
								$149
							</div>
						</row>
					</column>
				</row>
				<row [style.gap]="'16px'" [left]="true">
					<div class="banner-text">
						Upgrade today and scale your campaigns faster
					</div>
					<div class="banner-text">
						Unlock premium influencer marketing at an exclusive
						discount
					</div>
				</row>
			</column>
			<column [style.justify-content]="'space-between'">
				<column>
					<column [style.gap]="0">
						<div class="banner-number">50</div>
						<div class="banner-number-description">
							searches/month
						</div>
					</column>
					<column [style.gap]="0">
						<div class="banner-number">100</div>
						<div class="banner-number-description">
							unlocked email IDs
						</div>
					</column>
					<column [style.gap]="0">
						<div class="banner-number">100</div>
						<div class="banner-number-description">
							detailed profiles
						</div>
					</column>
				</column>
				<button banner (click)="onClickGetOffer()">Get offer</button>
			</column>
		</row>
	`,
	styles: [
		`
			:host {
				font-family: Gilroy;
			}

			.container {
				height: 400px;
				align-items: unset;
				border-radius: 15px;
				background-repeat: no-repeat;
				padding-left: 60px;
				padding-right: 60px;
				padding-top: 30px;
				padding-bottom: 30px;

				background-size: cover;
				background-image: url('./banner_background.png');
			}
		`
	],
	styleUrls: ['./banner.scss'],
	imports: [RowComponent, ColumnComponent, BannerButtonComponent],
	standalone: true
})
export class BannerComponent {
	private unSubscriber$ = new Subject<void>();

	constructor(
		private booking: BookingService,
		private matDialog: MatDialog
	) {}

	public onClickGetOffer() {
		const ref = this.matDialog.open(PlanComparisonComponent, {
			panelClass: 'lolly-lite-banner-pop-up'
		});

		ref.componentInstance.close.subscribe(() => {
			ref.close();
		});
	}
}
