import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

export type SocialMedia = 'youtube' | 'tiktok' | 'instagram';

export type SearchRequest = {
	type: SocialMedia;
	query: string;
};

@Component({
	selector: 'landing-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
	query: string = '';
	type: SocialMedia = 'youtube';

	@Output()
	queryChange = new EventEmitter<string>();

	@Output()
	typeChange = new EventEmitter<SocialMedia>();

	@Output()
	searchRequested = new EventEmitter<SearchRequest>();

	setQuery(event: string) {
		this.query = event;
	}

	setType(newType: SocialMedia) {
		this.type = newType;
	}

	onSearchClick() {
		this.searchRequested.emit({ type: this.type, query: this.query });
	}
}
