import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { SignupFieldControl } from './sign-up-fields';
import { CommonModule } from '@angular/common';
import { BaseModule, InputComponent } from 'components';

@Component({
	selector: 'custom-signup-field',
	template: `
		<row *ngIf="editable" class="editable-row">
			<checkbox [formControl]="form.controls.appEnabled"></checkbox>
			<input-app placeholder="Field name" [control]="form.controls.name">
				<button app-small type="submit" (click)="editDone.emit()">
					Save
				</button>
			</input-app>
		</row>

		<ng-container *ngIf="!editable">
			<row>
				<checkbox [formControl]="form.controls.appEnabled"></checkbox>
				<text variant="p2">{{ title }}</text>
			</row>
			<row>
				<mat-icon (click)="edit.emit()">edit</mat-icon>
				<mat-icon (click)="delete.emit()">delete</mat-icon>
			</row>
		</ng-container>
	`,
	styles: [
		`
			@use 'common';

			:host {
				@include common.row;
			}

			mat-icon {
				@include common.icon(16px);
				@include common.clickable;
			}

			input-app {
				flex: 1;
			}

			.editable-row {
				flex: 1;
			}
		`
	],
	imports: [
		MatIconModule,
		ReactiveFormsModule,
		CommonModule,
		BaseModule,
		InputComponent
	],
	standalone: true
})
export class CustomSignupFieldComponent {
	@Input() title!: string;
	@Input() form!: SignupFieldControl;

	@Input() editable?: boolean;

	@Output('edit-done') editDone = new EventEmitter<void>();
	@Output() edit = new EventEmitter<void>();
	@Output() delete = new EventEmitter<void>();
}
