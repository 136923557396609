import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SavePasswordRequestModel } from 'src/app/user-management/new-sprint/components/my-profile/model/profile.model';
import { BillingAddress } from 'src/app/user-management/shared/billing-and-payment/billing-and-payment.models';
import { FiledCreditCard } from '../../user-management/components/filed-cards/set-creditcard.models';
import { BaseApiUrl } from '../base-api-urls';
import { Country } from './back-office.models';

@Injectable({
	providedIn: 'root'
})
export class BackOfficeService {
	private readonly baseURL = `${BaseApiUrl.Users}`;

	constructor(protected http: HttpClient) {}

	public makeCardPrimary(cardId: string): Observable<void> {
		return this.http.put<void>(
			`${BaseApiUrl.Subscription}paymentCards/${cardId}/default`,
			{}
		);
	}

	public getBusinessOwnerCards(): Observable<FiledCreditCard[]> {
		return this.http.get<FiledCreditCard[]>(
			`${this.baseURL}paymentcards/my`
		);
	}

	public getAllCountriesList(): Observable<Country[]> {
		return this.http.get<Country[]>(`${BaseApiUrl.Subscription}countries`);
	}

	public getBillingAccountDetails(): Observable<BillingAddress> {
		return this.http.get<BillingAddress>(
			`${BaseApiUrl.Subscription}accounts`
		);
	}

	public downloadInvoiceHistoryPdf(
		chargebeeInvoiceId: string,
		inlineDisplay: string
	) {
		return this.http.get(
			`${BaseApiUrl.Subscription}billings/my/invoices/pdf`,
			{
				params: {
					chargebeeInvoiceId: chargebeeInvoiceId,
					inlineDisplay: inlineDisplay
				}
			}
		);
	}

	public getUserState(userId: number): Observable<number> {
		return of(1);
		// return this.http.get<number>(`${BaseApiUrl.Users}Users/${userId}/user-account-status`);
	}

	public getProfileDetails(token: string): Observable<any> {
		return this.http.get<any>(
			`${BaseApiUrl.SocialMessengerPython}users/view-profile`,
			{ headers: new HttpHeaders({ Authorization: token }) }
		);
	}

	public updateProfileDetails(form: FormData): Observable<any> {
		return this.http.patch(
			`${BaseApiUrl.SocialInfluencerPython}users/update-profile`,
			form
		);
	}

	public updatePassword(payload: SavePasswordRequestModel): Observable<any> {
		return this.http.patch(
			`${BaseApiUrl.SocialInfluencerPython}users/update-password`,
			payload
		);
	}
}
