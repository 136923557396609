import { Component, HostBinding, Input } from '@angular/core';

export type ButtonVariant = 'primary' | 'secondary';

@Component({
	selector: 'button[app-small]',
	template: `
		<ng-content></ng-content>
	`,
	styles: [
		`
			:host {
				background: black;

				height: unset;
				padding: 6px 8px 6px 8px;
				gap: 2px;
				border-radius: 4px;
			}
		`
	],
	standalone: true
})
export class SmallButtonComponent {
	@Input() variant: ButtonVariant = 'primary';

	@HostBinding('class')
	get className() {
		return this.variant === 'primary' ? 'primary-btn' : 'secondary-btn';
	}
}
