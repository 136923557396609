import { Clipboard } from '@angular/cdk/clipboard';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LollyLoadingDialogService } from 'src/app/shared-components/lolly-loading-dialog/lolly-loading-dialog.service';
import { StripeAccountDataModel } from 'src/app/shared/models/stripe-account.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PaypalIntegrationDialogComponent } from '../../component-pieces/paypal-integration-dialog/paypal-integration-dialog.component';

@Component({
	selector: 'app-payment-integrations',
	templateUrl: './payment-integrations.component.html',
	styleUrls: ['./payment-integrations.component.scss']
})
export class PaymentIntegrationsComponent implements OnInit, OnDestroy {
	@Input() public paymentIntegrationsData: string[] = [];
	public loading: boolean = false;
	public isConnected: boolean = false;
	public isPanelOpen: boolean = true;
	public stripeAccountData?: StripeAccountDataModel;
	public connectedPlatform: string = 'paypal';
	private unsubs$ = new Subject<void>();

	constructor(
		private matDialog: MatDialog,
		private clipboard: Clipboard,
		private sharedService: SharedService,
		private lollyLoadingDialogService: LollyLoadingDialogService,
		public activatedRoute: ActivatedRoute,
		private formBuilder: UntypedFormBuilder
	) {}

	ngOnInit(): void {
		if (this.paymentIntegrationsData?.length) {
			if (this.paymentIntegrationsData.includes('paypal')) {
				this.isConnected = true;
			}
		}
	}

	public copyToClipboard(value): void {
		this.clipboard.copy(value);
	}

	public connectStripe(): void {
		this.lollyLoadingDialogService.showLoader();
		this.sharedService
			.connectStripeAccount()
			.pipe(takeUntil(this.unsubs$))
			.subscribe(
				res => {
					if (res?.data?.message)
						window.location.replace(res?.data?.message);
				},
				err => {
					this.lollyLoadingDialogService.dismissLoader();
				}
			);
	}

	public changePanelState(value: boolean): void {
		this.isPanelOpen = value;
	}

	public onSaveClick(): void {}

	public openPaypalIntegrationDialog(): void {
		let matDialogConfig: MatDialogConfig = {
			panelClass: ['sharp-dialog-no-shadow', 'slide', 'slideInRight'],
			height: '100%',
			width: '514px',
			backdropClass: 'light-backdrop',
			scrollStrategy: new NoopScrollStrategy(),
			position: {
				right: '0'
			}
		};
		let dialogRef = this.matDialog.open(
			PaypalIntegrationDialogComponent,
			matDialogConfig
		);
		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.unsubs$))
			.subscribe(res => {});
	}

	ngOnDestroy(): void {
		this.unsubs$.next();
		this.unsubs$.complete();
	}
}
