import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaginationQuery } from '../../../../_models/pagination';
import { BaseApiUrl } from '../../../../_services/base-api-urls';
import { smiUrl } from '../../../utils/url-builder';
import { CampaignSortingQuery } from '../components/campaigns/campaigns-table.component';
import { CampaignContractsResponse } from '../models/campaigns-contracts';
import { CampaignsResponse } from '../models/campaigns-response.model';

export type SortingDirection = 'desc' | 'asc';

export interface CampaignsStateModel {
	pageNumber?: number;
	pageSize?: number;
	searchFilter?: string;
}

@Injectable({
	providedIn: 'root'
})
export class CampaignService {
	public selectedCampaignsIds: string[] = [];
	public defaultCampaignsIds: string[] = [];

	private triggerCheckCheckboxState = new Subject<void>();
	public selectedCampaignUrl = new BehaviorSubject<any>(null);

	public campaignsState: CampaignsStateModel = {
		pageNumber: 0,
		pageSize: 10,
		searchFilter: ''
	};

	unsubscribe = {
		campaigns: new Subject<void>(),
		jobsData: new Subject<void>()
	};

	constructor(private http: HttpClient) {}

	public getStripeConnectionId(code, state, scope) {
		let urlString = `${BaseApiUrl.SocialInfluencerPython}oauth/stripe/install?code=${code}&state=${state}&scope=${scope}`;

		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public editCampaignName(body: any) {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}campaignsPage/updateCampaignName`
		);
		return this.http.patch<any>(url.href, body);
	}

	public editCampaignbudget(body: any) {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}campaignsPage/updateCampaignBudget`
		);
		return this.http.patch<any>(url.href, body);
	}

	public getCampaignList(
		pagination: PaginationQuery,
		searchString?: string,
		sorting?: CampaignSortingQuery
	): Observable<CampaignsResponse> {
		this.unsubscribe.campaigns.next();
		const url = smiUrl()
			.add('campaigns')
			.add('campaignsPage')
			.pagination(pagination)
			.query('name', searchString)
			.query('budget', sorting?.budget)
			.query('number_of_posts', sorting?.numberOfPosts)
			.query('spent', sorting?.spent)
			.query('total_creators', sorting?.totalCreators)
			.query('total_revenue', sorting?.totalRevenue)
			.done();

		return this.http
			.get<CampaignsResponse>(url)
			.pipe(takeUntil(this.unsubscribe.campaigns));
	}

	public getJobsData(
		originUrl: string,
		pageNumber: number,
		pageSize: number,
		data?: {
			campaign_id?: number;
			influencer_id?: number;
			searchString?: string;
		}
	) {
		this.unsubscribe.jobsData.next();
		if (
			!data ||
			(!data?.campaign_id && !data?.influencer_id && data?.searchString)
		) {
			let urlString = `${BaseApiUrl.SocialInfluencerPython}jobsPage/jobs?page_size=${pageSize}&page_number=${pageNumber}`;
			if (data?.searchString) {
				urlString = urlString + `&name=${data?.searchString}`;
			}
			const url = new URL(urlString);
			return this.http.get<CampaignContractsResponse>(url.href);
		}
		let urlString = `${BaseApiUrl.SocialInfluencerPython}${originUrl}/jobs?page_size=${pageSize}&page_number=${pageNumber}`;

		if (data?.campaign_id) {
			urlString = urlString + `&campaign_id=${data.campaign_id}`;
		}
		if (data?.influencer_id) {
			urlString = urlString + `&influencer_id=${data.influencer_id}`;
		}
		if (data?.searchString) {
			urlString = urlString + `&name=${data?.searchString}`;
		}
		const url = new URL(urlString);
		return this.http.get<CampaignContractsResponse>(url.href);
	}

	public getSelectedCampaignData(campaignId: number): Observable<any> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}campaigns/${campaignId}`;
		const url = new URL(urlString);
		return this.http.get<any>(url.href);
	}

	public getJobList(id: number): Observable<any> {
		const url = new URL(
			`${BaseApiUrl.SocialInfluencerPython}jobs/${id}/creators`
		);
		return this.http.get<any>(url.href);
	}

	public deleteCampaign(id: number): Observable<any> {
		const url = new URL(
			`${BaseApiUrl.SocialMessengerPython}campaigns/${id}`
		);
		return this.http.delete<any>(url.href);
	}
}
