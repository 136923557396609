import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TextComponent } from 'components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthPaymentService } from '../../../../../authentication/services/auth-payment.service';
import { LollySpinnerLoaderComponent } from '../../../../../shared-components/loaders/lolly-spinning-loader/lolly-spinning-loader.component';
import { LollyLoadingDialogService } from '../../../../../shared-components/lolly-loading-dialog/lolly-loading-dialog.service';
import { PlansDataModel } from '../../../../../shared-components/models/plans-data.model';
import { ToastNotificationService } from '../../../../../shared/toast-notification/toast-notification.service';
import { MyPlanService } from '../../../../../user-management/new-sprint/components/my-plan/my-plan.service';

@Component({
	selector: 'app-landing-page-offer',
	template: `
		<div class="wrapper" @fadeInAnimation>
			<div class="description">
				<text variant="h2" class="title">
					Limited-Time Offer: Get Lolly Lite for Just $149!
				</text>
				<text variant="p3" class="subtitle">
					Unlock premium influencer marketing tools at an exclusive
					discount.
					<br />
					Upgrade today and scale your campaigns faster
				</text>
				<button
					class="primary-btn offer-button"
					(click)="onClickGetOffer()"
				>
					Get Offer
				</button>
			</div>
			<div class="limitations">
				<div class="item">
					<mat-icon
						class="item-icon"
						style="background-color: #ffe3ce;"
					>
						manage_search
					</mat-icon>
					<text variant="p3" class="item-text">
						50 searches/month
					</text>
				</div>
				<div class="item">
					<mat-icon
						class="item-icon"
						style="background-color: #c1e6fe;"
					>
						alternate_email
					</mat-icon>
					<text variant="p3" class="item-text">
						100 unlocked email IDs
					</text>
				</div>
				<div class="item">
					<mat-icon
						class="item-icon"
						style="background-color: #ffcee3;"
					>
						legend_toggle
					</mat-icon>
					<text variant="p3" class="item-text">
						100 detailed profiles
					</text>
				</div>
			</div>
			<div class="image-wrapper">
				<img
					src="assets/landing-page/offer-image.png"
					class="offer-image"
				/>
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;

				.wrapper {
					display: grid;
					grid-template-columns: 1fr 1fr 0.3fr;
					padding: 24px;
					height: 200px;
					box-shadow: 1px 2px 6px #12121229;
					transition: box-shadow 0.3s;
					border-radius: 5px;
					background: linear-gradient(to bottom, #d0ccff, #bbbaf8);

					&:hover {
						box-shadow: 1px 8px 26px #12121229;
					}

					.description {
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.subtitle {
							font-weight: 500;
						}

						.offer-button {
							width: min-content;
						}
					}

					.limitations {
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.item {
							display: flex;
							column-gap: 8px;
							align-items: center;

							.item-icon {
								width: 30px;
								height: 30px;
								border-radius: 50%;
								display: flex;
								justify-content: center;
								align-items: center;
							}

							.item-text {
								font-weight: 500;
							}
						}
					}

					.image-wrapper {
						display: flex;
						align-items: center;
						justify-content: right;
						height: 100%;

						.offer-image {
							object-fit: contain;
							height: 152px;
							display: block;
						}
					}
				}
			}
		`
	],
	animations: [
		trigger('fadeInAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('0.5s ease-out', style({ opacity: 1 }))
			]),
			transition(':leave', [
				animate('0.5s ease-in', style({ opacity: 0 }))
			])
		])
	],
	imports: [TextComponent, MatIconModule],
	standalone: true
})
export class OfferComponent {
	private unSubscriber$ = new Subject<void>();

	constructor(
		public authPaymentService: AuthPaymentService,
		public myPlanService: MyPlanService,
		private lollyLoadingDialogService: LollyLoadingDialogService,
		private toastNotificationService: ToastNotificationService
	) {}

	public onClickGetOffer() {
		this.redirectStripeApi();
	}

	private redirectStripeApi(): void {
		this.lollyLoadingDialogService.showLoader();
		this.myPlanService
			.getStripePlanByAlias('lite')
			.pipe(takeUntil(this.unSubscriber$))
			.subscribe({
				next: plan => {
					this.authPaymentService.redirectToStripe(plan).subscribe({
						next: res => {
							this.lollyLoadingDialogService.dismissLoader();
							window.open(res, '_self');
						},
						error: () => {
							this.lollyLoadingDialogService.dismissLoader();
							this.toastNotificationService.sendErrorToast(
								'Some Error Occurred. Try Again Later.'
							);
						}
					});
				},
				error: () => {
					this.lollyLoadingDialogService.dismissLoader();
					this.toastNotificationService.sendErrorToast(
						'Some Error Occurred. Try Again Later.'
					);
				}
			});
	}
}
