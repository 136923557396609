import { Component, EventEmitter, Output } from '@angular/core';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { PaginationService } from '../../services';

@Component({
	selector: 'paginator',
	template: `
		<mat-paginator
			class="new-custom-paginator"
			(page)="onPage($event)"
			[length]="pagination.totalCount"
			[showFirstLastButtons]="true"
			[pageSizeOptions]="pagination.options"
			[hidePageSize]="false"
			[pageSize]="pagination.size"
			[pageIndex]="pagination.page"
		></mat-paginator>
	`,
	imports: [MatPaginatorModule],
	standalone: true
})
export class PaginatorComponent {
	@Output() change = new EventEmitter<PageEvent>();

	constructor(protected pagination: PaginationService) {}

	onPage(event: PageEvent) {
		this.pagination.pageEvent(event);
		this.change.emit(event);
	}
}
