import { Component } from '@angular/core';

@Component({
	selector: 'box',
	template: `
		<ng-content></ng-content>
	`,
	styles: [
		`
			:host {
				width: 100%;
				height: 100%;
				display: flex;
				flex: 1;
			}
		`
	],
	standalone: true
})
export class BoxComponent {}
