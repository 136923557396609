import { Component, Input } from '@angular/core';
import { image } from 'zrender/lib/svg-legacy/graphic';

@Component({
	selector: 'company-chip',
	template: `
		<img [src]="imageUrl" />
		<div>{{ text }}</div>
	`,
	styles: [
		`
			:host {
				display: flex;
				padding: 16px 24px;
				justify-content: center;
				align-items: center;
				gap: 4px;
				border-radius: 5px;
				flex: 1;
				background: var(--Bg-gray, #f3f3f3);
			}
			div {
				color: var(--Text-gray-light, #b2b2b2);

				/* H2 */
				font-family: Gilroy;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px; /* 133.333% */
			}
		`
	],
	standalone: true
})
export class CompanyChipComponent {
	@Input() imageUrl!: string;
	@Input() text!: string;
	protected readonly image = image;
}
