import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { UserDetails } from '../_models/identity-models/identity-detail';
import { UserDetailsInterface } from '../_models/identity-models/user-details.interface';
import { StorageKey } from '../_models/local-storage-key';
import { PermissionsService } from '../shared/permisions/permissions.service';
import { SetUserDetails } from '../shared/state/user/user.actions';
import {
	getAccountState,
	hasBackOfficePermission,
	UserState
} from '../shared/state/user/user.reducer';
import { BaseApiUrl } from './base-api-urls';
import { TokenService } from './token.service';
import { UserStateEnum } from './user/user-state.enum';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	public logoutSubject: Subject<void> = new Subject();
	public allowedMegaRoutes$: BehaviorSubject<any[]> = new BehaviorSubject(
		null
	);
	public triggerNavRoute$: BehaviorSubject<string> = new BehaviorSubject(
		'audience'
	);

	public setSubNavIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
	public expandMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public navMenuRoute$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setMegaRouteIndex$: BehaviorSubject<number> = new BehaviorSubject(0);

	private userDetails: UserDetails;

	constructor(
		private tokenService: TokenService,
		private permissionsService: PermissionsService,
		private userStore: Store<UserState>,
		private http: HttpClient
	) {}

	public isLoggedIn() {
		const tokenExists: boolean = !!localStorage.getItem(StorageKey.token);
		if (tokenExists) {
			const decodedJwt = JSON.parse(
				localStorage.getItem(StorageKey.decodedJwtIo)
			);
			if (!decodedJwt) {
				return false;
			}
			const currentTime = moment().format();
			const timeFromJwt = moment(decodedJwt.exp * 1000).format();
			const decodedJwtIsValid: boolean =
				!moment(currentTime).isAfter(timeFromJwt);
			this.initUserDetails();

			return tokenExists && decodedJwtIsValid;
		}
	}

	public initUserDetails(): void {
		if (!this.userDetails) {
			const decodedToken = this.tokenService.decodeToken(
				localStorage.getItem(StorageKey.token)
			);
			this.userDetails = this.getUserDetailsFromJwt(decodedToken);
			this.userStore.dispatch(new SetUserDetails(this.userDetails));
		}
	}

	public hasCreditCard(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState =>
				of(
					accountState !== UserStateEnum.NoCreditCard &&
						accountState !==
							UserStateEnum.FreeTrialExpiredNoCreditCard
				)
			)
		);
	}

	public isAdmin(): Observable<boolean> {
		return this.userStore.pipe(select(hasBackOfficePermission), take(1));
	}

	public isFreemiumExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState =>
				of(accountState === UserStateEnum.FreemiumExpiredNoCreditCard)
			)
		);
	}

	public isFreeTrialExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState =>
				of(accountState === UserStateEnum.FreeTrialExpiredNoCreditCard)
			)
		);
	}

	public getUserDetailsFromJwt(
		decodedJwt: UserDetailsInterface
	): UserDetails {
		let googleIntegratedStatus;
		let itemString: string = localStorage.getItem(
			StorageKey.installedPlatorm
		);
		if (!!itemString && JSON.parse(itemString)?.includes('Google')) {
			googleIntegratedStatus = decodedJwt.user_filed_id;
		}
		const codedPermissions: string[] =
			decodedJwt.permissions_filed.split('|');
		const decodedPermissions = codedPermissions
			.map(permission =>
				this.permissionsService.decodePermission(permission)
			)
			.filter(permission => permission.permissions.length);
		return {
			FiledId: parseInt(decodedJwt.user_filed_id),
			OriginalFiledId: parseInt(decodedJwt.original_filed_id),
			AccountState: parseInt(decodedJwt.user_account_state),
			FacebookBusinessOwnerId: decodedJwt.user_facebook_businessowner_id,
			GoogleBusinessOwnerId: googleIntegratedStatus,
			IsFrontOfficeUser: decodedJwt.user_is_frontoffice_user === 'True',
			RejectedReason: parseInt(decodedJwt.user_rejected_reason),
			IsImpersonated: false,
			Permissions: decodedPermissions
		};
	}

	public authGoogle(redirect_url: string = ''): Observable<any> {
		return this.http.get(
			`${BaseApiUrl.SocialMessengerPython}oauth/gmail/install${redirect_url}`
		);
	}

	public authPreInstall(
		platform: string,
		redirect_url: string = ''
	): Observable<any> {
		return this.http.get(
			`${BaseApiUrl.SocialMessengerPython}oauth/${platform}/preinstall${redirect_url}`
		);
	}

	public authMakeDefault(payload): Observable<any> {
		return this.http.patch(
			`${BaseApiUrl.SocialMessengerPython}emails`,
			payload
		);
	}

	public authUnInstallV2(platform: string, email: string): Observable<any> {
		return this.http.get(
			`${BaseApiUrl.SocialMessengerPython}oauth/${platform}/uninstall?email=${email}`
		);
	}

	public getConnectedEmails(): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}emails`);
	}
}
