import { NgModule } from '@angular/core';
import { ITooltipComponent } from './i-tooltip';
import { TooltipComponent } from './tooltip';

const importExport = [TooltipComponent, ITooltipComponent];

@NgModule({
	imports: importExport,
	exports: importExport
})
export class TooltipsModule {}

export * from './i-tooltip';
export * from './tooltip';
