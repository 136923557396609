import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmPopupService } from 'src/app/shared/services/confirm-popup.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UtilsService } from 'src/app/shared/utils';

@Component({
	selector: 'app-paypal-integration-dialog',
	templateUrl: './paypal-integration-dialog.component.html',
	styleUrls: ['./paypal-integration-dialog.component.scss']
})
export class PaypalIntegrationDialogComponent implements OnInit {
	public loading: boolean = false;
	public isConnected: boolean = false;
	public paypalCredentialsForm: UntypedFormGroup;
	public paypalAccountData?;
	public ZOHO_PAYPAL_KB_LINK: string = UtilsService.ZOHO_PAYPAL_KB_LINK;

	private connectedPlatform: string = 'paypal';
	private unsubscriber$ = new Subject<void>();

	constructor(
		private confirmPopupService: ConfirmPopupService,
		private clipboard: Clipboard,
		private sharedService: SharedService,
		private toast: ToastNotificationService
	) {}

	ngOnInit(): void {
		this.createForm();
		this.getPayoutAccountData();
	}

	private createForm(): void {
		this.paypalCredentialsForm = new UntypedFormGroup({
			client_id: new UntypedFormControl('', [Validators.required, UtilsService.noWhitespaceAtStartValidator]),
			client_secret: new UntypedFormControl('', [Validators.required, UtilsService.noWhitespaceAtStartValidator]),
			confirmation_check: new UntypedFormControl(false, [Validators.requiredTrue])
		});
	}

	private getPayoutAccountData(): void {
		this.loading = true;
		this.sharedService
			.getPayoutAccountData()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res.data != null) {
						this.isConnected = true;
						this.paypalAccountData = res.data;
						this.paypalCredentialsForm.patchValue({
							client_id: res.data.client_id,
							client_secret: res.data.client_secret,
							confirmation_check: true
						});
						this.toggleEnableAllFormControls(false);
					}
					this.loading = false;
				},
				err => {
					this.loading = false;
				}
			);
	}

	public onClickDisconnect(): void {
		this.confirmPopupService.showPopup('Are you sure you want to disconnect Paypal?', 'Yes', 'No').then(res => {
			if (res?.primaryAction === 'true') {
				this.disconnectPaymentPlatform();
			}
		});
	}

	private disconnectPaymentPlatform(): void {
		this.loading = true;
		this.sharedService
			.disconnectPaymentAccount(this.connectedPlatform)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.paypalAccountData = null;
					this.paypalCredentialsForm.reset();
					this.toggleEnableAllFormControls(true);
					this.isConnected = false;
					this.loading = false;
					this.toast.sendSuccessToast('Account disconnected successfully');
				},
				err => {
					this.loading = false;
					this.onError();
				}
			);
	}

	public savePaypalInfo(): void {
		if (this.paypalCredentialsForm.invalid) {
			this.paypalCredentialsForm.markAllAsTouched();
			return;
		}
		const paypalInfo = {
			client_id: this.paypalCredentialsForm.get('client_id').value,
			client_secret: this.paypalCredentialsForm.get('client_secret').value
		};
		this.loading = true;
		this.sharedService
			.savePaypalInfo(paypalInfo)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.paypalAccountData = paypalInfo;
					this.toggleEnableAllFormControls(false);
					this.isConnected = true;
					this.loading = false;
					this.toast.sendSuccessToast('Account connected successfully');
				},
				err => {
					this.loading = false;
					this.toast.sendErrorToast(err.error.detail);
				}
			);
	}

	private onError(): void {
		this.toast.sendErrorToast('Some error occurred. Try again later');
	}

	private toggleEnableAllFormControls(value: boolean): void {
		if (value) {
			Object.keys(this.paypalCredentialsForm.controls).forEach(controlName => {
				this.paypalCredentialsForm.controls[controlName].enable();
			});
		} else {
			Object.keys(this.paypalCredentialsForm.controls).forEach(controlName => {
				this.paypalCredentialsForm.controls[controlName].disable();
			});
		}
	}

	public copyToClipboard(text: string): void {
		this.clipboard.copy(text);
		this.toast.sendSuccessToast('Copied to clipboard!');
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
