import {
	AfterContentInit,
	AfterViewInit,
	Component,
	EventEmitter,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { GenericDropdownComponent } from '../generic-dropdown/generic-dropdown.component';
import { SocialMedia } from '../search-bar/search-bar.component';

type DropdownData = {
	id: string;
	url: string;
	value: SocialMedia;
	label: string;
};

@Component({
	selector: 'search-custom-dropdown',
	templateUrl: './custom-dropdown.component.html',
	styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements OnInit {
	@ViewChild(GenericDropdownComponent)
	genericDropdown!: GenericDropdownComponent;

	data: DropdownData[] = [
		{
			id: '0',
			url: 'assets/icons/social-media-icons/instagram.svg',
			value: 'instagram',
			label: 'Instagram'
		},
		{
			id: '1',
			url: 'assets/icons/social-media-icons/tiktok.svg',
			value: 'tiktok',
			label: 'TikTok'
		},
		{
			id: '2',
			url: 'assets/icons/social-media-icons/youtube.svg',
			value: 'youtube',
			label: 'YouTube'
		}
	];

	selectedItem: DropdownData = this.data[0];

	select(event: MouseEvent, item: DropdownData) {
		event.stopPropagation();
		this.selectedItem = item;
		this.updateType.emit(this.selectedItem.value);
		this.genericDropdown.closeDropdown();
	}

	dropdownOpened = false;

	onDropdownClick() {
		this.dropdownOpened = true;
	}

	onBackgroundClick() {
		this.closeDropdown();
	}

	closeDropdown() {
		this.dropdownOpened = false;
	}

	json(obj: any) {
		return JSON.stringify(obj);
	}

	@Output()
	updateType = new EventEmitter<SocialMedia>();

	ngOnInit(): void {
		this.updateType.emit(this.data[0].value);
	}
}
