<div class="dialog-con">
	<div class="close-btn">
		<mat-icon (click)="onNoClick()">close</mat-icon>
	</div>
	<div class="dialog-title">{{ data.exist ? 'You can choose only one platform to Connect' : 'Add' }}</div>
	<div class="email-btns">
		<div class="gmail-btn-wrapper">
			<a class="btn {{ data.exist ? 'disabled' : '' }}" (click)="signInWithGoogle()">
				<div class="google-icon">
					<img class="google-icon-2" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
				</div>
				<div class="google-text">
					Sign in with Google
				</div>
			</a>
		</div>
		<div class="outlook-btn-wrapper">
			<a class="btn {{ data.exist ? 'disabled' : '' }}" (click)="signInWithOutlook()">
				<div class="items">
					<img src="assets/images/outlook.svg" />
					<div class="text">{{ 'Outlook account' }}</div>
				</div>
			</a>
		</div>
	</div>
</div>
