import { Observable, Subscription, throwError } from 'rxjs';
import { OperatorFunction } from 'rxjs/internal/types';
import { catchError } from 'rxjs/operators';
import { CheckedError, ProgrammingError, UnknownError } from './network';

export type ErrorObservable<T, E = unknown> = Omit<
	Observable<T>,
	'subscribe' | 'pipe'
> & {
	pipe<A>(op1: OperatorFunction<T, A>): ErrorObservable<A, E>;
	pipe<A, B>(
		op1: OperatorFunction<T, A>,
		op2: OperatorFunction<A, B>
	): ErrorObservable<A, E>;
	pipe<A, B, C>(
		op1: OperatorFunction<T, A>,
		op2: OperatorFunction<A, B>,
		op3: OperatorFunction<B, C>
	): ErrorObservable<A, E>;
	// pipe<A, B, C, D>(
	// 	op1: OperatorFunction<T, A>,
	// 	op2: OperatorFunction<A, B>,
	// 	op3: OperatorFunction<B, C>,
	// 	op4: OperatorFunction<C, D>
	// ): ErrorObservable<A, E>;
	// pipe<A, B, C, D, EE>(
	// 	op1: OperatorFunction<T, A>,
	// 	op2: OperatorFunction<A, B>,
	// 	op3: OperatorFunction<B, C>,
	// 	op4: OperatorFunction<C, D>,
	// 	op5: OperatorFunction<D, EE>
	// ): ErrorObservable<EE, E>;
	// pipe<A, B, C, D, EE, F>(
	// 	op1: OperatorFunction<T, A>,
	// 	op2: OperatorFunction<A, B>,
	// 	op3: OperatorFunction<B, C>,
	// 	op4: OperatorFunction<C, D>,
	// 	op5: OperatorFunction<D, EE>,
	// 	op6: OperatorFunction<EE, F>
	// ): ErrorObservable<F, E>;
	// pipe<A, B, C, D, EE, F, G>(
	// 	op1: OperatorFunction<T, A>,
	// 	op2: OperatorFunction<A, B>,
	// 	op3: OperatorFunction<B, C>,
	// 	op4: OperatorFunction<C, D>,
	// 	op5: OperatorFunction<D, EE>,
	// 	op6: OperatorFunction<EE, F>,
	// 	op7: OperatorFunction<F, G>
	// ): ErrorObservable<G, E>;
	// pipe<A, B, C, D, EE, F, G, H>(
	// 	op1: OperatorFunction<T, A>,
	// 	op2: OperatorFunction<A, B>,
	// 	op3: OperatorFunction<B, C>,
	// 	op4: OperatorFunction<C, D>,
	// 	op5: OperatorFunction<D, EE>,
	// 	op6: OperatorFunction<EE, F>,
	// 	op7: OperatorFunction<F, G>,
	// 	op8: OperatorFunction<G, H>
	// ): ErrorObservable<H, E>;
	// pipe<A, B, C, D, EE, F, G, H, I>(
	// 	op1: OperatorFunction<T, A>,
	// 	op2: OperatorFunction<A, B>,
	// 	op3: OperatorFunction<B, C>,
	// 	op4: OperatorFunction<C, D>,
	// 	op5: OperatorFunction<D, EE>,
	// 	op6: OperatorFunction<EE, F>,
	// 	op7: OperatorFunction<F, G>,
	// 	op8: OperatorFunction<G, H>,
	// 	op9: OperatorFunction<H, I>
	// ): ErrorObservable<I, E>;
	pipe<R>(...operations: OperatorFunction<any, any>[]): ErrorObservable<R, E>;

	subscribe(handler: {
		next?: (value: T) => void;
		error: (error: E) => boolean;
		complete?: () => void;
	}): Subscription;
};

export function errors<T, Errs extends CheckedError[]>(
	source: Observable<T>,
	...providedErrors: Errs
): ErrorObservable<T, Errs[number]> {
	return source.pipe(
		catchError<T, Observable<T>>(err => {
			for (const error of providedErrors) {
				const checkedError = error.check(err);
				if (checkedError) {
					return throwError(() => checkedError);
				}
			}
			return throwError(() => err);
		})
	) as unknown as ErrorObservable<T, Errs[number]>;
}

export function httpErrors<T, Errs extends CheckedError[]>(
	source: Observable<T>,
	...providedErrors: Errs
): ErrorObservable<T, Errs[number] | ProgrammingError | UnknownError> {
	return source.pipe(
		catchError<T, Observable<T>>(err => {
			for (const error of [
				...providedErrors,
				ProgrammingError,
				UnknownError
			]) {
				const checkedError = error.check(err);
				if (checkedError) {
					return throwError(() => checkedError);
				}
			}
			return throwError(() => err);
		})
	) as ErrorObservable<T, Errs[number] | ProgrammingError | UnknownError>;
}
