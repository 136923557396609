import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrimaryUiKit } from '../primary-kit';

@Component({
	selector: 'app-tab-selector',
	template: `
		<div class="wrapper">
			<div class="indicator" [ngStyle]="indicatorStyle"></div>

			<div
				*ngFor="let item of options"
				class="option"
				(click)="selectItem(item)"
				[matTooltip]="item[tooltipKey]"
			>
				<mat-icon
					*ngIf="item[iconTypeKey] === 'icon'"
					[ngStyle]="getIconStyle(item)"
				>
					{{ item[iconKey] }}
				</mat-icon>
				<img
					*ngIf="item[iconTypeKey] === 'img'"
					[ngStyle]="getIconStyle(item)"
					[src]="item[iconKey]"
				/>
				<text
					variant="p5"
					[class.selected]="
						control.value[titleKey] === item[titleKey]
					"
				>
					{{ item[titleKey] }}
				</text>
			</div>
		</div>
	`,
	styles: [
		//language=scss
		`
			@import 'colors.scss';

			:host {
				display: block;
				width: 100%;
				height: 48px;
				padding: 3px;
				border-radius: 5px;
				background: #f5f4f7;

				.wrapper {
					position: relative;
					display: flex;
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					column-gap: 3px;

					.indicator {
						position: absolute;
						height: 100%;
						background: #fff;
						border-radius: 6px;
						box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
						transition: transform 0.2s ease-in-out;
						z-index: 0;
					}

					.option {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						column-gap: 8px;
						z-index: 1;
						cursor: pointer;

						mat-icon {
							color: $text-black;
						}
					}
				}
			}
		`
	],
	imports: [
		NgForOf,
		NgStyle,
		PrimaryUiKit,
		MatIconModule,
		NgIf,
		MatTooltipModule
	],
	standalone: true
})
export class TabSelectorComponent<T, K extends keyof T = keyof T>
	implements OnInit
{
	@Input() options: T[] = [];

	@Input() titleKey!: K;
	@Input() comparisonKey!: K;
	@Input() iconKey?: K;
	@Input() iconSizeKey?: K;
	@Input() iconTypeKey?: K;
	@Input() tooltipKey?: K;

	@Input() control: FormControl<T | null> = new FormControl(null);

	ngOnInit() {
		if (!this.control.value) {
			this.control.patchValue(this.options[0]);
		}
	}

	get selectedIndex(): number | null {
		if (this.comparisonKey && this.control.value !== null) {
			const index = this.options.findIndex(value => {
				return (
					value[this.comparisonKey] ===
					this.control.value?.[this.comparisonKey]
				);
			});
			return index !== -1 ? index : null;
		} else {
			return null;
		}
	}

	get indicatorStyle() {
		return {
			transform: `translateX(${(this.selectedIndex || 0) * 100}%)`,
			width: `${100 / this.options.length}%`
		};
	}

	public getIconStyle(item: T) {
		if (this.iconSizeKey) {
			return {
				'font-Size': `${item[this.iconSizeKey]}px`,
				height: `${item[this.iconSizeKey]}px`,
				width: `${item[this.iconSizeKey]}px`
			};
		}
		return '';
	}

	public selectItem(item: T) {
		if (!this.control.disabled) {
			this.control.setValue(item);
		}
	}
}
