import { Injectable } from '@angular/core';
import { Environment } from '../environments/models/environment.models';

@Injectable()
export class BaseApiUrl {
	public static Users: string;
	public static UserVerification: string;
	public static Contact: string;
	public static GeneralSettings: string;
	public static Subscription: string;
	public static UserViews: string;
	public static FiledSupport: string;
	public static Payment: string;
	public static BlueSnap: string;

	public static DexterAnalytics: string;
	public static DexterAnalyticsGoogle: string;
	public static OptimizationApi: string;
	public static OptimizationApiGoogle: string;
	public static DexterSync: string;
	public static DexterGenesis: string;
	public static DexterDashboard: string;
	public static GooglePythonCampaignBuilder: string;

	public static Schedules: string;
	public static PyReportingfb: string;
	public static PyReporting: string;
	public static Reporting: string;
	public static Assets: string;

	public static GoogleAccounts: string;
	public static GoogleInsights: string;
	public static PythonGoogleAccounts: string;
	public static GoogleDataPipeline: string;

	public static HubSpot: string;
	public static LogErrors: string;

	public static CreativeBuilder: string;
	public static CreativeBuilderSingleAdImage: string;
	public static FacebookAsset: string;

	public static ProductCatalog: string;
	public static PythonCatalogLambda: string;
	public static SocialInfluencerPython: string;
	public static SocialMessengerPython: string;
	public static SocialInfluencerCS: string;
	public static SocialMessengerSocket: string;
	public static stripekey: string;

	public static replace(env: Environment) {
		this.SocialInfluencerPython =
			env.SocialInfluencerApi.SocialInfluencerPython;
	}
}
