import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import domToImage from 'dom-to-image';
import jsPDF from 'jspdf';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InvoiceDetailsInterface } from 'src/app/user-management/models/invoice-details.interface';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { Store } from '@ngrx/store';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';

@Component({
	selector: 'app-invoice-dialog',
	templateUrl: './invoice-dialog.component.html',
	styleUrls: ['./invoice-dialog.component.scss']
})
export class InvoiceDialogComponent implements OnInit {
	@Input() InvoiceDetail: any;

	@ViewChild('dataToExport', { static: false }) public dataToExport: ElementRef;
	@ViewChild('mainDiv', { static: false }) public mainDiv: ElementRef;
	public pdfName = new UntypedFormControl('aaaa', [Validators.required]);
	public savingPdf = false;
	public loading = true;
	public invoice: InvoiceDetailsInterface;
	public currency: string;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<InvoiceDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		private toastService: ToastNotificationService,
		private backOfficeService: BackOfficeService,
		private sharedStore: Store<SharedState>
	) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.loading = false;
			this.sharedStore.dispatch(new HideGlobalSpinner());
		}, 3000);
	}

	public getInvoice(): void {}
	get getDates(): string {
		let dates = this.InvoiceDetail.billingPeriodTo.split('T');
		return dates[0];
	}
	get setCurrency(): string {
		let cur = this.InvoiceDetail?.currencyCode || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: this.InvoiceDetail?.currency || 'USD'
		}).format(this.InvoiceDetail?.amount || 0);
		return amount;
	}
	public downloadAsPdf(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.loading = true;
		this.savingPdf = true;
		const width = this.dataToExport.nativeElement.clientWidth;
		const height = this.dataToExport.nativeElement.clientHeight + 40;
		let orientation = '';
		let imageUnit = 'pt';

		domToImage
			.toPng(this.dataToExport.nativeElement, {
				width: width,
				height: height
			})
			.then(result => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				this.loading = false;
				let jsPdfOptions = {
					orientation: 'p',
					unit: imageUnit,
					format: 'a4'
				};
				const pdf = new jsPDF(jsPdfOptions);
				let pwidth = pdf.internal.pageSize.getWidth();
				let pheight = pdf.internal.pageSize.getHeight();
				pdf.addImage(result, 'PNG', 0, 0, pwidth, pheight);
				pdf.save(this.InvoiceDetail.transactionId ? this.InvoiceDetail.transactionId + '.pdf' : 'Untitled dashboard.pdf');

				this.savingPdf = false;
				this.toastService.sendSuccessToast('Your Invoice has been saved successfully.');
			})
			.catch(error => {
				this.savingPdf = false;
				this.sharedStore.dispatch(new HideGlobalSpinner());
				this.loading = false;
				this.toastService.sendErrorToast('Error occurred while saving your Invoice. Please try again later.');
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
