import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HintsService {
	public showSubject$ = new Subject<any>();
	public dismissSubject$ = new Subject<void>();

	constructor() {}
}
