import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ButtonsModule } from 'components';

@Component({
	selector: 'logo-picker',
	template: `
		<div>
			<img
				[class]="!image ? 'img-filter' : ''"
				[src]="image ?? '/assets/icons/creative-builder/image.svg'"
			/>
			<mat-icon *ngIf="image" (click)="onImageRemove()">close</mat-icon>
		</div>
		<button app-upload (click)="onLogoUpload()"></button>
	`,
	styles: [
		`
			@use 'common';

			div {
				position: relative;
				padding: 10px 28px;
				background: #f2f2f2;
				border-radius: 4px;
			}

			img {
				@include common.icon(40px);
				color: #dbdbdb;
			}

			.img-filter {
				filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
					saturate(4653%) hue-rotate(180deg) brightness(107%)
					contrast(72%);
			}

			mat-icon {
				@include common.icon(16px);
				@include common.clickable;
				position: absolute;
				top: 0;
				right: 0;
			}

			:host {
				@include common.row;

				border: 1px solid #dbdbdb;
				border-radius: 4px;

				padding: 10px 16px;
			}
		`
	],
	imports: [ButtonsModule, MatIconModule, CommonModule],
	standalone: true
})
export class LogoPickerComponent implements OnChanges {
	@Input() image?: string;

	@Output() imageRemove = new EventEmitter();
	@Output() uploadClick = new EventEmitter();

	constructor(private sanitizer: DomSanitizer) {}

	ngOnChanges(changes: SimpleChanges): void {}

	onLogoUpload() {
		this.uploadClick.emit();
	}

	onImageRemove() {
		this.imageRemove.emit();
	}
}
