import { Component } from '@angular/core';
import { ColumnComponent } from '../layout';
import { PaginatorComponent } from '../../advanced';

@Component({
	selector: 'table-with-paginator',
	template: `
		<column>
			<column class="table-wrapper">
				<ng-content></ng-content>
			</column>
			<paginator></paginator>
		</column>
	`,
	styles: [
		//language=scss
		`
			@use '../../../assets/styles/table';
			@use 'common';

			:host {
				@include common.paper;
				display: block;
			}

			.table-wrapper {
				@include table.scroll;
			}
		`
	],
	imports: [ColumnComponent, PaginatorComponent],
	standalone: true
})
export class TableWithPaginatorComponent {}
