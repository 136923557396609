<div class="invoice-cont" #mainDiv id="mainDiv">
	<ng-container *ngIf="loading">
		<app-lolly-spinning-loader class="spinner"></app-lolly-spinning-loader>
	</ng-container>

	<ng-container *ngIf="!loading">
		<div class="d-flex justify-content-between">
			<app-primary-button
				[buttonLable]="'Download Invoice'"
				[buttonType]="'Primary'"
				[isPeddingReduce]="true"
				(buttonClicked)="downloadAsPdf()"
			></app-primary-button>

			<button mat-icon-button mat-dialog-close>
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div class="invoice-overview" #dataToExport>
			<div class="invoice-main">
				<div class="d-flex justify-content-between">
					<div class="w-70p">
						<img src="assets/logo/lolly-logo.svg" />
						<filed-font class="mt-14px" *ngIf="description == null" [fontSize]="'14px'" [fontWeight]="'400'">LOLLY</filed-font>
						<filed-font class="mt-14px" *ngIf="description == null" [fontSize]="'14px'" [fontWeight]="'400'">The Old Truman Brewery</filed-font>
						<filed-font class="mt-14px" *ngIf="description == null" [fontSize]="'14px'" [fontWeight]="'400'">91 Brick LaneLondon,</filed-font>
						<filed-font class="mt-14px" *ngIf="description == null" [fontSize]="'14px'" [fontWeight]="'400'">London E1 6QL</filed-font>
						<filed-font class="mt-14px" *ngIf="description == null" [fontSize]="'14px'" [fontWeight]="'400'">United Kingdom</filed-font>
					</div>
					<div class="w-30p">
						<filed-font [fontSize]="'22px'">INVOICE</filed-font>
						<div class="d-flex">
							<filed-font [fontSize]="'14px'">Invoice</filed-font>
							<filed-font [fontSize]="'14px'">
								# {{ InvoiceDetail.externalTransactionId ? InvoiceDetail.externalTransactionId : InvoiceDetail.billingId }}
							</filed-font>
						</div>
						<div class="d-flex">
							<filed-font [fontSize]="'14px'">Invoice Date—</filed-font>
							<filed-font [fontSize]="'14px'">{{ getDates }}</filed-font>
						</div>
						<div class="d-flex">
							<filed-font [fontSize]="'14px'">Invoice Amount—</filed-font>
							<filed-font [fontSize]="'14px'">{{ setCurrency }} ({{ InvoiceDetail.currencyCode }})</filed-font>
						</div>

						<div class="d-flex">
							<filed-font [fontSize]="'14px'">Billing Period—</filed-font>
							<filed-font [fontSize]="'14px'">{{ getDates }}</filed-font>
						</div>

						<filed-font class="paid" [fontSize]="'16px'" *ngIf="InvoiceDetail.status === 'Succeeded'">PAID</filed-font>
						<filed-font [fontSize]="'16px'" class="unpaid" *ngIf="InvoiceDetail.status !== 'Succeeded'">UNPAID</filed-font>
					</div>
				</div>
				<div class="pt-30px d-flex justify-content-between">
					<div class="w-70p">
						<filed-font [fontSize]="'16px'" [fontSize]="'22px'">BILLED TO</filed-font>
						<filed-font [fontSize]="'16px'" [fontSize]="'22px'">{{ InvoiceDetail.firstName }} {{ InvoiceDetail.lastName }}</filed-font>
					</div>
					<div class="w-30p"></div>
				</div>
				<div class="pt-30px">
					<div>
						<div class="d-flex table-header">
							<div class="w-85p header-title">
								<filed-font [fontSize]="'16px'">Item Description</filed-font>
							</div>

							<div class="w-15p header-title">
								<filed-font [fontSize]="'16px'">Amount</filed-font>
							</div>
						</div>
						<div class="d-flex table-data">
							<div class="w-85p header-title">
								<filed-font [fontSize]="'16px'">LOLLY subscription</filed-font>
							</div>

							<div class="w-15p header-title">
								<filed-font [fontSize]="'16px'">{{ setCurrency }}</filed-font>
							</div>
						</div>
					</div>
					<div class="table-footer pt-30px">
						<div class="w-25p d-flex justify-content-between">
							<filed-font [fontSize]="'16px'" [fontSize]="'18px'">Total</filed-font>

							<filed-font [fontSize]="'16px'" [fontSize]="'18px'">{{ setCurrency }}</filed-font>
						</div>
						<div class="w-25p d-flex justify-content-between">
							<filed-font [fontSize]="'16px'" [fontSize]="'18px'">Amount Due</filed-font>
							<filed-font [fontSize]="'16px'" [fontSize]="'18px'" *ngIf="InvoiceDetail.status !== 'Succeeded'">
								({{ InvoiceDetail.currencyCode }}) {{ setCurrency }}
							</filed-font>
							<filed-font [fontSize]="'16px'" [fontSize]="'18px'" *ngIf="InvoiceDetail.status === 'Succeeded'">0.00</filed-font>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-address">
				<filed-font class="mt-14px" *ngIf="description == null" [fontSize]="'14px'" [fontWeight]="'400'">
					Abstract Inc Limited, 91 Brick Lane, London, United Kingdom, E1 6QL, Vat Number GB227405909
				</filed-font>
			</div>
		</div>
	</ng-container>
</div>
