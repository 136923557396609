import { Component } from '@angular/core';

@Component({
	selector: 'button[back]',
	template: `
		<img src="assets/icons/arrows/arrow-left.svg" />
	`,

	styles: [
		//language=scss
		`
			@use 'common';
			:host {
				@include common.clickable;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 5px;
				border: 1px solid #e3e8ef;
				width: 48px;
				height: 48px;
				background: #fff;
			}
		`
	],
	standalone: true
})
export class BackButtonComponent {}
