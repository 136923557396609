import { Component, HostBinding, Input } from '@angular/core';
import { styleClasses } from '../../utils/style-class';

export type TypographyVariant = keyof typeof mapping;
export type TypographyColors =
	| 'primary'
	| 'secondary'
	| 'tertiary'
	| 'accent'
	| 'gray';
export type TypographyTextAlign = 'left' | 'center' | 'right';

const mapping = {
	h1: 'header-h1',
	h2: 'header-h2',
	h3: 'header-h3',
	h4: 'header-h4',
	h24: 'header-24',
	p1: 'paragraph-1',
	p2: 'paragraph-2',
	p3: 'paragraph-3',
	p4: 'paragraph-4',
	p5: 'paragraph-5',
	p12: 'p12'
} as const;

const colors = {
	accent: '--primary-dark-blue'
};

@Component({
	selector: 'text',
	template: `
		<ng-content></ng-content>
	`,
	styles: [
		//language=scss
		`
			@use 'primary-typography' as typography;
			@use 'common';
			@use 'colors';

			:host {
				display: block;

				&.header-h1 {
					@include typography.header-h1;
				}

				&.header-h2 {
					@include typography.header-h2;
				}

				&.header-h3 {
					@include typography.header-h3;
				}

				&.header-h4 {
					@include typography.header-h4;
				}

				&.header-24 {
					@include typography.header-24;
				}

				&.paragraph-1 {
					@include typography.paragraph-1;
				}

				&.paragraph-2 {
					@include typography.paragraph-2;
				}

				&.paragraph-3 {
					@include typography.paragraph-3;
				}

				&.paragraph-4 {
					@include typography.paragraph-4;
				}

				&.paragraph-5 {
					@include typography.paragraph-5;
				}

				&.p12 {
					@include typography.p12;
				}

				&.tertiary {
					color: var(--grey-shade-7);
				}

				&.link {
					color: var(--primary-dark-blue-shade);
				}

				&.disabled-link {
					color: var(--blue-shade-3);
				}

				&.gray {
					color: var(--text-gray);
				}

				&.inline {
					display: inline;
				}

				&.left {
					text-align: left;
				}

				&.center {
					text-align: center;
				}

				&.right {
					text-align: right;
				}

				&.clickable {
					@include common.clickable;
				}
			}
		`
	],
	standalone: true
})
export class UiTextComponent {
	@Input() variant: TypographyVariant = 'p2';
	@Input() color: TypographyColors = 'primary';
	@Input() textAlign: TypographyTextAlign = 'left';
	@Input() disabled?: boolean;

	@HostBinding('class')
	get update() {
		return styleClasses(mapping[this.variant], this.textAlign);
	}

	@HostBinding('class.link')
	get isAccent() {
		return this.color === 'accent';
	}

	@HostBinding('class.secondary')
	get isSecondaryText() {
		return this.color === 'secondary';
	}

	@HostBinding('class.tertiary')
	get isTertiaryText() {
		return this.color === 'tertiary';
	}

	@HostBinding('class.gray') get isGray() {
		return this.color === 'gray';
	}

	@HostBinding('class.disabled-link')
	get isDisabledLink() {
		return this.color === 'accent' && this.disabled;
	}

	@HostBinding('class.inline')
	@Input()
	inline?: boolean;

	@HostBinding('class.clickable')
	@Input()
	clickable?: boolean;
}
