import { BaseModule } from 'components';
import { Component, Input } from '@angular/core';
import { MaterialModule } from '../../../../material.module';

export type SignupPreviewDevice = 'desktop' | 'phone';

@Component({
	selector: 'device-selector',
	template: `
		<div class="selection-box">
			<div class="filler" [style.flex]="gap"></div>
			<div class="selected"></div>
		</div>
		<mat-icon [class]="selected === 'desktop' ? 'active' : undefined">
			laptop
		</mat-icon>
		<mat-icon [class]="selected === 'phone' ? 'active' : undefined">
			smartphone
		</mat-icon>
	`,
	styles: [
		`
			@use 'common';

			mat-icon {
				display: block;
				@include common.icon(15px);
				width: 48px;
				height: 42px;
				user-select: none;
				padding: 12.5px 16px;
				z-index: 2;

				&.active {
					color: white;
					transition: 0.3s;
				}
			}

			:host {
				@include common.row;
				position: relative;
				justify-content: left;

				flex: 0;

				border: 1px solid #e3e8ef;
				border-radius: 4px;
				overflow: clip;
			}

			.filler {
				flex: 1;
				transition: 0.3s;
				height: 100%;
			}

			.selected {
				background: #035dd9;
				width: 48px;
				height: 42px;
			}

			.selection-box {
				display: flex;
				position: absolute;
				top: 0;
				left: 0;

				width: 100%;
				height: 100%;
				z-index: 1;
			}
		`
	],
	imports: [BaseModule, MaterialModule],
	standalone: true
})
export class PreviewDeviceSelectorComponent {
	@Input()
	selected: SignupPreviewDevice = 'desktop';

	get gap() {
		return this.selected === 'desktop' ? 0 : 1;
	}
}
