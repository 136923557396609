import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from '../../../../../projects/workshop/src/app/docs/pages/banner/banner.component';
import { OfferComponent } from './component-pieces/offer/offer.component';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { MaterialModule } from 'src/app/material.module';
import { SmiReusedModule } from '../../smi-reused.module';
import { NavCardsComponent } from './component-pieces/nav-cards/nav-cards.component';
import { WalkthroughDashboardComponent } from './component-pieces/walkthrough-dashboard/walkthrough-dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AllCampaignsComponent } from './components/landing-page/all-campaigns/all-campaigns.component';
import { PayPerPostComponent } from './components/landing-page/pay-per-post/pay-per-post.component';
import { PerformacedBasedComponent } from './components/landing-page/performance-based/performance-based.component';
import { UgcComponent } from './components/landing-page/ugc-component/ugn.component';
import { WelcomeScreenComponent } from './components/landing-page/welcome-screen/welcome-screen.component';
import { SearchBarModule } from './components/landing-page/search-bar/search-bar.module';
import { BaseModule } from 'components';

@NgModule({
	declarations: [
		LandingPageComponent,
		WalkthroughDashboardComponent,
		NavCardsComponent,
		AllCampaignsComponent,
		PayPerPostComponent,
		PerformacedBasedComponent,
		UgcComponent,
		WelcomeScreenComponent
	],
	imports: [
		CommonModule,
		BaseModule,
		LandingPageRoutingModule,
		MaterialModule,
		SmiReusedModule,
		SharedModule,
		SearchBarModule,
		OfferComponent,
		BannerComponent
	]
})
export class LandingPageModule {}
