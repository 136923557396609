import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-all-campaigns',
	templateUrl: './all-campaigns.component.html',
	styleUrls: ['./all-campaigns.component.scss']
})
export class AllCampaignsComponent implements OnInit {
	@Input() data: any;

	constructor() {}

	ngOnInit(): void {}
}
