import { Component, HostBinding, Input, OnInit, Optional } from '@angular/core';
import {
	FormControl,
	NonNullableFormBuilder,
	ReactiveFormsModule
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FieldService } from './field.service';

@Component({
	selector: 'textarea[app]',
	template: ``,
	styles: [
		`
			:host {
				font-family: inherit;
				font-size: 14px;
				line-height: 17px;

				border: 1px solid #d9d9d9;
				border-radius: 5px;
				resize: none;
				outline: none;

				width: 100%;
				height: 100%;
				padding: 14px 10px;

				&.invalid {
					border: 1px solid red;
				}

				&:focus {
					border: 1px solid #d9d9d9;
				}
			}
		`
	],
	host: {
		'[formControl]': 'control'
	},
	imports: [MatInputModule, ReactiveFormsModule],
	standalone: true
})
export class TextareaComponent implements OnInit {
	@Input() label?: string;
	@Input() placeholder?: string;
	control?: FormControl<string> = new FormControl('default value', {
		nonNullable: true
	});

	public isDestinationFocused?: boolean;
	public errorMessage?: string;

	fieldServiceNotNull = false;
	serviceProvidedControl = false;

	constructor(@Optional() private fieldService: FieldService<string>) {
		this.fieldService?.controlUpdates.subscribe(control => {
			this.control = control;
		});
	}

	@HostBinding('class.invalid')
	get invalid() {
		return this.control?.touched && this.control?.invalid;
	}

	ngOnInit(): void {}
}
