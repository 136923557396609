import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'input[app-email]',
	template: ``,
	styles: [``],
	imports: [],
	standalone: true
})
export class EmailInputComponent {
	@HostBinding('class.custom-input-name') get inputClass() {
		return true;
	}
}
