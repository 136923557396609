import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { SignupSettingsAccordionComponent } from './accordion';
import { FormControl } from '@angular/forms';
import { InputComponent } from 'components';

@Component({
	selector: 'terms-accordion',
	template: `
		<signup-settings-accordion
			title="Terms and conditions"
			[expanded]="expanded"
			(toggle)="toggle.emit()"
		>
			<input-app
				title="Link"
				placeholder="URL"
				[control]="control"
			></input-app>
		</signup-settings-accordion>
	`,
	imports: [
		MatExpansionModule,
		CommonModule,
		InputComponent,
		SignupSettingsAccordionComponent
	],
	standalone: true
})
export class TermsComponent {
	@Input() expanded?: boolean;

	@Input() control = new FormControl<string>('');

	@Output() toggle = new EventEmitter<void>();
}
