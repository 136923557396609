import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'search-bar-inner-text',
	templateUrl: './search-bar-inner-text.component.html',
	styleUrls: ['./search-bar-inner-text.component.scss']
})
export class SearchBarInnerTextComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
