import { I } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CountryDropdownValueInterface } from 'src/app/shared/models/country-dropdown-value.interface';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { FieldValidators } from 'src/app/user-management/models/validators';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { TokenService } from 'src/app/_services/token.service';
import { CompleteModalComponent } from '../../../modals/complete-modal/complete-modal.component';
import { VerifyAddUserComponent } from '../../../modals/verify-add-user/verify-add-user.component';
import { TokenDetailInterface } from 'src/app/_models/token.interface';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { TimeZoneService } from 'src/app/shared/services/time-zone.service';
import { ValidatorMessages } from 'src/app/shared-components/models/validator-messages.interface';

@Component({
	selector: 'app-failed-payment-notification',
	templateUrl: './failed-payment-notification.component.html',
	styleUrls: ['./failed-payment-notification.component.scss']
})
export class FailedPaymentNotificationComponent implements OnInit {
	public accountForm: UntypedFormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public userId: string;
	public decodeJwtToken: UserDetailsInterface;
	public validationMessages: ValidatorMessages[];
	public toggleCheckStatus: boolean = false;
	public isFirstTime: boolean = true;

	public toggleControl: UntypedFormControl;

	public defaultCountryCode = 'GB';
	public selectedCountry: CountryDropdownValueInterface;

	constructor(
		public dialog: MatDialog,
		public backOfficeService: BackOfficeService,
		private formBuilder: UntypedFormBuilder,
		private token: TokenService,
		private sharedStore: Store<SharedState>,
		private toaster: ToastNotificationService,
		private timeZoneService: TimeZoneService
	) {
		this.selectedCountry = {
			code: 'GB',
			dialcode: '+44'
		};
		const code = this.timeZoneService.getCountryCode();
		const dialCode = this.timeZoneService.getCountryDialCode();
		this.selectedCountry.code = code;
		this.defaultCountryCode = code;
		this.selectedCountry.dialcode = dialCode;
	}

	ngOnInit(): void {
		this.decodeJwtToken = JSON.parse(localStorage.getItem('decodedJwtIo')) as UserDetailsInterface;
		this.createForm();
		this.formChanges();
		this.getFailedPaymentStatus();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public getFailedPaymentStatus(): void {
		this.token
			.getPaymentFailureStatus()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(detail => {
				this.isFirstTime = detail;
			});
	}

	private createForm(): void {
		this.toggleControl = new UntypedFormControl(false);
		this.accountForm = this.formBuilder.group({
			dialcode: new UntypedFormControl(''),
			phone: new UntypedFormControl('', [
				Validators.minLength(7),
				Validators.required,
				Validators.maxLength(11),
				Validators.pattern(FieldValidators.Phone)
			])
		});
		this.accountForm.get('phone').disable();
	}

	public formChanges(): void {
		this.accountForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
		this.accountForm.controls['phone'].updateValueAndValidity();
		this.accountForm.controls['dialcode'].updateValueAndValidity();
	}

	public onSelectCountry(event: CountryDropdownValueInterface): void {
		this.selectedCountry = event;
		const dialCodeNumber = this.selectedCountry?.dialcode.replace(/^\D+/g, '');

		this.accountForm.controls['phone'].setValidators(Validators.maxLength(15 - dialCodeNumber.length));

		const dropdownCountryCode = this.accountForm.get('dialcode');
		if (this.selectedCountry?.dialcode !== undefined) {
			dropdownCountryCode.setValidators(null);
		}

		if (this.selectedCountry?.dialcode === undefined) {
			dropdownCountryCode.setValidators([Validators.required]);
		}

		this.accountForm.controls['dialcode'].setValue(this.selectedCountry.dialcode);
		dropdownCountryCode.updateValueAndValidity();
		if (this.accountForm.get('dialcode').valid) {
		}
	}

	public checkForInvalid(): void {
		const NDCandSN = this.accountForm.get('phone').value;
		const dialCodeNumber = this.selectedCountry?.dialcode.replace(/^\D+/g, '');
		if (dialCodeNumber === undefined) {
			this.accountForm.controls['phone'].setErrors({ dialCode: true });
		}

		if (NDCandSN !== null) {
			if (dialCodeNumber === undefined) {
				this.accountForm.controls['phone'].setErrors({ dialCode: true });
			}

			if (isNaN(Number(NDCandSN))) {
				this.accountForm.controls['phone'].setErrors({ numbersOnly: true });
			}

			if ((NDCandSN.toString() === '' || NDCandSN.toString().length < 7) && dialCodeNumber !== undefined) {
				this.accountForm.controls['phone'].setErrors({ phoneMinLength: true });
			}

			if (dialCodeNumber !== undefined && NDCandSN.toString().length > 7) {
				if (NDCandSN.toString().length + dialCodeNumber.length > 15) {
					this.accountForm.controls['phone'].setErrors({ phoneMaxLength: true });
				}
			}
		}
	}

	public onCheckToggle(): void {
		if (!this.toggleCheckStatus) {
			if (this.isFirstTime) {
				this.accountForm.controls['phone'].setValue('');
			}
			this.accountForm.disable();
		} else {
			this.accountForm.enable();
		}
	}

	public openConfirmModal(): void {
		const dialogRef = this.dialog.open(CompleteModalComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Text Alert',
			text: 'You have successfully turned on “Text Alert”. You will receive a text from us to confirm your number.',
			closeBtn: false,
			actionBtn: false
		};
		dialogRef.afterClosed().subscribe(() => {});
	}

	public onEnableConfirm(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());

		const phoneNumber = this.accountForm.controls['dialcode'].value + this.accountForm.controls['phone'].value;
		let formData = new FormData();
		formData.append('phone_number', phoneNumber);
		this.token
			.addContactToken(formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				detail => {
					if (detail) {
						const dialogRef = this.dialog.open(VerifyAddUserComponent, {
							width: '520px',
							height: '480px',
							disableClose: true
						});
						dialogRef.componentInstance.modelDetail = {
							title: 'Get a text when payments fail?',
							text: 'A message has sent to the provided phone number. Please enter 4 digit code to enable this service.',
							closeBtn: true,
							actionBtn: true,
							actionBtnText: 'CONFIRM',
							useOtp: true,
							otp: {
								secret: false,
								count: 6
							}
						};
						dialogRef.afterClosed().subscribe(details => {
							if (details.status) {
								this.confirmToken(phoneNumber, details.code);
							}
						});
					}
				},
				() => this.toaster.sendErrorToast('Error occured while sending you an sms. Please try again later!'),
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public confirmToken(phoneNumber: string, code: string): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		let formData = new FormData();
		formData.append('phone_number', phoneNumber);
		formData.append('code', code);
		this.token
			.verifyContactToken(formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				detail => {
					if (detail) {
						this.enablePaymentSms(this.isFirstTime);
					}
				},
				() => this.toaster.sendErrorToast('Error occured while sending you an sms. Please try again later!'),
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public enablePaymentSms(isToenable: boolean): void {
		this.token
			.enablePaymentConfirmToken({ IsEnabled: isToenable })
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				detail => {
					if (detail) {
						this.openConfirmModal();
					}
				},
				() => this.toaster.sendErrorToast('Error occured while sending you an sms. Please try again later!'),
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}
}
