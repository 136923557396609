import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LollyLoadingDialogService } from 'src/app/shared-components/lolly-loading-dialog/lolly-loading-dialog.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { GenericInputDataModel } from 'src/app/shared-components/models/generic.interface';
import { UtilsService } from 'src/app/shared/utils';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-pixel-integrations',
	templateUrl: './pixel-integrations.component.html',
	styleUrls: ['./pixel-integrations.component.scss']
})
export class PixelIntegrationsComponent implements OnInit {
	@Input() public ecommerceIntegrationsData: any;
	@Input() public pixelClientId: string;
	public loading: boolean = false;
	public isPanelOpen: boolean = true;
	public selectedTab: string = 'auto'; // auto or manual
	public domainControl = new UntypedFormControl('');
	public pixelManualCode: string = '';

	public ecommerceCards: GenericInputDataModel[] = [
		{
			id: 1,
			name: 'Shopify',
			value: 'shopify',
			image: 'assets/icons/shopify-icon.svg',
			is_pixel_installed: false,
			domain: ''
		},
		{
			id: 2,
			name: 'BigCommerce',
			value: 'bigcommerce',
			image: 'assets/icons/bigcommerce-icon.svg',
			is_pixel_installed: false,
			domain: ''
		}
	];
	public integratedDomains: string[] = [];
	private unsubs$ = new Subject<void>();

	constructor(
		private clipboard: Clipboard,
		private sharedService: SharedService,
		private toast: ToastNotificationService
	) {}

	ngOnInit(): void {
		this.pixelManualCode = UtilsService.getPixelManualCode(
			this.pixelClientId
		);
		if (this.ecommerceIntegrationsData?.length) {
			this.ecommerceCards.forEach(ecommerceCard => {
				const matchedIntegrationEl =
					this.ecommerceIntegrationsData.find(
						integrationEl =>
							ecommerceCard.value === integrationEl.alias
					);
				if (matchedIntegrationEl != undefined) {
					ecommerceCard.connected = true;
					ecommerceCard.is_pixel_installed =
						matchedIntegrationEl.is_pixel_installed;
					ecommerceCard.domain = matchedIntegrationEl.domain;
				}
			});
		}
		this.ecommerceIntegrationsData.forEach(integratedEl => {
			if (integratedEl.is_pixel_installed) {
				this.integratedDomains.push(integratedEl.domain);
			}
		});
	}

	public changeTab(tab: string): void {
		this.selectedTab = tab;
	}

	public copyTag(): void {
		this.clipboard.copy(this.pixelManualCode);
		this.toast.sendSuccessToast('Tag copied to clipboard!');
	}

	public onCardClick(card: GenericInputDataModel): void {
		this.sharedService
			.installPixelPlatform(card.value)
			.pipe(takeUntil(this.unsubs$))
			.subscribe(
				res => {
					if (res?.is_success) {
						this.toast.sendSuccessToast(res.message);
						this.integratedDomains.push(card.domain);
					} else {
						this.toast.sendErrorToast(res.message);
					}
				},
				err => {
					this.toast.sendErrorToast(err?.error?.message);
				}
			);
	}

	public verifyPixelInstall() {
		this.sharedService
			.preinstallPixelPlatform({
				domain: this.domainControl.value,
				client_id: this.pixelClientId
			})
			.pipe(takeUntil(this.unsubs$))
			.subscribe(
				res => {
					window
						.open(`${this.domainControl.value}?vge=true`, '_blank')
						.focus();
				},
				err => {
					if (err.status != 403) {
						this.toast.sendErrorToast(err?.error?.message);
					}
				}
			);
	}

	public changePanelState(value: boolean): void {
		this.isPanelOpen = value;
	}

	ngOnDestroy(): void {
		this.unsubs$.next();
		this.unsubs$.complete();
	}
}
