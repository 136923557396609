import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import {
	AgreementMetadata,
	BrandingSettingsService,
	ButtonComponent,
	CheckboxState,
	ColumnComponent,
	Fields,
	InputBrandingSettings,
	RowComponent,
	ServerBrandingSettings,
	TextComponent,
	unsafeKeys
} from 'components';
import { LogoPickerAccordion } from './logo/logo-accordion';
import { TitleAndDescriptionComponent } from './title-and-description';
import {
	SignupFieldControl,
	SignUpFieldsComponent
} from './custom-fields/sign-up-fields';
import { ButtonsSettingsAccordion } from './buttons/buttons-settings';
import { AgreementAccordion } from './agreement/agreement';
import { TermsComponent } from './terms';
import { FileSelectionService } from '../../../services/file-selection.service';
import { NonNullableFormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';

const files = {
	logo: { accept: ['.png', '.jpg'] },
	favicon: { accept: ['.png', '.jpg'] }
};

@Component({
	selector: 'signup-settings',
	template: `
		<column [paper]="true">
			<text variant="h2">Sign Up Customisation</text>
			<logo-accordion
				[expanded]="activeAccordion === 'logo'"
				[logo]="logoUrl"
				[favicon]="faviconUrl"
				(toggle)="activeAccordion = 'logo'"
				(logoReset)="onLogoReset()"
				(logoRemove)="onLogoRemove()"
				(faviconRemove)="onFaviconRemove()"
				(logoUpload)="onLogoUploadClick()"
				(faviconUpload)="onFaviconUploadClick()"
			></logo-accordion>
			<title-and-description
				(toggle)="activateAccordion('title')"
				[expanded]="activeAccordion === 'title'"
				[text]="controls.title"
				[description]="controls.description"
			></title-and-description>
			<signup-fields-accordion
				(toggle)="activateAccordion('fields')"
				[expanded]="activeAccordion === 'fields'"
				[form]="controls.fields"
				(fieldSave)="onFieldSave()"
			></signup-fields-accordion>
			<button-settings-accordion
				(toggle)="activateAccordion('buttons')"
				[expanded]="activeAccordion === 'buttons'"
				[buttonColorControl]="controls.primaryButtonColor"
				[textColorControl]="controls.primaryButtonTextColor"
				[buttonTextControl]="controls.buttonText"
				(reset)="onButtonReset()"
			></button-settings-accordion>
			<agreement-accordion
				(toggle)="activateAccordion('agreement')"
				[expanded]="activeAccordion === 'agreement'"
				[agreementMetadata]="agreementMetadata"
				(selectionCancel)="onAgreementSelect(undefined)"
				(fileSelect)="onAgreementSelect($event)"
			></agreement-accordion>
			<terms-accordion
				(toggle)="activateAccordion('terms')"
				[expanded]="activeAccordion === 'terms'"
				[control]="controls.termsAndConditionsUrl"
			></terms-accordion>
			<row>
				<button app (click)="onSave()">Save</button>
			</row>
		</column>
	`,
	providers: [FileSelectionService],
	styles: [
		`
			column {
				padding: 20px;
			}

			row {
				justify-content: end;
			}
		`
	],
	imports: [
		TextComponent,
		LogoPickerAccordion,
		TitleAndDescriptionComponent,
		SignUpFieldsComponent,
		ButtonsSettingsAccordion,
		AgreementAccordion,
		TermsComponent,
		ColumnComponent,
		RowComponent,
		ButtonComponent
	],
	standalone: true
})
export class SignupSettingsComponent implements OnChanges {
	@Input() defaultValue?: ServerBrandingSettings;

	@Output() formChange = new EventEmitter<InputBrandingSettings>();
	@Output() logoChange = new EventEmitter<string>();

	@Output() save = new EventEmitter<InputBrandingSettings>();

	logo?: File;
	favicon?: File;

	agreement?: File;
	agreementMetadata?: AgreementMetadata;

	logoUrl?: string;
	faviconUrl?: string;

	activeAccordion?: string;

	form = this.builder.group({
		title: this.builder.control('Text'),
		description: this.builder.control('Description'),
		primaryButtonColor: this.builder.control('#FFFFFF'),
		primaryButtonTextColor: this.builder.control('#000000'),
		buttonText: this.builder.control('Signup'),
		termsAndConditionsUrl: this.builder.control(''),
		fields: this.builder.array<SignupFieldControl>([]),
		removeFields: this.builder.control([] as string[])
	});

	get controls() {
		return this.form.controls;
	}

	url(file?: File, oldUrl?: string): string | undefined {
		if (oldUrl) {
			URL.revokeObjectURL(oldUrl);
		}
		if (file) {
			const blobUrl = URL.createObjectURL(file);
			return this.sanitizer.bypassSecurityTrustUrl(blobUrl) as string;
		}
	}

	constructor(
		private builder: NonNullableFormBuilder,
		private sanitizer: DomSanitizer,
		private fileService: FileSelectionService<typeof files>,
		private brandingService: BrandingSettingsService,
		private toast: ToastNotificationService
	) {
		this.fileService.initDef(files);

		this.fileService.fileInput('logo').fileSelect.subscribe(file => {
			this.logo = file;
			this.onLogoChange(file);
			this.unremoveField('logo');
		});

		this.fileService.fileInput('favicon').fileSelect.subscribe(file => {
			this.favicon = file;
			this.onFaviconChange(file);
			this.unremoveField('favicon');
		});

		this.subscribeToForm();
	}

	subscribeToForm() {
		this.form.valueChanges.subscribe(() => {
			const newSettings = this.prepareBrandingSettings();
			this.formChange.emit(newSettings);
		});
	}

	unremoveField(field: string) {
		const val = this.form.controls.removeFields.value;

		const newVal = val.filter(f => f !== field);
		this.form.controls.removeFields.setValue(newVal);
	}

	onLogoChange(logo: File) {
		if (logo.size > 512 * 1024 * 1024) {
			this.toast.sendErrorToast('Logo too large');
			return;
		}
		const safeUrl = this.url(logo, this.logoUrl);
		this.logoUrl = safeUrl;

		this.formChange.emit({
			logo: logo
		});

		this.logoChange.emit(safeUrl);
	}

	onFaviconChange(favicon: File) {
		if (favicon.size > 512 * 1024 * 1024) {
			this.toast.sendErrorToast('Favicon too large');
			return;
		}
		this.faviconUrl = this.url(favicon, this.faviconUrl);
	}

	onAgreementSelect(file: File | undefined) {
		if (file && file.size > 512 * 1024 * 1024) {
			this.toast.sendErrorToast('Logo too large');
			return;
		}
		if (!file) {
			this.agreement = undefined;
			this.agreementMetadata = undefined;
			return;
		}
		const url = this.url(file, this.agreementMetadata?.url);
		this.agreement = file;
		this.agreementMetadata = {
			url: url ?? '',
			name: file.name,
			size: file.size
		};
	}

	ngOnChanges(changes: SimpleChanges) {
		const change = changes['defaultValue'];
		const logo = changes['logo'];
		const favicon = changes['favicon'];

		if (change && change.currentValue) {
			const defaults = change.currentValue as ServerBrandingSettings;
			this.form.reset({
				title: defaults.title ?? '',
				description: defaults.description ?? '',
				primaryButtonColor: defaults.primary_button_color ?? '#FFFFFF',
				primaryButtonTextColor:
					defaults.primary_button_text_color ?? '#000000',
				buttonText: defaults.button_text ?? 'Signup',
				termsAndConditionsUrl: defaults.terms_and_conditions_url ?? ''
			});

			this.form.controls.fields.reset();
			for (const field of defaults.branding_fields) {
				this.form.controls.fields.push(
					this.builder.group({
						appEnabled: field.enabled
							? ('on' as CheckboxState)
							: ('off' as CheckboxState),
						name: field.title as string,
						id: field.id as number | undefined
					})
				);
			}

			if (defaults.agreement_metadata) {
				this.agreementMetadata = defaults.agreement_metadata;
			}

			if (defaults.logo_url) {
				this.logoUrl = defaults.logo_url;
			}

			if (defaults.favicon_url) {
				this.faviconUrl = defaults.favicon_url;
			}
		}

		if (logo && logo.currentValue) {
		}

		if (favicon && favicon.currentValue) {
			const faviconFile = favicon.currentValue as File;

			this.faviconUrl = this.url(faviconFile, this.faviconUrl);
		}
	}

	activateAccordion(selectedAccordion: string) {
		this.activeAccordion = selectedAccordion;
	}

	onLogoUploadClick() {
		this.fileService.fileInput('logo').openFileDialog();
	}

	onFaviconUploadClick() {
		this.fileService.fileInput('favicon').openFileDialog();
	}

	onLogoReset() {
		this.logo = undefined;
		this.favicon = undefined;
		this.logoUrl = this.defaultValue!.logo_url;
		this.faviconUrl = this.defaultValue!.favicon_url;
		this.logoChange.emit(this.logoUrl);
	}

	onLogoRemove() {
		this.logo = undefined;
		this.logoUrl = undefined;

		this.formChange.emit({
			logo: undefined
		});

		this.logoChange.emit(undefined);
		this.form.controls.removeFields.setValue([
			...this.form.controls.removeFields.value,
			'logo'
		]);
	}

	onFaviconRemove() {
		this.favicon = undefined;
		this.faviconUrl = undefined;
	}

	onButtonReset() {
		this.form.patchValue({
			buttonText: this.defaultValue?.button_text,
			primaryButtonColor: this.defaultValue?.primary_button_color,
			primaryButtonTextColor: this.defaultValue?.primary_button_text_color
		});
	}

	prepareBrandingFields() {
		// check for
	}

	prepareBrandingSettings() {
		const fileInputs: Fields<
			InputBrandingSettings,
			File | null | undefined
		>[] = ['logo', 'favicon', 'agreement'];

		const stringInputs: Fields<
			InputBrandingSettings,
			string | undefined
		>[] = [
			'title',
			'description',
			'primaryButtonColor',
			'primaryButtonTextColor',
			'buttonText',
			'termsAndConditionsUrl'
		];

		const newSettings: InputBrandingSettings = {};

		for (const fileInput of fileInputs) {
			if (fileInput && this[fileInput]) {
				newSettings[fileInput] = this[fileInput];
			}
		}

		for (const stringInput of stringInputs) {
			if (
				stringInput &&
				this.controls[stringInput] &&
				this.controls[stringInput].dirty
			) {
				newSettings[stringInput] = this.controls[stringInput].value;
			}
		}

		if (this.controls.fields.dirty) {
			newSettings.brandingFields = this.controls.fields.controls.map(
				control => ({
					id: control.value.id,
					enabled: control.value.appEnabled === 'on',
					title: control.value.name ?? 'Default Title'
				})
			);
		}

		if (this.controls.removeFields.value.length > 0) {
			newSettings.removeFields = this.controls.removeFields
				.value as (keyof Omit<InputBrandingSettings, 'removeFields'>)[];
		}

		if (unsafeKeys(newSettings).length === 0) {
			return;
		}

		return newSettings;
	}

	onSave() {
		if (this.agreement == null && this.agreementMetadata == null) {
			this.toast.sendErrorToast('Affiliate agreement must be provided');
			return;
		}

		if (!this.form.controls.termsAndConditionsUrl.value) {
			this.toast.sendErrorToast('Terms and conditions must be specified');
			return;
		}

		const newSettings = this.prepareBrandingSettings();

		this.save.emit(newSettings);
	}

	onFieldSave() {
		this.form.controls.fields.markAsDirty();
		const newSettings = this.prepareBrandingSettings();
		this.formChange.emit(newSettings);
	}
}
