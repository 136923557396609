import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignupSettingsAccordionComponent } from '../accordion';
import {
	AgreementMetadata,
	FileDragAndDropComponent,
	RowComponent,
	TextComponent
} from 'components';
import { MatIconModule } from '@angular/material/icon';
import { FileSelectionService } from '../../../../services/file-selection.service';

const files = {
	pdf: {
		accept: ['.pdf', '.doc']
	}
};

@Component({
	selector: 'agreement-accordion',
	template: `
		<signup-settings-accordion
			title="Affiliate Agreement"
			[expanded]="expanded"
			(toggle)="toggle.emit()"
		>
			<text>
				To add / remove the affiliate agreement checkbox, please go to
				Manage agreements page and enable / disable an agreement for
				this campaign.
			</text>

			<file-picker
				(file)="onFile($event)"
				(fileSelect)="onFileSelect()"
				(close)="selectionCancel.emit()"
				[selectedFile]="agreementMetadata"
			></file-picker>
			<row>
				<div></div>
			</row>
		</signup-settings-accordion>
	`,
	styles: [
		`
			button {
				padding-left: 16px;
			}
		`
	],
	providers: [FileSelectionService],
	imports: [
		SignupSettingsAccordionComponent,
		TextComponent,
		FileDragAndDropComponent,
		RowComponent,
		MatIconModule
	],
	standalone: true
})
export class AgreementAccordion {
	@Input() expanded?: boolean;

	@Input() agreementMetadata?: AgreementMetadata;

	@Output() fileSelect = new EventEmitter<File>();
	@Output() selectionCancel = new EventEmitter<void>();
	@Output() reset = new EventEmitter<void>();
	@Output() toggle = new EventEmitter<void>();

	selectedFile?: File;

	constructor(
		private fileSelectionService: FileSelectionService<typeof files>
	) {
		fileSelectionService.initDef(files);
	}

	onFile(event: File) {
		this.selectedFile = event;
		this.fileSelect.emit(event);
	}

	onFileSelect() {
		const pdf = this.fileSelectionService.fileInput('pdf');
		pdf.fileSelect.subscribe(file => this.onFile(file));
		pdf.openFileDialog();
	}

	get resetDisabled() {
		return !this.selectedFile;
	}

	onReset() {
		this.selectedFile = undefined;
	}
}
