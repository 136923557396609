<div class="dashboard-container">
	<!-- !overview -->
	<div class="overview">
		<ng-container>
			<div class="brand-title">
				<span *ngIf="!loading">
					{{
						dashboradData?.company
							? 'Hello ' + dashboradData?.company
							: 'Hello '
					}}
				</span>
				<ngx-skeleton-loader
					*ngIf="loading"
					[theme]="{
						height: '28px',
						width: '200px',
						borderRadius: '5px'
					}"
					style="height: 28px; width: 100%"
				></ngx-skeleton-loader>
			</div>
			<div class="brand-subtitle">
				What would you like to do?
				<!-- <a href="https://lolly.zohodesk.in/portal/en/kb/articles/welcome-to-lolly" target="_blank">Learn More</a> -->
			</div>
		</ng-container>
	</div>

	<column>
		<landing-search-bar
			(searchRequested)="onDiscoverySearchRequested($event)"
		></landing-search-bar>

		<lolly-lite-banner
			*ngIf="
				trialPeriodData?.is_on_trial && !trialPeriodData?.with_cc_plan
			"
		></lolly-lite-banner>
	</column>
	<div class="body-cards-grid">
		<ng-container *ngFor="let card of cardsV1">
			<div class="card-item" (click)="routeTo(card.value)">
				<div class="card-header">
					<img class="card-icon" [src]="card.icon" />
					<div class="d-flex flex-column">
						<h6 class="card-title">{{ card.name }}</h6>
						<p>{{ card.desc }}</p>
					</div>
				</div>
				<div class="card-body">
					<img class="card-back" [src]="card.image" />
				</div>
			</div>
		</ng-container>
	</div>

	<div class="mid-cards">
		<div class="card-ele" (click)="onAdvSearchClick()">
			<div class="left">
				<h4>Advance Search</h4>

				<div class="d-flex flex-column" [style.gap]="'4px'">
					<h6>Let our AI find you the perfect influencers!</h6>
					<p>
						We will make a curated list that fits perfectly for you
					</p>
				</div>
			</div>

			<div class="right">
				<img src="assets/images/map-graphic.svg" />
			</div>
		</div>

		<div class="card-ele">
			<div class="left between">
				<div class="d-flex flex-column" [style.gap]="'8px'">
					<h4>Book a demo</h4>

					<div class="d-flex flex-column" [style.gap]="'4px'">
						<h6>Need help? Dont worry, just speak to an expert.</h6>
						<p>Our team will contact you and guide you</p>
					</div>
				</div>

				<button
					class="primary-btn w-fit"
					[style.padding-inline]="'24px'"
					(click)="onBookADemoClick()"
				>
					Book a demo
				</button>
			</div>

			<div class="right">
				<img src="assets/landing-page/purple-calendar.svg" />
			</div>
		</div>
	</div>

	<div class="footer-card" (click)="onEcommerceClick()">
		<div class="name">E-Commerce</div>
		<div class="value">
			Get ready to unlock new opportunities for growth and engagement by
			linking your e-commerce store with Lolly!
		</div>
	</div>
</div>
