import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'subscription-downgrade-warning',
	templateUrl: './subscription-downgrade-warning.component.html',
	styleUrls: ['subscription-downgrade-warning.component.scss']
})
export class SubscriptionDowngradeWarningComponent {
	@Input()
	planName = '...';

	@Input()
	cancellationDate = '01-01-2025';

	@Output()
	cancel = new EventEmitter();
}
