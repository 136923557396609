import { NgModule } from '@angular/core';
import { InfluencerInputFieldComponent } from './influencer-input-field.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { LollyInputComponent } from './lolly-input.component';
import { InputComponent } from './input';

export { InfluencerInputFieldComponent, InputComponent, InputSearchComponent };

const importExport = [
	InfluencerInputFieldComponent,
	LollyInputComponent,
	InputComponent,
	InputSearchComponent
];

@NgModule({
	imports: importExport,
	exports: importExport
})
export class InputModule {}

export * from './lolly-input.component';
export * from './textarea.component';
export * from './textarea-field.component';
export * from './field.component';
export * from './email-input';
