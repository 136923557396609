import { TextComponent, TypographyColors1 } from './text.component';
import { Component } from '@angular/core';

@Component({
	selector: 'app-link',
	template: `
		<ng-content></ng-content>
	`,
	styles: [
		//language=scss
		`
			@use '../../../../../src/assets/styles/common';

			:host {
				@include common.clickable;
			}
		`
	],
	standalone: true
})
export class LinkComponent extends TextComponent {
	override color: TypographyColors1 = 'accent';
	override inline = true;
}
