import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { snakeCase } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { singleRequest, smiUrl, unsafeKeys } from '../../utils';
import {
	InputBrandingSettings,
	ServerBrandingSettings
} from './branding-settings.dto';

@Injectable({
	providedIn: 'root'
})
export class BrandingSettingsService {
	unsubscribe = {
		get: new Subject<void>(),
		post: new Subject<void>()
	};

	loading = {
		get: false,
		post: false
	};

	constructor(private http: HttpClient) {}

	get baseUrl() {
		return smiUrl().add('branding-settings');
	}

	getBrandingSettings(): Observable<ServerBrandingSettings> {
		return singleRequest(
			this.http
				.get<ServerBrandingSettings>(this.baseUrl.done())
				.pipe(takeUntil(this.unsubscribe.get))
				.pipe(),
			[this.loading, 'get'],
			this.unsubscribe.get
		);
	}

	getInfluencerBrandingSettings(
		brandId: number
	): Observable<ServerBrandingSettings> {
		return singleRequest(
			this.http.get<ServerBrandingSettings>(
				this.baseUrl.add('brand').query('brand_id', brandId).done()
			),
			[this.loading, 'get'],
			this.unsubscribe.get
		);
	}

	postBrandingSettings(brandingSettings: InputBrandingSettings) {
		this.unsubscribe.post.next();

		const url = this.baseUrl.done();

		const data = this.prepareFormData(brandingSettings);

		return singleRequest(
			this.http.post(url, data),
			[this.loading, 'post'],
			this.unsubscribe.post
		);
	}

	prepareFormData(brandingSettings: InputBrandingSettings) {
		const data = new FormData();

		const keys = unsafeKeys(brandingSettings);

		for (const key of keys) {
			const value = brandingSettings[key];

			if (value instanceof File) {
				data.set(snakeCase(key), value, value.name);
			} else if (typeof value === 'string') {
				data.set(snakeCase(key), value);
			} else {
				if (key === 'brandingFields') {
					data.set('branding_fields', JSON.stringify(value));
				}
			}
		}

		return data;
	}
}
