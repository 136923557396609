import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, forkJoin, throwError } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { SubUserV2 } from 'src/app/user-management/models/sub-user.interface';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { AddNewTeamComponent } from './add-new-team/add-new-team.component';
import { TitleCasePipe } from '@angular/common';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
	selector: 'app-user-teams',
	templateUrl: './user-teams.component.html',
	styleUrls: ['./user-teams.component.scss']
})
export class UserTeamsComponent implements OnInit, OnDestroy {
	public loading = false;
	private unsubscriber$ = new Subject<void>();
	public isSubUser: boolean = true;
	public currentUserIndex: number = 0;
	public subUsers: SubUserV2[] = [];
	public admin = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
	public token: string = localStorage.getItem(StorageKey.token);
	public userEmail = this.admin.sub;
	public planDetails;
	public userLimitExceed = false;

	constructor(
		public titleCasePipe: TitleCasePipe,
		public dialog: MatDialog,
		private userService: UserServiceApi,
		private sharedService: SharedService,
		private toastService: ToastNotificationService
	) {}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}

	ngOnInit(): void {
		const decodedJwt = JSON.parse(
			localStorage.getItem(StorageKey.decodedJwtIo)
		);
		this.isSubUser = decodedJwt?.sub_user == true ? true : false;
		this.initData();
	}

	private initData(): void {
		this.listSubUser();
	}

	private getSubuserInfo(): void {
		this.loading = true;
		this.userService
			.getAllSubUsers()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.handleSubusersResponse(res?.data);
					this.loading = false;
				},
				err => {
					this.loading = false;
				}
			);
	}

	private handleSubusersResponse(res): void {
		this.subUsers = res;
		this.currentUserIndex = this.subUsers.findIndex(
			x => x?.id == this.admin?.user_filed_id
		);
	}

	private handleCreditUsageData(res): void {
		this.planDetails = res;
		if (
			this.planDetails?.users?.total === undefined ||
			this.planDetails?.users?.total === null
		) {
			this.planDetails.users.total = 10000000;
		}
	}

	public listSubUser(): void {
		this.loading = true;
		forkJoin([
			this.userService.getAllSubUsers(),
			this.sharedService.getPlanDetails()
		])
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => throwError(error))
			)
			.subscribe(
				(res: any[]) => {
					this.handleSubusersResponse(res[0]?.data);
					this.handleCreditUsageData(res[1]?.data);
					this.loading = false;
				},
				err => {
					this.loading = false;
					this.toastService.sendErrorToast(
						'Something went wrong, Sub Users could not be Fetched'
					);
				}
			);
	}

	public getTitleCaseName(str: string): string {
		let updatedStr = str.replace(/_/g, ' ');
		updatedStr = this.titleCasePipe.transform(updatedStr);
		return updatedStr;
	}

	public openCreateModal(data = {} as SubUserV2): void {
		let modalConfig;
		modalConfig = {
			width: '870px',
			height: '325px',
			minHeight: '325px',
			hasBackdrop: true
		};
		const dialogRef = this.dialog.open(AddNewTeamComponent, {
			...modalConfig,
			panelClass: 'modal-wrapper',
			data: {
				isEdit: Boolean(Object.keys(data).length),
				user: data
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res?.reload && res?.reload === true) {
				this.initData();
			}
		});
	}

	public removeSubUser(user: SubUserV2): void {
		this.loading = true;
		this.userService
			.deleteSubUser(user.id)
			.pipe(take(1))
			.subscribe(
				res => {
					this.toastService.sendSuccessToast(
						'User Removed Successfully!'
					);
					this.initData();
				},
				err => {
					this.loading = false;
					this.toastService.sendErrorToast('Some Error Occurred!');
				}
			);
	}
}
