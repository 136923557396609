import { BaseApiUrl } from '../../_services/base-api-urls';
import { PaginationQuery } from '../../_models/pagination';

/**
 * Example:
 *
 * ```ts
 * 	const url = smiUrl()
 * 		.add('campaigns')
 * 		.add(campaignId)
 * 		.pagination()
 * 		.done()
 * ```
 */
export class UrlBuilder {
	url: string;
	hadQuery = false;

	constructor() {
		this.url = BaseApiUrl.SocialInfluencerPython.substring(
			0,
			BaseApiUrl.SocialInfluencerPython.length - 1
		);
	}

	add(path: string | number): UrlBuilder {
		this.url += '/' + String(path);
		return this;
	}

	query(key: string, value: string | number | undefined) {
		if (value) {
			const encodedKey = encodeURIComponent(key);
			const encodedValue = encodeURIComponent(String(value));
			this.querySeparator();
			this.url += `${encodedKey}=${encodedValue}`;
		}
		return this;
	}

	querySeparator() {
		this.url += this.hadQuery ? '&' : '?';
		this.hadQuery = true;
		return this;
	}

	/**
	 * this function expects 0-based pages
	 *
	 * server expects 1-based page numbers
	 */
	pagination(pagination?: PaginationQuery): UrlBuilder {
		if (pagination) {
			this.query('page_size', pagination.size).query(
				'page_number',
				pagination.page + 1
			);
		}

		return this;
	}

	public trailingSlash() {
		if (this.url.endsWith('/')) {
			return this;
		}
		this.url += '/';
		return this;
	}

	done(): string {
		return this.url;
	}
}

export function smiUrl(): UrlBuilder {
	return new UrlBuilder();
}
