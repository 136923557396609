import { Component, Inject, OnInit } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { GenericDialogDataModel } from './model/generic-dialog-data.model';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
@Component({
	selector: 'app-generic-sm-dialog',
	templateUrl: './generic-sm-dialog.component.html',
	styleUrls: ['./generic-sm-dialog.component.scss'],
	imports: [MatIconModule, MatDialogModule, NgTemplateOutlet, CommonModule],
	standalone: true
})
export class GenericSmDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<GenericSmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: GenericDialogDataModel
	) {}

	ngOnInit(): void {}

	public getImgHovered(imgSrc: string | undefined): string {
		if (imgSrc != null) {
			return imgSrc.replace('black', 'white');
		}
		return '';
	}

	public primaryAction(): void {
		this.dialogRef.close({ primaryAction: 'true' });
	}

	public secondaryAction(): void {
		this.dialogRef.close({ secondaryAction: 'true' });
	}

	public alternativeAction(): void {
		this.dialogRef.close({ alternativeAction: 'true' });
	}
}
