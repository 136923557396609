import { Component, EventEmitter, Output } from '@angular/core';
import { ColumnComponent, RowComponent, UiTextComponent } from 'components';
import { BannerLeftComponent } from './banner-left';
import { BannerRightPanel } from './banner-right';

@Component({
	selector: 'lolly-lite-fullscreen',
	template: `
		<banner-left></banner-left>
		<banner-right
			(bookDemo)="bookDemo.emit()"
			(viewPricing)="viewPricing.emit()"
			(close)="close.emit()"
		></banner-right>
	`,
	styles: [
		`
			@use 'common';

			:host {
				@include common.row;

				width: 100%;
				height: 100%;
			}

			video {
				width: 100%;
				height: auto;
				max-width: 100%;
			}

			row {
				gap: 12px;
				flex-wrap: wrap;
			}

			column {
				gap: 12px;
			}
		`
	],
	imports: [
		UiTextComponent,
		RowComponent,
		ColumnComponent,
		BannerRightPanel,
		BannerLeftComponent
	],
	standalone: true
})
export class LollyLiteFullscreenComponent {
	@Output() bookDemo = new EventEmitter<void>();
	@Output() viewPricing = new EventEmitter<void>();
	@Output() close = new EventEmitter<void>();
}
