import {
	AfterContentInit,
	AfterViewInit,
	Component,
	EventEmitter,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'generic-dropdown',
	templateUrl: './generic-dropdown.component.html',
	styleUrls: ['./generic-dropdown.component.scss'],
	host: {
		'(click)': 'onDropdownClick()'
	}
})
export class GenericDropdownComponent {
	dropdownOpened = false;

	onDropdownClick() {
		this.dropdownOpened = true;
	}

	onBackgroundClick(event: MouseEvent) {
		this.dropdownOpened = false;
		event.stopPropagation();
	}

	onPanelClick(event: MouseEvent) {
		event.stopPropagation();
	}
	closeDropdown() {
		this.dropdownOpened = false;
	}

	platform = new FormControl('0');

	json(obj: any) {
		return JSON.stringify(obj);
	}
}
