<div class="cancel-subs-root">
	<div class="sp-card what-to-lose-wrapper">
		<div class="left">
			<h3>What will you be losing</h3>

			<ul class="losing-list d-flex flex-column">
				<li>
					<img src="assets/icons/green-checkbox.svg" />
					<div class="value">
						Access to exclusive content and features
					</div>
				</li>
				<li>
					<img src="assets/icons/green-checkbox.svg" />
					<div class="value">
						Regular updates and improvements
					</div>
				</li>
				<li>
					<img src="assets/icons/green-checkbox.svg" />
					<div class="value">
						Priority customer support
					</div>
				</li>
				<li>
					<img src="assets/icons/green-checkbox.svg" />
					<div class="value">
						Special discounts and offers
					</div>
				</li>
			</ul>
		</div>

		<div class="right">
			<!-- <h3>What our users say about us</h3>

            <div class="w-100 d-flex k-align-items-center" [style.gap]="'16px'">
                <img src="https://source.unsplash.com/random/?portrait,happy,colorful">
                <img src="https://source.unsplash.com/random/?portrait,happy,colorful">
                <img src="https://source.unsplash.com/random/?portrait,happy,colorful">
                <img src="https://source.unsplash.com/random/?portrait,happy,colorful">
            </div> -->
		</div>
	</div>

	<div class="sp-card feedback-wrapper">
		<div class="d-flex flex-column" [style.gap]="'8px'">
			<h3>We value your feedback</h3>

			<div class="value">
				Before you go, we’d love to hear your thoughts. Your feedback
				helps us improve our service. Please take a moment to share your
				opinions with us
			</div>
		</div>

		<div class="divider"></div>

		<div class="d-flex k-align-items-center">
			<div class="flex-1 d-flex k-align-items-center">
				<h3>
					How would you rate your overall experience with our product?
				</h3>
			</div>

			<div class="stars flex-1">
				<ng-container *ngFor="let i of [1, 2, 3, 4, 5]">
					<mat-icon
						(click)="updateStarCount(i)"
						class="star-icon {{
							i <= form.get('stars_count').value ? 'selected' : ''
						}}"
					>
						star
					</mat-icon>
				</ng-container>
			</div>
		</div>

		<div class="divider"></div>

		<div class="d-flex">
			<div class="d-flex flex-1">
				<h3>What led you to cancel your subscription?</h3>
			</div>

			<mat-radio-group
				class="flex-1 radio-list"
				[formControl]="form.get('reason_to_cancel')"
			>
				<ng-container *ngFor="let item of reasons">
					<mat-radio-button
						class="black-radio-button"
						[value]="item.name"
					>
						{{ item.name }}
					</mat-radio-button>
				</ng-container>
			</mat-radio-group>
		</div>

		<div class="divider"></div>

		<div class="d-flex">
			<div class="d-flex flex-1">
				<h3>Would you consider returning if we fix your issues?</h3>
			</div>

			<mat-radio-group
				class="flex-1 radio-list"
				[formControl]="form.get('will_return')"
			>
				<ng-container *ngFor="let item of booleanList">
					<mat-radio-button
						class="black-radio-button"
						[value]="item.name"
					>
						{{ item.name }}
					</mat-radio-button>
				</ng-container>
			</mat-radio-group>
		</div>

		<div class="divider"></div>

		<div class="d-flex k-align-items-center">
			<div class="d-flex flex-1">
				<h3>Any specific feedback or suggestion? (Optional)</h3>
			</div>

			<input
				type="text"
				class="custom-input-name flex-1"
				[formControl]="form.get('suggestion')"
			/>
		</div>
	</div>

	<div class="sp-card reminder-wrapper" [style.gap]="'16px'">
		<h3>Reminder email</h3>

		<div
			class="w-100 d-flex k-align-items-center k-justify-content-between"
		>
			<div class="value">
				Would you like us to send you a reminder email about returning
				to the application after a month?
			</div>

			<mat-radio-group
				class="radio-list as-row"
				[formControl]="form.get('reminder_email')"
			>
				<ng-container *ngFor="let item of booleanList">
					<mat-radio-button
						class="black-radio-button"
						[value]="item.name"
					>
						{{ item.name }}
					</mat-radio-button>
				</ng-container>
			</mat-radio-group>
		</div>
	</div>
</div>
