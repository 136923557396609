import { NgModule } from '@angular/core';
import { FileDragAndDropComponent } from './file-drag-and-drop';
import { NoSelectedFileComponent } from './no-selected-file.component';
import { PaginatorComponent } from './paginator.component';
import { SelectedFileComponent } from './selected-file.component';
import { TabSelectorComponent } from './tab-selector.component';

const importExport = [
	FileDragAndDropComponent,
	NoSelectedFileComponent,
	PaginatorComponent,
	SelectedFileComponent,
	TabSelectorComponent
];

@NgModule({
	imports: importExport,
	exports: importExport
})
export class AdvancedModule {}

export * from './file-drag-and-drop';
export * from './no-selected-file.component';
export * from './paginator.component';
export * from './selected-file.component';
export * from './tab-selector.component';
