import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { GenericInputDialogComponent } from 'src/app/shared-components/generic-input-dialog/generic-input-dialog.component';
import { EcommerceService } from 'src/app/social-media-influencer/modules/ecommerce-v2/services/ecommerce.service';
import { ToastNotificationService } from '../toast-notification/toast-notification.service';
import { ConfirmPopupService } from './confirm-popup.service';

@Injectable({
	providedIn: 'root'
})
export class EcommerceImplService {
	constructor(
		private matDialog: MatDialog,
		private ecommerceService: EcommerceService,
		private toast: ToastNotificationService,
		private confirmPopupService: ConfirmPopupService
	) {}

	public connectToBigcommerceFlow(): void {
		let matDialogData = {
			title: 'BigCommerce integration',
			subtitle: 'BigCommerce Email',
			primaryButton: 'Continue',
			placeholder: 'Enter email'
		};
		let matDialogConfig: MatDialogConfig = {
			panelClass: 'center-dialog-no-shadow',
			minHeight: '221px',
			width: '434px',
			backdropClass: 'light-backdrop',
			data: matDialogData
		};
		let dialogRef = this.matDialog.open(
			GenericInputDialogComponent,
			matDialogConfig
		);
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe(res => {
				if (res && res?.name) {
					this.connectToBigcommerce(res.name);
				}
			});
	}

	private connectToBigcommerce(email): void {
		this.ecommerceService
			.connectBigcommerce(email)
			.pipe(take(1))
			.subscribe(
				result => {
					window.location.replace(result.url);
				},
				err => {
					this.toast.sendErrorToast(
						'Something went wrong. Try again later'
					);
				}
			);
	}

	public connectToShopifyFlow(): void {
		let matDialogData = {
			title: 'Connect Shopify Store',
			subtitle: 'Shop URL',
			placeholder: 'your-store-name',
			suffixText: '.myshopify.com',
			primaryButton: 'Continue'
		};
		let matDialogConfig: MatDialogConfig = {
			panelClass: 'center-dialog-no-shadow',
			backdropClass: 'light-backdrop',
			minHeight: '221px', // Default Height For 1366px Resolution
			width: '472px',
			data: matDialogData
		};
		let dialogRef = this.matDialog.open(
			GenericInputDialogComponent,
			matDialogConfig
		);
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe(res => {
				if (res && res?.name) {
					res.name = res.name + '.myshopify.com';
					this.connectToShopify(res.name);
				}
			});
	}

	private connectToShopify(shop: string): void {
		this.ecommerceService
			.connectShopify(shop)
			.pipe(take(1))
			.subscribe(
				res => {
					window.location.replace(res.url);
				},
				err => {
					this.toast.sendErrorToast(
						'Something went wrong. Try again later'
					);
				}
			);
	}

	public disconnectEcommercePlatformFlow(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.confirmPopupService
				.showPopup(
					'Are you sure you want to disconnect account ?',
					'Yes',
					'No'
				)
				.then(res => {
					if (res?.primaryAction === 'true') {
						this.ecommerceService
							.deleteEcommerceUserIntegration()
							.pipe(take(1))
							.subscribe(
								res => {
									this.toast.sendSuccessToast(res.message);
									resolve({ response: res, reload: true });
								},
								err => {
									reject(err);
									this.toast.sendErrorToast(
										err?.error?.details
									);
								}
							);
					} else {
						reject();
					}
				});
		});
	}

	private disconnectEcommercePlatform(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.ecommerceService
				.deleteEcommerceUserIntegration()
				.pipe(take(1))
				.subscribe(
					res => {
						this.toast.sendSuccessToast(res.message);
						resolve(res);
					},
					err => {
						reject(err);
						this.toast.sendErrorToast(err?.error?.details);
					}
				);
		});
	}
}
