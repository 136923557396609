import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-walkthrough-dashboard',
	templateUrl: './walkthrough-dashboard.component.html',
	styleUrls: ['./walkthrough-dashboard.component.scss']
})
export class WalkthroughDashboardComponent implements OnInit {
	@ViewChild('videoPlayer') videoPlayer: ElementRef;
	public name: string = '';
	public isFirstTimePlay: boolean = true;

	constructor(public dialogRef: MatDialogRef<WalkthroughDashboardComponent>, @Inject(MAT_DIALOG_DATA) public data: { name?: string }) {
		this.name = data?.name;
	}

	ngOnInit(): void {}

	public closeDialog(flag: boolean): void {
		this.dialogRef.close({ response: flag });
	}

	public async togglePlayPause() {
		if (this.isFirstTimePlay) {
			this.isFirstTimePlay = false;
		}
		const video: HTMLVideoElement = this.videoPlayer.nativeElement;

		if (video.paused) {
			await video.play();
		} else {
			await video.pause();
		}
	}

	public openInNewTab(url: string): void {
		window.open(url, '_blank');
	}
}
