import { Component, HostBinding, Input } from '@angular/core';

export const columnHostStyle = `
	:host {
  		display: flex;
		gap: 1rem;
		flex-direction: column;
	}
`;

@Component({
	selector: 'column',
	template: '<ng-content></ng-content>',
	styles: [
		columnHostStyle,
		//language=scss
		`
			@use 'common';

			:host.paper {
				@include common.paper;
			}
		`
	],
	standalone: true
})
export class ColumnComponent {
	@HostBinding('class.paper')
	@Input()
	paper?: boolean;

	// @Input() gap: number = 1;
	//
	// @HostBinding('style.gap')
	// get styleGap() {
	// 	return `${this.gap}rem`;
	// }
}
