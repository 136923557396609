import { CommonModule } from '@angular/common';
import {
	AccordionComponent,
	AccordionContentContainer,
	AccordionTitleContainer
} from './accordion/accordion';
import { NgModule } from '@angular/core';
import { ButtonsModule } from './button';
import { CheckboxComponent } from './checkbox.component';
import { DividerComponent } from './divider.component';
import { DropdownComponent } from './dropdowns/dropdown.component';
import { LayoutModule } from './layout';
import { LinkComponent } from './link.component';
import { LoadersModule } from './loaders';
import { TableModule } from './table';
import { TextComponent } from './text.component';
import { TooltipsModule } from './tooltips';

const importExports = [
	ButtonsModule,
	LayoutModule,
	TooltipsModule,
	TextComponent,
	CheckboxComponent,
	LinkComponent,
	DividerComponent,
	DropdownComponent,
	LoadersModule,
	TableModule,
	CommonModule,
	AccordionComponent,
	AccordionTitleContainer,
	AccordionContentContainer
];
@NgModule({
	imports: importExports,
	exports: importExports
})
export class BaseModule {}

export * from './button';
export * from './inputs';
export * from './checkbox.component';
export * from './divider.component';
export * from './dropdowns/dropdown.component';
export * from './loaders';
export * from './table';
export * from './tooltips';
export * from './layout';
export * from './accordion/accordion';
export * from './text.component';
export * from './link.component';
