import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
	FormControl,
	NonNullableFormBuilder,
	ReactiveFormsModule,
	UntypedFormControl
} from '@angular/forms';
import { unsafeKeys } from '../../../utils';
import { FieldErrorComponent } from './field-error.component';
import { FieldService } from './field.service';
import { TextareaComponent } from './textarea.component';

@Component({
	selector: 'field',
	template: `
		<ng-content></ng-content>
		<error>{{ errorText }}</error>
	`,
	styles: [
		`
			:host {
				display: flex;
				flex-direction: column;
			}

			error {
				min-height: 1.4em;
			}
		`
	],
	providers: [FieldService],
	imports: [
		ReactiveFormsModule,
		TextareaComponent,
		CommonModule,
		FieldErrorComponent
	],
	standalone: true
})
export class FieldComponent<
	T extends {
		[key: string]: string;
	},
	ControlValue
> implements OnInit
{
	@Input() control: FormControl<ControlValue> = new UntypedFormControl();
	@Input() errors?: T;

	constructor(
		private field: FieldService<ControlValue>,
		private builder: NonNullableFormBuilder
	) {}

	ngOnInit(): void {
		this.field.setControl(this.control);
	}

	get error() {
		return this.control.touched && this.control.errors !== null;
	}

	get errorText(): string | undefined {
		if (this.error) {
			const errorKeys = unsafeKeys(this.control.errors!);
			return this.errors?.[errorKeys[0]];
		}
	}
}
