<div
	*ngIf="isToastVisible"
	class="toast-container"
	[ngClass]="{
		'toast-info': toastModel.type === toastTypes.Info,
		'toast-error': toastModel.type === toastTypes.Error,
		'toast-warning': toastModel.type === toastTypes.Warning,
		'toast-success': toastModel.type === toastTypes.Success
	}"
>
	<div class="notification-content">
		<mat-icon class="close-btn" (click)="dismiss()">close</mat-icon>
		<i-tooltip [text]="'LOL'"></i-tooltip>
		<div class="content-toast">
			<h6 class="content-title">
				{{ toastModel.customTitle }}
			</h6>
			<p class="content-desc">{{ toastModel.message }}</p>
		</div>
	</div>
</div>
