import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	BaseModule,
	CheckboxComponent,
	CheckboxState,
	InfluencerInputFieldComponent
} from '../../base';
import { InputCustomBrandingField } from '../../../services';
import {
	FormArray,
	FormControl,
	FormGroup,
	NonNullableFormBuilder,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { LinkComponent } from '../../base/link.component';
import { unsafeKeys } from '../../../utils';

export type SignupFormConfig = {
	logoUrl: string;

	title: string;
	description: string;

	button: {
		color: string;
		textColor: string;
		text: string;
	};

	termsAndConditions?: string;

	fields: InputCustomBrandingField[];
};

export type SignupForm = FormGroup<{
	first_name: FormControl<string>;
	last_name: FormControl<string>;
	email: FormControl<string>;
	password: FormControl<string>;
	terms: FormControl<CheckboxState>;
	affiliate: FormControl<CheckboxState>;
	additionalFields: FormArray<FormControl<string>>;
}>;

@Component({
	selector: 'signup-form',
	template: `
		<column>
			<img *ngIf="config.logoUrl" [src]="config.logoUrl" />
			<text variant="h1">{{ config.title }}</text>
			<text variant="p3">{{ config.description }}</text>
		</column>

		<row>
			<signup-input
				[inputControl]="form.controls.first_name"
				placeholder="First Name"
				[autocomplete]="autocomplete"
				[errorMsg]="!valid('first_name') ? required() : undefined"
			></signup-input>
		</row>

		<signup-input
			[inputControl]="form.controls.last_name"
			placeholder="Last Name"
			[autocomplete]="autocomplete"
			[errorMsg]="!valid('last_name') ? required() : undefined"
		></signup-input>
		<signup-input
			[inputControl]="form.controls.email"
			placeholder="Email Address"
			[autocomplete]="autocomplete"
			[errorMsg]="!valid('email') ? required() : undefined"
		></signup-input>
		<signup-input
			[inputControl]="form.controls.password"
			type="password"
			placeholder="Create Password"
			[autocomplete]="autocomplete"
			[errorMsg]="!valid('password') ? getPasswordErrorText() : undefined"
		></signup-input>

		<signup-input
			*ngFor="let field of enabledFields; index as i"
			[placeholder]="field.title"
			[autocomplete]="autocomplete"
			[inputControl]="form.controls.additionalFields.at(i) ?? null"
			[errorMsg]="
				this.submitted && !form.controls.additionalFields.at(i).valid
					? required()
					: undefined
			"
		></signup-input>

		<column class="checkbox-container">
			<row>
				<checkbox [formControl]="form.controls.terms"></checkbox>
				<text>
					I acknowledge that I have thoroughly read and fully accept
					the
					<app-link>
						<a
							target="_blank"
							[href]="'//' + config.termsAndConditions"
						>
							Terms and Conditions
						</a>
					</app-link>
					.
				</text>
			</row>
			<text *ngIf="submitted && form.controls.terms.errors != null">
				Required
			</text>
		</column>

		<column class="checkbox-container">
			<row>
				<checkbox [formControl]="form.controls.affiliate"></checkbox>
				<text>
					I agree to receive email notifications (like when I earn a
					commission) and other important emails regarding the
					affiliate program
				</text>
			</row>
			<text *ngIf="submitted && form.controls.affiliate.errors != null">
				Required
			</text>
		</column>

		<button
			app
			(click)="onSignupClick()"
			[style.background]="config.button.color"
			[style.color]="config.button.textColor"
		>
			{{ config.button.text }}
		</button>

		<text class="bottom-text" variant="p3">
			Already have an account?
			<app-link (click)="loginClick.emit()">Login</app-link>
		</text>
	`,
	styles: [
		//language=scss
		`
			@use '../../../../../../src/assets/styles/common';

			:host {
				@include common.column;
				gap: 1rem;
				width: 400px;
			}

			column {
				align-items: center;
			}

			button {
				margin-top: 1rem;
			}

			.link {
				@include common.clickable;
			}

			.bottom-text {
				align-self: center;
			}

			.checkbox-container {
				gap: 0;
				align-items: start;

				& > text {
					color: #fc4a4a;
				}
			}

			signup-input {
				flex: 1;
			}

			img {
				@include common.icon(40px);
			}
		`
	],
	imports: [
		BaseModule,
		CommonModule,
		InfluencerInputFieldComponent,
		CheckboxComponent,
		ReactiveFormsModule,
		LinkComponent,
		InfluencerInputFieldComponent
	],
	standalone: true
})
export class SignupFormComponent implements OnChanges {
	@Input() config!: SignupFormConfig;
	@Input() form: SignupForm = this.builder.group({
		first_name: this.builder.control(''),
		last_name: this.builder.control(''),
		email: this.builder.control(''),
		password: this.builder.control(''),
		terms: this.builder.control<CheckboxState>(
			'off',
			Validators.pattern('on')
		),
		affiliate: this.builder.control<CheckboxState>(
			'off',
			Validators.pattern('on')
		),
		additionalFields: this.builder.array([] as string[])
	});

	@Input() submitted?: boolean;
	@Input() autocomplete = true;

	@Output() loginClick = new EventEmitter<void>();
	@Output() signup = new EventEmitter<
		Partial<{
			first_name: string;
			last_name: string;
			email: string;
			password: string;
			additionalFields: string[];
		}>
	>();

	constructor(private builder: NonNullableFormBuilder) {}

	get enabledFields() {
		return this.config.fields.filter(field => field.enabled);
	}

	get controls() {
		return this.form.controls;
	}

	valid(key: keyof SignupForm['controls']) {
		return !this.submitted || this.form.controls[key].valid;
	}

	public getPasswordErrorText(): string | undefined {
		const password = this.form.controls.password;

		const a = {
			required: 'Please enter a password',
			noSmallCase:
				'Password must contain at least one lowercase character',
			noCapitalCase:
				'Password must contain at least one uppercase character',
			noNumber: 'Password must contain at least one number',
			noSpecialCharacters:
				'Password must contain at least one special character',
			minLength: 'Password must be at least 8 characters long',
			maxLength: 'Password must at most be 50 characters long'
		} as const;

		for (const key of unsafeKeys(a)) {
			if (password.hasError(key)) {
				return a[key];
			}
		}
		return;
	}

	required() {
		return 'This field is required';
	}

	ngOnChanges(changes: SimpleChanges) {}

	onSignupClick() {
		this.signup.emit(this.form.value);
	}
}
