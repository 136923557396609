import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'th[app]',
	template: `
		<ng-content></ng-content>
	`,
	styles: [
		//language=scss
		`
			@use 'common';

			:host {
				@include common.ellipsis;
				@include common.cell-border(20px);

				height: 40px;
				padding: 0 16px;
				color: #4a4a4a;
				font-weight: 600;
				vertical-align: middle;
				border-bottom: 1px solid #dbdbdb;

				&.center {
					text-align: center;
				}
			}
		`
	],
	standalone: true
})
export class HeaderComponent {
	@Input() align: 'left' | 'center' = 'left';

	@HostBinding('class.center')
	private get isCenter() {
		return this.align === 'center';
	}
}
