import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignupSettingsAccordionComponent } from './accordion';
import { FormControl, NonNullableFormBuilder } from '@angular/forms';
import { BaseModule, InputComponent } from 'components';

@Component({
	selector: 'title-and-description',
	template: `
		<signup-settings-accordion
			title="Title and Description"
			[expanded]="expanded"
			(toggle)="toggle.emit()"
		>
			<input-app
				title="Sign up title"
				placeholder="Let's Get Started"
				[control]="text"
			></input-app>
			<input-app
				title="Sign up description"
				placeholder="You'll be up & running in 2 minutes"
				[control]="description"
			></input-app>
		</signup-settings-accordion>
	`,
	imports: [SignupSettingsAccordionComponent, BaseModule, InputComponent],
	styles: [
		`
			@use 'common';
			:host {
				@include common.row;
			}
		`
	],
	standalone: true
})
export class TitleAndDescriptionComponent implements OnInit {
	@Input() expanded?: boolean;

	@Input() text!: FormControl<string>;
	@Input() description!: FormControl<string>;

	@Output() toggle = new EventEmitter<void>();

	constructor(private builder: NonNullableFormBuilder) {}

	ngOnInit() {
		if (!this.text || !this.description) {
			console.warn(
				'no form controls are provided to title-and-description accordion\nSwitching to self-controlled mode'
			);
			if (this.text == null) {
				this.text = this.builder.control('');
			}
			if (this.description == null) {
				this.description = this.builder.control('');
			}
		}
	}
}
