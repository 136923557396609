import { Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'plan-advantage',
	template: `
		<mat-icon>check</mat-icon>
		<div class="text">{{ advantage }}</div>
	`,
	styles: [
		`
			@use 'common';
			:host {
				@include common.row;
				justify-content: start;
				gap: 8px;
				padding: 8px 0px;

				mat-icon {
					@include common.icon(24px);
				}

				&.divider {
					border-bottom: 1px solid #dedede;
				}

				.text {
					font-size: 14px;
					font-weight: 500;
					line-height: 20px; /* 142.857% */
				}
			}
		`
	],
	imports: [MatIconModule],
	standalone: true
})
export class PlanAdvantage {
	@HostBinding('class.divider')
	@Input()
	divider?: boolean = true;

	@Input() advantage?: string;
}
