import { Component } from '@angular/core';
import { UiTextComponent, RowComponent, ColumnComponent } from 'components';
import { BannerRightPanel } from './banner-right';
import { BannerTag } from './banner-tag';
import { CompanyChipComponent } from './company-chip';

@Component({
	selector: 'banner-left',
	template: `
		<column>
			<text variant="h1">Unlock Lolly's Full Potential</text>
			<text>
				Discover powerful features that transform influencer
				marketing—book your demo or upgrade now!
			</text>
			<div class="video-container">
				<video
					controls
					[controlsList]="['noremoteplayback']"
					poster="assets/images/patrick_fullscreen.jpg"
					src="https://s3.us-east-2.amazonaws.com/lolly-influence.com/demo_lolly.mp4"
				></video>
			</div>
			<column>
				<row [left]="true">
					<banner-tag>Real-Time Campaign Insights</banner-tag>
					<banner-tag>Largest Global Creator Database</banner-tag>
				</row>
				<row [left]="true">
					<banner-tag>Automated Outreach & CRM</banner-tag>
					<banner-tag>Performance-Based Campaigns</banner-tag>
				</row>
			</column>
		</column>
		<row gap="'24px'">
			<company-chip
				text="Google"
				imageUrl="assets/icons/companies/google.svg"
			></company-chip>
			<company-chip
				text="Microsoft"
				imageUrl="assets/icons/companies/microsoft.svg"
			></company-chip>
			<company-chip
				text="Apple"
				imageUrl="assets/icons/companies/apple.svg"
			></company-chip>
			<company-chip
				text="Airbnb"
				imageUrl="assets/icons/companies/airbnb.svg"
			></company-chip>
		</row>
	`,
	styles: [
		`
			@use 'common';

			:host {
				@include common.column;
				padding-top: 60px;
				padding-left: 60px;
				padding-right: 60px;
				padding-bottom: 60px;
				height: 100%;
				gap: 8px;
				justify-content: space-between;
			}

			.video-container {
				flex-shrink: 1;
				display: flex;
				align-items: center;
				justify-content: center;

				margin: 32px 0px;
				object-fit: contain;
				border-radius: 10px;
				border: 2px solid black;
			}

			video {
				border-radius: 7px;
				flex: 1; /* Makes the video grow to fill space */
				max-width: 100%; /* Ensures it doesn’t overflow */

				max-height: 480px;

				object-fit: cover;
			}

			row {
				gap: 12px;
				flex-wrap: wrap;
			}

			column {
				gap: 12px;
			}
		`
	],
	imports: [
		UiTextComponent,
		BannerTag,
		RowComponent,
		ColumnComponent,
		BannerRightPanel,
		CompanyChipComponent
	],
	standalone: true
})
export class BannerLeftComponent {}
