import { Component } from '@angular/core';
import { ButtonComponent, ButtonVariant } from './button.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'button[app-reset]',
	template: `
		<mat-icon>restart_alt</mat-icon>
		Reset
	`,
	styleUrls: ['./button.scss'],
	imports: [MatIconModule],
	standalone: true
})
export class ResetButtonComponent extends ButtonComponent {
	variant: ButtonVariant = 'secondary';
}
