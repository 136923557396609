import { Component, Input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgIf } from '@angular/common';
import { LollySpinnerLoaderComponent } from './lolly-spinning-loader/lolly-spinning-loader.component';

@Component({
	selector: 'loader',
	template: `
		<ng-container *ngIf="loading; else content">
			<app-lolly-spinning-loader></app-lolly-spinning-loader>
		</ng-container>

		<ng-template #content>
			<ng-content></ng-content>
		</ng-template>
	`,
	styles: [
		`
			:host {
				width: 100%;
				height: 100%;
				flex: 1;
			}
		`
	],
	imports: [NgxSkeletonLoaderModule, NgIf, LollySpinnerLoaderComponent],
	standalone: true
})
export class LoaderComponent {
	@Input() loading?: boolean;
}
