import { BaseApiUrl } from '../services';
import { UrlBuilder } from './url-builder';

export function smiUrl(): UrlBuilder {
	const baseUrl = BaseApiUrl.SocialInfluencerPython.substring(
		0,
		BaseApiUrl.SocialInfluencerPython.length - 1
	);
	return new UrlBuilder(baseUrl);
}
