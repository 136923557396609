import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PreviewButtonComponent } from './preview-button.component';

import { BaseModule, SignupFormComponent, SignupFormConfig } from 'components';

@Component({
	selector: 'signup-preview',
	template: `
		<row>
			<preview-button (click)="previewRequest.emit()"></preview-button>
		</row>

		<signup-form [config]="config" [autocomplete]="false"></signup-form>
	`,
	styles: [
		`
			row {
				padding-top: 16px;
				padding-right: 16px;
				justify-content: right;
				width: 100%;
				preview-button {
					flex: 0;
				}
			}

			:host {
				height: 100%;
				display: flex;
				flex-direction: column;
				flex: 1;
				align-items: center;
				justify-content: center;
				padding-bottom: 50px;
			}
		`
	],
	imports: [BaseModule, PreviewButtonComponent, SignupFormComponent],
	standalone: true
})
export class SignupPreviewComponent {
	@Input() config!: SignupFormConfig;

	@Output() previewRequest = new EventEmitter<void>();
}
